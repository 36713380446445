<div>
  <h5 class="fnt-size18px text-heading-grey mb-3">{{'SUSPECT_MED_INFO' | translate}}</h5>
  <form [formGroup]="suspectMedInfoForm">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{(checkForServierProcess() ? "MEDIC_LIST" :"DRUG_NAME") | translate}}
              <span class="text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input (keyup)="userInput('drugName', $event)" formControlName="drugName" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_DRUG' | translate}}" [maxlength]="100" />
          </div>
          <div *ngIf="form.drugName.touched && form.drugName.invalid" class="text-danger fnt-size12px">
            {{"DRUG_NAME" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="" class="fnt-size14px">{{'DRUGS_STRENGTH' | translate}}<span class="d-none text-danger">*</span>
          </label>
          <div class="row no-gutters border rounded">
            <div class="col-8 border-right">
              <input type="number" class="form-control fnt-size14px border-0" name="drugStrength"
                formControlName="drugStrength" placeholder="{{'ENTER_STRENGTH' | translate}}" dngxDigitOnly/>
            </div>
            <div class="col-4">
              <select class="form-control rounded-left-none fnt-size14px border-0"
                formControlName="drugStrengthUnit">
                <option *ngFor="let dsu of drugStrengthUnitList" [value]="dsu.name"
                  [selected]="dsu.name === form.drugStrengthUnit?.value" class="mt-5">
                  {{dsu.name}}
                </option>
              </select>
            </div>
          </div>
          <div *ngIf="form.drugStrength.touched && form.drugStrength.invalid" class="text-danger fnt-size12px">
            {{"ONLY_NUMBERS_ALLOWED" | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"ADMINISTRATION_ROUTE" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input *ngIf="!checkForServierProcess()" formControlName="administrationRoute" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_ADMINISTRATION_ROUTE' | translate}}" [maxlength]="100" />
            <select class="form-control fnt-size12px fnt_Medium bg-porcelain-white" *ngIf="checkForServierProcess()"
            formControlName="administrationRoute">
            <option value='' disabled selected class="d-none">
              {{'SELECT_ADMINISTRATION_ROUTE' | translate}}
            </option>
            <option *ngFor="let form of administrationRouteList" [value]="form.name"
              [selected]="form.name === form.administrationRoute?.value" class="mt-5">
              {{form.name}}
            </option>
          </select>
          </div>
          <div *ngIf="form.administrationRoute.touched && form.administrationRoute.invalid"
            class="d-none text-danger fnt-size12px">
            {{"ADMINISTRATION_ROUTE" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{(checkForServierProcess() ? "DOSING_DOSAGE_APPLICATION" :"DOSING_REGIMEN_DAILY_DOSING") | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="dailyDosing" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_DAILY_DOSING' | translate}}" [maxlength]="50" />
          </div>
          <div *ngIf="form.dailyDosing.touched && form.dailyDosing.invalid" class="d-none text-danger fnt-size12px">
            {{"DOSING_REGIMEN_DAILY_DOSING" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="!checkForServierProcess()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"FORMULATION" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="formulation" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_FORMULATION' | translate}}" [maxlength]="50" />
          </div>
          <div *ngIf="form?.formulation?.touched && form?.formulation?.invalid" class="d-none text-danger fnt-size12px">
            {{"FORMULATION" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group mb-2 position-relative">
          <label class="fnt-size14px">
            {{(checkForServierProcess() ? "ADMINISTERED_FROM" :"TREATMENT_START_DATE") | translate}}

            <span class="text-danger d-none">*</span>
          </label>
          <div class="calendar-field w-100">
            <p-calendar [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [touchUI]="false"
              yearRange="1922:2030" [inline]="false" panelStyleClass="bg-white border"
              [styleClass]="form.treatmentStartDate.touched && form.treatmentStartDate.invalid ? 'pcomponent-invalid w-100' : 'w-100'"
              [inputStyleClass]="'form-control fnt-size12px fnt_Medium w-100 rounded'"
              formControlName="treatmentStartDate" placeholder="{{'SELECT_DATE' | translate}}" dateFormat="dd M yy">
            </p-calendar>
          </div>
          <div *ngIf="(form.treatmentStartDate.touched ) && form.treatmentStartDate.invalid"
            class="d-none text-danger fnt-size10px">
            {{'TREATMENT_START_DATE' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group mb-2 position-relative">
          <label class="fnt-size14px">
            {{'TREATMENT_END_DATE' | translate}}
            <span class="text-danger d-none">*</span>
          </label>
          <div class="calendar-field w-100">
            <p-calendar [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [touchUI]="false"
              yearRange="1922:2030" [inline]="false" panelStyleClass="bg-white border"
              [styleClass]="form.treatmentEndDate.touched && form.treatmentEndDate.invalid ? 'pcomponent-invalid w-100' : 'w-100'"
              [inputStyleClass]="'form-control fnt-size12px fnt_Medium w-100 rounded'"
              formControlName="treatmentEndDate" placeholder="{{'SELECT_DATE' | translate}}" dateFormat="dd M yy">
            </p-calendar>
          </div>
          <div *ngIf="(form.treatmentEndDate.touched ) && form.treatmentEndDate.invalid"
            class="d-none text-danger fnt-size10px">
            {{'TREATMENT_END_DATE' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"INDICATION_FOR_USE" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="indicationForUse" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_INDICATION' | translate}}" [maxlength]="500" />
          </div>
          <div *ngIf="form.indicationForUse.touched && form.indicationForUse.invalid"
            class="d-none text-danger fnt-size12px">
            {{"INDICATION_FOR_USE" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"BATCH_NO_EXPIRY_DATE" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="batchNoAndExpiryDate" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_HERE' | translate}}" [maxlength]="500" />
          </div>
          <div *ngIf="form.batchNoAndExpiryDate.touched && form.batchNoAndExpiryDate.invalid"
            class="d-none text-danger fnt-size12px">
            {{"BATCH_NO_EXPIRY_DATE" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="fnt-size12px">
            {{ 'ABATE_AFTER_STOPPING' | translate }}
          </label>
          <div class="custom-checkbox">
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="abateYes" value="Yes"
                formControlName="abateAfterStopping" (click)="clearSelection('abateAfterStopping', 'Yes')">
              <label class="radio-button-text cursor-pointer" for="abateYes">{{ 'YES' | translate }}</label>
            </span>
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="abateNo" value="No"
                formControlName="abateAfterStopping" (click)="clearSelection('abateAfterStopping', 'No')">
              <label class="radio-button-text cursor-pointer" for="abateNo">{{ 'NO' | translate }}</label>
            </span>
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="abateUnknown" value="Unknown"
                formControlName="abateAfterStopping" (click)="clearSelection('abateAfterStopping', 'Unknown')">
              <label class="radio-button-text cursor-pointer" for="abateUnknown">{{ 'UNKNOWN' | translate }}</label>
            </span>
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="abateNA" value="Not Applicable"
                formControlName="abateAfterStopping" (click)="clearSelection('abateAfterStopping', 'Not Applicable')">
              <label class="radio-button-text cursor-pointer" for="abateNA">{{ 'NOT_APPLICABLE' | translate }}</label>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="fnt-size12px">
            {{ 'RECUR_ON_RE_ADMINISTRATION' | translate }}
          </label>
          <div class="custom-checkbox">
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="recurYes" value="Yes"
                formControlName="recurOnReadMinistration" (click)="clearSelection('recurOnReadMinistration', 'Yes')">
              <label class="radio-button-text cursor-pointer" for="recurYes">{{ 'YES' | translate }}</label>
            </span>
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="recurNo" value="No"
                formControlName="recurOnReadMinistration" (click)="clearSelection('recurOnReadMinistration', 'No')">
              <label class="radio-button-text cursor-pointer" for="recurNo">{{ 'NO' | translate }}</label>
            </span>
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="recurUnknown" value="Unknown"
                formControlName="recurOnReadMinistration" (click)="clearSelection('recurOnReadMinistration', 'Unknown')">
              <label class="radio-button-text cursor-pointer" for="recurUnknown">{{ 'UNKNOWN' | translate }}</label>
            </span>
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="recurNA" value="Not Applicable"
                formControlName="recurOnReadMinistration" (click)="clearSelection('recurOnReadMinistration', 'Not Applicable')">
              <label class="radio-button-text cursor-pointer" for="recurNA">{{ 'NOT_APPLICABLE' | translate }}</label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
