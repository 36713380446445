/**
 * Angular imports.
 */
import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Ngrx Imports.
import { Store } from '@ngrx/store';
import * as LoginAction from '../../../store/actions/login/login.actions';
import * as LoginSelector from '../../../store/selectors/login/login.selectors';

/**
 * Constant import.
 */
import { LANGUAGES } from '../../../constant/app.constant';

// Model import.
import { ErrorHandler } from '../../../models/responses.model';

// Rxjs Import.
import { Subscription } from 'rxjs';

// Interface defined for form data.
export interface RequestData {
  email: string;
}

@Component({
  selector: 'pap-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, AfterContentChecked, OnDestroy {

  /**
   * Get logo src.
   */
  public logoSrc = 'assets/images/logo.svg';

  /**
   * Get support language.
   */
  public languages = LANGUAGES;

  /**
   * Submit button status
   */
  public disable = true;

  /**
   * Error Object
   */
  public errorObj: ErrorHandler;

  /**
   * Error Message
   */
  public errorMessage: string;

  /**
   * Subscription
   */
  public resetPasswordErrorSub: Subscription;

  /**
   * Var used show for selected country value.
   */
  public selectedCountry = '';

  // Form submit function.
  getFromData(data: RequestData): void {
    this.store.dispatch(LoginAction.requestChangeLoginPassword(data));
  }

  // Function to check if input is empty.
  checkInput(email: string): void {
    if (email.length !== 0) {
      this.disable = false;
    }
    else {
      this.disable = true;
    }
  }

  // Function to clear form error.
  onFocus(): void {
    this.errorObj = null;
  }

  // This is used to detect error changes from api.
  ngOnInit(): void {
    this.store.dispatch(LoginAction.resetLoginStore());
    this.resetPasswordErrorSub = this.store.select(LoginSelector.getLoginError).subscribe(error => {
      this.errorObj = null;
      if (error != null) {
        this.errorObj = error;
        if (this.errorObj.message !== '') {
          this.errorMessage = this.errorObj.message;
        }
      }
    });
    this.selectedCountry = localStorage.getItem('country');
    if (this.selectedCountry !== null && this.selectedCountry !== '') {
      return null;
    } else {
      this.router.navigate(['/']);
    }
  }

  // This is used to detect changes after page load.
  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  // This is used to unsubscribe from error.
  ngOnDestroy(): void {
    if (this.resetPasswordErrorSub) {
      this.resetPasswordErrorSub.unsubscribe();
    }
  }

  // This is used to initialize instances.
  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private cdRef: ChangeDetectorRef,
    private router: Router) { }

}
