import { mergeMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as DoctorAction from '../../actions/doctor/doctor.actions';
import * as ErrorAction from '../../actions/error/error.actions';
import { API } from '../../../constant/api.constant';
import { HttpClient } from '@angular/common/http';
import {
  DoctorReqOtpResponse, DoctorResendOtpResponse,
  DoctorVerifyOtpResponse, ProgramListResponse, SpecialtyListResponse, DoctorResponse,
  EducationListResponse, EducationList, DoctorCredUpdateResponse, HospitalAffiliationListResponse
} from '../../../models/responses.model';
import { Router, ActivatedRoute } from '@angular/router';
/**
 * Rxjs Operators.
 */
import { JwtHelperService } from '@auth0/angular-jwt';
/**
 * constant imports
 */
import { PARAMS_KEY, REQUEST_TYPE, USER_TYPE } from '../../../constant/app.constant';
@Injectable()
export class DoctorEffects {
  // todo to handle condtional params through interceotor
  /**
   * Effect for loading programs.
   */
  public loadPrograms$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(DoctorAction.loadPapPrograms),
        mergeMap((action) =>
          this.http.get<ProgramListResponse>(
            API.PROGRAMS, {
            params: {
              pharma: action.pharma,
              limit: action.limit
            }
          }
          ).pipe(
            map(res => {
              return DoctorAction.setPapPrograms({ data: res.data.list });
            }),
            catchError(err => {
              return [DoctorAction.setErrors(
                { error: err.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );
  /**
   * load pap programs
   */
  public loadSpecialties$ = createEffect(() => this.actions$.pipe(
    ofType(DoctorAction.loadSpeciality),
    mergeMap((action) =>
      this.http.get<SpecialtyListResponse>(API.SPECIALTY, {
        params: {
          limit: '100',
        }
      }
      ).pipe(
        map(res => {
          return DoctorAction.setSpecialty({ data: res.data.list });
        }),
        catchError(err => {
          return [DoctorAction.setErrors({
            error: err.error
          }),
          ErrorAction.catchError({ error: err })];
        }),
      )
    ),
  ));

  /**
   * Effect for submit form data API.
   */
  public registerDoctorWithDCPM$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(DoctorAction.registerDoctorWithDCPM),
        mergeMap((action) =>
          // TODO: sumit add data typing.
          this.http.post<any>(
            API.ADD_DOCTOR_PROFILE, action.formData
          ).pipe(
            map(res => {
              if (res.success === true) {
                return DoctorAction.setDoctorSubmitSuccess({ success: true });
              } else {
                return DoctorAction.setDoctorSubmitSuccess({ success: false });
              }
            }),
            catchError(err => {
              return [DoctorAction.setErrors(
                { error: err.error }
              ),
              ErrorAction.catchError({ error: err })

              ];
            })
          )
        )
      )
  );


  public submitDoctorFormDataDCPM$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(DoctorAction.submitDoctorFormData),
        mergeMap((action) =>
          // TODO: sumit add data typing.
          this.http.post<any>(
            API.DOCTOR_FORM_SUBMIT,
            action.formData, { headers: { 'login-auth': action.token } }
          ).pipe(
            map(res => {
              if (res.success === true) {
                localStorage.setItem('doctorAuth', res.data.token);
                return DoctorAction.setDoctorSubmitSuccess({ success: true });
              } else {
                return DoctorAction.setDoctorSubmitSuccess({ success: false });
              }
            }),
            catchError(err => {
              return [DoctorAction.setErrors(
                { error: err.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );


  public getDoctorDetail$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(DoctorAction.getDoctorDetail),
        mergeMap((action) => (
          action.pharmaCode ? this.http.get<DoctorResponse>(
            API.DOCTOR_GET_DETAIL + action.id + '/' + 'profile',
            {
              headers: {
                'login-auth': action.token
              },
              params: {
                pharmaCode: action.pharmaCode
              }
            }
          ) : this.http.get<DoctorResponse>(
            API.DOCTOR_GET_DETAIL + action.id + '/' + 'profile', {
            headers: {
              'login-auth': action.token,
            }
          }
          ))
          .pipe(
            map(res => {
              if (res.success === true) {
                return DoctorAction.setDoctorDetail({ doctorData: res.data });
              }
              else {
                return DoctorAction.setDoctorDetail({ doctorData: res.data });
              }
            }),
            catchError(err => {
              return [DoctorAction.setErrors(
                { error: err.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );

  public editDoctorFormData$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(DoctorAction.editDoctorFormData),
        mergeMap((action) =>
          // TODO: rahul add data typing.
          this.http.patch<any>(
            API.DOCTOR_GET_DETAIL + action.id + '/' + 'profile', action.formData,
            { headers: { 'login-auth': action.token } }
          ).pipe(
            map(res => {
              if (res.success === true) {
                return DoctorAction.editDoctorSuccess({ success: true });
              } else {
                return DoctorAction.editDoctorSuccess({ success: false });
              }
            }),
            catchError(err => {
              return [DoctorAction.setErrors(
                { error: err.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );

  /**
   * Effect for loading otp.
   */
  public loadOTP$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(DoctorAction.loadOTP),
        mergeMap((action) =>
          (action.pharmaCode ? this.http.post<DoctorReqOtpResponse>(
            API.DOCTOR_OTP_REQUEST, action.requestType === 'phoneNumber' ? {
              phoneNumber: action.phoneNumber,
              countryCode: action.countryCode,
              requestType: action.requestType,
              hCaptchaToken: action.hCaptchaToken,
              pharmaCode: action.pharmaCode
            } : {
            email: action.email,
            requestType: action.requestType,
            hCaptchaToken: action.hCaptchaToken,
            pharmaCode: action.pharmaCode
          }
          ) : this.http.post<DoctorReqOtpResponse>(
            API.DOCTOR_OTP_REQUEST, action.requestType === 'phoneNumber' ? {
              phoneNumber: action.phoneNumber,
              countryCode: action.countryCode,
              requestType: action.requestType,
              hCaptchaToken: action.hCaptchaToken
            } : {
            email: action.email,
            requestType: action.requestType,
            hCaptchaToken: action.hCaptchaToken
          }
          )).pipe(
            map(res => {
              return DoctorAction.setOTP({ otpRequestToken: res.data.token });
            }),
            catchError(err => {
              return [DoctorAction.setErrors(
                { error: err.error.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );

  /**
   * Effect for verifying otp.
   */
  public verifyOTP$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(DoctorAction.verifyOTP),
        mergeMap((action) =>
          (action.pharmaCode ? this.http.post<DoctorVerifyOtpResponse>(
            API.DOCTOR_OTP_VERIFY, {
            otp: action.otp
          },
            {
              headers: {
                'login-auth': action.otpRequestToken,
              },
              params: {
                pharmaCode: action.pharmaCode
              }
            }
          ) : this.http.post<DoctorVerifyOtpResponse>(
            API.DOCTOR_OTP_VERIFY, {
            otp: action.otp
          },
            {
              headers: {
                'login-auth': action.otpRequestToken,
              }
            }
          )).pipe(
            map(res => {
              console.log('Verify-otp', res);
              const helper = new JwtHelperService();
              const decodedToken = helper.decodeToken(res.data.token);
              localStorage.setItem('doctorAuth', res.data.token);
              const param = PARAMS_KEY.PHARMA_CODE;
              const pharmaCode = this.route.snapshot.queryParams[param];
              if (pharmaCode) {
                const pharmaId = (decodedToken.data?.pharma?.id).toString();
                localStorage.setItem('pharmaId', pharmaId);
              }
              if (decodedToken.sub === 'newRegistrationSession') {
                if (pharmaCode) {
                  this.routerNavigation('/addDoctor', pharmaCode);
                }
                else {
                  this.routerNavigation('/addDoctor', null);
                }
              } else {
                if (pharmaCode) {
                  this.routerNavigation('/viewDoctor', pharmaCode);
                }
                else {
                  this.routerNavigation('/viewDoctor', null);
                }
              }
              return DoctorAction.setVerifyOTPData({ otpVerifyToken: res.data.token });
            }),
            catchError(err => {
              console.log('Verify-otp catchError', err);
              return [DoctorAction.setErrors(
                { error: err.error.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );

  /**
   * Effect for resending otp.
   */
  public resendOTP$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(DoctorAction.resendOTP),
        mergeMap((action) =>
          this.http.patch<DoctorResendOtpResponse>(
            // TODO: sumit add data typing.
            API.DOCTOR_OTP_RESEND, {
            hCaptchaToken: action.hCaptchaToken
          },
            {
              headers: {
                'login-auth': action.otpRequestToken,
              }
            }
          ).pipe(
            map(res => {
              return DoctorAction.setOTP({ otpRequestToken: action.otpRequestToken });
            }),
            catchError(err => {
              return [DoctorAction.setErrors(
                { error: err.error.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );

  /**
   * Effect for request otp for update cred.
   */
  public updateDoctorCredRequest$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(DoctorAction.updateDoctorCredRequest),
        mergeMap((action) =>
          (action.requestType === REQUEST_TYPE.EMAIL ? this.http.post<DoctorCredUpdateResponse>(
            API.UPDATE_DOCTOR_PROFILE + action.id + '/update-request', {
            requestType: action.requestType,
            email: action?.email,
            requesterUserRole: action.requesterUserRole
          },
            {
              headers: {
                'login-auth': action.loginAuth,
              }
            }
          ) : this.http.post<DoctorCredUpdateResponse>(
            API.UPDATE_DOCTOR_PROFILE + action.id + '/update-request', {
            phoneNumber: action.phoneNumber,
            countryCode: action.countryCode,
            requestType: action.requestType,
            requesterUserRole: action.requesterUserRole
          },
            {
              headers: {
                'login-auth': action.loginAuth,
              }
            }
          ))
            .pipe(
              map(res => {
                return DoctorAction.setOTP({ otpRequestToken: res.data.verificationSignature });
              }),
              catchError(err => {
                return [DoctorAction.setErrors(
                  { error: err.error.error }
                ),
                ErrorAction.catchError({ error: err })
                ];
              })
            )
        )
      )
  );

  public updateDoctorCredVerify$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(DoctorAction.verifyDoctorCredRequest),
        mergeMap((action) =>
          this.http.post<DoctorCredUpdateResponse>(
            API.UPDATE_DOCTOR_PROFILE + action.id + '/update-verify', {
            verificationSignature: action.verificationSignature,
            otp: action.otp
          },
            {
              headers: {
                'login-auth': action.loginAuth,
              }
            }
          )
            .pipe(
              map(res => {
                return DoctorAction.setDoctorSubmitSuccess({ success: true });
              }),
              catchError(err => {
                return [DoctorAction.setErrors(
                  { error: err.error.error }
                ),
                ErrorAction.catchError({ error: err }),
                DoctorAction.setDoctorSubmitSuccess({ success: false })
                ];
              })
            )
        )
      )
  );
  /**
   * Effect for loading programs.
   */
  public loadEducation$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(DoctorAction.loadEducation),
        mergeMap((action) =>
          // TODO: rahul add data typing.
          this.http.get<EducationList>(
            API.EDUCATION
          ).pipe(
            map(res => {
              return DoctorAction.setEducation({ data: res.data });
            }),
            catchError(err => {
              return [DoctorAction.setErrors(
                { error: err.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      ));

  /**
   * Effect for loading hospital affiliation list.
   */
  public loadHospitalAffiliation$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(DoctorAction.loadHospitalAffiliation),
        mergeMap((action) =>
          this.http.get<HospitalAffiliationListResponse>(
            API.HOSPITAL_AFFILIATION,
            {
              params: {
                keyword: action?.keyword,
                limit: action?.limit?.toString()
              }
            }).pipe(
              map(res => {
                return DoctorAction.setHospitalAffiliationList({ hospitalAffiliationList: res.data });
              }),
              catchError(err => {
                return [DoctorAction.setErrors(
                  { error: err.error }
                ),
                ErrorAction.catchError({ error: err })
                ];
              })
            )
        )
      )
  );

  /**
   * Effect for loading hospital affiliation list.
   */
  public findDoctor$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(DoctorAction.findDoctor),
        mergeMap((action) =>
          this.http.post<DoctorReqOtpResponse>(
            API.FIND_DOCTOR, action.requestType === 'phoneNumber' ? {
              phoneNumber: action.phoneNumber,
              countryCode: action.countryCode,
              requestType: action.requestType,
              params: {
                pharma: action.pharmaCode
              }
            } : {
            email: action.email,
            requestType: action.requestType,
            params: {
              pharma: action.pharmaCode
            }
          }).pipe(
            map(res => {
              console.log('Verify-otp', res);
              const token = res.data.token;
              const helper = new JwtHelperService();
              const decodedToken = helper.decodeToken(token);
              localStorage.setItem('findDoctorAuth', token);
              const pharmaCode = localStorage.getItem('pharmaCode');
              if (action.role === USER_TYPE.APPROVER) {
                this.router.navigate(['/approval/findDoctor/ViewDoctorDetail'],
                  { queryParams: { pharmaCode, role: action.role } });
              } else {
                this.router.navigate(['/supervisor/findDoctor/ViewDoctorDetail'],
                  { queryParams: { pharmaCode, role: action.role } });
              }
              return DoctorAction.setDoctorErrors({ doctorFindError: null });
            }),
            catchError(err => {
              console.log('err', err);
              return [DoctorAction.setDoctorErrors(
                { doctorFindError: err.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );

  public routerNavigation(name: string, pharmaCode: string, role?: string): void {
    if (pharmaCode) {
      this.router.navigate([name], {
        queryParams: {
          pharmaCode,
          r: role ? role : false
        },
      });
    }
    else {
      this.router.navigate([name], { queryParams: { r: role ? role : false } });
    }
  }

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) { }

}
