import { PharmaStoreComponent } from './components/main/pharma-store/pharma-store.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/**
 * Component imports.
 */
import { MainComponent } from './components/main/main.component';
import { SignUpChatbotComponent } from './components/main/sign-up-chatbot/sign-up-chatbot.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { CreatePasswordComponent } from './components/main/create-password/create-password.component';
import { CreatePasswordSuccessComponent } from './components/main/create-password-success/create-password-success.component';
import { ResetPasswordComponent } from './components/main/reset-password/reset-password.component';
import { HybridComponent } from './components/main/hybrid/hybrid.component';
import { LoginComponent } from './components/main/doctor/login/login.component';
import { OtpVerifyComponent } from './components/main/doctor/otp-verify/otp-verify.component';
import { AddDoctorComponent } from './components/main/doctor/add-doctor/add-doctor.component';
import { ViewDoctorComponent } from './components/main/doctor/view-doctor/view-doctor.component';
import { PharmaViewComponent } from './components/main/pharma-view/pharma-view.component';
import { DoctorContactComponent } from './components/main/doctor/doctor-contact/doctor-contact.component';
import { PharmaPolicyComponent } from './components/main/pharma-view/pharma-policy/pharma-policy.component';
import { DoctorAeReportingComponent } from './components/main/doctor/doctor-ae-reporting/doctor-ae-reporting.component';

/**
 * Guards import.
 */
import { AuthGuard } from './guards/auth.guard';
import { NoAuthGuard } from './guards/no-auth.guard';
import { FindDoctorComponent } from './components/main/doctor/find-doctor/find-doctor.component';
import { SearchDoctorComponent } from './components/main/doctor/search-doctor/search-doctor.component';
import { ResourcesComponent } from './components/main/doctor/resources/resources.component';
import { AttendentLoginComponent } from './components/main/attendent-login/attendent-login.component';
import { AttendentRegisterComponent } from './components/main/attendent-register/attendent-register.component';


const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    pathMatch: 'full',
    canActivate: [NoAuthGuard]
  },
  {
    path: 'signUpChatbot',
    component: SignUpChatbotComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'createPassword/:token',
    component: CreatePasswordComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'createPasswordSuccess',
    component: CreatePasswordSuccessComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'viewDoctor',
    component: ViewDoctorComponent,
    canActivate: [NoAuthGuard]
  },
  // TODO: UNCOMMENT FOR PHASE-2
  // {
  //   path: 'attendant',
  //   component: AttendentLoginComponent,
  // },
  // {
  //   path: 'attendant/register',
  //   component: AttendentRegisterComponent,
  // },

  {
    path: 'pharma/:pharmaCode',
    component: PharmaViewComponent,
  },
  {
    path: 'pharma/:pharmaCode/policy',
    component: PharmaPolicyComponent,
  },
  {
    path: 'pharma/:pharmaCode/pharma-store',
    component: PharmaStoreComponent
  },
  /**
   * country routes.
   */
  {
    path: 'pharma/:pharmaCode/:country',
    component: PharmaViewComponent,
  },
  {
    path: 'pharma/:pharmaCode/policy/:country',
    component: PharmaPolicyComponent,
  },
  {
    path: 'pharma/:pharmaCode/pharma-store/:country',
    component: PharmaStoreComponent
  },
  /**
   * end country routes.
   */
  {
    path: '',
    loadChildren: () => import('./featureModules/dashboard/dashboard.module')
      .then(m => m.DashboardModule),
    canActivate: [AuthGuard],
    data: { title: 'Dashboard', pageIdentifier: 'dashboard' }
  },
  {
    path: 'patient',
    component: HybridComponent,
    canActivate: [NoAuthGuard],
    children: [{
      path: ':pharmaCode',
      component: HybridComponent,
      canActivate: [NoAuthGuard]
    }
    ]
  },

  {
    path: 'addDoctor',
    component: AddDoctorComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'findDoctor',
    component: FindDoctorComponent,
    canActivate: [NoAuthGuard],
    children: [
      {
        path: '',
        component: SearchDoctorComponent
      },
      {
        path: 'ViewDoctorDetail',
        component: ViewDoctorComponent
      },
      {
        path: 'editDoctor',
        component: AddDoctorComponent,
      }
    ]
  },
  {
    path: 'doctor',
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'login/:country',
        component: LoginComponent,
      },
      {
        path: 'otp-verify',
        component: OtpVerifyComponent,
      },
      {
        path: 'contact-us',
        component: DoctorContactComponent
      },
      {
        path: 'ae-reporting',
        component: DoctorAeReportingComponent
      },
      {
        path: 'resources',
        component: ResourcesComponent
      }
    ]
  },
  { path: '**', redirectTo: '404' },
  { path: '404', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
