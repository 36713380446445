    <form [formGroup]="acknowledgmentInfoForm">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
              <span>
                {{"ACKNOWLEDGMENT_NUMBER" | translate}}
              <span class="text-danger">*</span>
              </span>
            </label>
            <div class="position-relative">
              <input formControlName="acknowledgmentNumber" (keyup)="userInput('acknowledgmentNumber', $event)" type="text" class="form-control fnt-size14px"
                placeholder="Enter Acknowledgement number" [maxlength]="20" [attr.disabled]="disabled ? true : null" />
                <ng-container *ngIf="disabled"  class="cursor-pointer">
                  <i class="pi pi-pencil text-success edit-btn" (click)="toggleDisable()"></i>
                  <hr class="text-success small-hr">
                </ng-container>
             </div>
            <div *ngIf="form.acknowledgmentNumber.touched && form.acknowledgmentNumber.invalid" class="text-danger fnt-size12px">
              {{"ACKNOWLEDGMENT_NUMBER" | translate}} {{ "IS_REQUIRED" | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-4 d-flex align-items-start">
          <div class="form-group mb-2 position-relative">
            <div class="spinner-border text-secondary" role="status" *ngIf="changeStatusLoader"></div>
            <button *ngIf="!changeStatusLoader" [disabled]="form.acknowledgmentNumber.invalid || disabled" class="btn btn-outline-success float-right fnt-size14px px-4 py-2" (click)="onSave()">{{'SAVE' | translate}}</button>
          </div>
        </div>
      </div>
    </form>
  