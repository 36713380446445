/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/**
 * Rxjs Operators.
 */
import { catchError, mergeMap, map, switchMap } from 'rxjs/operators';

/**
 * Ngrx store
 */
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as HybridAction from '../../actions/hybrid/hybrid.actions';
import * as ErrorAction from '../../actions/error/error.actions';
import { API } from '../../../constant/api.constant';

/**
 * Model imports.
 */
import {
  SigninResponse,
  ProgramListResponse,
  HybridFormListResponse,
  ProgramSubResponse,
  CaregiverDataResponse,
  PharmaResponse,
  PatientRequestOtpResponse
} from './../../../models/responses.model';
import { APPLICATION_TYPE, CHATBOT_PROGRAM_FETCH_LIMIT, ERROR_CODE } from '../../../constant/app.constant';

@Injectable()
export class HybridEffects {
  /**
   * Effect for loading forms.
   */
  public loadHybridForms$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(HybridAction.loadHybridForms),
        mergeMap((action) =>
          this.http.get<HybridFormListResponse>(
            API.FORMS,
            {
              params: {
                applicationType: APPLICATION_TYPE.HYBRID,
                token: action.consentToken
              }
            }
          ).pipe(
            map(res => {
              return HybridAction.setHybridForms({ hybridData: res.data });
            }),
            catchError(err => {
              return [HybridAction.setErrors(
                { error: err.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );

  /**
   * Effect for loading programs.
   */
  public loadPrograms$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(HybridAction.loadPrograms),
        mergeMap((action) =>
          this.http.get<ProgramListResponse>(
            '/' + action.endPoint,
            {
              params: {
                keyword: action?.keyword,
                drugSearch: 'true',
                pharma: action.pharma,
                limit: CHATBOT_PROGRAM_FETCH_LIMIT
              }
            }).pipe(
              map(res => {
                return HybridAction.setProgramList({ programList: res.data.list });
              }),
              catchError(err => {
                return [HybridAction.setErrors(
                  { error: err.error }
                ),
                ErrorAction.catchError({ error: err })
                ];
              })
            )
        )
      )
  );

  /**
   * Effect for submit form data API.
   */
  public loginPatient$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(HybridAction.submitLoginData),
        switchMap((action) =>
          (action.pharmaCode ? this.http.post<SigninResponse>(
            action.endPoint + `?countryCode=${action.formData.countryCode}&email=${action.formData.email}&phoneNumber=${action.formData.phone}&pharmaCode=${action.pharmaCode}&process=${action.process}`,
            {}
          ) : this.http.post<SigninResponse>(
            action.endPoint + `?countryCode=${action.formData.countryCode}&email=${action.formData.email}&phoneNumber=${action.formData.phone}`,
            {}
          )).pipe(
            map(userDetails => {
              localStorage.setItem('patientAuth', JSON.stringify(userDetails.data));
              return HybridAction.setCreds({ patientAuthResult: userDetails.data });
            }),
            catchError(err => {
              return [HybridAction.setErrors(
                { error: err.error.data.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );

  /**
   * Effect for submit prgram data API.
   */
  public submitProgram$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(HybridAction.submitProgramData),
        mergeMap((action) =>
          this.http.post<ProgramSubResponse>(
            '/' + action.endPoint,
            action.formData,
            {
              headers: {
                'pap-hybrid': action.applicationType === APPLICATION_TYPE.HYBRID ? 'true' : 'false',
                'pap-authorization': action.applicationType === APPLICATION_TYPE.HYBRID ? action.accessToken : '',
              }
            }).pipe(
              map(res => {
                return HybridAction.setConsentToken({
                  consentToken: res.data.consent_token,
                  showAdditionalInfo: Number(res.data.showAdditionalInfo)
                });
              }),
              catchError(err => {
                return [HybridAction.setErrors(
                  { error: err.error }
                ),
                ErrorAction.catchError({ error: err })
                ];
              })
            )
        )
      )
  );

  /**
   * Effect for deleting caregiver API.
   */
  public deleteCareGiver$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(HybridAction.deleteCareGiver),
        mergeMap((action) =>
          this.http.patch<any>(
            API.SIGNUP_CONSENT + action.token + '/caregiver/status',
            action.careGiverRequest,
            {
              headers: action.applicationType === APPLICATION_TYPE.HYBRID ? {
                'pap-hybrid': 'true',
                'pap-authorization': action.accessToken,
              } : undefined
            }).pipe(
              map(res => {
                return HybridAction.setDeletedCaregiver({ isCaregiverDeleted: true });
              }),
              catchError(err => {
                return [HybridAction.setErrors(
                  { error: err.error }
                ),
                ErrorAction.catchError({ error: err }),
                HybridAction.resetDeletedCaregiver({})
                ];
              })
            )
        )
      )
  );
  /**
   * Effect for load caregiver data.
   */
  public loadCareGiver$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(HybridAction.loadCaregiver),
        mergeMap((action) =>
          this.http.get<CaregiverDataResponse>(
            API.SIGNUP_CONSENT + action.token + '/caregiver/detail',
            {
              headers: action.applicationType === APPLICATION_TYPE.HYBRID ? {
                'pap-hybrid': action.applicationType === APPLICATION_TYPE.HYBRID ? 'true' : 'false',
                'pap-authorization': action.applicationType === APPLICATION_TYPE.HYBRID ? action.accessToken : '',
              } : undefined,
              params: {
                caregiverEmail: action.caregiverEmail,
                caregiverPhoneNumber: action.caregiverPhoneNumber
              }
            }).pipe(
              map(res => {
                return HybridAction.setCaregiver({ caregiverData: res.data.data });
              }),
              catchError(err => {
                if (err?.error?.data?.error?.code === ERROR_CODE.E4024) {
                  return [HybridAction.resetCaregiver({}), HybridAction.setErrors({ error: err?.error?.data?.error })];
                } else {
                  return [HybridAction.resetCaregiver({})];
                }
              })
            )
        )
      )
  );

  /**
   * Effect for loading programs.
   */
  public loadHybridPharmas$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(HybridAction.loadHybridPharmas),
        mergeMap((action) =>
          this.http.get<PharmaResponse>(
            API.PHARMA_DETAIL + action.pharmaCode + '/pap'
          ).pipe(
            map(res => {
              return HybridAction.setHybridPharmas({ data: res.data });
            }),
            catchError(err => {
              return [HybridAction.setErrors(
                { error: err.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );
  /**
   * Requesting OTP for patient
   */
  public requestOtp$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(HybridAction.requestOtp),
        mergeMap((action) =>
          this.http.get<PatientRequestOtpResponse>(
            API.REQUEST_OTP + action.consentToken + '/resendOtp'
          ).pipe(
            map(res => {
              return HybridAction.setRequestOtpResponse({ data: res.data });
            }),
            catchError(err => {
              return [HybridAction.setErrors(
                { error: err.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );
  constructor(
    private actions$: Actions,
    private http: HttpClient,
  ) { }

}
