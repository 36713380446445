import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { SelectItem } from 'primeng/api';

/**
 * Component used to show the search dropdown.
 */
@Component({
  selector: 'dngx-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.css']
})
export class SearchDropDownComponent {

  /**
   * Set the option list for dropdown.
   */
  @Input() list: SelectItem[];

  /**
   * Get the selected value two way binding.
   */
  @Input() selectedValue: string;

  /**
   * Flag for virtual scroll enable/disable.
   */
  @Input() virtualScroll: boolean;

  /**
   * Flag for itemSize of virtual scroll.
   */
  @Input() itemSize: number;

  /**
   * Flag for search filter enable/disable.
   */
  @Input() filter: boolean;

  /**
   * Flag for clear button enable/disable.
   */
  @Input() showClear: boolean;

  /**
   * Set the placeholder
   */
  @Input() placeholder: string;

  /**
   * Used to store the suggestions list array for dropdown.
   */
  @Input() suggestionValue: TemplateRef<string>;

  /**
   * Used to store the selected items for Dom.
   */
  @Input() selectedItem: TemplateRef<string>;

  /**
   * Used to hold the dropdown icon css text.
   */
  @Input() dropdownIcon: string;

  /**
   * Used to reset the search filter on hide.
   */
  @Input() resetFilterOnHide: boolean;

  /**
   * Used to disabled the dropdown.
   */
  @Input() disabled: boolean;

  /**
   * Used to add the style class for the dropdown.
   */
  @Input() styleClass = 'form-control p-0 light-grey-bg input-height border';

  /**
   * Used to add the message when no search is found.
   */
  @Input() emptyFilterMessage: string;

  /**
   * Used to emit the selected value.
   */
  @Output() emitSelectedValue: EventEmitter<string> = new EventEmitter();

  /**
   * Used to emit the selected data outside.
   */
  selectedData(event: string): void {
    this.emitSelectedValue.emit(event);
  }
}
