import { DNGXToastrComponent } from './components/toastr/toastr.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';

@NgModule({
  declarations: [DNGXToastrComponent],
  imports: [
    CommonModule,
    ToastModule
  ],
  exports: [DNGXToastrComponent]
})
export class ToastrModule { }
