import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dngx-toastr',
  templateUrl: './toastr.component.html',
  styleUrls: ['./toastr.component.css']
})
export class DNGXToastrComponent implements OnInit {

  /**
   * Supports various positions where default is "top-right".
   * top-right, top-left, bottom-right, bottom-left, top-center, bottom-center, center
   */
  @Input() position: string;

  /**
   * Key of the message in case message is targeted to a specific toast component.
   * A page may have multiple toast components,
   * in case you'd like to target a specific message to a particular toast,
   * use the key property so that toast and the message can match.
   */
  @Input() key: string;

  constructor() { }

  ngOnInit(): void {
  }

}
