import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { requestOtp } from '../../../store/actions/attendent/attendent.action';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as SignupAction from '../../../store/actions/signup/signup.actions';
import * as SignupSelector from '../../../store/selectors/signup/signup.selectors';
import { Subscription } from 'rxjs';
import { Country } from '../../../models/sign-up-chatbot.model';
import { PATTERN, USER_TYPE } from '../../../constant/app.constant';
import * as attendantAction from '../../../store/actions/attendent/attendent.action'
import * as attendant from '../../../store/selectors/attendent/attendent.selector'
import { HelperService } from '../../../helper.service';
import * as LoginAction from '../../../../../../nga-PAP/src/app/store/actions/login/login.actions'
import { Refresh } from '@ngrx/store-devtools/src/actions';
import { data } from 'jquery';

@Component({
  selector: 'pap-attendent-login',
  templateUrl: './attendent-login.component.html',
  styleUrls: ['./attendent-login.component.scss']
})
export class AttendentLoginComponent implements OnInit {

  /**
   * view otp1 child.
   */
   @ViewChild('otp1')
   otp1: ElementRef;
   /**
    * view otp2 child.
    */
   @ViewChild('otp2')
   otp2: ElementRef;
   /**
    * view otp3 child.
    */
   @ViewChild('otp3')
   otp3: ElementRef;
   /**
    * view otp4 child.
    */
   @ViewChild('otp4')
   otp4: ElementRef;
  //  /**
  //  * Used for resend otp view counter value.
  //  */
  public counterValue = 0;
  /**
   * Used for resend otp counter.
   */
  public counter = 0;
  /**
   * Used for resend otp counter interval.
   */
  public interval = 0;
  /**
   * This is used to store the api error message.
   */
  public apiErrorMsg: string;
  public loginInput: string;
  public maskedLoginInput: string;
  public defaultCountry: Country;
  public otpRequestSub:Subscription;

  private requestOtpErrorMsg: string = '';




  mobileForm: FormGroup;
  public otpForm: FormGroup;

  isValid = false;

  showOtpComponent = false;

  public getOtpToken = '';

   /**
    * Array to hold the OTP digits
    */
   otpDigits: string[] = ['', '', '', ''];
   otp: string[] = ['', '', '', ''];
   public verifyOtpCnt = 0;
  countriesSub: Subscription;
  public countries: Country[] = [];
  userDetail: any;


  constructor(
    private fb: FormBuilder,private store: Store ,
    private router: Router,
     private formBuilder: FormBuilder,
     public helperService: HelperService
  ) {
    this.mobileForm = this.attendantLoginForm();
  }

  attendantLoginForm(): FormGroup {
    return this.fb.group({
      countryCode: ['65',this.defaultCountry],
      phone_number: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[0-9]+$/),
          Validators.maxLength(16),
          Validators.minLength(8)
        ]
      ],
      user_type: [USER_TYPE.ATTENDANT]
    });

  }

  /**
   * Submit confirm otp api.
   */
  enterConfirmOtp(): void {
    this.verifyOtpCnt++;
    console.log('check',localStorage.getItem('otpRequest'))
    const otp = this.otpForm.get('otp1').value + this.otpForm.get('otp2').value
      + this.otpForm.get('otp3').value + this.otpForm.get('otp4').value;
    this.store.dispatch(attendantAction.verifyOTP({ otp: otp, otpRequestToken: localStorage.getItem('otpRequest') }));
    this.store.dispatch(attendantAction.resetErrors());
    this.apiErrorMsg = '';
  }

  ngOnInit(): void {
    console.log(this.showOtpComponent);
    this.store.dispatch(SignupAction.loadCountries({}));
    this.countriesSub = this.store.select(SignupSelector.getCountryList).subscribe(data => {
      if (data !== null) {
        this.countries = data.list;
      }
    });
    this.store.select(attendant.getAttendantApiError).subscribe(data => {
      if (data) {
        this.requestOtpErrorMsg = data;
      }
    })

    this.otpForm = this.formBuilder.group({
      otp1: ['', [Validators.required]],
      otp2: ['', [Validators.required]],
      otp3: ['', [Validators.required]],
      otp4: ['', [Validators.required]]
    });

    //  /**
    //  * Resend otp timer.
    //  */
    this.counterValue = 10;
    this.counter = 10;
    this.interval = 1000;
    this.store.select(attendant.verifyAttendantRequestOTP).subscribe(otpverify=>{
      if (otpverify) {
        console.log("otpverify",otpverify)
        const apiResponse = {
          user_detail: {
            first_name: otpverify?.user_detail?.first_name,
            last_name: otpverify?.user_detail?.last_name,
            email:otpverify?.user_detail?.email ,
          },
          user_register:{
          is_registered:otpverify.is_registered
          }
          
        };
        console.log("sdfh",apiResponse.user_detail)
      this.helperService.userDetail = apiResponse.user_detail;
      this.helperService.userRegister = apiResponse.user_register;
      //  console.log("userdata", this.helperService.userDetail)
        
        this.store.dispatch(attendantAction.resetVerifyOtp());
        
        this.router.navigate(['/attendant/register']);
      }
    })
    this.otpRequestSub = this.store.select(attendant.getAttendantRequestOTP).subscribe(otpToken => {
      if (otpToken != null) {
        this.getOtpToken = otpToken;
        this.showOtpComponent = true;
        localStorage.setItem('otpRequest',otpToken)
        this.loginInput = this.mobileForm.get('phone_number')?.value;
        localStorage.setItem('phoneNumber', this.loginInput.toString());
        this.maskedLoginInput = this.loginInput.substring(0, this.loginInput.length - 4).replace(/\w/g, 'X') 
                                    + this.loginInput.substring(this.loginInput.length - 4, this.loginInput.length);
      }
    });
    const alreadyRefreshed = localStorage.getItem('alreadyRefreshed');
    if (!alreadyRefreshed) {
      localStorage.setItem('alreadyRefreshed', 'true');
      window.location.reload();
    }
  }


  getCountryInfo(): void {
    switch (localStorage.getItem('country')) {
      case 'SG':
        this.defaultCountry = {
          code: localStorage.getItem('country'),
          countryCode: 65,
          id: 5,
          logo: 'assets/images/singapore-signup-icon.png\n',
          name: 'singapore'
        };
        break;
      default:
        break;
    }
  }

  /**
   * Receive counter value.
   */
  receiveCounterValue(event): void {
    this.counterValue = event;
    if (this.counterValue === 0) {
      this.counter = 0;
    }
  }

  resendOtp(): void {
    this.store.dispatch(attendantAction.resendOTP({otpRequestToken:this.getOtpToken}));
    console.log("token",this.getOtpToken)
    this.counterValue = 20;
    this.counter = 20;
    this.interval = 1000;
  }

  /**
   *  Used to auto focus.
   */
  keytab(event, index: number): void {
    const numbers = PATTERN.WHOLE_NUMBER_REGEX;
    if (event.code === 'Backspace' || event.key.match(numbers)) {
      if (index === 1) {
        event.code === 'Backspace' ? this.otp1.nativeElement.focus() : this.otp2.nativeElement.focus();
      } else if (index === 2) {
        event.code === 'Backspace' ? this.otp1.nativeElement.focus() : this.otp3.nativeElement.focus();
      } else if (index === 3) {
        event.code === 'Backspace' ? this.otp2.nativeElement.focus() : this.otp4.nativeElement.focus();
      } else {
        event.code === 'Backspace' ? this.otp3.nativeElement.focus() : this.otp4.nativeElement.focus();
      }
    }
  }

  onSubmit(): void {
    this.store.dispatch(attendantAction.loadOTP({
      countryCode: this.mobileForm.get('countryCode')?.value as string,
      phone_number: this.mobileForm.get('phone_number')?.value as string,
      user_type:this.mobileForm.get('user_type')?.value as string
    }));
  }


  validateOtp(): boolean {
    return this.otp.every(digit => digit.length === 1);
  }

  /**
   * to validate no. on bases of event
   * @param event keyboard event
   */
  validateNumberLength(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
      return;
    }
    if (!/^\d$/.test(event.key)) {
      event.preventDefault();
      return;
    }
    if (input.value.length === 0 && event.key === '0') {
      event.preventDefault();
      return;
    }
    if (input.value.length >= 15) {
      event.preventDefault();
      return;
    }
  }

  backEditAttendantLoginPage(){
    console.log("hello")
    this.showOtpComponent = false;
    // this.otpRequestSub.unsubscribe()
    // this.ngOnInit();
    
  }

    onInputChange(event: Event): void {
      const input = (event.target as HTMLInputElement).value;
      if (input.length >= 8 && input.length <= 15 && !input.startsWith('0') && /^\d+$/.test(input)) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }

      this.requestOtpErrorMsg = '';
    }


    // onRequestOtp() {
    //   const countryCode = this.mobileForm.value.countryCode;
    //   const mobile = this.mobileForm.value.mobileNumber;
    //   this.store.dispatch(requestOtp({ countryCode, mobile }));
    // }



 public moveToNext(currentInput, nextInputId): void {
    if (currentInput.value.length === 1) {
        document.getElementById(nextInputId).focus();
    }
 }

 onKeyUp(event: KeyboardEvent, index: number): void {
  const input = event.target as HTMLInputElement;
  console.log('index ', index );


  if (input.value.length === 1 && index < 3) {
    const nextInput = document.getElementById('otp' + (index + 1));
    console.log('nex input', nextInput);
    if (nextInput) {
      nextInput.focus();
    }
  }


  if (event.key === 'Backspace' && input.value.length === 0 && index > 0) {
    const prevInput = document.getElementById('otp' + (index - 1));
    if (prevInput) {
      prevInput.focus();
    }
  }
}

 ngOnDestroy(): void{
  console.log("hello");
  this.store.dispatch(attendantAction.destroyToken());

 }

}
