/**
 * Ngrx store Imports.
 */
import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import * as fromEvent from './home.reducer';

/**
 * Environment store Imports.
 */
import { environment } from '../../../../../../../environments/environment';

export const stateFeatureKey = 'home';

export interface State {
  homeData: fromEvent.State;
}

export const reducers: ActionReducerMap<State> = {
  homeData: fromEvent.reducer
};


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
