<div class="my-4">
    <h5 class="fnt-size18px text-heading-grey mb-3">{{'USER_DETAILS' | translate}}</h5>
    <form [formGroup]="userNameInfo">
      <div class="row">
        <div class="col-md-3 col-12">
          <label for="userName" class="text-secondary-grey fnt-size14px">
            <span>
              {{'USERNAME' | translate}}
              <span class="text-danger">*</span>
            </span>
          </label>
          <p-autoComplete class="w-100" (onKeyUp)="userInput('userName', $event)"  [inputStyleClass]="'form-control fnt-size14px w-100'" [panelStyleClass]="'w-100 autocomplete-box-shadow'" [suggestions]="filterUserDetailsList" [disabled]="disabled || aeCurrentStatus === aeReportStatus.RE_REVIEW"
          placeholder="{{'SEARCH' | translate}} {{'USERNAME' | translate}}" [autoHighlight]="true" [dropdownIcon]="'pi pi-search search-icon text-secondary'" formControlName="userName" [emptyMessage]="'No data available'"
          [completeOnFocus]="true" [dropdown]="true" (completeMethod)="onCompleteMethod($event)" (onSelect)="OnItemSelected($event)" [delay]="1000" (onKeyUp)="onInput($event)">
          <ng-template let-item pTemplate="item">
               <div class="d-flex dropdown-container">
                <div class="m-4 w-100 d-flex" *ngIf="item.type === 'header'" id="header">
                  <img *ngIf="item.value === 'No Result Found'" class="header-no-result-icon mr-3" src="assets/images/empty_result.svg" alt="No Result Found" />
                  <span class="fnt-size14px text-secondary">{{item.value}}</span>
                </div>
                  <div class="col-3 d-inline-block text-center" *ngIf="item.type === 'item'">
                    <img src="assets/images/doctor.svg" alt="contact us" />
                </div>
                <div class="col-9" *ngIf="item.type === 'item'">
                    <div class="d-flex justify-content-between">
                        <span class="fnt-size14px text-truncate">{{item.value.userName}}</span>
                        <div class="rounded-circle-icon">
                            <span class="fnt-size10px p-2 text-secondary">{{item.value.socialHandle}}</span>
                        </div>
                    </div>
                    <div class="row my-2 d-flex justify-content-between">
                      <div class="d-flex justify-content-center align-items-center">
                        <span class="fnt-size10px text-secondary">{{'PATIENT_ID' | translate}} :  </span>
                        <span class="fnt-size10px text-secondary">{{item.value.patientId}}</span>
                      </div>
                      <div class="dot text-secondary"></div>
                      <div class="d-flex justify-content-center align-items-center">
                        <span class="fnt-size10px text-secondary">{{'CREATED_BY' | translate}} : </span>
                        <span class="fnt-size10px text-secondary">{{item.value.createdBy}}</span>
                      </div>
             
                      </div>
                </div>
                  <hr *ngIf="item.type != 'button' && item.type != 'header' && item.value != ''" class="text-black-50">
                  <div class="mx-3 my-4 w-100 d-inline-block text-center" *ngIf="item.type === 'button'" id="button">
                    <button class="btn btn-outline-success fnt-size14px w-100">
                      {{item.value}}
                    </button>
                  </div>
              </div> 
          </ng-template>
      </p-autoComplete>
      <div *ngIf="(form.userName.touched) && (form.userName.invalid || !isItemSelected)" class="text-danger fnt-size12px">
        {{'USERNAME' | translate}} {{'IS_REQUIRED' | translate}}
      </div>
     <div>
      
      </div>
        </div>
        <div class="col-md-3 col-12">
            <div class="form-group">
                <label for="socialHandle" class="text-secondary-grey fnt-size14px">
                  {{"SOCIAL_MEDIA" | translate}}
                  <span class="text-danger">*</span>
                </label>
                <select class="form-control fnt-size14px"
                  formControlName="socialHandle" [attr.disabled]="disabled || aeCurrentStatus === aeReportStatus.RE_REVIEW ? true : null">
                  <option value='' disabled selected class="d-none">
                    Select Channel
                  </option>
                  <option *ngFor="let form of socialMediaList" [value]="form.name"
                    [selected]="form.name === form.userDetails?.value" class="mt-5">
                    {{form.name}}
                  </option>
                </select>
                <div *ngIf="(form.socialHandle.touched) && form.socialHandle.invalid" class="text-danger fnt-size12px">
                  {{"SOCIAL_MEDIA" | translate}} {{'IS_REQUIRED' | translate}}
                </div>
            </div>
        </div>
        <div class="col-md-3 col-12">
            <div class="form-group">
              <label for="interactionId" class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
                <span>
                  {{"INTERACTION_ID" | translate}}
                  <span class="text-danger">*</span>
                </span>
              </label>
              <div class="position-relative">
                <input (keyup)="userInput('interactionId', $event)" formControlName="interactionId" type="text" class="form-control fnt-size14px"
                  placeholder="{{'ENTER_ID' | translate}}" [maxlength]="20"  [attr.disabled]="userNameInfo?.get('interactionId')?.disabled?true:null"/>
              </div>
              <div *ngIf="(form.interactionId.touched) && form.interactionId.invalid" class="text-danger fnt-size12px">
                {{"INTERACTION_ID" | translate}} {{ "IS_REQUIRED" | translate }}
              </div>
            </div>
          </div>
      </div>
    </form>
  </div>
  

     