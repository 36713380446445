/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

/**
 * Rxjs Operators
 */
import { catchError, mergeMap, tap, map } from 'rxjs/operators';

/**
 * Ngrx store imports.
 */
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LoginAction from '../../actions/login/login.actions';
import * as ErrorAction from '../../actions/error/error.actions';

/**
 * Constants imports.
 */
import { API } from '../../../constant/api.constant';
import { USER_TYPE } from '../../../constant/app.constant';

/**
 * Model imports.
 */
import { SigninResponse } from '../../../models/responses.model';

/**
 * Jwt import.
 */
import { JwtHelperService } from '@auth0/angular-jwt';

// Null expression imports.
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';

// Rxjs Import.
import { dispatch } from 'rxjs/internal/observable/pairs';

@Injectable()
export class LoginEffects {
  /**
   * Hold token.
   */
  public idToken: string;

  /**
   * Calling of sign-in api to login.
   */
  public verifyEmailPassword$ = createEffect(() => this.actions$.pipe(
    ofType(LoginAction.verifyEmailPassword),
    mergeMap((action) =>
      this.http.post<SigninResponse>(
        API.LOGIN, {
        email: action.email,
        password: action.password
      }).pipe(
        map(userDetails => {
          this.idToken = userDetails.data.accessToken;
          const helper = new JwtHelperService();
          const decodedToken = helper.decodeToken(this.idToken);
          if (decodedToken.role === USER_TYPE.APPROVER) {
            this.router.navigate(['/approval']);
          } else if (decodedToken.role === USER_TYPE.PAP_SUPERVISOR) {
            this.router.navigate(['/supervisor']);
          }
          else {
            this.router.navigate(['/store']);
          }
          localStorage.setItem('login', JSON.stringify(userDetails.data));
          return LoginAction.setUserCredentials({ authenticationResult: userDetails.data });
        }),
        catchError(err => {
          return [LoginAction.setLoginError({
            error: err.error
          }),
          ErrorAction.catchError({ error: err })];
        }),
      )
    ),
  ));


  /**
   * Reset login store.
   */
  public resetLoginStore$ = createEffect(() => this.actions$.pipe(
    ofType(LoginAction.resetLoginStore),
    tap(() => {
      localStorage.removeItem('login');
      localStorage.removeItem('pharmaCode');
      localStorage.removeItem('verifyOtpToken');
    })
  ),
    { dispatch: false });

  /**
   * Handel error in login section.
   */
  public setLoginError$ = createEffect(() => this.actions$.pipe(
    ofType(LoginAction.setLoginError),
    tap((payload) => { })
  ),
    { dispatch: false });

  /**
   * Get refresh token.
   */
  public loadRefreshToken$ = createEffect(() => this.actions$.pipe(
    ofType(LoginAction.loadRefreshToken),
    mergeMap((action) =>
      this.http.get<SigninResponse>(
        API.REFRESH_TOKEN, {
      }).pipe(
        map(userDetails => {
          if (userDetails.success === true) {
            localStorage.setItem('login', JSON.stringify(userDetails.data));
            return LoginAction.setUserCredentials({ authenticationResult: userDetails.data });
          }
        }),
        catchError(err => {
          localStorage.removeItem('login');
          localStorage.removeItem('pharmaCode');
          this.store.dispatch(LoginAction.resetLoginStore());
          return [LoginAction.setLoginError({
            error: err.error
          }),
          ErrorAction.catchError({ error: err })];
        }),
      )
    ),
  ));

  /**
   * Create Login Password
   */
  public createLoginPassword$ = createEffect(() => this.actions$.pipe(
    ofType(LoginAction.createLoginPassword),
    mergeMap((action) =>
      // TODO: Aditya: Interface for Response to be created.
      this.http.post<any>(
        API.CREATE_LOGIN_PASSWORD, {
        password: action.password,
        confirm_password: action.confirm_password,
        verify_token: action.verify_token
      }).pipe(
        map(() => {
          this.router.navigate(['/createPasswordSuccess']);
          return LoginAction.resetLoginStore();
        }),
        catchError(err => {
          return [LoginAction.setLoginError({
            error: err.error
          }),
          ErrorAction.catchError({ error: err })];
        }),
      )
    ),
  ));

  /**
   * Change Login Password
   */
  public requestChangeLoginPassword$ = createEffect(() => this.actions$.pipe(
    ofType(LoginAction.requestChangeLoginPassword),
    mergeMap((action) =>
      // TODO: Aditya: Interface for Response to be created.
      this.http.patch<any>(
        API.RESET_LOGIN_PASSWORD_REQUEST, {
        email: action.email
      }).pipe(
        map(userDetails => {
          if (userDetails.success === true) {
            this.router.navigate(['/']);
            return LoginAction.resetLoginStore();
          }
        }),
        catchError(err => {
          return [LoginAction.setLoginError({
            error: err.error
          }),
          ErrorAction.catchError({ error: err })];
        }),
      )
    ),
  ));

  /**
   * Object of Actions, Router, and store.
   */
  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store,
    private http: HttpClient,
  ) { }

}
