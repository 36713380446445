import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import * as HomeAction from '../../../../../featureModules/dashboard/featureModules/home/store/actions/home.actions';
import * as HomeSelector from '../../../../../featureModules/dashboard/featureModules/home/store/selectors/home.selectors';
import { AE_REPORT_STATUS } from 'projects/nga-PAP/src/app/constant/app.constant';

/**
 * ngrx imports
 */
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
/**
 * models and constant imports
 */

import { UserDetails } from 'projects/nga-PAP/src/app/featureModules/dashboard/featureModules/home/models/prescription-detail';


@Component({
  selector: 'pap-user-details-form',
  templateUrl: './user-details-form.component.html',
  styleUrls: ['./user-details-form.component.scss']
})
export class UserDetailsFormComponent implements OnInit, OnChanges {

  @Input() functionality = '';
  @Input() formData = null;

  @Input() aeCurrentStatus = '';

  @Output() fetchAeReportEvent = new EventEmitter<UserDetails>();

  /**
   * Form pediatric report.
   */
  public userNameInfo: FormGroup;
  /**
   * Properties related to user details retrieval and filtering.
   */
  public filterUserDetailsList = [];
  public userDetail: UserDetails;
  private pageNumber = 1;
  public limit = 50;
  private isNextPageAvailable = false;

  public aeReportStatus  = AE_REPORT_STATUS;

  /**
   * subscription
   */
  private subscriptions: Subscription = new Subscription();

  /**
   * hold the input value for form
   */
  private previousInputValue = '';
  private inputValue = '';

  private PHARMA_CODE = 'pharmaCode';
  public disabled: boolean;

  /**
   * drop-down value of social media
   */
  socialMediaList = [
    { id: 1, name: 'X' },
    { id: 2, name: 'Facebook' },
    { id: 3, name: 'TikTok' },
    { id: 1, name: 'Instagram' },
    { id: 2, name: 'Blogs' },
    { id: 3, name: 'Youtube' },
    { id: 2, name: 'WordPress' },
    { id: 3, name: 'Others' }
  ];

  /**
   * check if the any item is selected
   * from username options
   */
  isItemSelected = false;

  /**
   * Getter methods related to the userNameInfo FormGroup.
   *
   * - `form`: Retrieves the form controls of the userNameInfo FormGroup, indexed by their names.
   * - `formValidity`: Retrieves the validity of the userNameInfo FormGroup,
   *                    returning a boolean indicating whether the form is valid.
   * - `formValues`: Retrieves the raw form values of the userNameInfo FormGroup.
   */
  get form(): { [key: string]: AbstractControl } { return this.userNameInfo.controls; }
  get formValidity(): boolean { return this.userNameInfo.valid && this.isItemSelected ; }
  get formValues(): { userNameInfo: any; } { return { userNameInfo: this.userNameInfo.getRawValue() }; }

  /**
   * Necessary instances.
   */
  constructor(private fb: FormBuilder, private store: Store) { }

  /**
   * Calling initialize form.
   */
  ngOnInit(): void {
    this.store.dispatch(HomeAction.resetUsersList());
    this.disabled = this.functionality === 'view';
  }

  /**
   * to detect changes on incoming form values
   * @param changes changes on formData
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formData) {
      this.disabled = this.functionality === 'view';
      this.initializeForm();
      this.userNameInfo.valid ? this.isItemSelected = true : this.isItemSelected = false;
    }
  }

  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.userNameInfo = this.fb.group({
      userName: new FormControl(
        { value: this.formData?.userName ? this.formData?.userName : '', disabled: this.disabled },
        [Validators.required]),
      socialHandle: new FormControl(
        { value: this.formData?.socialHandle ? this.formData?.socialHandle : '', disabled: this.disabled },
        [Validators.required]),
      interactionId: new FormControl(
        { value: this.formData?.interactionId ? this.formData?.interactionId : '', disabled: this.disabled },
        [Validators.required, Validators.maxLength(20)]),
    });
  }

  /**
   * Fetches the list of users based on the search query.
   * Dispatches an action to retrieve users from the API using the provided query, page number, and limit.
   *
   * @param query The search query entered by the user.
   */

  getUsersListOnSearch(query: string): void {
    const pharmaCode = localStorage.getItem(this.PHARMA_CODE) || '';
    this.store.dispatch(HomeAction.getUsersList({
      query, pharmaCode,
      page: this.pageNumber.toString(),
      limit: this.limit.toString()
    }));
    this.subscriptions.add(
      this.store.select(HomeSelector.getUsersList).subscribe(users => {
        if (!users) { return; }
        const userListData = users.data.list;
        this.filterUserDetailsList = [];
        if (userListData.length === 0) {
          this.filterUserDetailsList.push({ label: 'item', value: 'No Result Found', type: 'header' });
          this.filterUserDetailsList.push({ label: 'item', value: 'Create New Patient', type: 'button' });
        } else {
          this.filterUserDetailsList.push(
            { label: 'item', value: `${userListData.length} Result Found`, type: 'header' }
          );
          userListData.forEach(item => {
            this.filterUserDetailsList.push({ label: 'item', value: item, type: 'item' });
          });
          this.filterUserDetailsList.push({ label: 'item', value: 'Create New Patient', type: 'button' });
        }
      })
    );
  }
  /**
   * function to restrict only blankspaces entry.
   */
  userInput(fieldName: string, event: any): void {
    if (event.target.value) {
      const value = event.target.value;
      if (value[0] === ' ') {
        this.userNameInfo.get(fieldName).setValue(value.trimStart());
      }
    }
  }

  onCompleteMethod(event: any): void {
    const query = event.query.trim();
    if (query) {
      this.previousInputValue = query;
      this.getUsersListOnSearch(query);
    }
  }

  onInput(event: any): void {
    this.inputValue = event.target.value;
  }
  /**
   * Handles the selection of an item from the user details dropdown or list.
   * Emits an event to fetch adverse event report data when a new patient needs to be created.
   *
   * @param event The event object containing information about the selected item.
   */

  OnItemSelected(event: any): void {
    if (event?.type === 'header' || event?.type === 'button') {
      if (event.value === 'Create New Patient') {
        const userData: UserDetails = {
          patientId: 0,
          socialHandle: '',
          createdBy: '',
          userName: '',
          createNewPatient: true
        };
        this.fetchAeReportEvent.emit(userData);
      }
      this.userNameInfo.get('userName')?.reset();
      this.userNameInfo.get('userName')?.setValue(this.inputValue);
    } else if (event?.type === 'item') {
      const value = event.value;
      this.userNameInfo.get('userName')?.setValue(value.userName);
      this.userNameInfo.get('socialHandle')?.setValue(value.socialHandle);
      this.disabled = true;
      const userData: UserDetails = {
        patientId: value.patientId,
        socialHandle: '',
        createdBy: '',
        userName: '',
        createNewPatient: false
      };
      this.fetchAeReportEvent.emit(userData);
    }
    this.isItemSelected = true;
  }

}
