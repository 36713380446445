import { Component, OnInit } from '@angular/core';
import { Country } from '../../../models/sign-up-chatbot.model';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PATTERN } from '../../../constant/app.constant';
import { Store } from '@ngrx/store';
import * as attendantAction from '../../../store/actions/attendent/attendent.action'
import * as attendant from '../../../store/selectors/attendent/attendent.selector'
import { Subscription } from 'rxjs';
import { ToastrService } from 'projects/ngp-docquity-ds/src/lib/toastr/services/toastr.service';
import { pharma } from '../../../store/selectors/pharma/pharma.selectors';
import { PharmaNameService } from '../../../services/pharma/pharma-name.service';


@Component({
  selector: 'pap-add-attendant',
  templateUrl: './add-attendant.component.html',
  styleUrls: ['./add-attendant.component.scss']
})
export class AddAttendantComponent implements OnInit {

  public defaultCountry: Country;
  private subscriptions: Subscription = new Subscription();
  get form(): { [key: string]: AbstractControl; } { return this.addAttendantForm.controls; }

  addAttendantForm: FormGroup = this.fb.group({
    phone_number: ['', [Validators.required, Validators.maxLength(15),Validators.pattern(/^[1-9][0-9]*$/)]],
    email: ['', [Validators.required, Validators.email,Validators.pattern(PATTERN.EMAIL)]],
    first_name: ['', [Validators.required, Validators.pattern(/^[^\s][\w\s]*$/)]],
    last_name : ['', [Validators.required, Validators.pattern(/^[^\s][\w\s]*$/)]],
  });
  error: string;
  private showErrorMessage: string = '';

  pharma_id : number = 0;
  public attendantDcpmSub: Subscription;
  public attendantDcpmSubError: Subscription;
  private pharmaNameChangeSub: Subscription;


  constructor(private fb: FormBuilder,private store: Store,private toastr: ToastrService,public pharmaNameChange: PharmaNameService,) { }

  ngOnInit(): void {
    this.getCountryInfo();
    this.initializeForm();
    this.attendantDcpmSub = this.store.select(attendant.addAttendantDCPM).subscribe(data => {
      if (data) {
        this.toastr.showSuccess('Attendant has been created!', 'Success');
        this.store.dispatch(attendantAction.addAttendReset());
      }
    })
    this.attendantDcpmSubError = this.store.select(attendant.getAttendantApiError).subscribe(data => {
      if (data) {
        this.showErrorMessage = data;
        this.toastr.showError(data.message);
        console.log("error",data.message)
        this.store.dispatch(attendantAction.resetErrors())
      }
    })
    this.pharmaNameChangeSub = this.pharmaNameChange.changePharmaForPatientListing$.subscribe(data => {
      if(data){
        this.pharma_id = Number.parseInt((JSON.parse(localStorage.getItem('pharma')) as any).id);
        this.store.dispatch(attendantAction.addAttendReset());
        this.onAddAttendant()
      }
    });

    this.pharma_id = Number.parseInt((JSON.parse(localStorage.getItem('pharma')) as any).id);
  }
  /**
   * get country info 
   */
  getCountryInfo(): void {
    switch (localStorage.getItem('country')) {
      case 'TH':
        this.defaultCountry = {
          code: localStorage.getItem('country'),
          countryCode: 66,
          id: 4,
          logo: 'assets/images/thailand-signup-icon.png\n',
          name: 'Thailand'
        };
        break;
      case 'PH':
        this.defaultCountry = {
          code: localStorage.getItem('country'),
          countryCode: 63,
          id: 3,
          logo: 'assets/images/philippines-signup-icon.png\n',
          name: 'philippines'
        };
        break;
      case 'SG':
        this.defaultCountry = {
          code: localStorage.getItem('country'),
          countryCode: 65,
          id: 5,
          logo: 'assets/images/singapore-signup-icon.png\n',
          name: 'singapore'
        };
        break;
      default:
        break;
    }
  }

  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.addAttendantForm = this.fb.group({
      countryCode: [this.defaultCountry.countryCode],
      phone_number: ['', [Validators.required,this.minLengthValidator(8), this.maxLengthValidator(15),Validators.pattern(/^[1-9][0-9]*$/)]],
      email: ['', [Validators.email, Validators.pattern(PATTERN.EMAIL)]],
      first_name: ['', [Validators.required ,Validators.pattern(PATTERN.DOCTOR_NAME_REG_SG)]],
      last_name : ['', [Validators.required,Validators.pattern(/^[^\s][\w\s]*$/)]],
    });
  }
  /**
   * validator for minimum length
   */
  minLengthValidator(minLength: number): any {
    return (control) => {
      if (control.value) {
        const stringValue = control.value.toString();
        if (stringValue.length < minLength) {
          return { minLength: { requiredLength: minLength } };
        }
      }
      return null;
    };
  }
  /**
   * validator for maximum length
   */
  maxLengthValidator(maxLength: number): any {
    return (control) => {
      if (control.value !== null) {
        const stringValue = control.value.toString();
        if (stringValue.length > maxLength) {
          return { maxLength: { requiredLength: maxLength } };
        }
      }
      return null;
    };
  }
  preventWhitespace(event: KeyboardEvent): void {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }

  /**
   * validates a form for adding an attendant
   */
  onAddAttendant(): void {
    if (this.addAttendantForm.invalid) {
      return;
    }
    this.store.dispatch(attendantAction.addAttendantDCPM({
      country_code:this.addAttendantForm.get('countryCode')?.value as number, 
      phone_number: this.addAttendantForm.get('phone_number')?.value as string,
      first_name: this.addAttendantForm.get('first_name')?.value as string,
      last_name: this.addAttendantForm.get('last_name')?.value as string,
      email:this.addAttendantForm.get('email')?.value as string,
      pharma_id:this.pharma_id as number,
    }))
    this.addAttendantForm.reset();
    this.initializeForm();
  }

  ngOnDestroy(): void {
    if (this.attendantDcpmSub) {
      this.attendantDcpmSub.unsubscribe();

    }
    if (this.attendantDcpmSubError) {
      this.attendantDcpmSubError.unsubscribe();
    }
  }


}
