import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

/**
 * Rxjs imports.
 */
import { Observable } from 'rxjs';

/**
 * Environment import.
 */
import { environment } from '../../environments/environment';

/**
 * Ngrx import.
 */
import { Store } from '@ngrx/store';
import * as ErrorAction from '../store/actions/error/error.actions';


/**
 * This Interceptor is used to get all HTTP request.
 */
@Injectable()
export class PapInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authReq;
    if (navigator.onLine === false) {
      const data = { isOnline: true, showModal: true };
      this.store.dispatch(ErrorAction.setOnlineStatus({ internetConnectionStatus: data }));
      return;
    }

    let params = request.params;
    for (const key of request.params.keys()) {
        if (params.get(key) === undefined) {
            params = params.delete(key, undefined);
        }
    }

    if (request.url.indexOf('amazonaws') !== -1) {
      authReq = request.clone({
        url: request.url,
        params
      });
    } else {
      if (request.url.indexOf('/assets/translate') !== -1) {
        authReq = request.clone({
          url: request.url,
          params
        });
      } else {
        authReq = request.clone({
          url: environment.API_END_POINT + request.url,
          params
        });
      }

    }
    return next.handle(authReq);
  }

  constructor(private store: Store) { }
}
