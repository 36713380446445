<span class="p-fluid position-relative">
  <p-autoComplete
    #autoComplete
    [ngClass]="{ 'is-invalid': submitted && valueForValidation === 0 }"
    [(ngModel)]="preFetchValue"
    [field]="field"
    [emptyMessage]="emptyMessageForAutoComplete"
    [suggestions]="suggestionsList"
    [placeholder]="placeholderForAutoComplete"
    [multiple]="true"
    [autoHighlight]="true"
    [disabled]="disabledValue"
    (onBlur)="onBlur($event)"
    (click)="openSuggestion(autoComplete)"
    (onSelect)="onSelect($event)"
    (onUnselect)="onUnselect($event)"
    (completeMethod)="searchAvailableInList($event.query)"
    (ngModelChange)="selectedValues($event)"
    class="px-0 py-0 light-grey-bg h-auto border rounded input-height-auto-complete d-block form-control"
    inputStyleClass="p-0 fnt-size15px h-auto text-dark"
  >
    <ng-template pTemplate="selectedItem" let-value>
      <ng-container
        *ngTemplateOutlet="selectedItem; context: { $implicit: value }"
      ></ng-container>
    </ng-template>

    <ng-template pTemplate="item" let-suggestionsList>
      <ng-container
        *ngTemplateOutlet="
          suggestionValue;
          context: { $implicit: suggestionsList }
        "
      ></ng-container>
    </ng-template>
  </p-autoComplete>
</span>
