
<div class="page-content pt-4 pl-5 pr-5 pb-1 h-100 " id="content">
    <div class="h-100">
      <div class="row">
        <div class="col-6">
        </div>
        <div class="col-6 userSearch mr-0">
          <div class="row">
            <div class="col-12">
              <span class="p-input-icon-left p-ml-auto position-relative w-100 userSearchInput">
                <i class="pi pi-search userSearchIcon"></i>
                <input class="w-100" pInputText type="text"
                  placeholder="search" (keyup)="searchData($event)" />
              </span>
            </div>
            <div class="col-2 pl-0" >
              <img src="assets/images/search-filter-icon.svg" alt="search filter" class="cursor-pointer"
                 />
            </div>
          </div>

        </div>
      </div>

      <div *ngIf="!showEmptyCard" class="card recordList h-100 mt-3" style="max-height: calc( 100% - 56px );">

        <p-table #dt [columns]="scrollableCols" [scrollable]="true"[value]="hasListData ? attendantList : []"
          frozenWidth="0px" scrollHeight="calc(100vh - 255px)" [rows]="10" [paginator]="false"
            [loading]="isLoading" >

          <ng-template pTemplate="colgroup" let-columns>
            <colgroup>
              <col *ngFor="let col of columns" class="w300">
            </colgroup>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th id="" *ngFor="let col of columns">
                {{col.header}}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr *ngIf="!hasListData">
              <td colspan="7" class="text-left">{{'NO_RECORD_FOUND' | translate}}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-customer let-columns="columns">
            <tr *ngIf="!hasListData">
              <td colspan="7" class="text-left">{{'NO_RECORD_FOUND' | translate}}</td>
            </tr>
            <tr *ngIf="hasListData" 
              class="trBorder cursor-pointer">
              <td class="font-weight-medium font-14" *ngFor="let col of columns">
                <span class="attendantId ml-3" *ngIf="col.field === ('attendant' | translate)">
                  {{customer?.id | json}}
                </span>
                <span class="font-12 createdDate" *ngIf="col.field === ('doc' | translate)">
                  {{customer?.created_at | date: 'dd MMM yyyy, h:mm a'}}
                </span>
                <span class="medium-font-weight ml-2 " *ngIf="col.field === ('attendantName' | translate)">
                  {{customer?.first_name}} {{customer?.last_name}}
                </span>
                <span class="medium-font-weight" *ngIf="col.field === ('attendantNumber' | translate)">
                  +65-{{customer?.phone_number}}
                </span>
                <span class="medium-font-weight ml-2" *ngIf="col.field === ('email' | translate)">
                  {{customer?.email}}
                </span>
                <span class="medium-font-weight " *ngIf="col.field === ('action' | translate)">
                  <div class="edit-dropdown">
                    <a class="cursor-pointer text-center listDropdown " >
                      <img src="assets/images/Vector.svg" alt="menu icon" (click)="showdropdown()"/>
                    </a>
                    <div *ngIf="showdropdownflag">
                      <button class="dropdown-item" (click)="selectDropdownOption('edit')"  >View Patients</button>
                      <button class="dropdown-item" (click)="selectDropdownOption('preview')" >Add New Patient</button>
                    </div>
                 </div>
                 <!-- <ul class="dropdown-menu font-14" [ngClass]="getDropDownClass()"
                 (mouseleave)="toggleMoreDropdown(false)">
                 <ng-container *ngFor="let item of actionDropdownList">
                     <li  role="button"  *ngIf="item && item.value !== 'MORE'"><span class="dropdown-item"
                             (click)="dropdownSelect(item,rowData)">{{item.name}}</span>
                     </li>
                     <li  role="button"  *ngIf="item.value === 'MORE'" class="moreDropdown"><span class="dropdown-item"
                             (mouseover)="toggleMoreDropdown(true)">
                             {{item.name}} <i class="pi pi-angle-down" style="font-size: 1rem"></i>
                         </span>
                         <ul *ngIf="showMoreContainer" class="dropdown-menu font-14 d-block">
                             <ng-container *ngFor="let item of actionDropdownMoreList">
                                 <li  role="button"  *ngIf="item"><span class="dropdown-item"
                                         (click)="dropdownSelect(item, rowData)">{{item.name}}</span>
                                 </li>
                             </ng-container>
                         </ul>
                     </li>
                 </ng-container>
                 </ul> -->
                </span>

                <span *ngIf="col.field === 'program'">
                  {{customer?.program}}
                </span>

                <span *ngIf="col.field === 'contactOn'" class="date-field d-flex">
                  <span *ngIf="customer?.contactOn !== 0">{{ customer?.contactOn}}
                  </span>
                  <span *ngIf="customer?.contactOn === 0">-</span>
                  <span class="ml-auto">
                    <img src="assets/images/right-arrow-grey.png" alt="right arrow" width="15px">
                  </span>
                </span>

           
                <!-- <p *ngIf="col.field === 'transitionTime' && customer?.status === patientStatus.ACTIVE">
                  <span *ngIf="customer?.transitionTime?.value !== 0"
                    class="text-DC1313 bg-DC1313-30 fnt_Medium px-2 py-1 radius-20 d-flex align-items-center ml-auto">
                    <img src="assets/images/timer.svg" alt="inactive" class="mr-1" /> Inactive in
                    {{customer?.transitionTime?.value}} {{customer?.transitionTime?.unit}}
                  </span>
                  <span *ngIf="customer?.transitionTime?.value === 0">-</span>
                </p> -->
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="position-relative">
          <p-paginator [hidden]="tableDataLoading" #paginator * [rows]="limit"
            [totalRecords]="patientDataList.data.page.totalCount" [alwaysShow]="false"
            (onPageChange)="setMyPagination($event)" [styleClass]="tableDataLoading ? 'pointer-events-none' : ''">
          </p-paginator>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showEmptyCard" class="d-flex justify-content-center align-items-center bg-white shadow-sm ml-3 mr-5">
    <div class="text-center">
      
      <div class="mb-3">
        <img src="assets/images/Patient.svg" alt="">
        <i class="bi bi-person-plus" style="font-size: 3rem; color: gray;"></i>
      </div>
      
      <h5 class="mb-1">Your attendant list is empty</h5>
     
      <p class="text-muted mb-4">Add patient to view the list</p>
      
      <button routerLinkActive="active" [routerLink]="userRole === userLoginRole.APPROVER ? '/approval/addAttendant' : '/supervisor/addAttendant'" class="btn mb-3 btn-success btn-lg">
        <i class="bi bi-plus me-1"></i> +  Add New Attendant
      </button>
    </div>
  </div>