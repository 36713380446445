/**
 * Ngrx store imports.
 */
 import { createSelector, createFeatureSelector } from '@ngrx/store';
 import * as fromHome from '../reducers';

 export interface HomeStage {
   home: fromHome.State;
 }

 export const home = createFeatureSelector
   <HomeStage, fromHome.State>(fromHome.stateFeatureKey);

 /**
  * Selector for HomePage data.
  */
 export const getHome = createSelector(
   home,
   ((state: fromHome.State) => state.homeData)
 );
