/**
 * Angular imports.
 */
import { Component, OnInit, OnDestroy, ElementRef, AfterViewInit, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
/**
 * Model imports.
 */
import { Country } from '../../../../models/sign-up-chatbot.model';
import { InputLog } from 'projects/nga-PAP/src/app/models/doctor.model';
/**
 * Ngrx store.
 */
import { Store } from '@ngrx/store';
import * as SignupAction from '../../../../store/actions/signup/signup.actions';
import * as SignupSelector from '../../../../store/selectors/signup/signup.selectors';
import * as DoctorAction from '../../../../store/actions/doctor/doctor.actions';
import * as DoctorSelector from '../../../../store/selectors/doctor/doctor.selectors';
/**
 * Environment import.
 */
import { environment } from '../../../../../environments/environment';
/**
 * Rxjs import.
 */
import { Subscription } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { delay, take } from 'rxjs/operators';
/**
 * Constant import.
 */
import {
  COUNTRIES_LIST, LANGUAGES,
  LOCAL_STORAGE_COUNTRY, DOCTOR_CONSTANTS,
  PATTERN, PARAMS_KEY, UN_EMBEDDED_PHARMA
} from '../../../../constant/app.constant';
/*
 * Service Imports.
 */
import { TranslateService } from '@ngx-translate/core';

/**
 * Login component for doctor.
 */
@Component({
  selector: 'pap-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * get pharma code.
   */
  public pharmaCode: string;
  /**
   * Contain login formGroup.
   */
  public loginForm: FormGroup;
  /**
   * Get support language.
   */
  public languages = LANGUAGES;
  /**
   * Countries subscription.
   */
  public countriesSub: Subscription;
  /**
   * Request OTP Subscription.
   */
  public requestOTPSubscription: Subscription;
  /**
   * This is used to hold countries data.
   */
  public countries: Country[];
  /**
   * Country
   */
  public country: string;
  /**
   * var for default country.
   */
  public defaultCountry: Country;
  /**
   * var for radio selection.
   */
  public radioSelect = DOCTOR_CONSTANTS.PHONE;
  /**
   * var for storing requestOtpToken.
   */
  public authToken: string;
  /**
   * var for holding login input i.e email or phone.
   */
  public loginInput: string;
  /**
   * login data subscription data.
   */
  public apiErrorMsgSub: Subscription;
  /**
   * query params data subscription data.
   */
  public pharmaQueryParam = new Subscription();
  /**
   * This is used to store the api error message.
   */
  public apiErrorMsg: string;
  /**
   * var for fetching login input from routing.
   */
  public routeObject: InputLog;

  public disabledButton = false;
  /**
   * function for login form submission.
   */
  onSubmit(): void {
    if (this.checkInvalid() && this.loginForm.invalid) {
      return;
    }
    this.store.dispatch(DoctorAction.loadOTP({
      email: this.loginForm.get('email').value as string,
      phoneNumber: this.loginForm.get('phone').value as string,
      countryCode: this.loginForm.get('countryCode').value as string,
      requestType: this.radioSelect === 'phone' ? 'phoneNumber' : 'email',
      hCaptchaToken: this.loginForm.get('hCaptchaToken').value as string,
      pharmaCode: this.pharmaCode
    }));

    this.loginInput = this.radioSelect === DOCTOR_CONSTANTS.PHONE
      ? this.loginForm.get('phone').value as string : this.loginForm.get('email').value;
    this.store.dispatch(DoctorAction.setLoginInput({ loginInput: this.loginInput }));
    this.requestOTPSubscription = this.store.select(DoctorSelector.getRequestOTPToken).subscribe(data => {
      if (data != null) {
        this.store.dispatch(DoctorAction.resetErrors());
        this.authToken = data;
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(this.authToken);
        if (decodedToken.data?.pharma?.process === UN_EMBEDDED_PHARMA) {
          sessionStorage.setItem('process', decodedToken.data?.pharma?.process);
          localStorage.setItem('doctorAuth', this.authToken);
          const param = PARAMS_KEY.PHARMA_CODE;
          const pharmaCode = this.route.snapshot.queryParams[param];
          if (pharmaCode) {
            const pharmaId = (decodedToken.data?.pharma?.id).toString();
            localStorage.setItem('pharmaId', pharmaId);
          }
          if (decodedToken.sub === 'newRegistrationSession') {
            if (pharmaCode) {
              this.routerNavigation('/addDoctor', pharmaCode);
            }
            else {
              this.routerNavigation('/addDoctor', null);
            }
          } else {
            if (pharmaCode) {
              this.routerNavigation('/viewDoctor', pharmaCode);
            }
            else {
              this.routerNavigation('/viewDoctor', null);
            }
          }
          DoctorAction.setVerifyOTPData({ otpVerifyToken: this.authToken });
        } else {
          if (sessionStorage.getItem('process')) {
            sessionStorage.removeItem('process');
          }
          if (this.pharmaCode) {
            this.router.navigate(['/doctor/otp-verify'], {
              queryParams: {
                pharmaCode: this.pharmaCode
              },
            });
          }
          else {
            this.router.navigate(['/doctor/otp-verify']);
          }
        }
      }
    });
  }

  public routerNavigation(name: string, pharmaCode: string, role?: string): void {
    if (pharmaCode) {
      this.router.navigate([name], {
        queryParams: {
          pharmaCode,
          r: role ? role : false
        },
      });
    }
    else {
      this.router.navigate([name], { queryParams: { r: role ? role : false } });
    }
  }

  /**
   * function to restrict paste input in number
   */
  // tslint:disable-next-line:no-shadowed-variable
  public onPaste(event: any, fieldName: string): void {
    if (this.loginForm.get(fieldName).value?.length > 0) {
      let value = this.loginForm.get(fieldName).value;
      if (value[0] === '0') {
        value = value.substring(1, value.length);
        this.loginForm.get(fieldName).setValue(value);
      }
    }
  }
  /**
   * function to restrict zero at first index
   */
  // tslint:disable-next-line:no-shadowed-variable
  public phoneValidator(event: any, fieldName: string): void {
    if (this.loginForm.get(fieldName).value?.length > 0) {
      const pattern = PATTERN.NO_ZERO;
      if (!pattern.test(event.target.value)) {
        this.loginForm.get(fieldName).setValue(event.target.value.substring(1, event.target.value.length));
      }
    }
  }

  /**
   * hcaptcha api response handling function.
   */
  showResponse(result: string): void {
    this.loginForm.get('hCaptchaToken').setValue(result);
    this.disabledButton = true;
    this.store.dispatch(DoctorAction.setHcaptchaToken({ hcaptchaToken: result }));
    this.onSubmit();
  }
  onExpired(response: any): void {
    this.loginForm.get('hCaptchaToken').setValue('');
  }

  /**
   * resetting email and phone on radio change.
   */
  // tslint:disable-next-line:no-shadowed-variable
  onRadioChange(event): void {
    this.radioSelect = event.target.value;
    this.loginForm.get('phone').setValue('');
    this.loginForm.get('email').setValue('');
  }
  /**
   * used to disable button on click.
   */
  disableOnclick(): void {
    this.disabledButton = true;
  }

  /**
   * function for validating invalidity of email and phone.
   */
  checkInvalid(): boolean {
    if (
      (this.loginForm.get('phone').value || this.loginForm.get('email').value)
    ) {
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    this.pharmaQueryParam.add(this.route.queryParams.subscribe(params => {
      if (params.pharmaCode) {
        this.pharmaCode = params.pharmaCode;
      } else {
        localStorage.removeItem('pharmaCode');
        localStorage.removeItem('pharmaUrl');
      }
    }));
    /**
     * subscribe route params.
     */
    this.pharmaQueryParam.add(this.route.params.subscribe(data => {
      if (data.country) {
        if (data.country === COUNTRIES_LIST.PHILLIPPINS.value
          || data.country === COUNTRIES_LIST.THAILAND.value
          || data.country === COUNTRIES_LIST.SINGAPORE.value) {
          localStorage.setItem('country', data.country);
        } else {
          localStorage.removeItem('country');
          this.router.navigate(['404']);
        }
      } else {
        localStorage.setItem('country', COUNTRIES_LIST.PHILLIPPINS.value);
      }
    }));
    this.store.dispatch(DoctorAction.resetErrors());
    this.store.dispatch(DoctorAction.resetOTPRequestToken({}));
    this.apiErrorMsgSub = this.store.select(DoctorSelector.getError).subscribe(data => {
      if (data != null) {
        this.apiErrorMsg = data.messages[0];
        this.disabledButton = false;
      }
    });
    this.store.dispatch(SignupAction.loadCountries({}));
    this.countriesSub = this.store.select(SignupSelector.getCountryList).subscribe(data => {
      if (data !== null) {
        const countryHeader = this.route.snapshot.paramMap.get('country');
        if (countryHeader === COUNTRIES_LIST.THAILAND.value
          || countryHeader === COUNTRIES_LIST.PHILLIPPINS.value
          || countryHeader === COUNTRIES_LIST.SINGAPORE.value ) {
          localStorage.setItem('country', countryHeader);
        } else {
          localStorage.setItem('country', COUNTRIES_LIST.PHILLIPPINS.value);
        }
        this.country = localStorage.getItem(LOCAL_STORAGE_COUNTRY.COUNTRY);
        for (const country of data.list) {
          if (country.code === this.country) {
            this.defaultCountry = country;
            break;
          }
          this.defaultCountry = country;
        }
        this.loginForm = this.formBuilder.group({
          email: ['', [Validators.pattern(PATTERN.EMAIL)]],
          phone: ['', [Validators.minLength(7), Validators.maxLength(14)]],
          countryCode: [this.defaultCountry.countryCode],
          hCaptchaToken: ['']
        });
        if (this.routeObject?.isRouted) {
          if (this.routeObject.inputType === 'email') {
            this.radioSelect = 'email';
            this.loginForm.get('email').setValue(this.routeObject.inputValue);
          } else {
            this.radioSelect = 'phone';
            this.loginForm.get('phone').setValue(this.routeObject.inputValue);
          }
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.routeObject = JSON.parse(this.route.snapshot.paramMap.get('inputLog'));
  }
  /**
   * used to reset errors.
   */
  resetErrors(): void {
    this.store.dispatch(DoctorAction.resetErrors());
    this.apiErrorMsg = '';
  }
  ngOnDestroy(): void {
    if (this.countriesSub) {
      this.countriesSub.unsubscribe();
    }
    if (this.requestOTPSubscription) {
      this.requestOTPSubscription.unsubscribe();
    }
    if (this.apiErrorMsgSub) {
      this.apiErrorMsgSub.unsubscribe();
    }
    if (this.pharmaQueryParam) {
      this.pharmaQueryParam.unsubscribe();
    }
  }
  /**
   * used to create all necessary instances.
   *
   * @param formBuilder use to create form builder.
   * @param router used to navigate the page.
   * @param translate  used to handle the text from language file.
   * @param store used for hit the api's and get data after success or false.
   * @param route used to get data from query params from url.
   */
  constructor(
    private formBuilder: FormBuilder, private router: Router,
    public translate: TranslateService, private store: Store,
    private route: ActivatedRoute) {
  }
}
