<div class="p-fluid position-relative createmeeting-datepicker">
  <p-calendar (onSelect)="selectedValues($event)" [(ngModel)]="defaultDate"
    class="form-control p-0 fnt-size12px createmeeting-datepicker__input light-grey-bg input-height"
    styleClass="h-100 light-grey-bg rounded" [showTime]="showTime" [minDate]="minDate" [hourFormat]="hourFormat"
    [placeholder]="placeholder" [disabled]="disabled" [timeOnly]="timeOnly" [showButtonBar]="showButtonBar"
    [todayButtonStyleClass]="todayButtonStyleClass" [clearButtonStyleClass]="clearButtonStyleClass"
    [panelStyleClass]="panelClass"
    [maxDate]="maxDate"
    [monthNavigator]="monthNavigator"
    [yearNavigator]="yearNavigator"
    [yearRange]="yearRange"
    [showIcon]="showIcon"
    dateFormat="dd M yy"
    inputStyleClass="border-0 py-0 bg-transparent createmeeting-datepicker__table medium-font-weight fnt-size12px"
    [ngClass]="{ 'is-invalid': submitted && valueForValidation === ''}" [readonlyInput]="true"
    (onClearClick)="onClearClick($event)">
  </p-calendar>
  <div *ngIf="showCustomIcon" class="custom-icon-position">
    <div class="cursor-pointer" (click)="onCustomClick()" #dataContainer></div>
  </div>
</div>
