/**
 * angular imports
 */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
/**
 * constants imports
 */
import aeReportValidation from '../../../.../../../../../assets/static/ae-report-validation.json';

/**
 * Used to show the form for treatment details.
 */
@Component({
  selector: 'pap-treatment-details-form',
  templateUrl: './treatment-details-form.component.html',
  styleUrls: ['./treatment-details-form.component.scss']
})
export class TreatmentDetailsFormComponent implements OnInit, OnChanges {
  @Input() functionality = '';
  @Input() formData = null;
  @Input() process: string;
  /**
   * current date
   */
  currentDate = new Date();

  /**
   * Form treatment details.
   */
  public treatmentDetailsForm: FormGroup;
  disabled: boolean;

  /**
   * case type options
   */
  caseTypeOptions = [
    { id: 1, name: 'Spontaneous' },
    { id: 2, name: 'Report from Program Participants' },
  ];
  selectedCase = {id: 0, name: ''};
  /**
   * action options
   */
  actionOptions = [
    { id: 1, name: 'Continued' },
    { id: 2, name: 'Discontinued' },
    { id: 3, name: 'Suspended' },
    { id: 4, name: 'NA' },
  ];
  selectedAction = {id: 0, name: ''};
  /**
   * remarks list
   */
  private remarksList = [
    { id: 1, name: 'Deceased from Cancer' },
    { id: 2, name: 'Deceased from Other causes' },
    { id: 3, name: 'Death Cause Unknown' },
    { id: 4, name: 'Serious Adverse Events' },
    { id: 5, name: 'Non-Serious Adverse Events' },
    { id: 6, name: 'Lost to Follow-up' },
    { id: 7, name: 'Non-compliance' },
    { id: 8, name: 'Drug Exposure During Pregnancy' },
    { id: 9, name: 'Exposure During Breast Feeding or Lactation' },
    { id: 10, name: 'Drug Overdose' },
    { id: 11, name: 'Drug Abuse' },
    { id: 12, name: 'Misuse' },
    { id: 13, name: 'Off Label Use' },
    { id: 14, name: 'Medication Error' },
    { id: 15, name: 'Occupational Exposure' },
    { id: 16, name: 'Lack of Efficacy and Disease progression' },
    { id: 17, name: 'Exposure to an Infectious Agent' },
    { id: 18, name: 'Drug Interaction' },
    { id: 19, name: 'Medical Devices' },
    { id: 20, name: 'Suicide or Attempted Suicide' },
    { id: 21, name: 'Unexpected Benefit' },
    { id: 22, name: 'Changed Treatment from Disease progression' },
    { id: 23, name: 'Changed Treatment from Drug resistance' },
    { id: 24, name: 'Changed Treatment from Unknown reason' },
    { id: 25, name: 'Change Treatment from Other reasons' },
    { id: 26, name: 'Changed Treatment from SAEs' },
    { id: 27, name: 'Changed Treatment from Non-Serious Adverse Events' },
    { id: 28, name: 'NA' },
  ];
  public filterRemarksList = [];

  /**
   * store to local variable to use
   * in template
   */
  aeReportValidation = aeReportValidation;

  /**
   * setter for the form controls.
   */
  get form(): { [key: string]: AbstractControl } { return this.treatmentDetailsForm.controls; }
  get formValidity(): boolean { return this.treatmentDetailsForm.valid; }
  get formValues(): { treatmentDetailsForm: any; } { return { treatmentDetailsForm: this.treatmentDetailsForm.value }; }

  constructor(private fb: FormBuilder, ) {}

  ngOnInit(): void {
    this.disabled = this.functionality === 'view';
    this.initializeForm();
  }

  /**
   * to detect changes on incoming form values
   * @param changes changes on formData
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formData) {
      this.initializeForm();
    }
  }
  /**
   * function to restrict only blankspaces entry.
   */
  userInput(fieldName: string, event: any): void {
    if (event.target.value) {
      const value = event.target.value;
      if (value[0] === ' ') {
        this.treatmentDetailsForm.get(fieldName).setValue(value.trimStart());
      }
    }
  }

  initializeForm(): void {
    this.treatmentDetailsForm = this.fb.group({
      drugName: new FormControl(
        {value : this.formData?.drugName ? this.formData?.drugName : '', disabled: this.disabled},
        (this.checkIfFieldExists('drugName') && this.checkIfFieldIsMandatory('drugName'))
        ? [Validators.required, Validators.maxLength(100)] : null),
      dosingRegimen: new FormControl(
        {value : this.formData?.dosingRegimen ? this.formData?.dosingRegimen : '', disabled: this.disabled},
        (this.checkIfFieldExists('dosingRegimen') && this.checkIfFieldIsMandatory('dosingRegimen'))
        ? [Validators.required, Validators.maxLength(100)] : null),
      caseType: new FormControl(
        {value : this.formData?.caseType ? this.formData?.caseType : '', disabled: this.disabled},
        (this.checkIfFieldExists('caseType') && this.checkIfFieldIsMandatory('caseType'))
        ? [Validators.required] : null),
      dateReceivedInfo: new FormControl(
        {value : this.formData?.dateReceivedInfo ? this.formData?.dateReceivedInfo : '',
        disabled: this.disabled},
        (this.checkIfFieldExists('dateReceivedInfo') && this.checkIfFieldIsMandatory('dateReceivedInfo'))
        ? [Validators.required] : null),
      dateReportToPs: new FormControl(
        {value : this.formData?.dateReportToPs ? this.formData?.dateReportToPs : '',
        disabled: this.disabled},
        (this.checkIfFieldExists('dateReportToPs') && this.checkIfFieldIsMandatory('dateReportToPs'))
        ? [Validators.required] : null),
      aeInteractionId: new FormControl(
        {value : this.formData?.aeInteractionId ? this.formData?.aeInteractionId : '', disabled: this.disabled},
        (this.checkIfFieldExists('aeInteractionId') && this.checkIfFieldIsMandatory('aeInteractionId'))
        ? [Validators.required, Validators.maxLength(100)] : null),
      safetyReportIntId: new FormControl(
        {value : this.formData?.safetyReportIntId ? this.formData?.safetyReportIntId : '', disabled: this.disabled},
        (this.checkIfFieldExists('safetyReportIntId') && this.checkIfFieldIsMandatory('safetyReportIntId'))
        ? [Validators.required, Validators.maxLength(100)] : null),
      action: new FormControl(
        {value : this.formData?.action ? this.formData?.action : '', disabled: this.disabled},
        (this.checkIfFieldExists('action') && this.checkIfFieldIsMandatory('action'))
        ? [Validators.required] : null),
      remark: new FormControl(
        {value : this.formData?.remark ? this.formData?.remark : '', disabled: this.disabled},
        (this.checkIfFieldExists('remark') && this.checkIfFieldIsMandatory('remark'))
        ? [Validators.required] : null),
    });
    if (this.formData?.caseType) {
      this.selectedCase = this.caseTypeOptions.find(
        (caseType) => caseType.name === this.formData.caseType
      );
    }
    if (this.formData?.action) {
      this.selectedAction = this.actionOptions.find(
        (action) => action.name === this.formData?.action
      );
    }
  }

  /**
   * check if the input field name exists for
   * given process
   * @param fieldName field name
   */
  checkIfFieldExists(fieldName: string): boolean {
    return fieldName in aeReportValidation[this.process]?.treatmentDetails;
  }

  /**
   * check if the input field is mandatory or not
   */
  checkIfFieldIsMandatory(fieldName: string): boolean {
    return aeReportValidation[this.process]?.treatmentDetails[fieldName]?.mandatory;
  }

  /**
   * get name of the field
   * @param fieldName field name
   * @returns name of the field
   */
  getFieldName(fieldName: string): string {
    return aeReportValidation[this.process]?.treatmentDetails[fieldName]?.title;
  }
  /**
   * get placeholder of the field
   * @param fieldName field name
   * @returns placeholder value of the field
   */
  getFieldPlaceholder(fieldName: string): string {
    return aeReportValidation[this.process]?.treatmentDetails[fieldName]?.placeholder;
  }

  /**
   * set input case to form
   * @param event selected case
   */
  setDataToForm(event, fieldName: string): void {
    this.treatmentDetailsForm.get(fieldName).setValue(event.name);
  }

  /**
   * to filter remarks based on input query
   * @param event input query
   */
  filterRemarksOnInput(event): void {
    this.filterRemarksList = this.remarksList;
    const filtered: any[] = [];
    const query = event.query;

    for (const factor of this.remarksList) {
      const remark = factor;
      if (remark.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(remark);
      }
    }

    this.filterRemarksList = filtered;
  }

}
