<dngx-toastr></dngx-toastr>
<router-outlet></router-outlet>


<div *ngIf="(isOnlineObservable$ | async).isOnline === true && (isOnlineObservable$ | async).showModal === true">
  <dngx-model
   [displayModal]="(isOnlineObservable$ | async).isOnline"
   [dismissableMask]="false"
   [headerVisible]="false"
   [closable]="true"
  (hideModal)="hideModal($event)">
   <p class="text-center fnt-size16px pb-2">No internet connection available</p>
   <p class="text-center pb-4">Please check your internet connection</p>
  </dngx-model>
</div>
