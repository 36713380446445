import { createAction, props } from '@ngrx/store';


export const loadOTP = createAction(
  'Attendant| Login Load OTP',
  props<{phoneNumber: string, countryCode: string, user_type: string, hCaptchaToken: string, }>()
);

export const setOtp = createAction(
  'Attendant|Set otp',
  props<{otpRequestToken: string}>()
);


export const submitForm = createAction(
  'Attendant | Submit Form',
  props<{ first_name: string; last_name: string; email: string }>()
);
export const submitFormSuccess = createAction(
  'Attendant | Submit Form Success',
  props<{ response: any }>()
);

export const submitFormFailure = createAction(
  'Attendant | Submit Form Failure',
  props<{ error: any }>()
);
