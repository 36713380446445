/**
 * Angular imports.
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HybridComponent } from './hybrid/hybrid.component';

/**
 * Rxjs import.
 */
import { Subscription } from 'rxjs';

/**
 * Ngrx store
 */
import { Store } from '@ngrx/store';
import * as LoginAction from '../../store/actions/login/login.actions';
import * as LoginSelector from '../../store/selectors/login/login.selectors';

/**
 * Load sign-in component.
 */
@Component({
  selector: 'pap-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  /**
   * login data subscription data.
   */
  public apiErrorMsgSub: Subscription;

  /**
   * This is used to store the api error message.
   */
  public apiErrorMsg: string;

  ngOnInit(): void {
    // parent ngOnInit runs before child component.
    this.store.dispatch(LoginAction.resetLoginStore());

    this.apiErrorMsgSub = this.store.select(LoginSelector.getLoginError).subscribe(data => {
      if (data && data.error && data.error.messages && data.error.messages.length > 0) {
        this.apiErrorMsg = data.error.messages[0];
      }
    });
  }
  /**
   * Receive login data.
   */
  loginData(data): void {
    localStorage.removeItem('login');
    localStorage.removeItem('pharmaCode');
    this.store.dispatch(LoginAction.resetLoginStore());
    this.store.dispatch(LoginAction
      .verifyEmailPassword({ email: data.email, password: data.password }));
  }

  /**
   * Unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.apiErrorMsgSub) {
      this.apiErrorMsgSub.unsubscribe();
    }
  }

  /**
   * All Required instances.
   */
  constructor(private store: Store) { }

}
