import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { requestOtp } from '../../../store/actions/attendent/attendent.action';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as SignupAction from '../../../store/actions/signup/signup.actions';
import * as SignupSelector from '../../../store/selectors/signup/signup.selectors';
import { Subscription } from 'rxjs';
import { Country } from '../../../models/sign-up-chatbot.model';
import { PATTERN } from '../../../constant/app.constant';


@Component({
  selector: 'pap-attendent-login',
  templateUrl: './attendent-login.component.html',
  styleUrls: ['./attendent-login.component.scss']
})
export class AttendentLoginComponent implements OnInit {

  /**
   * view otp1 child.
   */
   @ViewChild('otp1')
   otp1: ElementRef;
   /**
    * view otp2 child.
    */
   @ViewChild('otp2')
   otp2: ElementRef;
   /**
    * view otp3 child.
    */
   @ViewChild('otp3')
   otp3: ElementRef;
   /**
    * view otp4 child.
    */
   @ViewChild('otp4')
   otp4: ElementRef;
  //  /**
  //  * Used for resend otp view counter value.
  //  */
  public counterValue = 0;
  /**
   * Used for resend otp counter.
   */
  public counter = 0;
  /**
   * Used for resend otp counter interval.
   */
  public interval = 0;




  mobileForm: FormGroup;
  public otpForm: FormGroup;

  isValid = false;

  showOtpComponent = false;
   /**
    * Array to hold the OTP digits
    */
   otpDigits: string[] = ['', '', '', ''];
   otp: string[] = ['', '', '', ''];
  countriesSub: Subscription;
  public countries: Country[];


  constructor(private fb: FormBuilder ,
              private store: Store , private router: Router,
              private formBuilder: FormBuilder, ) {
    this.mobileForm = this.fb.group({
      countryCode: ['66', Validators.required],
      mobileNumber: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[0-9]/), Validators.maxLength(16), Validators.minLength(8)
        ]
      ],
    });
  }

  ngOnInit(): void {
    this.store.dispatch(SignupAction.loadCountries({}));
    this.countriesSub = this.store.select(SignupSelector.getCountryList).subscribe(data => {
      if (data !== null) {
        this.countries = data.list;
      }
    });



    this.otpForm = this.formBuilder.group({
      otp1: ['', [Validators.required]],
      otp2: ['', [Validators.required]],
      otp3: ['', [Validators.required]],
      otp4: ['', [Validators.required]]
    });

    //  /**
    //  * Resend otp timer.
    //  */
    this.counterValue = 500;
    this.counter = 500;
    this.interval = 1000;
  }

  /**
   * Receive counter value.
   */
  receiveCounterValue(event): void {
    this.counterValue = event;
    if (this.counterValue === 0) {
      this.counter = 0;
    }
  }

  resendOtp(): void {
    this.counterValue = 20;
    this.counter = 20;
    this.interval = 1000;
  }

  /**
   *  Used to auto focus.
   */
  keytab(event, index: number): void {
    const numbers = PATTERN.WHOLE_NUMBER_REGEX;
    if (event.code === 'Backspace' || event.key.match(numbers)) {
      if (index === 1) {
        event.code === 'Backspace' ? this.otp1.nativeElement.focus() : this.otp2.nativeElement.focus();
      } else if (index === 2) {
        event.code === 'Backspace' ? this.otp1.nativeElement.focus() : this.otp3.nativeElement.focus();
      } else if (index === 3) {
        event.code === 'Backspace' ? this.otp2.nativeElement.focus() : this.otp4.nativeElement.focus();
      } else {
        event.code === 'Backspace' ? this.otp3.nativeElement.focus() : this.otp4.nativeElement.focus();
      }
    }
  }

  onSubmit(): void {
    if (this.mobileForm.valid) {
      this.router.navigate(['/attendent/otp']);
    }
  }

  getOtp(): void {
    this.showOtpComponent = true;
  }

  validateOtp(): boolean {
    return this.otp.every(digit => digit.length === 1);
  }

  /**
   * to validate no. on bases of event
   * @param event keyboard event
   */
  validateNumberLength(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {
      return;
    }
    if (!/^\d$/.test(event.key)) {
      event.preventDefault();
      return;
    }
    if (input.value.length === 0 && event.key === '0') {
      event.preventDefault();
      return;
    }
    if (input.value.length >= 15) {
      event.preventDefault();
      return;
    }
  }

  // backEditAttendantLoginPage(){
  //   this.showOtpComponent = false;
  // }

    onInputChange(event: Event): void {
      const input = (event.target as HTMLInputElement).value;
      if (input.length >= 8 && input.length <= 15 && !input.startsWith('0') && /^\d+$/.test(input)) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    }


    // onRequestOtp() {
    //   const countryCode = this.mobileForm.value.countryCode;
    //   const mobile = this.mobileForm.value.mobileNumber;
    //   this.store.dispatch(requestOtp({ countryCode, mobile }));
    // }



 public moveToNext(currentInput, nextInputId): void {
    if (currentInput.value.length === 1) {
        document.getElementById(nextInputId).focus();
    }
 }

 onKeyUp(event: KeyboardEvent, index: number): void {
  const input = event.target as HTMLInputElement;
  console.log('index ', index );


  if (input.value.length === 1 && index < 3) {
    const nextInput = document.getElementById('otp' + (index + 1));
    console.log('nex input', nextInput);
    if (nextInput) {
      nextInput.focus();
    }
  }


  if (event.key === 'Backspace' && input.value.length === 0 && index > 0) {
    const prevInput = document.getElementById('otp' + (index - 1));
    if (prevInput) {
      prevInput.focus();
    }
  }
}


}
