<div>
  <h5 class="fnt-size18px text-heading-grey mb-3">{{'SUSPECT_MED_DEVICE_INFO' | translate}}</h5>
  <form [formGroup]="suspectMedDeviceInfoForm">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"DEVICE_NAME" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="deviceName" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_DEVICE_NAME' | translate}}" [maxlength]="20" />
          </div>
          <div *ngIf="form.deviceName.touched && form.deviceName.invalid" class="d-none text-danger fnt-size12px">
            {{"DEVICE_NAME" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"ASSOCIATE_MED_PRO_INDICATION" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="associatedMedicinalProduct" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_ASSOCIATE_MED_PRO' | translate}}" [maxlength]="20" />
          </div>
          <div *ngIf="form.associatedMedicinalProduct.touched && form.associatedMedicinalProduct.invalid"
            class="d-none text-danger fnt-size12px">
            {{"ASSOCIATE_MED_PRO" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            {{'USER_DEVICE' | translate}}
            <span class="text-danger d-none">*</span>
          </label>
          <select class="form-control fnt-size12px fnt_Medium bg-porcelain-white"
            formControlName="userOfDevice">
            <option value='' disabled selected class="d-none">
              {{'SELECT_USER' | translate}}
            </option>
            <option *ngFor="let form of userOfDeviceList" [value]="form.name"
              [selected]="form.name === form.userOfDevice?.value" class="mt-5">
              {{form.name}}
            </option>
          </select>
          <div *ngIf="(form.userOfDevice.touched) && form.userOfDevice.invalid" class="text-danger fnt-size10px d-none">
            {{'USER_DEVICE' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-3">
        <div class="form-group">
          <label class="fnt-size12px">
            {{ 'DEVICE_REUSED' | translate }}
          </label>
          <div class="custom-checkbox">
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="deviceYes" value="Yes"
                formControlName="wasDeviceReused">
              <label class="radio-button-text cursor-pointer" for="deviceYes">{{ 'YES' | translate }}</label>
            </span>
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="deviceNo" value="No"
                formControlName="wasDeviceReused">
              <label class="radio-button-text cursor-pointer" for="deviceNo">{{ 'NO' | translate }}</label>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group mb-2 position-relative">
          <label class="fnt-size12px">
            {{'DEVICE_USE_START_DATE' | translate}}
            <span class="text-danger d-none">*</span>
          </label>
          <div class="calendar-field w-100">
            <p-calendar [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [touchUI]="false"
              yearRange="1922:2030" [inline]="false" panelStyleClass="bg-white border"
              [styleClass]="form.deviceUseStartDate.touched && form.deviceUseStartDate.invalid ? 'pcomponent-invalid w-100' : 'w-100'"
              [inputStyleClass]="'form-control fnt-size12px fnt_Medium w-100 rounded'"
              formControlName="deviceUseStartDate" placeholder="{{'SELECT_DATE' | translate}}" dateFormat="dd M yy">
            </p-calendar>
          </div>
          <div *ngIf="(form.deviceUseStartDate.touched ) && form.deviceUseStartDate.invalid"
            class="d-none text-danger fnt-size10px">
            {{'DEVICE_USE_START_DATE' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group mb-2 position-relative">
          <label class="fnt-size12px">
            {{'DEVICE_USE_STOP_DATE' | translate}}
            <span class="text-danger d-none">*</span>
          </label>
          <div class="calendar-field w-100">
            <p-calendar [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [touchUI]="false"
              yearRange="1922:2030" [inline]="false" panelStyleClass="bg-white border"
              [styleClass]="form.deviceUseStopDate.touched && form.deviceUseStopDate.invalid ? 'pcomponent-invalid w-100' : 'w-100'"
              [inputStyleClass]="'form-control fnt-size12px fnt_Medium w-100 rounded'"
              formControlName="deviceUseStopDate" placeholder="{{'SELECT_DATE' | translate}}" dateFormat="dd M yy">
            </p-calendar>
          </div>
          <div *ngIf="(form.deviceUseStopDate.touched ) && form.deviceUseStopDate.invalid"
            class="d-none text-danger fnt-size10px">
            {{'DEVICE_USE_STOP_DATE' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"UDI" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="udiAndSoftwareVersion" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_UDI' | translate}}" [maxlength]="100" />
          </div>
          <div *ngIf="form.udiAndSoftwareVersion.touched && form.udiAndSoftwareVersion.invalid"
            class="d-none text-danger fnt-size12px">
            {{"UDI" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"ACTION_TAKEN_LOCATION_SUSPECTED_DEVICE" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="actionAndLocation" type="text" class="form-control fnt-size14px"
              placeholder="{{'REPLACED_LOCATION_TIME_REPORTING' | translate}}" [maxlength]="100" />
          </div>
          <div *ngIf="form.actionAndLocation.touched && form.actionAndLocation.invalid"
            class="d-none text-danger fnt-size12px">
            {{"ACTION_TAKEN_LOCATION_SUSPECTED_DEVICE" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"SOFTWARE_VERSION_SERIAL_NO_BATCH_NO_EXPIRY_DATE" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="softwareVersion" type="text" class="form-control fnt-size14px"
              placeholder="{{'SOFTWARE_VERSION_SERIAL_NO_BATCH_NO_EXPIRY_DATE' | translate}}" [maxlength]="100" />
          </div>
          <div *ngIf="form.udiAndSoftwareVersion.touched && form.udiAndSoftwareVersion.invalid"
            class="d-none text-danger fnt-size12px">
            {{"SOFTWARE_VERSION_SERIAL_NO_BATCH_NO_EXPIRY_DATE" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
