/**
 * ngrx store imports
 */
import { createAction, props } from '@ngrx/store';
import { PharmaInfoData } from '../../../models/pharma.model';
/**
 * model imports
 */
import { Error, PharmaResponse, PharmaDetail, PharmaUserData, DoctorPharmaConsent } from '../../../models/responses.model';

/**
 * laoading pharma details
 */
export const loadPharmas = createAction(
  '[Pharma] Load Pharmas',
  props<{ pharmaCode: string}>()
);
/**
 * storing pharma details
 */
export const setPharmas = createAction(
  '[Pharma] Pharma Detail',
  props<{ data: PharmaDetail}>()
);
/**
 * flag for success
 */
export const loadPharmasSuccess = createAction(
  '[Pharma] Load Pharmas Success',
  props<{ data: boolean }>()
);
/**
 * to store error
 */
export const loadPharmasFailure = createAction(
  '[Pharma] Load Pharmas Failure',
  props<{ error: Error }>()
);
/**
 * to reset error
 */
export const resetError = createAction(
  '[Pharma] Reset Pharmas Failure',
);
/**
 * load pharmaInfo
 */
export const loadPharmaInfo = createAction(
  '[Pharma] Load Pharma Info',
  props<{ pharmaCode: string}>()
);
/**
 * set pharmaInfo data
 */
export const setPharmaInfo = createAction(
  '[Pharma] Set Pharma Info',
  props<{ data: PharmaInfoData}>()
);
/**
 * to get pharma user mapped detail
 */
export const getUserMappedPharmaList = createAction(
  '[Pharma] GET PHARMA LIST MAPPED WITH USER',
  props<{userId: number}>()
);
/**
 * storing pharma list
 */
export const setUserMappedPharmaList = createAction(
  '[Pharma] SET PHARMA LIST MAPPED WITH USER',
  props<{pharmaList: PharmaUserData[]}>()
);
/**
 * Reset pharma list
 */
export const resetUserMappedPharmaList = createAction(
  '[Pharma] RESET PHARMA LIST MAPPED',
  props<{}>()
);
/**
 * pharma specific doctor consent
 */
export const getDoctorPharmaConsent = createAction(
  '[Pharma] GET PHARMA SPECIFIC DOCTOR CONSENT',
  props<{pharmaCode: string, token: string}>()
);
/**
 * set doctor pharma consent
 */
export const setDoctorPharmaConsent = createAction(
  '[Pharma] SET PHARMA SPECIFIC DOCTOR CONSENT',
  props<{consent: DoctorPharmaConsent}>()
);
