import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';


/**
 * Store imports.
 */
import { Store } from '@ngrx/store';
import * as HomeAction from '../../../../../../featureModules/dashboard/featureModules/home/store/actions/home.actions';
import * as HomeSelector from '../../../../../../featureModules/dashboard/featureModules/home/store/selectors/home.selectors';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pap-acknowledment-info-form',
  templateUrl: './acknowledment-info-form.component.html',
  styleUrls: ['./acknowledment-info-form.component.scss']
})
export class AcknowledmentInfoFormComponent implements OnInit, OnDestroy {

  @Input() functionality = '';
  @Input() formData = null;

  @Output() saveAcknowledgment = new EventEmitter<any>();

  /**
   * Form acknowledgment report.
   */
  public acknowledgmentInfoForm: FormGroup;
  public disabled = false;

  /**
   * used to hold the change status loader.
   */
  public changeStatusLoader = false;
  private subscriptions: Subscription = new Subscription();
  public activatedRouteDataSub: Subscription;



  /**
   * setter for the form controls.
   */
  get form(): { [key: string]: AbstractControl } { return this.acknowledgmentInfoForm.controls; }
  get formValidity(): boolean { return this.acknowledgmentInfoForm.valid; }
  get formValues(): { acknowledgementInfoForm: any; } {
    return { acknowledgementInfoForm: this.acknowledgmentInfoForm.value };
  }

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private activatedRoute: ActivatedRoute
  ) {
    this.store.dispatch(HomeAction.resetAeSubmitRes());
  }

  /**
   * Calling initialize form.
   */
  ngOnInit(): void {
    this.initializeForm();
    this.setValueOnForm();
    this.handleResponseFromApi();
  }

  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.acknowledgmentInfoForm = this.fb.group({
      acknowledgmentNumber: new FormControl('', [Validators.required, Validators.maxLength(50)])
    });
  }
  /**
   * function to restrict only blankspaces entry.
   */
  userInput(fieldName: string, event: any): void {
    if (event.target.value) {
      const value = event.target.value;
      if (value[0] === ' ') {
        this.acknowledgmentInfoForm.get(fieldName).setValue(value.trimStart());
      }
    }
  }
  /**
   * on save emit submit event
   */
  onSave(): void {
    const ackForms = this.acknowledgmentInfoForm.getRawValue();
    this.changeStatusLoader = true;
    this.activatedRouteDataSub = this.activatedRoute.params.subscribe(data => {
      this.store.dispatch(HomeAction.submitAcknowledgementReport({ id: data.id, form: ackForms }));
    });
  }
  /**
   * handle success response from ACK api
   */
  handleResponseFromApi(): void {
    this.subscriptions.add(this.store.select(HomeSelector.getAcknowledgmentSubmitRes)
      .subscribe(res => {
        if (res) {
          this.changeStatusLoader = false;
          this.disabled = true;
          this.store.dispatch(HomeAction.resetAckSubmitRes());
        }
      }));
  }

  /**
   * set prefilled value on acknowledgment form
   */
  setValueOnForm(): void {
    if (this.formData !== undefined && this.formData !== null && this.formData.trim() !== '') {
      this.disabled = true;
      this.acknowledgmentInfoForm.patchValue({ acknowledgmentNumber: this.formData || '' });
    }
  }

  /**
   * enable edit mode
   */
  toggleDisable(): void {
    this.disabled = !this.disabled;
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}
