import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModelComponent } from './components/model/model.component';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  declarations: [ModelComponent],
  imports: [
    CommonModule,
    DialogModule
  ],
  exports: [ModelComponent]
})
export class ModelsModule { }
