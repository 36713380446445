<div class="row no-gutters align-items-center mt-5 pt-5 create-password">
    <div class="col-12 col-md-6 col-lg-4 mx-auto">
      <div class="header  pt-4 bg-white mb-2 rounded">
            <div class="text-center pb-4">
            <img [src]=this.logoSrc alt="Logo">
            </div>
      </div>
  
      <form #resetForm="ngForm" (ngSubmit)="getFromData(resetForm.value)">
        <div class="body p-4 bg-white rounded">
            <h4 class="fnt_Bold fnt-size20px">{{'RESET_PASSWORD'| translate}}</h4>
            <p class="fnt-size15px fnt_Bold text-abbey-grey mb-3">
                {{'RESET_PASSWORD_TEXT'| translate}}
            </p>
            <div class="form-group">
                <label class="fnt-size12px fnt_Medium text-abbey-grey">{{'EMAIL_ID'| translate}}</label>
                <div class="position-relative">
                    <input ngModel type="email" placeholder="{{'ENTER_EMAIL'| translate}}" 
                    class="form-control" #email name="email" 
                    (keyup)="checkInput(email.value)" (focus)="onFocus()">
                </div>
            </div>
            <p *ngIf="errorObj && !errorMessage" class="mb-2 alert alert-danger">{{errorObj.error.message}}</p>
            <p *ngIf="errorObj && errorMessage" class="mb-2 alert alert-danger">{{errorMessage}}</p>
            <button class="w-100 btn btn-success border-none rounded" [disabled]=disable>
                {{'SEND'| translate}}
            </button>
        </div>
      </form>
    </div>
  </div>
  