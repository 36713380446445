<!-- Modal -->
<p-dialog
  [(visible)]="displayModal"
  [modal]="true"
  [maximizable]="maximizable"
  [baseZIndex]="baseZIndex"
  [draggable]="draggable"
  [resizable]="resizable"
  [dismissableMask]="dismissableMask"
  [styleClass]="styleClass"
  [contentStyleClass]="contentStyleClass"
  (onHide)="onHide($event)"
  [closable]="closable"
  [blockScroll]="blockScroll"
>
  <p-header
    *ngIf="headerVisible"
    class="font-weight-bold fnt-size20px text-dark"
  >
    <div>{{ modalTitle }}</div>
    <ng-container *ngTemplateOutlet="modalContent"> </ng-container>
  </p-header>
  <ng-content></ng-content>
</p-dialog>
