/**
 * Angular imports.
 */
import { Component } from '@angular/core';
import { Router } from '@angular/router';

/**
 * Ngrx store
 */
import { Store } from '@ngrx/store';
import * as LoginAction from '../../../store/actions/login/login.actions';

@Component({
  selector: 'pap-create-password-success',
  templateUrl: './create-password-success.component.html',
  styleUrls: ['./create-password-success.component.scss']
})
export class CreatePasswordSuccessComponent{

 /**
  * Get logo src.
  */
  public logoSrc = 'assets/images/logo.svg';

 /**
  * Get success logo src.
  */
  public successLogoSrc = 'assets/images/create-password-success-icon.svg';

  // Function used to navigate to login page.
  goToLogin(): void{
    this.store.dispatch(LoginAction.resetLoginStore());
    this.router.navigate(['/']);
  }

  // This is used to initialize instances.
  constructor(private router: Router, private store: Store){}
}
