/**
 * Angular imports.
 */
import { Component, Input, Output, EventEmitter, OnDestroy, ElementRef, ViewChild, OnInit, AfterViewInit } from '@angular/core';

/**
 * Service imports.
 */
import { TranslateService } from '@ngx-translate/core';

/**
 * PrimeNg imports.
 */
import { PrimeNGConfig } from 'primeng/api';

/**
 * Rxjs imports.
 */
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

/**
 * Common Component For primeng date.
 */
@Component({
  selector: 'dngx-date-form',
  templateUrl: './date-form.component.html',
  styleUrls: ['./date-form.component.css']
})
export class DateFormComponent implements AfterViewInit, OnDestroy {

  /**
   * Used to send the selected date outside.
   */
  @Output() emitSelectedDate: EventEmitter<string> = new EventEmitter();

  /**
   * Used to send the clear button event outside.
   */
  @Output() emitClearButton: EventEmitter<string> = new EventEmitter();
  /**
   * Used to send the event on clicking the custom button.
   */
  @Output() emitCustomButton: EventEmitter<boolean> = new EventEmitter();

  /**
   * Used to store the check if date is required.
   */
  @Input() submitted: boolean;

  /**
   * Store the check for start and end date.
   */
  @Input() selectFor: string;

  /**
   * Store the check for start and end date.
   */
  @Input() hourFormat: number;

  /**
   * Store the check for show time or not.
   */
  @Input() showTime: boolean;

  @Input() monthNavigator: boolean;
  @Input() yearNavigator: boolean;
  @Input() yearRange: boolean;

  /**
   * Store the minDate for start time.
   */
  @Input() minDate = new Date();

  /**
   * Flag for validation.
   */
  @Input() valueForValidation: string;

  /**
   * Gets default date.
   */
  @Input() defaultDate!: string;

  /**
   * This is used for calendar design class.
   */
  @Input() panelClass: string;

  /**
   * Whether to display today and clear buttons at the footer.
   */
  @Input() showButtonBar: string;

  /**
   * Style class of the today button.
   */
  @Input() todayButtonStyleClass: string;

  /**
   * Style class of the clear button.
   */
  @Input() clearButtonStyleClass: string;

  /**
   * Used to disabled the calendar.
   */
  @Input() disabled: boolean;

  /**
   * Used to set the placeholder for calendar.
   */
  @Input() placeholder: string;

  /**
   * Used to set the time only for calendar.
   */
  @Input() timeOnly: boolean;

  @Input() maxDate!: string;

  @Input() showIcon!: boolean;

  @Input() showCustomIcon!: boolean;

  @Input() customIcon!: string;

  @ViewChild('dataContainer') dataContainer!: ElementRef;
  /**
   * User to subscribe the translation subscription.
   */
  public translateSub: Subscription;

  /**
   * Used to emit the selected date.
   */
  selectedValues(event: string): void {
    this.emitSelectedDate.emit(event);
  }

  ngAfterViewInit(): void {
    if (this.dataContainer?.nativeElement) {
      this.dataContainer.nativeElement.innerHTML = this.customIcon;
    }
  }

  /**
   * Create all necessary instances and set the translation for the calendar.
   */
  constructor(private config: PrimeNGConfig, private translateService: TranslateService) {
    this.translateSub = this.translateService.get('PRIMENG')
      .pipe(take(1))
      .subscribe(res => this.config.setTranslation(res));
  }

  /**
   * Used to emit the clear date button.
   */
  onClearClick(event): void {
    this.emitClearButton.emit(event);
  }

  /**
   * on clicking custom button
   */
  onCustomClick(): void {
    this.emitCustomButton.emit(true);
  }

  /**
   * Used to unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    this.translateSub.unsubscribe();
  }
}
