/**
 * Angular imports.
 */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PATTERN, TH_SERVIER_PROCESS, TH_SPR_PROCESS } from 'projects/nga-PAP/src/app/constant/app.constant';

/**
 * Used to show the form for pediatric report.
 */
@Component({
  selector: 'pap-reporter-info-form',
  templateUrl: './reporter-info-form.component.html',
  styleUrls: ['./reporter-info-form.component.scss']
})
export class ReporterInfoFormComponent implements OnInit, OnChanges {
  @Input() functionality = '';
  @Input() formData = null;
  @Input() process: string;
  /**
   * Form pediatric report.
   */
  public reportedInfoForm: FormGroup;
  private disabled: boolean;
  /**
   * setter for the form controls.
   */
  get form(): { [key: string]: AbstractControl } { return this.reportedInfoForm.controls; }
  get formValidity(): boolean { return this.reportedInfoForm.valid; }
  get formValues(): { reportedInfoForm: any; } { return { reportedInfoForm: this.reportedInfoForm.value }; }
  /**
   *
   */
  reportedTypeList = [
    { id: 1, name: 'HCP' },
    { id: 2, name: 'Consumer' },
    { id: 3, name: 'Other' }
  ];

  specialityOptions = [
    { id: 1, name: 'Oncology' },
    { id: 2, name: 'Oncology and Hematology' },
    { id: 3, name: 'Oncosurgery' },
    { id: 4, name: 'Medicinal Oncology' },
    { id: 5, name: 'Radiation Oncology' },
  ];

  selectedSpeciality: any;

  /**
   * Necessary instances.
   */
  constructor(private fb: FormBuilder) { }

  /**
   * Calling initialize form.
   */
  ngOnInit(): void {
    this.disabled = this.functionality === 'view';
    this.initializeForm();
  }

  /**
   * to detect changes on incoming form values
   * @param changes changes on formData
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formData) {
      this.initializeForm();
    }
    /**
     * when change in process
     */
    if (changes?.process) {
      this.updateFormValidation();
    }
  }

  /**
   * update the form validation wrt
   * change in process
   */
  private updateFormValidation(): void {
    if (this.reportedInfoForm && this.process) {
      if (this.checkForServierProcess()) {
        this.reportedInfoForm.removeControl('reporterOccupation');
        this.reportedInfoForm.removeControl('fax');
        this.reportedInfoForm.removeControl('reporterFax');
        this.reportedInfoForm.removeControl('reporterAddress');
        this.reportedInfoForm.removeControl('consentToContactPhysician');
        this.reportedInfoForm.addControl('physicianSpeciality',
          this.fb.control({value : this.formData?.physicianSpeciality ? this.formData?.physicianSpeciality : '',
            disabled: this.disabled}, [Validators.required]));
        if (this.formData?.physicianSpeciality) {
          this.selectedSpeciality = this.specialityOptions.find(
            (speciality) => speciality.name === this.formData.physicianSpeciality
          );
        }
      }
    }
  }

  /**
   * function to restrict only blankspaces entry.
   */
  userInput(fieldName: string, event: any): void {
    if (event.target.value) {
      const value = event.target.value;
      if (value[0] === ' ') {
        this.reportedInfoForm.get(fieldName).setValue(value.trimStart());
      }
    }
  }
  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.reportedInfoForm = this.fb.group({
      reporterType: new FormControl(
        {value : this.formData?.reporterType ? this.formData?.reporterType : '', disabled: this.disabled},
        Validators.required),
      otherReporterType: new FormControl(
        {value : this.formData?.otherReporterType ? this.formData?.otherReporterType : '', disabled: this.disabled},
        Validators.maxLength(50)),
      reporterName: new FormControl(
        {value : this.formData?.reporterName ? this.formData?.reporterName : '', disabled: this.disabled},
        !this.checkForProcess() ? [Validators.required, Validators.maxLength(100)] : [Validators.maxLength(100)]),
      reporterOccupation: new FormControl(
        {value : this.formData?.reporterOccupation ? this.formData?.reporterOccupation : '', disabled: this.disabled},
        !this.checkForProcess() ? [Validators.required, Validators.maxLength(50)] : [Validators.maxLength(50)]),
      reporterMobileNumber: new FormControl(
        {value : this.formData?.reporterMobileNumber ? this.formData?.reporterMobileNumber : '',
         disabled: this.disabled},
         !this.checkForProcess() && !this.checkForServierProcess()
            ? [Validators.required, Validators.maxLength(20)]
            : [Validators.maxLength(20)]),
      reporterEmail: new FormControl(
        {value : this.formData?.reporterEmail ? this.formData?.reporterEmail : '', disabled: this.disabled},
        !this.checkForProcess() ? [Validators.required, Validators.maxLength(100)] : [Validators.maxLength(100)]),
      reporterFax: new FormControl(
        {value : this.formData?.reporterFax ? this.formData?.reporterFax : '', disabled: this.disabled},
        !this.checkForProcess() ? [Validators.required, Validators.maxLength(20)] : [Validators.maxLength(20)]),
      reporterAddress: new FormControl(
        {value : this.formData?.reporterAddress ? this.formData?.reporterAddress : '', disabled: this.disabled},
        !this.checkForProcess() ? [Validators.required, Validators.maxLength(100)] : [Validators.maxLength(100)]),
      consentToContactPhysician: new FormControl(
        {value : this.formData?.consentToContactPhysician ? this.formData?.consentToContactPhysician : 'Yes',
         disabled: this.disabled},
        Validators.required),
      physicianName: new FormControl(
        {value : this.formData?.physicianName ? this.formData?.physicianName : '', disabled: this.disabled},
        !this.checkForProcess() ? [Validators.required, Validators.maxLength(100)] : [Validators.maxLength(100)]),
      physicianMobileNumber: new FormControl(
        {value : this.formData?.physicianMobileNumber ? this.formData?.physicianMobileNumber : '',
          disabled: this.disabled},
          !this.checkForProcess() ? [Validators.required, Validators.maxLength(20)] : [Validators.maxLength(20)]),
      physicianEmail: new FormControl(
        {value : this.formData?.physicianEmail ? this.formData?.physicianEmail : '', disabled: this.disabled},
        !this.checkForProcess() ? [Validators.required, Validators.maxLength(100)] : [Validators.maxLength(100)]),
      physicianAddress: new FormControl(
        {value : this.formData?.physicianAddress ? this.formData?.physicianAddress : '', disabled: this.disabled},
        !this.checkForProcess() ? [Validators.required, Validators.maxLength(100)] : [Validators.maxLength(100)]),
      fax: new FormControl(
        {value : this.formData?.fax ? this.formData?.fax : '', disabled: this.disabled},
        !this.checkForProcess() ? [Validators.required, Validators.maxLength(20)] : [Validators.maxLength(20)]),
    });

    /**
     * update form fields wrt process
     */
    this.updateFormValidation();
  }

  /**
   * set input speciality to form
   * @param event selected speciality
   */
  setSpecialityToForm(event): void {
    this.reportedInfoForm.get('physicianSpeciality').setValue(event.name);
  }

  /**
   * Check for a particular process
   */
  checkForProcess(): boolean {
    return this.process === TH_SPR_PROCESS;
  }

  /**
   * clear the field in form
   * @param fieldName input field name
   */
  clearFormField(fieldName: string): void {
    this.reportedInfoForm.get(fieldName).setValue('');
  }

  /**
   * Check if process is for servier flow
   */
  checkForServierProcess(): boolean {
    return this.process === TH_SERVIER_PROCESS;
  }
}

