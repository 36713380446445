/**
 * Ngrx store.
 */
import { createSelector } from '@ngrx/store';
import * as fromRoot from '../../reducers/index';
import * as fromFile from '../../reducers/file/file.reducer';

export const file = (state: fromRoot.State) => state.file;

/**
 * Get file presigned url.
 */
export const getFilePresignedUrl = createSelector(
  file,
  (state: fromFile.State) => state.presignedUrl
);

/**
 * Get file uploaded time.
 */
export const getFileUploadingTime = createSelector(
  file,
  (state: fromFile.State) => state.uploadReqTime
);

/**
 * Get file presigned request time.
 */
export const getFilePresignedReqTime = createSelector(
  file,
  (state: fromFile.State) => state.presignedReqTime
);

export const getFilePresignedUrlWithIndex = createSelector(
  file,
  (state: fromFile.State) => state.presignedUrlWithIndex
);
