/**
 * angular imports.
 */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
/**
 * model imports.
 */
import { DoctorData } from 'projects/nga-PAP/src/app/models/responses.model';
/**
 * component used to show the header part after doctor login.
 */
@Component({
  selector: 'pap-doctor-header',
  templateUrl: './doctor-header.component.html',
  styleUrls: ['./doctor-header.component.scss']
})
export class DoctorHeaderComponent implements OnInit {
  /**
   * used to input doctor details
   */
  @Input() doctorDetails: DoctorData = null;
  /**
   * used to input doctor user profile details
   */
  @Input() userProfile: string;
  /**
   * used to hold the doctor name first letter.
   */
  public doctorName: string;
  /**
   * create all necessary instance.
   * @param router used to create route.
   */
  constructor(
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.doctorName = this.doctorDetails?.personalDetails?.firstName[0];
  }
  /**
   * logout session
   */
  logout(): void {
    localStorage.removeItem('doctorAuth');
    window.location.reload();
  }
  /**
   * used to navigate to contact us page.
   * @param router used to create route.
   * @param redirectUrlCode used to get pharma code from localStorage.
   */
  navigateToContactUs(): void {
    if (localStorage.getItem('pharmaCode')) {
      const redirectUrlCode = localStorage.getItem('pharmaCode');
      this.router.navigate(['/doctor/contact-us'], {
        queryParams: {
          pharmaCode: redirectUrlCode
        }
      });
    } else {
      this.router.navigateByUrl('/doctor/contact-us');
    }
  }
}
