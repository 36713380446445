<div *ngIf="invalidTokenMessage" class="d-flex justify-content-center align-items-center signup-invalid-token">
  <div>
    <div class="d-flex justify-content-center">
      <img src="assets/images/no-link.svg" alt="no link found">
    </div>
    <div class="d-flex justify-content-center fnt-size20px fnt_Medium text-abbey-grey">No Link found</div>
    <div class="d-flex justify-content-center fnt-size12px text-abbey-grey">Kindly check the provided link</div>
  </div>
</div>
<div *ngIf="chatBlocks && !invalidTokenMessage" #scrollMe class="row no-gutters h-100 scrollMe bg-porcelain-white">
  <div class="col-12 col-md-6 col-lg-4 mx-auto h-100">
    <div class="header text-center py-3 bg-white border-bottom">
      <img src="{{chatBlocks.productLogo}}" alt="product logo">
      <hr />
      <span class="text-abbey-grey d-flex justify-content-center fnt-size15px ml-2 fnt_Medium">
        {{chatBlocks.chatBotHeading}}
      </span>
    </div>
    <div *ngIf="somethingWentWrongMessage" class="d-flex justify-content-center align-items-center alert alert-danger">
      {{somethingWentWrongMessage}}
    </div>
    <div *ngIf="!somethingWentWrongMessage">
      <div *ngFor="let blockData of chatBlocks.block; index as i">
        <div *ngIf="blockData.type === this.blockType.INFO && currentTime(i, docquityMessage)">
          <div class="d-flex flex-row align-items-start mt-3 ml-2">
            <img class="docquityLogo mr-2" src="{{chatBlocks.images.chatLogo}}" alt="docquity logo">
            <div class="flex-col signup-info-width">
              <div  [innerHTML]="blockData.content" class="mt-1"></div>
              <div class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
            </div>
          </div>
        </div>
        <div *ngIf="blockData.type === this.blockType.CONSENT && currentTime(i, docquityMessage)">
          <div class="d-flex flex-row align-items-start mt-3 ml-2">
            <img class="mr-2 docquityLogo" src="{{chatBlocks.images.chatLogo}}" alt="docquity logo">
            <div class="w-100">
              <div class="bg-white p-2 w-75">
                <span class="text-abbey-grey fnt-size12px fnt_Medium">{{blockData.title}}</span>
                <div *ngFor="let item of blockData.attributes | slice:0:2; index as i">
                  <div class="mt-2" *ngIf="i === 1; then thenBlock else elseBlock"></div>
                  <ng-template #thenBlock>
                    <div class="d-flex flex-row align-items-start mt-2 custom-control custom-checkbox">
                      <input
                        class="mr-2 custom-control-input"
                        type="checkbox"
                        id="checkbox_{{i}}"
                        [checked]="true"
                        [disabled]="true"
                      >
                      <label class="custom-control-label" for="checkbox_{{i}}">
                        <span class="fnt-size12px text-rolling-stone-grey">{{(readMore) ? item.title : item.title | slice:0:55}}</span>
                        <span class="fnt-size12px text-rolling-stone-grey" *ngIf="!readMore">...</span>
                        <a class="text-success fnt-size12px" href="javascript:;"
                          *ngIf="!readMore"
                          (click)="readMore=true"
                        >
                          read more
                        </a>
                      </label>
                    </div>
                  </ng-template>
                  <ng-template #elseBlock>
                    <div class="d-flex flex-row align-items-start mt-2 custom-control custom-checkbox">
                      <input
                        class="mr-2 custom-control-input"
                        type="checkbox"
                        id="checkbox_{{i}}"
                        [checked]="true"
                        [disabled]="true"
                      >
                      <label for="checkbox_{{i}}" class="custom-control-label">
                        <span class="fnt-size12px text-rolling-stone-grey">{{item.title}}</span>
                      </label>
                    </div>
                  </ng-template>
                </div>
                <ng-container *ngIf="readMore">
                  <ng-container *ngFor="let expandedItem of blockData.attributes; let i = index">
                    <div class="d-flex flex-row align-items-start mt-2 custom-control custom-checkbox" *ngIf="i >= 2">
                      <input
                        class="mr-2 custom-control-input"
                        id="checkbox_{{i}}"
                        type="checkbox"
                        [checked]="true"
                        [disabled]="true"
                      >
                      <label for="checked_{{i}}" class="custom-control-label">
                        <span class="fnt-size12px text-rolling-stone-grey">{{ expandedItem.title }}</span>
                      </label>
                    </div>
                  </ng-container>
                </ng-container>
                <button
                  class="fnt-size12px fnt_Medium btn btn-block mt-3"
                  type="button"
                  [ngClass]="{'btn-success border-0': !blockData.submitted}"
                  [disabled]="blockData.submitted"
                  (click)="givePatientConsent(blockData)"
                >
                  <span class="text-white">{{blockData.submitText}}</span>
                </button>
              </div>
              <div class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
              <div *ngIf="blockData.submitted && currentTime(i, userMessage)">
                <div class="d-flex justify-content-end">
                  <span class="bg-light-green px-2 py-1 mr-2 rounded fnt-size12px fnt_Medium userReplyMessages">{{blockData.response}}</span>
                </div>
                <div class="d-flex justify-content-end fnt-size10px text-santas-grey mt-2">
                  {{ userTime[i] | date:'shortTime' | lowercase}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="blockData.type === this.blockType.FORM && currentTime(i, docquityMessage)">
          <pap-patient-form
            [responseData]="blockData"
            [logo]="chatBlocks.images.chatLogo"
            [currentChatBlock]="i"
            [countries]="countries"
            [reapplication]="this.reapplication"
            [reapplicationCount]="this.reapplicationCount"
            [token]="this.token"
            [application]="'CHATBOT'"
            (submitPatientData)="submitPatientData($event)"
            (editPatientData)="editPatientData($event)"
            (deleteEpapCaregiverData)="deleteEpapCaregiverData($event)"
            (deleteEpapCareGiverConfirm)="deleteEpapCareGiverConfirm($event)"
          >
          </pap-patient-form>
          <div *ngIf="blockData.submitted && currentTime(i, userMessage)">
            <div class="d-flex justify-content-end">
              <span class="bg-light-green px-2 py-1 mr-2 rounded fnt-size12px fnt_Medium userReplyMessages">{{blockData.response}}</span>
            </div>
            <div class="d-flex justify-content-end fnt-size10px text-santas-grey mt-2">
              {{ userTime[i] | date:'shortTime' | lowercase}}
            </div>
          </div>
        </div>
        <div *ngIf="blockData.type === this.blockType.OPTION && currentTime(i, docquityMessage)">
          <div>
            <div class="d-flex flex-row align-items-start mt-3 ml-2">
              <img class="mr-2 docquityLogo" [src]="chatBlocks.images.chatLogo" alt="docquity logo">
              <div class="w-100">
                <div class="bg-white p-2 w-75">
                  <div class="text-abbey-grey fnt-size12px fnt_Medium" [innerHtml]="blockData.title"></div>
                  <button
                    class="btn btn-block btn-outline-success mt-4 fnt-size12px fnt_Medium"
                    [disabled]="blockData.submitted || this.editMode"
                    (click)="careGiverOption(blockData.index - 1, i, true)"
                  >
                    Yes
                  </button>
                  <button
                    class="btn btn-block btn-outline-success mt-2 fnt-size12px fnt_Medium"
                    [disabled]="blockData.submitted || this.editMode"
                    (click)="careGiverOption(blockData.index - 1, i, false)"
                  >
                    No
                  </button>
                </div>
                <div *ngIf="currentTime()" class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
              </div>
            </div>
          </div>
          <div *ngIf="blockData.submitted && currentTime(i, userMessage)">
            <div class="d-flex justify-content-end">
              <span class="bg-light-green px-2 py-1 mr-2 rounded fnt-size12px fnt_Medium userReplyMessages">
                  {{this.selectedOption || 'Yes'}}
              </span>
            </div>
            <div class="d-flex justify-content-end fnt-size10px text-santas-grey mt-2">
              {{ userTime[i] | date:'shortTime' | lowercase}}
            </div>
          </div>
        </div>
        <div *ngIf="blockData.type === this.blockType.REJECT_REASONS_MESSAGE && currentTime(i, docquityMessage)">
          <div>
            <div class="d-flex flex-row align-items-start mt-3 ml-2">
              <img class="mr-2 docquityLogo" [src]="chatBlocks.images.chatLogo" alt="docquity logo">
              <div class="w-100">
                <div class="bg-white p-2 w-75 text-abbey-grey fnt-size12px fnt_Medium">
                  <div >Hi, {{blockData.patientName}}.</div>
                  <div >Your application has been rejected for the following reasons:</div>
                  <ol class="pl-3 mb-0">
                    <ng-container *ngFor="let reason of blockData.rejectReasons">
                      <li>{{reason}}</li>
                    </ng-container>
                  </ol>
                  <div>Please fill the correct details.</div>
                </div>
                <div *ngIf="currentTime()" class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="blockData.type === this.blockType.REJECT_APP_THANKYOU_MESSAGE && currentTime(i, docquityMessage)">
          <div class="d-flex flex-row align-items-start mt-3 ml-2">
            <img class="docquityLogo mr-2" src="{{chatBlocks.images.chatLogo}}" alt="docquity logo">
            <div class="flex-col signup-info-width">
              <div class="mt-1">
                <div class="bg-white p-2">
                  <div class="d-flex justify-content-center my-3">
                    <img src="assets/images/thumbs-up.svg" alt="thumbs up" class="signup-thumbs-up d-flex">
                  </div>
                  <div class="fnt-size15px font-weight-bold text-abbey-grey">{{ "Awesome" | translate }}</div>
                  <div class="fnt-size12px fnt_Medium text-abbey-grey mt-2">
                    <p><span>{{ "EPAP_REJECT" | translate }}</span>
                    </p>
                    <p class="mt-2 text-break">
                      <span>{{ "EPAP_QUERIES" | translate }} </span>
                      <span class="fnt_Bold">{{ "CONTACT_DOCQUITY" | translate }} </span>
                      <span>{{ "OR" | translate }} </span>
                      <span class="fnt_Bold">{{ "DOCQUITY_MAIL" | translate }} </span>
                      <span>{{ "DOCQUITY_WORKING_HOURS" | translate }}</span></p>
                  </div>
                </div>
              </div>
              <div class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
            </div>
          </div>
        </div>

        <!-- TODO: sumit do language translation -->
        <div *ngIf="blockData.type === this.blockType.DR_SHIFT_OPTION && currentTime(i, docquityMessage)">
          <div class="d-flex flex-row align-items-start mt-3 ml-2">
            <img class="docquityLogo mr-2" src="{{chatBlocks.images.chatLogo}}" alt="docquity logo">
            <div class="flex-col signup-info-width">
              <div class="mt-1">
                <div class="bg-white p-2">
                  <div class="fnt-size12px fnt_Medium text-abbey-grey font-weight-bold">
                    Hey ! {{ blockData.patient.name }},
                  </div>
                  <div class="fnt-size12px fnt_Medium text-abbey-grey">
                    Till now you were consulting Dr. {{blockData.oldDoc.name}} but now you have consulted Dr. {{blockData.currDoc.name}}.
                    Since all your current benefits are from to Dr. {{blockData.oldDoc.name}}, on changing to Dr. {{blockData.currDoc.name}}
                    you may lose your benefits. You need to contact Docquity Care (+639176279371) to transfer your benefits if you select Dr. {{blockData.currDoc.name}}
                  </div>
                  <div class="fnt-size12px fnt_Medium text-abbey-grey font-weight-bold mt-2">
                    With whom would you like to continue?
                  </div>
                  <button
                    class="btn btn-block btn-outline-success mt-4 fnt-size12px fnt_Medium"
                    [disabled]="blockData.submitted || this.editMode"
                    (click)="drShiftOption(blockData, blockData.oldDoc.id, blockData.index - 1, i, blockData.oldDoc.name)"
                  >
                    {{ blockData.oldDoc.name }}
                  </button>
                  <button
                    class="btn btn-block btn-outline-success mt-2 fnt-size12px fnt_Medium"
                    [disabled]="blockData.submitted || this.editMode"
                    (click)="drShiftOption(blockData, blockData.currDoc.id, blockData.index - 1, i, blockData.currDoc.name)"
                  >
                    {{ blockData.currDoc.name }}
                  </button>
                </div>
              </div>
              <div class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
            </div>
          </div>
          <div *ngIf="blockData.submitted && currentTime(i, userMessage)">
            <div class="d-flex justify-content-end">
              <span class="bg-light-green px-2 py-1 mr-2 rounded fnt-size12px fnt_Medium userReplyMessages">
                  {{this.selectedDoctor || this.blockData.submitText}}
              </span>
            </div>
            <div class="d-flex justify-content-end fnt-size10px text-santas-grey mt-2">
              {{ userTime[i] | date:'shortTime' | lowercase}}
            </div>
          </div>
        </div>


        <div *ngIf="blockData.type === this.blockType.DR_SHIFT_CONFIRMATION && currentTime(i, docquityMessage)">
          <div>
            <div class="d-flex flex-row align-items-start mt-3 ml-2">
              <img class="mr-2 docquityLogo" [src]="chatBlocks.images.chatLogo" alt="docquity logo">
              <div class="w-100">
                <div class="bg-white p-2 w-75">
                  <div class="text-abbey-grey fnt-size12px fnt_Medium">
                    Are you sure you want to continue with Dr. {{this.selectedDoctor || this.blockData.selectedDoc.name || 'Submitted'}}?
                  </div>
                  <button
                    class="btn btn-block btn-outline-success mt-4 fnt-size12px fnt_Medium"
                    [disabled]="blockData.submitted || this.editMode"
                    (click)="doctorConfirmationOption(blockData.index - 1, i, true)"
                  >
                    Yes
                  </button>
                  <button
                    class="btn btn-block btn-outline-success mt-2 fnt-size12px fnt_Medium"
                    [disabled]="blockData.submitted || this.editMode"
                    (click)="doctorConfirmationOption(blockData.index - 1, i, false)"
                  >
                    No
                  </button>
                </div>
                <div *ngIf="currentTime()" class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
              </div>
            </div>
          </div>
          <div *ngIf="blockData.submitted && currentTime(i, userMessage)">
            <div class="d-flex justify-content-end">
              <span class="bg-light-green px-2 py-1 mr-2 rounded fnt-size12px fnt_Medium userReplyMessages">
                  {{this.selectedDoctorConfirmationOption || 'Yes'}}
              </span>
            </div>
            <div class="d-flex justify-content-end fnt-size10px text-santas-grey mt-2">
              {{ userTime[i] | date:'shortTime' | lowercase}}
            </div>
          </div>
        </div>

        <div *ngIf="blockData.type === this.blockType.DR_SHIFT_FAILURE && currentTime(i, docquityMessage)">
          <div class="d-flex flex-row align-items-start mt-3 ml-2">
            <img class="docquityLogo mr-2" src="{{chatBlocks.images.chatLogo}}" alt="docquity logo">
            <div class="flex-col signup-info-width">
              <div  [innerHTML]="blockData.content" class="mt-1">
              </div>
              <div class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] | date:'shortTime' | lowercase}}</div>
            </div>
          </div>
        </div>
        <div *ngIf="blockData.type === this.blockType.DELETE_CAREGIVER_CONFIRMATION && currentTime(i, docquityMessage)">
          <div>
            <div class="d-flex flex-row align-items-start mt-3 ml-2">
              <img class="mr-2 docquityLogo" [src]="chatBlocks.images.chatLogo" alt="docquity logo">
              <div class="w-100">
                <div class="bg-white p-2 w-75">
                  <div class="text-abbey-grey fnt-size12px fnt_Medium mb-4">Are you sure you want to remove {{this.careGiverName}} from your caregiver?</div>
                  <button class="btn btn-block btn-outline-success fnt-size12px fnt_Medium" (click)="deleteEpapCareGiverConfirm(i, 'Yes, Delete it', true)"
                    [disabled]="blockData.submitted || this.isSubmissionOngoing">Yes, Delete it
                  </button>
                  <button class="btn btn-block btn-outline-success fnt-size12px fnt_Medium" (click)="deleteEpapCareGiverConfirm(i, 'No', false)"
                    [disabled]="blockData.submitted || this.isSubmissionOngoing">No
                  </button>
                </div>
                <div *ngIf="currentTime()" class="fnt-size10px text-santas-grey mt-2">{{ docquityTime[i] |
                  date:'shortTime' | lowercase}}</div>
              </div>
            </div>
          </div>
          <div *ngIf="blockData.submitted && currentTime(i, userMessage)">
            <div class="d-flex justify-content-end">
              <span class="bg-light-green px-2 py-1 mr-2 rounded fnt-size12px fnt_Medium userReplyMessages w-auto">
                {{blockData.response}}
              </span>
            </div>
            <div class="d-flex justify-content-end fnt-size10px text-santas-grey mt-2">
              {{ userTime[i] | date:'shortTime' | lowercase}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
