<div>
  <h5 class="fnt-size18px text-heading-grey mb-3">{{'AGENT_INFORMATION' | translate}}</h5>
  <form [formGroup]="agentInfoForm">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{checkForServierProcess() ? ("RECORD_BY" | translate) : ("NAME" | translate)}}
              <span class="text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input (keyup)="userInput('agentName', $event)" formControlName="agentName" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_FULL_NAME' | translate}}" [maxlength]="100" />
          </div>
          <div *ngIf="form.agentName.touched && form.agentName.invalid" class="text-danger fnt-size12px">
            {{"NAME" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group mb-2 position-relative">
          <label class="fnt-size14px">
            {{'DATE_RECEIVING_INFO' | translate}}
            <span class="text-danger">*</span>
          </label>
          <div class="calendar-field w-100">
            <p-calendar [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [touchUI]="false"
              yearRange="1922:2030" [inline]="false" panelStyleClass="bg-white border"
              [styleClass]="form.dateReceivedInfo.touched && form.dateReceivedInfo.invalid ? 'pcomponent-invalid w-100' : 'w-100'"
              [inputStyleClass]="'form-control fnt-size12px fnt_Medium w-100 rounded'"
              formControlName="dateReceivedInfo" placeholder="{{'SELECT_DATE' | translate}}" dateFormat="dd M yy">
            </p-calendar>
          </div>
          <div *ngIf="(form.dateReceivedInfo.touched ) && form.dateReceivedInfo.invalid"
            class="text-danger fnt-size12px">
            {{'DATE_RECEIVING_INFO' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
