<div class="row no-gutters align-items-center signin-box">
  <div class="col-12 col-md-6 col-lg-4 mx-auto">
    <div class="header  pt-4 bg-white mb-2 signin-box__content-box rounded">
      <div class="text-center pb-4">
        <img [src]=this.logoSrc alt="Logo">
      </div>
      <!-- country div -->
      <div class="border-top p-4 countryDiv__loginPage">
        <p>{{'SELECT_COUNTRY'| translate}}</p>
        <dngx-drop-down
          [list]="countries"
          [itemSize]="10"
          [filter]="false"
          [showClear]="false"
          placeholder="select a country" dropdownIcon="dropdown" [selectedValue]="selectedCountry"
          [selectedItem]="selectedItemForCountry" [suggestionValue]="suggestionsItemForCountry"
          [resetFilterOnHide]="true" (emitSelectedValue)="setSelectedCountry($event)"></dngx-drop-down>
        <ng-template let-selected #selectedItemForCountry>
          <span>
            <img src="assets/images/flag_placeholder.png" [class]="'flag flag-' + selected.value" alt="{{ selected.name }} flag" />
          </span>
          <span>
            {{ selected.name }}
          </span>
        </ng-template>
        <ng-template let-item #suggestionsItemForCountry>
          <div>
            <img src="assets/images/flag_placeholder.png" [class]="'flag flag-' + item.value" alt="{{ item.name }} flag"/> {{ item.name }}
          </div>
        </ng-template>
        <!--  -->
      <p class="mt-2 text-danger fnt-size12px" [ngClass]="{'d-none': this.selectedCountry !== null && this.selectedCountry !== '' && this.selectedCountry !== 'undefined'}" id="countryError"></p>
      </div>
    </div>

    <div class="body p-4 bg-white signin-box__content-box rounded">

      <p *ngIf="apiError" class="text-center text-danger pb-2" innerHtml="{{apiError}}"></p>

      <p class="font-weight-bold fnt-size20px mb-2">
        {{'LOGIN_TO_YOUR_ACCOUNT' | translate}}
      </p>
      <p class="font-weight-bold fnt-size15px mb-3">{{'ENTER_THE_EMAIL_YOU_SIGNED_UP_AND_EXPLORE_THE_PANEL' | translate}}</p>

      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label>{{'EMAIL_ID' | translate}}</label>
          <input type="text" formControlName="email" placeholder="{{'YOUREMAILID@DOCQUITY_COM' | translate}}" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && email.errors }" />

          <div *ngIf="submitted && email.errors" class="invalid-feedback">
            <div *ngIf="email.errors.required">
              {{'EMAIL_IS_REQUIRED'| translate}}
            </div>
            <div *ngIf="email.errors.email">
             {{'EMAIL_MUST_BE_A_VALID_EMAIL_ADDRESS'| translate}}
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>{{'PASSWORD' | translate}}</label>
          <input type="password" formControlName="password" placeholder="{{'YOUR_PASSWORD' | translate}}" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && password.errors }" />

          <div *ngIf="submitted && password.errors" class="invalid-feedback">
            <div *ngIf="password.errors.required">
              {{'PASSWORD_IS_REQUIRED' | translate}}
            </div>
            <div *ngIf="password.errors.minlength">
              {{'PASSWORD_MUST_BE_AT_LEAST_6_CHARACTERS' | translate}}
            </div>
          </div>
        </div>

        <div class="text-right">
          <a class="fnt-size15px text-abbey-grey" (click)="resetPassword()">{{'FORGOT_PASSWORD' | translate}}</a>
        </div>

        <button [disabled]="selectedCountry === '' || selectedCountry === null" type="submit" class="btn btn-success btn-block mt-3">
          {{'LOG_IN' | translate}}
        </button>
      </form>
    </div>
  </div>
</div>
