/**
 * Angular imports.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Primeng imports.
 */
import { GalleriaModule } from 'primeng/galleria';

/**
 * Components import.
 */
import { FullViewGalariaComponent } from './components/full-view-galaria/full-view-galaria.component';

/**
 * This module is used to display the image galary.
 */

@NgModule({
  declarations: [FullViewGalariaComponent],
  imports: [
    CommonModule,
    GalleriaModule
  ],
  exports: [FullViewGalariaComponent]
})
export class DocGalariaModule { }
