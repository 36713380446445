
  <div class="dashboard-container">
    <!-- Sidebar -->
    <nav class="sidebar">
       <img src="assets/images/logo.svg" alt="">
      <ul>
        <li class="action-side-btn cursor-pointer fnt-size14px p-2 " [ngClass]="isUrlActive ? 'active' : ''" >Dashboard</li>
        <li class="action-side-btn cursor-pointer fnt-size14px p-2 "  [routerLink]="'addPatient'"
        routerLinkActive="active">Add New Patient</li>
        <li class="action-side-btn cursor-pointer fnt-size14px p-2 ">View Existing Patients</li>
        <li class="action-side-btn cursor-pointer fnt-size14px p-2 ">Update Profile</li>
        
      </ul>
    </nav>

    <!-- Main Content -->
    <main class="main-content box-shadow">
      <header  class="bg-white p-2 d-flex justify-content-end " style="border-bottom: 0.1px solid lightgrey;">
        
        <div class="profile">
          <!-- <select>
            <option>Docquity Pharma</option>
          </select> -->
          <!-- <button class="profile-icon">P</button> -->


          <div class="header p-3 position-sticky w-100">
            <span class="float-right">
              <dngx-popover class="medium-font-weight position-static" #op popOverCssClass="userProfilePopover"
                [headerItems]="logoutPopover">
                <div>
                  <span class="profile-pic downArrow">
                    {{(userEmail)? userEmail[0]:''}}
                  </span>
                </div>
              </dngx-popover>
              <ng-template #logoutPopover>
                <ul class="profileItems">
                  <li>
                    <span class="profile-pic mr-2 fnt-size14px">
                      {{(userEmail)? userEmail[0]:''}}
                    </span>
                    {{userEmail}}
                  </li>
                  <li class="logout  fnt-size14px" (click)="logout()">
                    <img src="assets/images/logout.svg" alt="logout" class="mr-2" /> {{'LOGOUT'| translate}}
                  </li>
                </ul>
              </ng-template>
            </span>
           
            <span class="float-right">
              <div >
                <form [formGroup]="" class="pharma-dropdown mr-3">
                  <p-dropdown panelStyleClass="bg-white border p-2 fnt-size14px"
                    [ngClass]="{'d-none': this.programPageFlag ===  true}" styleClass="form-control fnt-size14px"
                    require="true" [options]="" formControlName="pharma" optionLabel="name" 
                    (onChange)="onPharmaNameChange($event)" placeholder="{{'SELECT_PHARMA' | translate}}">
                    <ng-template let-item pTemplate="item">
                      <span class="ml-2 mr-2">{{item.name}}</span>
                      <span class="float-right">
                        <img width="25" *ngIf="this.pharmaForm.get('pharma').value === item.code"
                          src="assets/images/checkbox.svg" alt="green checked checkbox">
                      </span>
                    </ng-template></p-dropdown>
                </form>
              </div>
            </span>
          </div>
          

        </div>
      </header>
      <!-- <div class="welcome p-3">
        <h3>Welcome </h3>
        <p>What would you like to do?</p>
      </div>

      <section class="actions p-3">
        
        <div class="col-3 bg-white section-div text-center p-3 cursor-pointer rounded" (click)="changeRoute()">
           <img src="assets/images/add-patient.svg" alt="">
           <p>Add New Patient</p>
        </div>
        <div class="col-3 bg-white section-div text-center p-3 cursor-pointer rounded">
            <img src="assets/images/clipboard.svg" alt="">
            <p>View Existing Patients</p>
         </div>
      </section> -->
      <div class="dashboard_Rightpart">
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>

