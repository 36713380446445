/**
 * Angular imports.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

/**
 * Ngx translate imports.
 */
import { TranslateModule as Translate, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

/**
 * Component imports.
 */
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';

/**
 * Module imports.
 */
import { DropDownModule } from '../drop-down/drop-down.module';

/**
 * Used to initialize the different project module translate file.
 */
export function HttpLoaderFactory(httpClient: HttpClient): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(httpClient, [
    { prefix: '/assets/translate/', suffix: '.json' }
  ]);
}

@NgModule({
  imports: [
    CommonModule,
    DropDownModule,
    Translate.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [Translate, LanguageSelectorComponent],
  declarations: [LanguageSelectorComponent],
})
export class TranslateModule {
  /**
   * Created the translate instance
   * Add the languages then set the english language as default
   * If lang available in localstorage then set into browserLang otherwise
   * Call getBrowserLang() and check it to the available language and then set it into localstorage as lang.
   */
  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'id', 'vi', 'th']);
    let browserLang = '';
    if (!localStorage.getItem('lang')) {
      browserLang = translate.getBrowserLang();
      // localStorage.setItem('lang', browserLang.match(/en|id|vi|th/) ? browserLang : 'en');
    } else {
      browserLang = localStorage.getItem('lang');
    }
    translate.use(browserLang.match(/en|id|vi|th/) ? browserLang : 'en');
  }
}
