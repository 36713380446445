import { COUNTRIES_LIST } from './../../../constant/app.constant';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * Ngrx Imports.
 */
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { PharmaInfoData, PharmaStore, ProgramInfo } from '../../../models/pharma.model';
import * as PharmaAction from '../../../store/actions/pharma/pharma.actions';
import * as PharmaSelector from '../../../store/selectors/pharma/pharma.selectors';

@Component({
  selector: 'pap-pharma-store',
  templateUrl: './pharma-store.component.html',
  styleUrls: ['./pharma-store.component.scss']
})
export class PharmaStoreComponent implements OnInit, OnDestroy {

  public selectedProgram: ProgramInfo;
  public pharmaCode: string;
  public pharmaInfo: PharmaInfoData;
  public programs: ProgramInfo[];
  public stores: PharmaStore[];
  public pharmaInfoSub: Subscription;
  public papCode: string;
  public papCodeSub = new Subscription();

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router
  ){}

  onProgramChange(): void {
    this.stores = this.selectedProgram.pharmaStores;
  }

  ngOnInit(): void {
    this.pharmaCode = this.route.snapshot.paramMap.get('pharmaCode');
    this.papCodeSub =  this.route.queryParams.subscribe(params => {
      this.papCode = params.papCode;
    });
    this.papCodeSub.add(this.route.params.subscribe(data => {
      if (data.country) {
        if (data.country === COUNTRIES_LIST.PHILLIPPINS.value
          || data.country === COUNTRIES_LIST.THAILAND.value
          || data.country === COUNTRIES_LIST.SINGAPORE.value ) {
          localStorage.setItem('country', data.country);
        } else {
          localStorage.removeItem('country');
          this.router.navigate(['404']);
        }
      } else {
        localStorage.setItem('country', COUNTRIES_LIST.PHILLIPPINS.value);
      }
    }));
    this.store.dispatch(PharmaAction.loadPharmaInfo({ pharmaCode: this.pharmaCode }));
    this.pharmaInfoSub = this.store.select(PharmaSelector.getPharmaInfo).subscribe(data => {
      if (data != null) {
        this.pharmaInfo = JSON.parse(JSON.stringify(data));
        this.pharmaInfo.programInfo.sort((a, b) => {
          if (this.papCode) {
            if (a.code.trim().toLowerCase() === this.papCode.trim().toLowerCase()) {
              return -1;
            }
            if (b.code.trim().toLowerCase() === this.papCode.trim().toLowerCase()) {
              return 1;
            }
          }
          return a.id > b.id ? 1 : -1;
        });
        this.programs = this.pharmaInfo.programInfo;
        this.stores = this.pharmaInfo?.programInfo[0]?.pharmaStores;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.pharmaInfoSub) {
      this.pharmaInfoSub.unsubscribe();
    }
  }

}
