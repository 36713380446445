import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ErrorAction from '../../actions/error/error.actions';
import * as AttendantAction from '../../actions/attendent/attendent.action';
import { ActivatedRoute, Router } from '@angular/router';
import { API } from '../../../constant/api.constant';
import { Store } from '@ngrx/store';
import * as LoginAction from '../../../store/actions/login/login.actions';



@Injectable()
export class AttendantEffects {
  constructor(private actions$: Actions, private http: HttpClient, private router: Router,private store: Store,
    private route: ActivatedRoute) {}

  /**
   * Effect for loading otp.
   */
   public loadOTP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttendantAction.loadOTP),
      switchMap((action) =>
        this.http.post(API.ATTENDANT_OTP_REQUEST, {
          country_code: action.countryCode,
          phone_number: action.phone_number,
          user_type: action.user_type,
        }).pipe(
          map((response: any) => {
            console.log(response);
            if (response) {
              return AttendantAction.setOtp({otpRequestToken: response.data.token})
            }
          }),  
          catchError(err => {
            console.log(err)
            return [
              AttendantAction.setErrors({
                error: err.error.message
              }),
            ];
          })
        )
      )
    )
  );



/**
 * verifyOtp
 */
  public verifyOTP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttendantAction.verifyOTP),  
      switchMap((action) =>
        this.http.post(API.ATTENDANT_OTP_VERIFY, {
          otp: action.otp,
        }, 
        {
          headers: {
            'Authorization': 'Bearer ' + action.otpRequestToken
          }
        }).pipe(
          map((response: any) => {
            console.log("data",response)
            if (response && response.success) {
              // this.store.dispatch(LoginAction.setUserCredentials({ authenticationResult: { accessToken: response.data.token, refreshToken: ''} }));
              // otpVerifyToken: response.data.token ,
              console.log("Value of response:", response);
              return AttendantAction.setVerifyOTPData({ user_detail:response.data }); 
            }
          }),
          catchError(err => {
            return [
              AttendantAction.setErrors({
                error: err.error?.message || 'Verification failed',
              }),
              ErrorAction.catchError({
                error: err,
              }),
            ];
          })
        )
      )
    )
  );
/**
 * resend otp
 */
  public resendOTP$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(AttendantAction.resendOTP),
        mergeMap((action) =>
          this.http.get(            
            API.ATTENDANT_OTP_RESEND,
             {
              headers: {
                'Authorization': 'Bearer ' + action.otpRequestToken
              }
          },
          ).pipe(
            map((response:any) => {
              // this.store.dispatch(LoginAction.setUserCredentials({ authenticationResult: response.data.token }));
              return AttendantAction.setVerifyOTPData({ user_detail:response.data.user_detail  });
            }),
            catchError(err => {
              console.log("coming here",action.otpRequestToken)
              return [AttendantAction.setErrors(
                { error: err.error.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );

/**
 * nurse details form 
 */
  submitForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttendantAction.submitForm),
      switchMap((action) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${action.otpRequestToken}`,
        });
        return this.http.post(API.ATTENDANT_PERSONAL_DETAILS, {
          first_name: action.first_name,
          last_name: action.last_name,
          email: action.email,
          pharma_id: action.pharma_id,
        }, { headers }).pipe(
          map((response) => AttendantAction.submitFormSuccess({ response })),
          catchError((error) => of(AttendantAction.submitFormFailure({ error })))
        );
      })
    )
  );
  

/**
 * add nurse through dcpm
 */
  public addAttendantDCPM$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttendantAction.addAttendantDCPM),
      mergeMap((action) =>
        this.http
          .post(
            API.ADD_ATTENDANT_DCPM, 
            {
              country_code: action.country_code,
              phone_number: action.phone_number,
              first_name: action.first_name,
              last_name: action.last_name,
              email: action.email,
              pharma_id:action.pharma_id
            },
            // { headers: { 'login-auth': action.token } }
          )
          .pipe(
            map((res) => {
              if (res) {
                // localStorage.setItem('AttendantAuth', res.data.token);
                return AttendantAction.addAttendtSuccess({ response: res });
              }
            }),
            catchError((err) => {
              console.log("first error -------> ",err);
              return [
                AttendantAction.setErrors({ error: err.error }),
              ];
            })
          )
      )
    )
  );

  /**
   * attendant-list
   */
  public loadPatientList$ = createEffect(() => this.actions$
  .pipe(
    ofType(AttendantAction.loadAttendantList),
    switchMap((action) =>
      this.http.get(
        API.ATTENDANT_LIST, {
        params:
        {
          limit: action.limit,
          pharma_code: action.pharma_code,
          search: JSON.stringify(action.search)
        }
      }
      ).pipe(
        switchMap((resData: any) => {
          return [AttendantAction.searchSuccess({ attendantList: resData?.data?.attendants! })];
        }),
        catchError(err => {
          return [AttendantAction.listFailure({
            error: err.error
          }),
          ErrorAction.catchError({ error: err })];
        }),
      )
    )
  ));

}
