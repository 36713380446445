<div class="pharma pharmaView">
  <div class="bg-white sticky-top header">
    <div class="container h-100">
      <nav class="navbar navbar-expand-md navbar-light position-sticky navbar-head h-100 px-0 justify-content-start">
        <a class="navbar-brand">
          <img src="assets/images/docquity-only.svg" class="mt-1  px-1 d-none d-md-block" alt="Logo">
          <img src="assets/images/docquity-image.png" class="mt-1 px-1 d-md-none d-block" alt="Logo">
        </a>
        <a class="navbar-brand">
          <img src="assets/images/line-image.png" class="px-1" alt="Logo">
        </a>
        <a *ngIf="pharma" class="navbar-brand">
          <img src={{pharma?.logo}} class="mt-1 pharma-logo px-1" alt="pharma Logo">
        </a>

      </nav>
    </div>
  </div>
  <div class="contentBar d-flex flex-column">
    <div class="p-md-5 p-2">
      <div>
        <p class="text-center font-weight-bold program-font pt-2 fnt-size32px mb-5 font-lexend text-1B4A52">{{"TNC" |
          translate}}</p>
        <div class="mx-auto d-flex p-2 px-md-3">
          <div class="container">
            <div class="terms text-break px-md-5" [innerHtml]="pharma?.termsAndConditions">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-2 bg-white mt-auto">
      <div class="py-2 bg-black text-center rounded-16">
        <div class="p-4 mt-2 mb-2">
          <img src="assets/images/docquity-logo.png" alt="docquity-logo">
          <p class="text-white mt-3">{{"RIGHTS_RESERVED" | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
