import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  constructor(private messageService: MessageService, private primengConfig: PrimeNGConfig) {
    this.primengConfig.ripple = true;
  }

  showSuccess(summary: string, detail?: string): void {
    this.messageService.add({ severity: 'success', summary, detail });
  }

  showInfo(summary: string, detail?: string): void {
    this.messageService.add({ severity: 'info', summary, detail });
  }

  showWarn(summary: string, detail?: string): void {
    this.messageService.add({ severity: 'warn', summary, detail });
  }

  showError(summary: string, detail?: string): void {
    this.messageService.add({ severity: 'error', summary, detail });
  }
}
