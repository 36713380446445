<div *ngIf="showChatBot" class="col-11 col-md-6 h-100 hyridComponent mx-auto mt-1">
  <div class="border-bottom text-center bg-white shadow-sm">
    <img class="p-3" src="assets/images/logo.svg" alt="logo">
    <h6 class="border-top p-3">
      Let's get healthy!
    </h6>
  </div>
  <pap-hybrid [chatView]="true" [pharmaCode]="pharmaCode"></pap-hybrid>
</div>
<span *ngIf="getPatientAuth()" class="chat-cross-icon" (click)="closeChatBot()">
  <img src="assets/images/cross-icon-without-bg.svg" class="position-absolute rt-0" alt="cross" width="48" height="48">
</span>
<p-dialog class="leave" [(visible)]="isLeaveSessionModal" [style]="'p-2'" [modal]="true" [closeOnEscape]="true" [closable]="true"
  [baseZIndex]="1005">
  <ng-template pTemplate="header">
    <img src="assets/images/leave-chatbot.png" class="mx-auto" alt="leave-chatbot">
  </ng-template>
  <p class="my-2 text-center font-weight-bold fnt-size20px">{{"CONFIRMATION_SURE" | translate}}?</p>
  <p class="mb-2 text-center  font-weight-bold fnt-size20px">{{"MISS_BENIFITS" | translate}}</p>
  <p class="mb-2 text-center  text-abbey-grey">{{"CLOSE_SESSION" | translate}}</p>
  <div class="text-center mb-3 d-flex p-3 justify-content-center">
    <button class="btn btn-border  dialog-button  py-2 mr-2" (click)="resetChatbotSession()">
      {{"YES_LEAVE" | translate}}
    </button>
    <button class="btn dialog-button  py-2 btn-success" (click)="isLeaveSessionModal = false;">
      {{"NO_COMPLETE" | translate}}
    </button>
  </div>
</p-dialog>
