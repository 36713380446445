import { Signin } from 'projects/nga-PAP/src/app/models/signin.model';
import { CareGiverDeleteRequest, HybridData, ProgramData } from './../../../models/sign-up-chatbot.model';
import { createAction, props } from '@ngrx/store';
import { CareGiverData, Error, PharmaDetail } from '../../../models/responses.model';

/**
 * Load Hybrid Forms.
 */
export const loadHybridForms = createAction(
  '[Hybrid] Load Hybrid Forms',
  props<{consentToken: string}>()
);

/**
 * Login through patient data.
 */
export const submitLoginData = createAction(
  'Form|API [Hybrid] Submit login data',
  props<{endPoint: string, formData: any, pharmaCode?: string, process?: string}>()
);

/**
 * Action for submitted program data.
 */
export const submitProgramData = createAction(
  'Form|API [Hybrid] Submit program data',
  props<{endPoint: string, method: string, formData: any, applicationType: string, accessToken: string}>()
);

/**
 * Set Hybrid Forms.
 */
export const setHybridForms = createAction(
  'Hybrid|SET [Hybrid Forms] Set hybrid forms',
  props<{ hybridData: HybridData}>()
);

/**
 * Set Consent Token.
 */
export const setConsentToken = createAction(
  'Hybrid|SET [Hybrid Forms] Set consent token',
  props<{ consentToken: string, showAdditionalInfo: number}>()
);

/**
 * Set Errors.
 */
export const setErrors = createAction(
  'ErrorHybrid|SET [Hybrid] Catch error of hybrid api',
  props<{ error: Error }>()
);

/**
 * Reset Errors.
 */
export const resetErrors = createAction(
  'ErrorHybrid|RESET [Hybrid] error',
  props<{}>()
);

/**
 * Set form submitted id.
 */
export const setFormSubmittedId = createAction(
  'Form|SET [Hybrid] Set form submitted id',
  props<{ formSubmittedId: number}>()
);

/**
 * Reset form submitted id.
 */
export const resetformSubmittedId = createAction(
  'FORM|SET [Hybrid] reset form submitted id',
  props<{ formSubmittedId: number }>()
);

/**
 * Load Programs
 */
export const loadPrograms = createAction(
  '[Hybrid] Load Programs',
  props<{ keyword: string, endPoint: string, method: string, pharma?: string, limit: string }>()
);

/**
 * Set Program List
 */
export const setProgramList = createAction(
  'Programs|SET [Hybrid] Set program list',
  props<{ programList: ProgramData[] }>()
);

export const resetProgramList = createAction(
  'Programs|RESET [Hybrid] Set program list'
);
/**
 * Set Credentials of patient.
 */
export const setCreds = createAction(
  'AUTH|SET [Hybrid] Set Creds',
  props<{ patientAuthResult: Signin }>()
);

/**
 * Reset Credentials of patient.
 */
export const resetCreds = createAction(
  'AUTH|SET [Hybrid] Reset Creds',
  props<{}>()
);
export const resetHybridForms = createAction(
  'Hybrid|SET [Hybrid Forms] reset  hybrid forms',
);
export const resetPatientConsent =  createAction(
  'Hybrid|RESET [Hybrid Forms] Reset consent token',
  props<{}>()
);

export const deleteCareGiver = createAction(
  'Hybrid|DELETE [Hybrid] Delete Caregiver',
  props<{token: string, careGiverRequest: CareGiverDeleteRequest, accessToken?: string, applicationType?: string}>()
);

export const emptyAction = createAction(
  'Hybrid|Empty Action [Hybrid] Empty Action',
  props<{}>()
);

export const loadCaregiver = createAction(
  'Hybrid|Load Action [Hybrid] load caregiver',
  props<{token: string, accessToken: string, applicationType: string,
    caregiverEmail: string, caregiverPhoneNumber: string}>()
);

export const setCaregiver = createAction(
  'Hybrid|SET [Hybrid] Set caregiver',
  props<{ caregiverData: CareGiverData }>()
);

export const resetCaregiver = createAction(
  'Hybrid|SET [Hybrid] Reset caregiver',
  props<{}>()
);

export const setDeletedCaregiver = createAction(
  'Hybrid|SET [Hybrid] Set Deleted caregiver',
  props<{ isCaregiverDeleted: boolean }>()
);

export const resetDeletedCaregiver = createAction(
  'Hybrid|SET [Hybrid] Reset caregiver',
  props<{}>()
);

/**
 * laoading pharma details
 */
export const loadHybridPharmas = createAction(
  '[Pharma] Load  Hybrid Pharmas',
  props<{ pharmaCode: string}>()
);

/**
 * storing pharma details
 */
export const setHybridPharmas = createAction(
  '[Pharma] Pharma Hybrid Detail',
  props<{ data: PharmaDetail}>()
);

/**
 * storing pharma details
 */
export const reSetHybridPharmas = createAction(
  '[Pharma] RESET Pharma Hybrid Detail'
);

export const requestOtp = createAction(
  '[Hybrid] Request OTP',
  props<{consentToken: string}>()
);

/**
 * storing Request Otp Response
 */
export const setRequestOtpResponse = createAction(
  '[Hybrid] Store Request Otp Response',
  props<{ data: boolean}>()
);
