import { Action, createReducer, on } from '@ngrx/store';
import * as ErrorAction from '../../../store/actions/error/error.actions';

/**
 * Modal imports.
 */
import { InternetConnectionStatus } from '../../../models/internetConnection.modal';


export const errorFeatureKey = 'error';



export interface State {
  internetConnectionStatus: InternetConnectionStatus;

}

export const initialState: State = {
  internetConnectionStatus: {isOnline: true, showModal: false}
};

export const reducer = createReducer(
  initialState,
  on(ErrorAction.setOnlineStatus, (state, payload) => ({
    ...state,
    internetConnectionStatus: payload.internetConnectionStatus
  })),

);

