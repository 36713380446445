<div class="p-fluid position-relative create-meeting__timezone">
<p-dropdown
  [styleClass]="styleClass"
  panelStyleClass = "fnt-size12px"
  [(ngModel)]="selectedValue"
  [options]="list"
  [virtualScroll]= "virtualScroll"
  [itemSize] = "itemSize"
  [filter]="filter"
  [disabled]="disabled"
  [emptyFilterMessage]="emptyFilterMessage"
  [resetFilterOnHide]="resetFilterOnHide"
  [showClear]="showClear"
  [dropdownIcon] = "dropdownIcon"
  [placeholder]="placeholder"
  (ngModelChange)="selectedData($event)"
>
  <ng-template pTemplate="selectedItem" let-value>
    <ng-container *ngTemplateOutlet="selectedItem; context: { $implicit: value  }"></ng-container>
 </ng-template>
 <ng-template let-item pTemplate="item">
  <ng-container *ngTemplateOutlet="suggestionValue; context: { $implicit: item  }"></ng-container>
 </ng-template>
</p-dropdown>
</div>
