import { createAction, props } from '@ngrx/store';
import { Error, SpecialtyData, SpecialtyList, SpecialtyListData, EducationListResponse, DoctorData } from '../../../models/responses.model';
import { HospitalList, ProgramData } from '../../../models/sign-up-chatbot.model';

/**
 * Load Pap programs.
 */
export const loadPapPrograms = createAction(
  '[Doctor] Load Pap Programs Forms',
  props<{ pharma?: string, limit: string }>()
);

/**
 * Set Pap Programs.
 */
export const setPapPrograms = createAction(
  'Doctor|SET [Pap Programs] Set pap programs',
  props<{ data: ProgramData[] }>()
);

/**
 * Load Speciality.
 */
export const loadSpeciality = createAction(
  '[Doctor] Load Speciality',
  props<{}>()
);

/**
 * Load hospital affiliation list.
 */
export const loadHospitalAffiliation = createAction(
  '[Doctor] Load hospital',
  props<{ keyword: string; limit: number }>()
);

/**
 * Set hospital affiliation list.
 */
export const setHospitalAffiliationList = createAction(
  '[Doctor] Set hospital Affiliation list',
  props<{ hospitalAffiliationList: HospitalList }>()
);

/**
 * Set Speciality.
 */
export const setSpecialty = createAction(
  'Doctor|SET [Pap Programs] Set specialty',
  props<{ data: SpecialtyListData[] }>()
);

/**
 * Set Errors.
 */
export const setErrors = createAction(
  'Doctor|SET [Doctor] Catch error of doctor api',
  props<{ error: Error }>()
);

/**
 * Reset Errors.
 */
export const resetErrors = createAction(
  'Doctor|RESET [Doctor] Reset error of doctor api'
);

/**
 * Doctor Registration form submission.
 */
export const submitDoctorFormData = createAction(
  'Doctor|API [Doctor] Submit form data',
  props<{ formData: any, token: string }>()
);

/**
 * Set Action form doctor form submission.
 */
export const setDoctorSubmitSuccess = createAction(
  'Doctor|Set [Doctor] Set doctor creation success',
  props<{ success: boolean }>()
);

/**
 * Reset Action form doctor form submission.
 */
export const resetDoctorSubmitSuccess = createAction(
  'Doctor|Reset [DoctorData] Reset doctor creation success'
);

/**
 * Load OTP for doctor login.
 */
export const loadOTP = createAction(
  'Doctor| Login Load OTP',
  props<{
    email: string, phoneNumber: string, countryCode: string, requestType: string,
    hCaptchaToken: string, pharmaCode?: string
  }>()
);

/**
 * Set OTP in store.
 */
export const setOTP = createAction(
  'Doctor| Login Set OTP',
  props<{ otpRequestToken: any }>()
);

/**
 * Action for OTP verification.
 */
export const verifyOTP = createAction(
  'Doctor| Verify OTP',
  props<{ otp: string, otpRequestToken: string, pharmaCode?: string }>()
);

/**
 * Action for storing authentication token.
 */
export const setVerifyOTPData = createAction(
  'Doctor| Set Verify OTP',
  props<{ otpVerifyToken: string }>()
);

/**
 * Action for storing hcaptcha token.
 */
export const setHcaptchaToken = createAction(
  'Doctor| Set Hcaptcha Token',
  props<{ hcaptchaToken: string }>()
);

/**
 * Action for resend OTP Request.
 */
export const resendOTP = createAction(
  'Doctor| Resend OTP',
  props<{ otpRequestToken: string, hCaptchaToken: string }>()
);

/**
 * Action for fetching doctor detail.
 */
export const getDoctorDetail = createAction(
  'Doctor|GET [Dotor] get doctor detail',
  props<{
    id: number, token: string, pharmaCode?: string
  }>()
);

/**
 * Action for storing doctor detail.
 */
export const setDoctorDetail = createAction(
  'Doctor|SET [Dotor] get doctor detail',
  props<{ doctorData: DoctorData }>()
);

/**
 * Action for editing doctor information.
 */
export const editDoctorFormData = createAction(
  'EDIT|DOCTOR [DOCTOR] edit doctor detail',
  props<{
    id: number, token: string, formData: any
  }>()
);

/**
 * Action for edit doctor response.
 */
export const editDoctorSuccess = createAction(
  'EDIT|DOCTOR [DOCTOR] edit doctor detail success',
  props<{ success: boolean }>()
);

/**
 * Action for Resetting OTP Request Token.
 */
export const resetOTPRequestToken = createAction(
  'Doctor| Reset Reqeust Token',
  props<{}>()
);

/**
 * Action for Resetting Hcaptcha Token.
 */
export const resetHcaptchaToken = createAction(
  'Doctor| Reset HcaptchaToken',
  props<{}>()
);

/**
 * Action for storing type of input. i.e email or phone.
 */
export const setLoginInput = createAction(
  'Doctor| Set Login Input',
  props<{ loginInput: string }>()
);

/**
 * Action for Resetting login input.
 */
export const resetLoginInput = createAction(
  'Doctor| Reset Login Input',
  props<{}>()
);

/**
 * Load education.
 */
export const loadEducation = createAction(
  '[Doctor] Load eduaction',
  props<{}>()
);

/**
 * Set education.
 */
export const setEducation = createAction(
  'Doctor|SET [Education] Set education',
  props<{ data: EducationListResponse[] }>()
);
/**
 * reset doctor data
 */
export const resetDoctorData = createAction(
  'Doctor|Reset [DoctorData] Reset data',
  props<{}>()
);
/**
 * Load Pap programs.
 */
export const resetPapPrograms = createAction(
  '[Doctor] reset Pap Programs Forms'
);

export const registerDoctorWithDCPM = createAction(
  '[DCPM | Supervisor Doctor] register doctor',
  props<{ formData: any }>()
);
/**
 * used to post the update doctor cred.
 */
export const updateDoctorCredRequest = createAction(
  '[Doctor] update doctor credential request',
  props<{
    email: string,
    requestType: string,
    phoneNumber: number,
    countryCode: number,
    loginAuth: string,
    id: number,
    requesterUserRole: string
  }>()
);
/**
 * used to verify doctor cred update.
 */
export const verifyDoctorCredRequest = createAction(
  '[Doctor] verify doctor credential request',
  props<{ otp: string, loginAuth: string, verificationSignature: string, id: number }>()
);

/**
 * used to verify doctor cred update.
 */
export const findDoctor = createAction(
  '[Doctor] FIND get the doctor detail',
  props<{
    countryCode: number;
    phoneNumber: number;
    email: string;
    requestType: string;
    role: string;
    pharmaCode: string
  }>()
);

/**
 * Set Errors.
 */
export const setDoctorErrors = createAction(
  'Doctor|SET [Doctor FIND] Catch error of doctor api',
  props<{ doctorFindError: Error }>()
);

/**
 * Reset Errors.
 */
export const resetDoctorErrors = createAction(
  'Doctor|RESET [Doctor FIND] Reset error of doctor api'
);
