/**
 * angular imports.
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
/**
 * Rxjs import.
 */
import { Subscription } from 'rxjs/internal/Subscription';
/**
 * Ngrx store imports.
 */
import { Store } from '@ngrx/store';
import * as LoginSelector from '../../store/selectors/login/login.selectors';
import * as LoginAction from '../../store/actions/login/login.actions';
import * as StoreOperatorAction from '../../featureModules/dashboard/featureModules/store-operator/store/actions/store-operator.actions';
/**
 * Constant import.
 */
import { USER_TYPE, AEREPORTING_URL } from '../../constant/app.constant';
/**
 * Jwt import.
 */
import { JwtHelperService } from '@auth0/angular-jwt';
/**
 * Environment import.
 */
import { environment } from '../../../environments/environment';
/**
 * this component used for show the ae reporting form.
 */
@Component({
  selector: 'pap-ae-reporting',
  templateUrl: './ae-reporting.component.html',
  styleUrls: ['./ae-reporting.component.scss']
})
export class AeReportingComponent implements OnInit, OnDestroy {
  /**
   * Hold user role.
   */
  public userRole: string;
  /**
   * Loigin detail subscription.
   */
  public loginDetailSub: Subscription;
  /**
   * Hold id token.
   */
  public idToken: string;
  /**
   * User Email id.
   */
  public userEmail: string;
  /**
   * User Email id.
   */
  public iframeUrl: string;
  /**
   * Created all required instances.
   */
  constructor(private store: Store) { }
  /**
   * used to subscribe login details.
   */
  ngOnInit(): void {
    if (localStorage.getItem('verifyOtpToken') !== null) {
      this.store.dispatch(StoreOperatorAction
        .setVerifyOtpToken({ verifyOtpToken: localStorage.getItem('verifyOtpToken') }));
    }
    this.loginDetailSub = this.store.select(LoginSelector.getUserCredential).subscribe(data => {
      if (data) {
        this.idToken = data.accessToken;
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(this.idToken);
        this.userEmail = decodedToken.email;
        this.userRole = decodedToken.role;
      }
    });

    if (this.userRole === USER_TYPE.APPROVER) {
      this.iframeUrl = AEREPORTING_URL.approval;
    } else {
      if (environment.production === true) {
        this.iframeUrl = AEREPORTING_URL.store_operator_prod;
      } else {
        this.iframeUrl = AEREPORTING_URL.store_operator;
      }
    }
  }
  /**
   * used to unsubscribe login details.
   */
  ngOnDestroy(): void {
    if (this.loginDetailSub) {
      this.loginDetailSub.unsubscribe();
    }
  }

}
