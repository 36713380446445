// TODO:Manish: Design and static svg.

import { Component, Input, Output, EventEmitter, OnInit, TemplateRef } from '@angular/core';

/**
 * Interface for  autocomplete Received Value.
 */
export interface AutoCompleteForm {
  id: string;
  name: string;
}

/**
 * Component For Multiple Autocomplete.
 */
@Component({
  selector: 'dngx-auto-complete',
  templateUrl: './auto-complete-form.component.html',
  styleUrls: ['./auto-complete-form.component.css']
})
export class AutoCompleteFormComponent implements OnInit {

  /**
   * Used to store the received array for autocomplete.
   */
  @Input() listData: AutoCompleteForm[];

  /**
   * Used to store the Placeholder for autocomplete.
   */
  @Input() placeholderForAutoComplete: string;

  /**
   * Used to store the Empty Message for autocomplete.
   */
  @Input() emptyMessageForAutoComplete: string;

  /**
   * Used to store the check if autocomplete is required.
   */
  @Input() submitted: boolean;

  /**
   * Used to store the field which shows the suggestion list.
   */
  @Input() field: string;

  /**
   * Used to store the suggestions list array for autocomplete.
   */
  @Input() suggestionsList: AutoCompleteForm[];

  /**
   * Used to store the suggestions list array for autocomplete.
   */
  @Input() suggestionValue: TemplateRef<any>;

  /**
   * Used to store the selected items for Dom.
   */
  @Input() selectedItem: TemplateRef<any>;

  /**
   * Used to store the flag for clear all value.
   */
  @Input() clearAll = '';

  /**
   * Used to store the suggestions list array for autocomplete.
   */
  @Input() inviteFlag = '';

  /**
   * Used to store the value For Validation.
   */
  @Input() valueForValidation: number;

  /**
   * Used to disabled the form.
   */
  @Input() disabledValue = false;
  /**
   * Used to emit the searchAvailable to parent component.
   */
  @Output() searchAvailable = new EventEmitter<string>();

  /**
   * Used to send the selected list array outside.
   */
  @Output() emitSelectedArray: EventEmitter<AutoCompleteForm[]> = new EventEmitter();

  /**
   * Used to send the on select object outside.
   */
  @Output() emitOnSelectValues: EventEmitter<AutoCompleteForm[]> = new EventEmitter();

  /**
   * Used to send the un selected object outside.
   */
  @Output() emitUnSelectValues: EventEmitter<AutoCompleteForm[]> = new EventEmitter();

  /**
   * Used to send the suggestion list on click outside.
   */
  @Output() emitOnClickEvent: EventEmitter<AutoCompleteForm[]> = new EventEmitter();


  /**
   * Used to store the copy of origin received array.
   */
  public copyOfDataArray: AutoCompleteForm[];

  /**
   * AutoComplete formControl for input validation.
   */
  @Input() preFetchValue = [];

  /**
   * AutoComplete formControl for autofocus on load.
   */
  @Input() autofocus: boolean;

  /**
   * Used to create form builder group and copy the original array.
   */
  ngOnInit(): void{
    this.copyOfDataArray = this.listData;
  }

  /**
   * Used to emit the selected values array.
   */
  selectedValues(event): void {
    this.emitSelectedArray.emit(event);
  }

  /**
   * Used to select the single selected value.
   */
  onSelect(event): void {
    this.emitOnSelectValues.emit(event);
    if (this.inviteFlag === 'invite'){
      this.listData = this.copyOfDataArray;
      this.preFetchValue = [];
    }
  }

  /**
   * Used to unselect the single selected value.
   */
  onUnselect(event): void {
    this.emitUnSelectValues.emit(event);
  }

  /**
   * Used to emit the search data text.
   */
  searchAvailableInList(text): void {
    this.searchAvailable.emit(text);
  }

  /**
   * Used to reset the typing text of input.
   */
  onBlur(event): void {
    event.target.value = '';
  }

  openSuggestion(event): void {
    this.emitOnClickEvent.emit(event);
  }

}
