/**
 * Angular imports.
 */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

/**
 * Interceptor imports.
 */
import { PapInterceptor } from '../interceptors/pap.interceptor';
import { AuthInterceptor } from '../interceptors/auth.interceptor';
/**
 * Used to combine the interceptors.
 */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: PapInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

