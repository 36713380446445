/**
 * angular imports
 */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Used to validate the field not contains given value.
 */
export function notValueValidator(notAllowedValue: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    // Check if value is equal to the notAllowedValue
    if (value === notAllowedValue) {
      return { notGivenValue: { value: notAllowedValue } };
    }
    return null;
  };
}
