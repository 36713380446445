/**
 * Angular imports.
 */
 import { Directive, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
 /**
  * Rxjs imports.
  */
 import { Subject, Observable, Subscription, timer } from 'rxjs';
 import { switchMap, take, tap } from 'rxjs/operators';

 /**
  * Used to animate the counter.
  */
 @Directive({
   selector: '[dngxTimer]'
 })
 export class TimerDirective implements OnChanges, OnDestroy {
   /**
    * Var used for the counter.
    */
   private timerSource$ = new Subject<any>();
   /**
    * Var used for subscribe the counter.
    */
   private subscription = Subscription.EMPTY;
   /**
    * Used to input value of the counter.
    */
   @Input() dngxTimer: number;
   /**
    * Used to input value of the interval.
    */
   @Input() interval: number;
   /**
    * Used to reset updated value.
    */
   @Output() value = new EventEmitter<number>();
   /**
    * Used to subscribe the counter.
    */
   constructor() {
     this.subscription = this.timerSource$.pipe(
       switchMap(({ interval, count }) =>
         timer(0, interval).pipe(
           take(count),
           tap(() => this.value.emit(--count))
         )
       )
     ).subscribe();
   }
   /**
    * Used to show the updated value of counter.
    */
   ngOnChanges(): void {
     this.timerSource$.next({ count: this.dngxTimer, interval: this.interval });
   }
   /**
    * Used to unsubscribe the counter.
    */
   ngOnDestroy(): void {
     this.subscription.unsubscribe();
   }
 }
