/**
 * Angular imports.
 */
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
/**
 * This pipe to use for sanitize the url.
 */
@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  /**
   * Created all required instances.
   */
  constructor(private sanitizer: DomSanitizer) { }
  // tslint:disable-next-line: typedef
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
