/**
 * Angular imports.
 */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

/**
 * Used to show the form for pediatric report.
 */
@Component({
  selector: 'pap-pediatric-report-form',
  templateUrl: './pediatric-report-form.component.html',
  styleUrls: ['./pediatric-report-form.component.scss']
})
export class PediatricReportFormComponent implements OnInit, OnChanges {
  @Input() functionality = '';
  @Input() formData = null;
  /**
   * Form pediatric report.
   */
  public pediatricReportForm: FormGroup;
  private disabled: boolean;

  /**
   * setter for the form controls.
   */
  get form(): { [key: string]: AbstractControl } { return this.pediatricReportForm.controls; }
  get formValidity(): boolean { return this.pediatricReportForm.valid; }
  get formValues(): { pediatricReportForm: any; } {
    return {
      pediatricReportForm: this.pediatricReportForm.value
    };
  }

  /**
   * Necessary instances.
   */
  constructor(private fb: FormBuilder) { }

  /**
   * Calling initialize form.
   */
  ngOnInit(): void {
    this.disabled = this.functionality === 'view';
    this.initializeForm();
  }

  /**
   * to detect changes on incoming form values
   * @param changes changes on formData
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formData) {
      this.initializeForm();
    }
  }

  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.pediatricReportForm = this.fb.group({

      age: new FormControl(
        {value : this.formData?.age ? this.formData?.age : '', disabled: this.disabled},
       [Validators.maxLength(20)]),
      majorDevelopmentalParameters: new FormControl(
        {value : this.formData?.majorDevelopmentalParameters ? this.formData?.majorDevelopmentalParameters : '',
       disabled: this.disabled},
       [Validators.maxLength(100)]),
      birthHistory: new FormControl(
        {value : this.formData?.birthHistory ? this.formData?.birthHistory : '', disabled: this.disabled},
       [Validators.maxLength(100)]),
      dosageCalculationMethod: new FormControl(
        {value : this.formData?.dosageCalculationMethod ? this.formData?.dosageCalculationMethod : '',
       disabled: this.disabled},
       [Validators.maxLength(100)]),
    });
  }
}
