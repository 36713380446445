
<div class="p-4 prescriptionFreezeDiv">
    <div class="row">
      <div class="col-md-12 prescriptionFreezeDiv__details">
        <div class="bg-white border rounded box-shadow p-3 prescriptionFreezeDiv__details-no-data-heading">
          <p class="fnt-size18px font-weight-bold mb-3 mb-1">Add New Attendant</p>
          <form [formGroup]="addAttendantForm" (ngSubmit)="onAddAttendant()">
            <div class="row justify-content-center">
              <div class="col-md-6 form-group">
                <p class="text-black font-weight-bold mb-1">{{'MOBILE_NUMBER'|translate}}<span class="text-danger">*</span></p>
                <div class="d-flex">
                  <div class="w-100 position-relative coutryCodeDesign countryDiv__findDoctor"
                    >
                    <p-dropdown
                      class="d-flex align-items-center position-absolute h-100  pr-2 form-control disable-country"
                      [options]="[this.defaultCountry]" [optionValue]="'countryCode'">
                      <ng-template pTemplate="selectedItem">
                        <div class="country-item-value">
                          <div class="fnt-size12px fnt_Medium text-rolling-stone-grey mr-1">
                            <img [src]="this.defaultCountry.logo" class="flag mr-1"
                              onerror="this.src='assets/images/logo.svg'" alt="country flag" />
                            +{{this.defaultCountry.countryCode}}
                          </div>
                        </div>
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        <div class="d-flex justify-content-between align-items-center p-2">
                          <div class="country-item-value d-flex">
                            <img [src]="item.logo" class="flag mr-2" onerror="this.src='assets/images/logo.svg'"
                              alt="country flag" />
                            <div class="fnt-size12px text-abbey-grey mr-2">
                              {{item.name}}
                            </div>
                            <div class="fnt-size12px text-abbey-grey">
                              +{{item.countryCode}}
                            </div>
                          </div>
                          <img src="assets/images/checkbox.svg" alt="green checked checkbox">
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                  <div class="w-100">
                    <input type="string" formControlName="phone_number"
                        maxlength="16"
                      class="form-control fnt-size14px" placeholder="{{'ATTENDANT_MOBILE' | translate }} "
                      dngxDigitOnly />
                  </div>
                </div>
                <div *ngIf="form.phone_number.dirty && form.phone_number.invalid" class="text-danger fnt-size12px"> {{
                  "MOBILE_NUMBER_LIMIT" | translate }}</div>
                  <div *ngIf="form?.phone_number?.pristine && form?.phone_number?.touched" class="text-danger fnt-size12px">
                    {{ "MOBILE_IS_REQUIRED" | translate }}
                  </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <p class="text-black mb-1 font-weight-bold">{{"EMAIL_ADDRESS" | translate}} <span class="text-danger">*</span></p>
                  
                  <div class="position-relative">
                    <input formControlName="email" type="email" class="form-control fnt-size14px" (keypress)="preventWhitespace($event)"
                      placeholder="{{'ATTENDANT_EMAIL_PLACEHOLDER' | translate}}" />
                  </div>
                  <div *ngIf="form?.email?.touched && form?.email?.invalid" class="text-danger fnt-size12px">
                    {{ "ATTENDANT_VALID_EMAIL" | translate }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <p class="text-black font-weight-bold">{{"First Name" | translate}}<span class="text-danger">*</span></p>
                  <div class="position-relative">
                    <input formControlName="first_name" type="text" class="form-control fnt-size14px"
                      placeholder="{{'ENTER_ATTENDANT_FIRST_NAME' | translate}}" />
                  </div>
                  <div *ngIf="form?.first_name?.touched && form?.first_name?.invalid" class="text-danger fnt-size12px">
                    {{ "DOCTOR_REG_FIRST_NAME_REQ" | translate }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <p class="text-black font-weight-bold">{{"Last Name" | translate}} <span class="text-danger">*</span> </p>
                  <div class="position-relative">
                    <input formControlName="last_name" type="text" class="form-control fnt-size14px"
                      placeholder="{{'ENTER_ATTENDANT_LAST_NAME' | translate}}" />
                  </div>
                  <div *ngIf="form?.last_name?.touched && form?.last_name?.invalid" class="text-danger fnt-size12px">
                    {{ "DOCTOR_REG_LAST_NAME_REQ" | translate }}
                  </div>
                </div>
              </div>
              <div class="col-12 d-flex justify-content-end">
                <button class="btn btn-success" type="submit" 
                  [disabled]="this.addAttendantForm.invalid || !(form?.phoneNumber?.value || form?.email?.value)">
                  {{'SAVE_DETAILS' | translate}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  