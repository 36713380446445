
/**
 * NGRX store imports.
 */
import { createReducer, on } from '@ngrx/store';
import * as LoginAction from '../../actions/login/login.actions';

/**
 * Model imports.
 */
import { Signin } from '../../../models/signin.model';
import { ErrorHandler } from '../../../models/responses.model';

/**
 * This is used to store the user login detail.
 */
export const loginFeatureKey = 'login';

export interface State {
  authenticationResult: Signin;
  error: ErrorHandler;
}

export const initialState: State = {
  authenticationResult: null,
  error: null
};


export const reducer = createReducer(
  initialState,

  on(LoginAction.setUserCredentials, (state, payload) => ({
    ...state,
    authenticationResult: payload.authenticationResult
  })),

  on(LoginAction.resetLoginStore, () => ({
    ...initialState,
  })),

  on(LoginAction.setLoginError, (state, payload) => ({
    ...state,
    error: payload.error
  }))
);

