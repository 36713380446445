import { Component, OnInit } from '@angular/core';
import { SignInCountry } from '../../../models/signin.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Country } from '../../../models/sign-up-chatbot.model';
import * as SignupAction from '../../../store/actions/signup/signup.actions';
import * as SignupSelector from '../../../store/selectors/signup/signup.selectors';
import { Store } from '@ngrx/store';
import { submitForm } from '../../../store/actions/attendent/attendent.action';
@Component({
  selector: 'pap-attendent-register',
  templateUrl: './attendent-register.component.html',
  styleUrls: ['./attendent-register.component.scss']
})
export class AttendentRegisterComponent implements OnInit {
  countriesSub: Subscription;
  public countries: Country[];

  registrationForm: FormGroup;
  constructor(private fb: FormBuilder, private store: Store ,

  ) {
    this.registrationForm = this.fb.group({
      countryCode: ['66', Validators.required],
      mobileNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]]
    });
  }

  ngOnInit(): void {
    this.store.dispatch(SignupAction.loadCountries({}));
    this.countriesSub = this.store.select(SignupSelector.getCountryList).subscribe(data => {
      if (data !== null) {
        this.countries = data.list;
      }
    });
  }

  onSubmit(): void {
    if (this.registrationForm.valid) {
      this.store.dispatch(submitForm(this.registrationForm.value));
      console.log(this.registrationForm);
    }
  }

}


