import { createAction, props } from '@ngrx/store';

/**
 * Modal imports.
 */
import { InternetConnectionStatus } from '../../../models/internetConnection.modal';

/**
 * This action should be triggered by every effect, if they are adding
 * cathError block. This effect basically calls another effect, which acts as a
 * generic error handler for all the effect and further throw the error to call
 * the application's error handler, to log the error.
 */
export const catchError = createAction(
  'ERROR|CATCH Global error handler for effects',
  props<{ error: Error }>()
);

/**
 * Set online status.
 */
export const setOnlineStatus = createAction(
  'ERROR|SET online status',
  props<{ internetConnectionStatus: InternetConnectionStatus}>()
);



