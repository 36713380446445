import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

/**
 * Popover component show data in popover.
 */
@Component({
  selector: 'dngx-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.css']
})
export class PopoverComponent {

  /**
   * Used to call the hide function of popover.
   */
  @ViewChild('op') op;

  /**
   * Used to store the headerItems.
   */
  @Input() headerItems: TemplateRef<any>;

  /**
   * Used to store the popOver css class.
   */
  @Input() popOverCssClass: string;

  /**
   * Used to store the popOver css class.
   */
  @Input() showCloseIcon: boolean;

  /**
   * Used to hide the popover.
   */
  hide(): void {
    this.op.hide();
  }

}
