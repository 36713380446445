import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'pap-add-new-patient',
  templateUrl: './add-new-patient.component.html',
  styleUrls: ['./add-new-patient.component.scss']
})
export class AddNewPatientComponent implements OnInit {

  form: FormGroup;
  maxDate: string;
  hospitalList: string[] = ['Hospital A', 'Hospital B', 'Hospital C'];
  fileError: string | null = null;

  constructor(private fb: FormBuilder) {
    const today = new Date();
    this.maxDate = today.toISOString().split('T')[0];

    this.form = this.fb.group({
      // Section 1: Attendant Details
      attendantName: [{ value: 'Prefilled Name', disabled: true }, Validators.required],
      attendantCountryCode: [{ value: '+65', disabled: true }, Validators.required],
      attendantMobile: [{ value: '12345678', disabled: true }, Validators.required],
      attendantEmail: [{ value: 'example@example.com', disabled: true }, [Validators.required, Validators.email]],
      relationshipWithPatient: ['', Validators.required],

      // Section 2: Patient Details
      patientFirstName: ['', Validators.required],
      patientMiddleName: [''],
      patientLastName: ['', Validators.required],
      patientDob: ['', Validators.required],
      patientGender: ['', Validators.required],
      patientIdCardType: ['', Validators.required],
      patientIdCardNumber: ['', Validators.required],
      patientMobile: ['', [Validators.pattern(/^[0-9]{8,16}$/)]],
      patientEmail: ['', Validators.email],

      // Section 3: Program Details
      programName: ['', Validators.required],
      patientConsentDate: ['', Validators.required],

      // Section 4: Prescription Details
      doctorName: ['', Validators.required],

      // Section 5: Treatment Details
      hospitalName: ['', Validators.required],
      clinicName: ['', Validators.required]
    });
  }

  ngOnInit(): void {}

  onFileChange(event: any): void {
    const files = event.target.files;

    if (files.length > 10) {
      this.fileError = 'Only 10 attachments allowed.';
      return;
    }

    for (const file of files) {
      if (file.size > 20 * 1024 * 1024) {
        this.fileError = 'Single file size must not exceed 20 MB.';
        return;
      }

      const allowedTypes = ['image/jpeg', 'image/png'];
      if (!allowedTypes.includes(file.type)) {
        this.fileError = 'Only jpg/jpeg/png files are allowed.';
        return;
      }
    }

    this.fileError = null; // Clear errors if all validations pass
  }

  onSubmit(): void {
    if (this.form.valid) {
      console.log('Form Submitted:', this.form.getRawValue());
    } else {
      console.log('Form is invalid');
    }
  }

}
