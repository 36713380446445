<div class="col-11 col-md-6 h-100 hyridComponent mx-auto mt-4">
    <div class="border-bottom bg-white shadow-sm">
        <div class="text-center">
            <img class="p-3" src="assets/images/logo.svg" alt="logo">
        </div>
        <div class="card-body bg-white shadow-sm border-top">
            <h5 class="card-title font-weight-bold fnt-size20px">
                Welcome to Patient Access Program Service
            </h5>
            <p class="card-text signup-message fnt-size15px mb-1">
                Please provide your details prior to registering for a new patient.
            </p>
            <div class="form-group">
                <form [formGroup]="registrationForm"  (ngSubmit)="onSubmit()">
                    <label for="email">Your Mobile Number</label>
                        <span class="text-danger">*</span>
                    <div class="input-group mb-2">
                        <p-dropdown class="d-flex align-items-center pr-2 "
                          [options]="countries" formControlName="countryCode" [optionValue]="'countryCode'">
                          <ng-template let-item pTemplate="selectedItem">
                            <div class="country-item-value" *ngIf="this.registrationForm.get('countryCode')">
                              <div class="fnt-size12px fnt_Medium text-rolling-stone-grey mr-1">
                                <img [src]="item.logo" class="flag mr-1" onerror="this.src='assets/images/logo.svg'"
                                  alt="country flag" />
                                +{{this.registrationForm.get('countryCode').value}}
                              </div>
                            </div>
                          </ng-template>
                          <ng-template let-item pTemplate="item">
                            <div class="d-flex justify-content-between align-items-center p-2">
                              <div class="country-item-value d-flex">
                                <img [src]="item.logo" class="flag mr-2" onerror="this.src='assets/images/logo.svg'"
                                  alt="country flag" />
                                <div class="fnt-size12px text-abbey-grey mr-2"
                                  [ngClass]="{'font-weight-bold': this.registrationForm.get('countryCode').value === item.countryCode}">
                                  {{item.name}}
                                </div>
                                <div class="fnt-size12px text-abbey-grey"
                                  [ngClass]="{'font-weight-bold': this.registrationForm.get('countryCode').value === item.countryCode}">
                                  +{{item.countryCode}}
                                </div>
                              </div>
                              <img *ngIf="this.registrationForm.get('countryCode').value === item.countryCode"
                                src="assets/images/checkbox.svg" alt="green checked checkbox">
                            </div>
                          </ng-template>
                        </p-dropdown>
                        <input type="number" id="mobileNumber" class="mobile-input" placeholder="Enter Mobile Number"
                          required [maxLength]="15"
                          formControlName="mobileNumber">
                      </div>

                    <div class="form-group">
                        <label for="email">Your Email Address</label>
                        <span class="text-danger">*</span>
                        <input type="email" formControlName="email" class="form-control" id="email"
                            placeholder="Enter Email Address">
                        <div *ngIf="registrationForm.controls.email.invalid && registrationForm.controls.email.touched"
                            class="text-danger">
                            Please enter a valid email address
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="firstName">Your First Name</label>
                        <span class="text-danger">*</span>
                        <input type="text" formControlName="firstName" class="form-control" id="firstName"
                            placeholder="Enter First Name">
                        <div *ngIf="registrationForm.controls.firstName.invalid && registrationForm.controls.firstName.touched"
                            class="text-danger">
                            First Name is required
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="lastName">Your Last Name</label>
                        <span class="text-danger">*</span>
                        <input type="text" formControlName="lastName" class="form-control" id="lastName"
                            placeholder="Enter Last Name">
                        <div *ngIf="registrationForm.controls.lastName.invalid && registrationForm.controls.lastName.touched"
                            class="text-danger">
                            Last Name is required
                        </div>
                    </div>

                    <button type="submit" class="btn btn-success btn-block"
                        [disabled]="registrationForm.invalid">Register</button>
                </form>
            </div>
        </div>
    </div>
</div>