<!-- {{registrationForm.value|json}} -->

<div class="col-11 col-md-6 h-100 hyridComponent mx-auto mt-4">
  <div class="border-bottom bg-white shadow-sm">
    <!-- {{registrationForm.value|json}} -->
    <div class="text-center">
      <img class="p-3" src="assets/images/logo.svg" alt="logo">
    </div>
    <div class="card-body bg-white shadow-sm border-top">
      <h5 class="card-title font-weight-bold fnt-size20px">
        Welcome to Patient Access Program Service
      </h5>
      <p class="card-text signup-message fnt-size15px mb-1">
        Please provide your details prior to registering for a new patient.
      </p>
      <div class="form-group">
        <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <p class="text-black font-weight-bold mb-1">{{"MOBILE_NUMBER" | translate}}</p>
            <div class="d-flex">
              <div class="w-100 position-relative coutryCodeDesign countryDiv__addAttendant">
                <p-dropdown class="d-flex align-items-center position-absolute h-100  pr-2 form-control disable-country"
                  [options]="[this.defaultCountry]" optionLabel="name" optionValue="code" readonly
                  >
                  <ng-template pTemplate="selectedItem">
                    <div class="country-item-value">
                      <div class="fnt-size12px fnt_Medium text-rolling-stone-grey mr-1">
                        <img [src]="this.defaultCountry.logo" class="flag mr-1"
                          onerror="this.src='assets/images/logo.svg'" alt="country flag" />
                        +{{this.defaultCountry.countryCode}}
                      </div>
                    </div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div class="d-flex justify-content-between align-items-center p-2">
                      <div class="country-item-value d-flex">
                        <img [src]="item.logo" class="flag mr-2" onerror="this.src='assets/images/logo.svg'"
                          alt="country flag" />
                        <div class="fnt-size12px text-abbey-grey mr-2">
                          {{item.name}}
                        </div>
                        <div class="fnt-size12px text-abbey-grey">
                          +{{item.countryCode}}
                        </div>
                      </div>
                      <img src="assets/images/checkbox.svg" alt="green checked checkbox">
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
              <div class="w-100">
                <input type="number" formControlName="phoneNumber" maxlength="14" class="form-control fnt-size14px"
                  placeholder="{{'ENTER_MOBILE_NUMBER' | translate }} " dngxDigitOnly readonly />
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="email">Your Email Address</label>
            <span class="text-danger">*</span>
            <input type="email" formControlName="email" class="form-control" id="email"
              placeholder="Enter Email Address">
            <div *ngIf="registrationForm.controls.email.invalid && registrationForm.controls.email.touched"
              class="text-danger">
              Please enter a valid email address
            </div>
          </div>

          <div class="form-group">
            <label for="firstName">Your First Name</label>
            <span class="text-danger">*</span>
            <input type="text" formControlName="first_name" class="form-control" id="firstName"
              placeholder="Enter First Name">
            <div *ngIf="registrationForm.controls.first_name.invalid && registrationForm.controls.first_name.touched"
              class="text-danger">
              First Name is required
            </div>
          </div>

          <div class="form-group">
            <label for="lastName">Your Last Name</label>
            <span class="text-danger">*</span>
            <input type="text" formControlName="last_name" class="form-control" id="lastName"
              placeholder="Enter Last Name">
            <div *ngIf="registrationForm.controls.last_name.invalid && registrationForm.controls.last_name.touched"
              class="text-danger">
              Last Name is required
            </div>
          </div>

          <button type="submit" class="btn btn-success btn-block"
            [disabled]="registrationForm.invalid">Register</button>
        </form>
      </div>
    </div>
  </div>
</div>