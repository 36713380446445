<div class="row no-gutters align-items-center mt-5 pt-5 create-password">
    <div class="col-12 col-md-6 col-lg-4 mx-auto">
      <div class="header  pt-4 bg-white mb-2 rounded">
            <div class="text-center pb-4">
            <img [src]=this.logoSrc alt="Logo">
            </div>
      </div>
      <form #createPasswordForm="ngForm" (ngSubmit)="createLoginPassword(createPasswordForm.value)">
        <div class="body p-4 bg-white rounded">
            <h4 class="fnt_Bold fnt-size20px">{{'CREATE_NEW_PASSWORD'| translate}}</h4>
            <div class="form-group">
                <label class="fnt-size12px fnt_Medium text-abbey-grey">{{'PASSWORD'| translate}}</label>
                <div class="position-relative">
                    <input ngModel [type]=passwordViewStatus placeholder="{{'ENTER_NEW_PASSWORD'| translate}}" 
                    class="form-control" #password id="password" value="" name="password" 
                    (keyup)="isEqual(password.value,confirmPassword.value)" (focus)="onFocus()">
                    <img src="{{passwordClosedSrc}}" alt="eye-closed" 
                    class="position-absolute password-icon" 
                    (click)="togglePasswordView(password)">
                </div>
            </div>
            <div class="form-group">
                <label class="fnt-size12px fnt_Medium text-abbey-grey">{{'CONFIRM_PASSWORD'| translate}}</label>
                <img *ngIf="isMatch" src="{{tickIconSrc}}" alt="tick-icon" class="ml-2">
                <div class="position-relative">
                    <input ngModel [type]=confirmPasswordViewStatus 
                    placeholder="{{'RE_ENTER_PASSWORD'| translate}}" class="form-control" 
                    #confirmPassword id="confirm-password" value="" 
                    (keyup)="isEqual(password.value,confirmPassword.value)" 
                    name="confirm_password" (focus)="onFocus()">
                    <img src="{{passwordClosedSrc}}" alt="eye-closed" 
                    class="position-absolute password-icon" 
                    (click)="togglePasswordView(confirmPassword)">
                </div>
            </div>
            <p *ngIf="errorObj && !errorMessage" class="mb-2 alert alert-danger">{{errorObj.error.message}}</p>
            <p *ngIf="errorObj && errorMessage" class="mb-2 alert alert-danger">{{errorMessage}}</p>
            <button class="w-100 btn btn-success border-none rounded" [disabled]=disable>
                {{'SUBMIT'| translate}}
            </button>
        </div>
      </form>
    </div>
  </div>
  