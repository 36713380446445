import { createReducer, on } from '@ngrx/store';
import * as AttendantAction from '../../actions/attendent/attendent.action';


export interface State {
  attendant: any;
  otpRequestToken: string;
  attendantForm: any;
}
export const initialState: State = {
  attendant: null,
  otpRequestToken: null,
  attendantForm: null

};
export const reducer = createReducer(
  initialState,
  on(AttendantAction.setOtp, (state, payload) => ({
    ...state,
    otpRequestToken: payload.otpRequestToken
  })),

  on(AttendantAction.submitFormSuccess, (state, payload) => ({
    ...state,
    attendantForm: payload.response
  })),
);



