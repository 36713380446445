/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/**
 * Rxjs Operators.
 */
import { catchError, mergeMap, map } from 'rxjs/operators';

/**
 * Ngrx store
 */
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as FileAction from '../../actions/file/file.action';
import * as ErrorAction from '../../actions/error/error.actions';

/**
 * Models imports.
 */
import { FileResponse } from '../../../models/responses.model';

@Injectable()
export class FileEffects {
  /**
   * Effect for getting presigned Url to view file.
   */
  public loadFilePresignedUrl$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(FileAction.loadFilePresignedUrl),
        mergeMap((action) =>
          this.http.get<FileResponse>(
            '/' + action.endPoint, {
            params: {
              type: action.fileType,
              fileName: action.fileName,
              contentType: action.contentType,
              put: action.fileMethod,
              module: action.module ? action.module : 'pap',
              country: action.country ? action.country : 'india'
            }
          }
          ).pipe(
            map(res => {
              if (res.success === true) {
                return FileAction.setFilePresignedUrl({
                  presignedUrl: res.data.preSignedUrl, presignedReqTime: action.time
                });
              } else {
                return FileAction.setFilePresignedUrl({ presignedUrl: '', presignedReqTime: null });
              }
            }),
            catchError(err => {
              return [FileAction.setErrors(
                { error: err.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );

  public fileUpload$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(FileAction.fileUpload),
        mergeMap((action) =>
          this.http.put<any>(
            action.endPoint,
            action.file
          ).pipe(
            map(res => {
              return FileAction.setUploadingTime({ uploadReqTime: action.uploadReqTime });
            }),
            catchError(err => {
              return [FileAction.setErrors(
                { error: err.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );


  /**
   * Effect for getting presigned Url to view file.
   */
  public loadFilePresignedUrlWithIndex$ = createEffect(
    () => this.actions$
      .pipe(
        ofType(FileAction.loadFilePresignedUrlWithIndex),
        mergeMap((action) =>
          this.http.get<FileResponse>(
            '/' + action.endPoint, {
            params: {
              type: action.fileType,
              fileName: action.fileName,
              contentType: action.contentType,
              put: action.fileMethod,
              module: action.module ? action.module : 'pap',
              country: action.country ? action.country : 'india'
            }
          }
          ).pipe(
            map(res => {
              if (res.success === true) {
                return FileAction.setFilePresignedUrlWithIndex({
                  presignedUrl: res.data.preSignedUrl,
                  presignedReqTime: action.time,
                  index: action.index
                });
              } else {
                return FileAction.setFilePresignedUrlWithIndex({
                  presignedUrl: '',
                  presignedReqTime: null,
                  index: null
                });
              }
            }),
            catchError(err => {
              return [FileAction.setErrors(
                { error: err.error }
              ),
              ErrorAction.catchError({ error: err })
              ];
            })
          )
        )
      )
  );

  /**
   * Declare actions and httpsClient for effects.
   */
  constructor(
    private actions$: Actions,
    private http: HttpClient,
  ) { }
}
