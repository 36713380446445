/**
 * Angular import.
 */
import { Component, OnInit, OnDestroy } from '@angular/core';

/**
 * Service import.
 */
import { ConnectionService } from 'ng-connection-service';
import { MessageService } from 'primeng/api';
import { ToastrService } from 'projects/ngp-docquity-ds/src/lib/toastr/services/toastr.service';

/**
 * Ngrx import.
 */
import { Store } from '@ngrx/store';
import * as ErrorAction from './store/actions/error/error.actions';
import * as ErrorSelector from './store/selectors/error/error.selectors';

/**
 * Rxjs import.
 */
import { Observable, Subscription } from 'rxjs';

/**
 * Interface imports.
 */
import { InternetConnectionStatus } from './models/internetConnection.modal';

/**
 * This is the root component.
 */

@Component({
  selector: 'pap-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService, ToastrService]
})
export class AppComponent implements OnInit, OnDestroy {
  /**
   * Hold online status.
   */
  public isOnlineObservable$: Observable<InternetConnectionStatus>;

  /**
   * Subscription for connection service.
   */
  public connectionServiceSub: Subscription;

  /**
   * Get online status from store.
   */
  ngOnInit(): void {
    this.isOnlineObservable$ = this.store.select(ErrorSelector.getOnlineStatus);

    this.connectionServiceSub = this.connectionService.monitor().subscribe(isConnected => {
      if (!isConnected) {
        const data = { isOnline: true, showModal: true };
        this.store.dispatch(ErrorAction.setOnlineStatus({ internetConnectionStatus: data }));
      }
    });
  }
  /**
   * Close modal.
   */
  hideModal(event): void {
    const data = { isOnline: true, showModal: false };
    this.store.dispatch(ErrorAction.setOnlineStatus({ internetConnectionStatus: data }));
  }

  /**
   * Created all required instances.
   */
  constructor(private connectionService: ConnectionService, private store: Store) { }

  ngOnDestroy(): void {
    if (this.connectionServiceSub) {
      this.connectionServiceSub.unsubscribe();
    }
  }

}
