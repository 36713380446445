import { HospitalList, ProgramData } from './../../../models/sign-up-chatbot.model';
import { createReducer, on } from '@ngrx/store';
import * as DoctorAction from '../../actions/doctor/doctor.actions';
import { Error, SpecialtyListData, EducationListResponse } from '../../../models/responses.model';


export const doctorFeatureKey = 'doctor';

export interface State {
  papProgramList: ProgramData[];
  specialityList: SpecialtyListData[];
  isDoctorCreated: any;
  otpRequestToken: string;
  loginInput: string;
  hcaptchaToken: string;
  error: Error;
  doctorData: any;
  DoctorEdit: boolean;
  educationList: EducationListResponse[];
  hospitalAffiliationList: HospitalList;
  doctorFindError: Error;
}

export const initialState: State = {
  papProgramList: null,
  specialityList: null,
  isDoctorCreated: null,
  otpRequestToken: null,
  loginInput: null,
  hcaptchaToken: null,
  error: null,
  doctorData: null,
  DoctorEdit: null,
  educationList: null,
  hospitalAffiliationList: null,
  doctorFindError: null
};


export const reducer = createReducer(
  initialState,
  on(DoctorAction.setPapPrograms, (state, payload) => ({
    ...state,
    papProgramList: payload.data,
  })),
  on(DoctorAction.setHospitalAffiliationList, (state, payload) => ({
    ...state,
    hospitalAffiliationList: payload.hospitalAffiliationList,
  })),
  on(DoctorAction.setSpecialty, (state, payload) => ({
    ...state,
    specialityList: payload.data,
  })),
  on(DoctorAction.setDoctorSubmitSuccess, (state, payload) => ({
    ...state,
    isDoctorCreated: payload.success,
  })),
  on(DoctorAction.resetDoctorSubmitSuccess, (state, payload) => ({
    ...state,
    isDoctorCreated: initialState.isDoctorCreated
  })),
  on(DoctorAction.setOTP, (state, payload) => ({
    ...state,
    otpRequestToken: payload.otpRequestToken
  })),
  on(DoctorAction.setHcaptchaToken, (state, payload) => ({
    ...state,
    hcaptchaToken: payload.hcaptchaToken
  })),
  on(DoctorAction.setDoctorDetail, (state, payload) => (
    {
      ...state,
      doctorData: payload.doctorData
    }
  )),
  on(DoctorAction.editDoctorSuccess, (state, payload) => (
    {
      ...state,
      DoctorEdit: payload.success
    }
  )),
  on(DoctorAction.resetHcaptchaToken, (state, payload) => ({
    ...state,
    hcaptchaToken: initialState.hcaptchaToken
  })),
  on(DoctorAction.resetOTPRequestToken, (state, payload) => ({
    ...state,
    otpRequestToken: initialState.otpRequestToken
  })),
  on(DoctorAction.setLoginInput, (state, payload) => ({
    ...state,
    loginInput: payload.loginInput
  })),
  on(DoctorAction.resetLoginInput, (state, payload) => ({
    ...state,
    loginInput: initialState.loginInput
  })),
  on(DoctorAction.setErrors, (state, payload) => ({
    ...state,
    error: payload.error
  })),
  on(DoctorAction.resetErrors, (state, payload) => ({
    ...state,
    error: initialState.error
  })),
  on(DoctorAction.setEducation , (state, payload) => ({
    ...state,
    educationList: payload.data
  })),
  on(DoctorAction.resetDoctorData , (state, payload) => ({
    ...state,
    doctorData: initialState.doctorData
  })),
  on(DoctorAction.resetPapPrograms, (state, paylaod) => ({
    ...state,
    papProgramList: initialState.papProgramList
  })),
  on(DoctorAction.setDoctorErrors, (state, payload) => ({
    ...state,
    doctorFindError: payload.doctorFindError
  })),
  on(DoctorAction.resetDoctorErrors, (state, payload) => ({
    ...state,
    doctorFindError: initialState.doctorFindError
  })),
);
