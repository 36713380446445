<dngx-drop-down
  placeholder="{{'SELECT_A_LANGUAGE' | translate}}"
  dropdownIcon="dropdown"
  styleClass="bg-transparent border-0 align-items-center language-selector"
  [list]="languages"
  [selectedValue]="selectedLanguage"
  emptyFilterMessage="{{'NO_RECORD_FOUND' | translate}}"
  [virtualScroll]="false"
  [filter]="false"
  [resetFilterOnHide]="true"
  [suggestionValue]="suggestionsForLang"
  [selectedItem]="selectedItemForLang"
  (emitSelectedValue)="onLangChange($event)">
</dngx-drop-down>
<ng-template let-selected #selectedItemForLang>
  <span>
    {{ selected.label | translate}}
  </span>
</ng-template>
<ng-template let-item #suggestionsForLang>
  <div class="text-left">{{ item.label | translate}}</div>
</ng-template>
