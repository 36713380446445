import { Injectable } from '@angular/core';
/**
 * jwt import.
 */
import { JwtHelperService } from '@auth0/angular-jwt';
/**
 * constant import
 */
import { COUNTRIES_LIST, COUNTRIES_NAME, FIELD_TYPE, LOCAL_STORAGE_COUNTRY } from './constant/app.constant';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  /**
   * helper function to generate dispense list.
   */
  generateDispenseList(maxQuantity: number, size: number): number[] {
    const list: number[] = [];
    for (let i = size; i <= maxQuantity; i = i + size) {
      list.push(i);
    }
    return list;
  }
  /**
   * function to fetch decoded token
   */
  public decodeTokenFunc(token: string): any {
    const idToken = localStorage.getItem('doctorAuth');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(idToken);
    return decodedToken;
  }
  public decodeFindDoctorTokenFunc(token: string): any {
    const idToken = localStorage.getItem('findDoctorAuth');
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(idToken);
    return decodedToken;
  }
  /**
   * function to validate number.
   */
  validateNumber(numberString: string): boolean {
    if (numberString === '' || isNaN(Number(numberString)) || Number(numberString) <= 0) {
      return false;
    }
    return true;
  }

  /**
   * mask the characters.
   */
  public hideCharacters(value: string, type: string): string {
    if (type === FIELD_TYPE.EMAIL) {
      const input = value.split('@');
      return input[0].length > 4 ?
      ('*'.repeat(input[0].length - 4) + input[0].substring(input[0].length - 4) + '@' + input[1]) : value;
    }
    return value.length > 4 ? ('*'.repeat(value.length - 4) + value.substring(value.length - 4)) : value;
  }

  public getCurrentCountryString(): string {
    const countryCode = localStorage.getItem(LOCAL_STORAGE_COUNTRY.COUNTRY);
    let countryName = COUNTRIES_NAME[countryCode];
    if (!countryName) {
      countryName = COUNTRIES_LIST.PHILLIPPINS.name;
    }
    return countryName;
  }
}
