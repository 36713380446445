import { COUNTRIES_LIST } from './../../../../constant/app.constant';
/**
 * angular imports.
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
/**
 * Ngrx Imports.
 */
import { Store } from '@ngrx/store';
import * as PharmaActions from '../../../../store/actions/pharma/pharma.actions';
import * as PharmaSelector from '../../../../store/selectors/pharma/pharma.selectors';
/**
 * rxjs imports
 */
import { Subscription } from 'rxjs';
/**
 * model imports.
 */
import { PharmaDetail } from 'projects/nga-PAP/src/app/models/responses.model';
/**
 * component used to show the pharma privacy policy page.
 */
@Component({
  selector: 'pap-pharma-policy',
  templateUrl: './pharma-policy.component.html',
  styleUrls: ['./pharma-policy.component.scss']
})
export class PharmaPolicyComponent implements OnInit, OnDestroy {
  /**
   * subscription for pharma data
   */
  private pharmaSubs = new Subscription();
  /**
   * subscription for invalid failure
   */
  public invalidFailure: Subscription;
  /**
   * variable to store pharma detail
   */
  public pharma: PharmaDetail;

  /**
   * variable to store pharma code
   */
  public pharmaCode: string;
  /**
   * public pharma code subscription from query params
   */
  public pharmaQueryParam: Subscription;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute
  ) { }
  /**
   * subscribe the pharma data from ngrx selector.
   */
  ngOnInit(): void {
    this.pharmaSubs.add(this.route.queryParams
      .subscribe(params => {
        this.pharmaCode = params.code;
      }));

    this.store.dispatch(PharmaActions.loadPharmas({ pharmaCode: this.pharmaCode }));
    this.pharmaSubs.add(this.store.select(PharmaSelector.getPharmaDetail).subscribe(data => {
      if (data != null) {
        this.pharma = data;
      }
    }));
    this.pharmaSubs.add(this.route.params.subscribe(data => {
      if (data.country) {
        if (data.country === COUNTRIES_LIST.PHILLIPPINS.value
          || data.country === COUNTRIES_LIST.THAILAND.value
          || data.country === COUNTRIES_LIST.SINGAPORE.value ) {
          localStorage.setItem('country', data.country);
        } else {
          localStorage.removeItem('country');
          this.router.navigate(['404']);
        }
      } else {
        localStorage.setItem('country', 'PH');
      }
    }));
    this.invalidFailure = this.store.select(PharmaSelector.pharmaError).subscribe(data => {
      if (data != null) {
        this.router.navigate(['/page-not-found']);
      }
    });
  }
  /**
   * redirect to pharma page.
   */
  redirectToPharmaPage(): void {
    const redirectionUrl = localStorage.getItem('pharmaUrl');
    const redirectUrlCode = localStorage.getItem('pharmaCode');
    this.router.navigate([redirectionUrl], {
      queryParams: {
        code: redirectUrlCode
      }
    });
  }

  /**
   * used to unsubscribed the variables.
   */
  ngOnDestroy(): void {
    if (this.pharmaSubs) {
      this.pharmaSubs.unsubscribe();
    }
  }
}
