<div>
    <h5 class="fnt-size18px text-heading-grey mb-3">{{aeReportValidation[process]?.treatmentDetails?.formTitle | translate}}</h5>
    <form [formGroup]="treatmentDetailsForm">
      <div class="row">
        <div class="col-md-6" *ngIf="checkIfFieldExists('drugName')">
          <div class="form-group">
            <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
              <span>
                {{getFieldName('drugName') | translate}}
                <span class="text-danger" *ngIf="checkIfFieldIsMandatory('drugName')">*</span>
              </span>
            </label>
            <div class="position-relative">
              <input (keyup)="userInput('drugName', $event)" formControlName="drugName" type="text" class="form-control fnt-size14px"
                [placeholder]="getFieldPlaceholder('drugName') | translate" [maxlength]="100" />
            </div>
            <div *ngIf="form.drugName.touched && form.drugName.invalid && this.checkIfFieldIsMandatory('drugName')" class="text-danger fnt-size12px">
              {{getFieldName('drugName') | translate}} {{ "IS_REQUIRED" | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="checkIfFieldExists('dosingRegimen')">
          <div class="form-group">
            <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
              <span>
                {{getFieldName('dosingRegimen') | translate}}
                <span class="text-danger" *ngIf="checkIfFieldIsMandatory('dosingRegimen')">*</span>
              </span>
            </label>
            <div class="position-relative">
              <input (keyup)="userInput('dosingRegimen', $event)" formControlName="dosingRegimen" type="text" class="form-control fnt-size14px"
                [placeholder]="getFieldPlaceholder('dosingRegimen') | translate" [maxlength]="100" />
            </div>
            <div *ngIf="form.dosingRegimen.touched && form.dosingRegimen.invalid && checkIfFieldIsMandatory('dosingRegimen')" class="text-danger fnt-size12px">
              {{getFieldName('dosingRegimen') | translate}} {{ "IS_REQUIRED" | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="checkIfFieldExists('caseType')">
          <div class="form-group">
            <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
              <span>
                {{getFieldName('caseType') | translate}}
                <span class="text-danger" *ngIf="checkIfFieldIsMandatory('caseType')">*</span>
              </span>
            </label>
            <div class="position-relative">
              <p-dropdown class="treatment_dropdown w-100 pr-2 " [options]="caseTypeOptions" optionLabel="name" [(ngModel)]="selectedCase"
                [ngModelOptions]="{standalone: true}" (onChange)="setDataToForm(($event).value, 'caseType')"
                [placeholder]="getFieldPlaceholder('caseType') | translate"
                [autoDisplayFirst]="false" panelStyleClass="bg-white border p-2 fnt-size14px"
                styleClass="form-control fnt-size14px"
                [disabled]="disabled">
            </p-dropdown>
            </div>
            <div *ngIf="form.caseType.touched && form.caseType.invalid && checkIfFieldIsMandatory('caseType')" class="text-danger fnt-size12px">
              {{getFieldName('caseType') | translate}} {{ "IS_REQUIRED" | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="checkIfFieldExists('dateReceivedInfo')">
          <div class="form-group mb-2 position-relative">
            <label class="fnt-size14px">
              {{getFieldName('dateReceivedInfo') | translate}}
              <span class="text-danger" *ngIf="checkIfFieldIsMandatory('dateReceivedInfo')">*</span>
            </label>
            <div class="calendar-field w-100">
              <p-calendar [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [touchUI]="false"
                yearRange="1922:2030" [inline]="false" panelStyleClass="bg-white border" [maxDate]="currentDate"
                [styleClass]="form.dateReceivedInfo.touched && form.dateReceivedInfo.invalid ? 'pcomponent-invalid w-100' : 'w-100'"
                [inputStyleClass]="'form-control fnt-size12px fnt_Medium w-100 rounded'"
                formControlName="dateReceivedInfo" [placeholder]="getFieldPlaceholder('dateReceivedInfo') | translate" dateFormat="dd M yy">
              </p-calendar>
            </div>
            <div *ngIf="(form.dateReceivedInfo.touched ) && form.dateReceivedInfo.invalid && checkIfFieldIsMandatory('dateReceivedInfo')"
              class="text-danger fnt-size12px">
              {{getFieldName('dateReceivedInfo') | translate}} {{'IS_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="checkIfFieldExists('dateReportToPs')">
          <div class="form-group mb-2 position-relative">
            <label class="fnt-size14px">
              {{getFieldName('dateReportToPs') | translate}}
              <span class="text-danger" *ngIf="checkIfFieldIsMandatory('dateReportToPs')">*</span>
            </label>
            <div class="calendar-field w-100">
              <p-calendar [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [touchUI]="false"
                yearRange="1922:2030" [inline]="false" panelStyleClass="bg-white border" [maxDate]="currentDate"
                [styleClass]="form.dateReportToPs.touched && form.dateReportToPs.invalid ? 'pcomponent-invalid w-100' : 'w-100'"
                [inputStyleClass]="'form-control fnt-size12px fnt_Medium w-100 rounded'"
                formControlName="dateReportToPs" [placeholder]="getFieldPlaceholder('dateReportToPs') | translate" dateFormat="dd M yy">
              </p-calendar>
            </div>
            <div *ngIf="(form.dateReportToPs.touched ) && form.dateReportToPs.invalid && checkIfFieldIsMandatory('dateReportToPs')"
              class="text-danger fnt-size12px">
              {{getFieldName('dateReportToPs') | translate}} {{'IS_REQUIRED' | translate}}
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="checkIfFieldExists('aeInteractionId')">
          <div class="form-group">
            <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
              <span>
                {{getFieldName('aeInteractionId') | translate}}
                <span class="text-danger" *ngIf="checkIfFieldIsMandatory('aeInteractionId')">*</span>
              </span>
            </label>
            <div class="position-relative">
              <input (keyup)="userInput('aeInteractionId', $event)" formControlName="aeInteractionId" type="text" class="form-control fnt-size14px"
                [placeholder]="getFieldPlaceholder('aeInteractionId') | translate" [maxlength]="100" />
            </div>
            <div *ngIf="form.aeInteractionId.touched && form.aeInteractionId.invalid && checkIfFieldIsMandatory('aeInteractionId')" class="text-danger fnt-size12px">
              {{getFieldName('aeInteractionId') | translate}} {{ "IS_REQUIRED" | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="checkIfFieldExists('safetyReportIntId')">
          <div class="form-group">
            <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
              <span>
                {{getFieldName('safetyReportIntId') | translate}}
                <span class="text-danger" *ngIf="checkIfFieldIsMandatory('safetyReportIntId')">*</span>
              </span>
            </label>
            <div class="position-relative">
              <input (keyup)="userInput('safetyReportIntId', $event)" formControlName="safetyReportIntId" type="text" class="form-control fnt-size14px"
                [placeholder]="getFieldPlaceholder('safetyReportIntId') | translate" [maxlength]="100" />
            </div>
            <div *ngIf="form.safetyReportIntId.touched && form.safetyReportIntId.invalid && checkIfFieldIsMandatory('safetyReportIntId')" class="text-danger fnt-size12px">
              {{getFieldName('safetyReportIntId') | translate}} {{ "IS_REQUIRED" | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-6" *ngIf="checkIfFieldExists('action')">
          <div class="form-group">
            <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
              <span>
                {{getFieldName('action') | translate}}
                <span class="text-danger" *ngIf="checkIfFieldIsMandatory('action')">*</span>
              </span>
            </label>
            <div class="position-relative">
              <p-dropdown class="treatment_dropdown w-100 pr-2 " [options]="actionOptions" optionLabel="name" [(ngModel)]="selectedAction"
                [ngModelOptions]="{standalone: true}" (onChange)="setDataToForm(($event).value, 'action')"
                [placeholder]="(getFieldPlaceholder('action') | translate)"
                [autoDisplayFirst]="false" panelStyleClass="bg-white border p-2 fnt-size14px"
                styleClass="form-control fnt-size14px"
                [disabled]="disabled">
            </p-dropdown>
            </div>
            <div *ngIf="form.caseType.touched && form.caseType.invalid && checkIfFieldIsMandatory('action')" class="text-danger fnt-size12px">
              {{getFieldName('action') | translate}} {{ "IS_REQUIRED" | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-12" *ngIf="checkIfFieldExists('remark')">
          <div class="form-group">
            <div class="form-group remark-search">
              <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
                <span>
                  {{getFieldName('remark') | translate}}
                  <span class="text-danger" *ngIf="checkIfFieldIsMandatory('remark')">*</span>
                </span>
              </label>
              <p-autoComplete class="w-100" (keyup)="userInput('remark', $event)" [dropdown]="true"
                [inputStyleClass]="'form-control fnt-size14px w-100'" [suggestions]="filterRemarksList" [multiple]="true"
                [styleClass]="form.remark.touched && form.remark.invalid ? 'pcomponent-invalid w-100 bg-e9ecef' : 'w-100 bg-e9ecef'"
                [placeholder]="getFieldPlaceholder('remark') | translate" [minLength]="1" (completeMethod)="filterRemarksOnInput($event)" [autoHighlight]="true"
                formControlName="remark" field="name" [completeOnFocus]="true">
                <ng-template let-remark pTemplate="item">
                  <div [innerHTML]="remark.name | highlight: toHighlight"></div>
                </ng-template>
              </p-autoComplete>
  
            <div *ngIf="(form.remark.touched) && form.remark.invalid && checkIfFieldIsMandatory('remark')"
              class="text-danger fnt-size12px">
              {{getFieldName('remark') | translate}} {{'IS_REQUIRED' | translate}}
            </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  