/**
 * Ngrx store.
 */
import { createSelector } from '@ngrx/store';
import * as fromRoot from '../../reducers/index';
import * as fromHybrid from '../../reducers/hybrid/hybrid.reducer';

export const hybrid = (state: fromRoot.State) => state.hybrid;
/**
 * Selector for hyrid forms api data.
 */
export const getHybridForms = createSelector(
  hybrid,
  (state: fromHybrid.State) => state.hybridData,
);

/**
 * Selector for programs data.
 */
export const getPrograms = createSelector(
  hybrid,
  (state: fromHybrid.State) => state.programList,
);

/**
 * Selector for hybrid errors.
 */
export const getHybridError = createSelector(
  hybrid,
  (state: fromHybrid.State) => state.error
);

/**
 * Selector for submitted form id.
 */
export const getSubmittedFormID = createSelector(
  hybrid,
  (state: fromHybrid.State) => state.formSubmittedId
);

/**
 * Selector for credentials.
 */
export const getCreds = createSelector(
  hybrid,
  (state: fromHybrid.State) => state.patientAuthResult
);

/**
 * Selector for consent token.
 */
export const getConsentToken = createSelector(
  hybrid,
  (state: fromHybrid.State) => state.consentToken
);

/**
 * Selector for caregiver data.
 */
export const getCareGiverData = createSelector(
  hybrid,
  ((state: fromHybrid.State) => state.careGiverData)
);

/**
 * Selector for isDeletedCaregiver data.
 */
export const getIsDeletedCaregiver = createSelector(
  hybrid,
  ((state: fromHybrid.State) => state.isCaregiverDeleted)
);

/**
 * selector for pharma detail
 */
export const getHybridPharmaDetail =  createSelector(
  hybrid,
  (state: fromHybrid.State) => state.pharmaData
);
