import { createAction, props } from '@ngrx/store';


export const loadOTP = createAction(
  'Attendant| Login Request OTP',
  props<{phone_number: string, countryCode: string, user_type: string}>()
);
export const setOtp = createAction(
  'Attendant|Set otp',
  props<{otpRequestToken: any}>()
);
export const setErrors = createAction(
  'Attendant|Catch error of Attendant api',
  props<{ error: Error }>()
);
export const resetErrors = createAction(
  'Attendant|Reset error of Attendant api'
);
export const verifyOTP = createAction(
  'Attendant| Verify OTP',
  props<{ otp: string , otpRequestToken:any}>()
);
export const destroyToken = createAction(
  'Attendant| destroyTokenOnBack',
);
export const setVerifyOTPData = createAction(
  'Attendant| Set Verify OTP',
  props<{ user_detail:any}>()
);
export const verifyAttendantCredRequest = createAction(
  '[Doctor] verify doctor credential request',
  props<{ otp: string, loginAuth: string, verificationSignature?: string, id?: number }>()
);

export const resendOTP = createAction(
  'Attendant| Resend OTP',
  props<{ otpRequestToken:any}>()
);


export const submitForm = createAction(
  'Attendant | Submit Form',
  props<{ first_name: string; last_name: string; email: string; pharma_id?:any; otpRequestToken:any }>()
);
export const submitFormSuccess = createAction(
  'Attendant | Submit Form Success',
  props<{ response: any }>()
);

export const submitFormFailure = createAction(
  'Attendant | Submit Form Failure',
  props<{ error: any }>()
);

export const addAttendantDCPM = createAction(
  'ATTENDANT|Submit form data',
  props<{ country_code:number,phone_number:string,first_name:string,last_name:string,email:string,pharma_id:number}>()
);

export const addAttendtSuccess = createAction(
  'ATTENDANT|Set submit attendant data',
  props<{response:any}>()
);
export const addAttendReset = createAction(
  'ATTENDANT|Reset submit attendant data',
);

export const resetVerifyOtp = createAction(
  'ATTENDANT|Reset verify attendant token',
)
export const loadAttendantList = createAction(
  'ATTENDANT|attendant data list',
  props<{ pharma_code: string, limit?: string,search?: string }>()
);

export const searchSuccess = createAction(
  'ATTENDANT | Search Data Success',
  props<{ attendantList: any }>()
);
export const listFailure = createAction(
  'Attendant | listFailure Failure',
  props<{ error: any }>()
);
export const attendantListReset = createAction(
  'ATTENDANT|Reset attendant data',
);