/**
 * Store imports.
 */
import { createAction, props } from '@ngrx/store';

/**
 * Load presigned URL.
 */
export const loadFilePresignedUrl = createAction(
  'FILE|API [File] Load presigned url',
  props<{
    endPoint: string, fileType: string, fileName: string, contentType: string,
    fileMethod: string, module?: string, country?: string, time: number
  }>()
);

/**
 * Set presigned URL.
 */
export const setFilePresignedUrl = createAction(
  'FILE|SET [File] set file presigned url',
  props<{ presignedUrl: string, presignedReqTime: number }>()
);

/**
 * This is used to reset file presigned url.
 */
export const resetFilePresignedUrl = createAction(
  'FILE|SET [File] reset presigned url',
  props<{ presignedUrl: string }>()
);

/**
 * This is used to set the signUp error messages.
 */
export const setErrors = createAction(
  'File Error |SET [File] Catch error of file api',
  props<{ error: Error }>()
);

/**
 * This is used to set the uploading time of file.
 */
export const setUploadingTime = createAction(
  'FILE|SET [File] set file uploading time',
  props<{ uploadReqTime: number }>()
);

/**
 * Upload File.
 */
export const fileUpload = createAction(
  'FILE|API [File] Upload file',
  props<{ endPoint: string, file: File, uploadReqTime: number }>()
);

/**
 * This is used to reset file presigned url.
 */
export const resetFilePresignedUrlWithIndex = createAction(
  'FILE|SET [File] reset presigned url with index'
);

/**
 * Load presigned URL.
 */
export const loadFilePresignedUrlWithIndex = createAction(
  'FILE|API [File] Load presigned url with Index',
  props<{
    endPoint: string, fileType: string, fileName: string, contentType: string,
    fileMethod: string, module?: string, country?: string, time: number, index: number
  }>()
);

/**
 * Set presigned URL.
 */
export const setFilePresignedUrlWithIndex = createAction(
  'FILE|SET [File] set file presigned url with index',
  props<{ presignedUrl: string, presignedReqTime: number, index: number }>()
);
export const requestOtp = createAction(
  '[OTP] Request OTP',
  props<{ countryCode: string; mobile: string }>()
);
