<div class="float-left d-block d-md-none">
  <img src="assets/images/docquity-image.png" alt="">
</div>
<span class="float-right">
  <dngx-popover class="medium-font-weight position-static mr-2" #op popOverCssClass="userProfilePopover"
    [headerItems]="logoutPopover1">
    <div>
      <span class="profile-pic mr-2">
        {{doctorName}}
      </span>
      <span>{{userProfile?.slice(0,12)}}</span>
      <span *ngIf="userProfile?.length > 12">...</span>
      <img src="assets/images/dropdown-icon.svg">
    </div>
  </dngx-popover>
  <ng-template #logoutPopover1>
    <ul class="profileItems text-left logoutPopOver">
      <li>
        <span class="profile-pic mr-2 fnt-size14px">
          {{doctorName}}
        </span>
        <span>{{doctorDetails?.personalDetails?.email}}</span>
      </li>
      <li class="fnt-size14px cursor-pointer" (click)="navigateToContactUs()">
        <svg class="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M20.7505 23.375H20.6018C3.40802 22.3862 0.966772 7.87875 0.625522 3.45125C0.598025 3.10701 0.638724 2.76072 0.745287 2.43223C0.85185 2.10375 1.02218 1.79952 1.24652 1.53697C1.47087 1.27443 1.74481 1.05873 2.05266 0.902234C2.3605 0.745742 2.6962 0.651532 3.04052 0.625001H7.86177C8.21226 0.624662 8.55477 0.729572 8.84495 0.926142C9.13513 1.12271 9.35959 1.40189 9.48927 1.7275L10.8193 5C10.9473 5.3181 10.9791 5.66682 10.9107 6.00283C10.8422 6.33884 10.6766 6.64734 10.4343 6.89L8.57052 8.77125C8.86165 10.4257 9.65393 11.9507 10.8402 13.1401C12.0264 14.3295 13.5494 15.1258 15.203 15.4212L17.1018 13.54C17.3481 13.3004 17.6595 13.1386 17.9971 13.0748C18.3348 13.011 18.6838 13.048 19.0005 13.1813L22.2993 14.5025C22.62 14.6363 22.8936 14.8625 23.0853 15.1523C23.2769 15.4422 23.378 15.7825 23.3755 16.13V20.75C23.3755 21.4462 23.099 22.1139 22.6067 22.6062C22.1144 23.0984 21.4467 23.375 20.7505 23.375ZM3.25052 2.375C3.01846 2.375 2.7959 2.46719 2.6318 2.63128C2.46771 2.79538 2.37552 3.01794 2.37552 3.25V3.32C2.77802 8.5 5.35927 20.75 20.698 21.625C20.813 21.6321 20.9282 21.6164 21.0371 21.5789C21.146 21.5414 21.2464 21.4827 21.3326 21.4063C21.4187 21.3299 21.489 21.2372 21.5392 21.1336C21.5895 21.03 21.6188 20.9175 21.6255 20.8025V16.13L18.3268 14.8087L15.8155 17.3025L15.3955 17.25C7.78302 16.2963 6.75052 8.68375 6.75052 8.605L6.69802 8.185L9.18302 5.67375L7.87052 2.375H3.25052Z"
            fill="#737475" />
        </svg>
        {{'CONTACT_US' | translate}}
      </li>
      <li class="logout  fnt-size14px" (click)="logout()">
        <img src="assets/images/logout.svg" alt="logout" class="mr-2" /> {{'LOGOUT'| translate}}
      </li>
    </ul>
  </ng-template>
</span>
