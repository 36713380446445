<div class="row no-gutters align-items-center mt-5 pt-5 create-password">
    <div class="col-12 col-md-6 col-lg-4 mx-auto">
        <div class="header  pt-4 bg-white mb-2 rounded">
                <div class="text-center pb-4">
                <img [src]=this.logoSrc alt="Logo">
                </div>
        </div>
        <div class="body p-4 bg-white rounded mx-auto">
            <div class="mb-3 text-center">
                <img src={{successLogoSrc}} alt='success icon'>
            </div>
            <p class="mb-3 text-center fnt_Medium fnt-size20px text-abbey-grey">
                {{'PASSWORD_CREATED_SUCCESSFULLY'| translate}}</p>
            <p class="mb-3 text-center fnt-size15px text-abbey-grey">
                {{'GO_TO_LOGIN_TEXT'| translate}}</p>
            <div class="text-center">
                <button class="btn btn-success px-4" (click)="goToLogin()">
                {{'GO_TO_LOGIN'| translate}}</button>
            </div>
        </div>
    </div>
</div>
  