<div *ngIf="this.pharmaInfo" class="page-content pharmaView">
  <div>
    <div class="header bg-white sticky-top">
      <div class="container headerBar h-100">
        <nav
          class="navbar navbar-expand-md  navbar-light bg-white position-sticky navbar-head justify-content-start h-100">
          <a class="navbar-brand">
            <img src="assets/images/docquity-only.svg" class="mt-1  px-1 d-none d-md-block" alt="Logo">
            <img src="assets/images/docquity-image.png" class="mt-1 px-1 d-md-none d-block" alt="Logo">
          </a>
          <a class="navbar-brand">
            <img src="assets/images/line-image.png" class="px-1" alt="Logo">
          </a>
          <a *ngIf="pharmaInfo" class="navbar-brand">
            <img src={{pharmaInfo.pharmaLogo}} class="mt-1 pharma-logo px-1" alt="Logo">
          </a>
        </nav>
      </div>
    </div>
    <div class="contentBar d-flex flex-column pt-5">
      <div class="container mb-5">
        <div class="row">
          <div class="d-md-flex justify-content-between col-12">
            <div class="my-md-0 my-3">
              <div class="medium-font-weight color-grey-650 text-center text-md-left pharma-name font-lexend">
                {{ this.pharmaInfo.pharmaName }}
              </div>
              <div class="medium-font-weight color-red-50 text-center text-md-left pharma-store-name font-lexend">
                {{"PHARMACY_STORES" | translate}}
              </div>
            </div>
            <div class="abcd mx-auto mx-md-0 align-self-end">
              <p-dropdown (onChange)="onProgramChange()" [options]="programs" [(ngModel)]="selectedProgram"
                optionLabel="name"></p-dropdown>
            </div>
          </div>
        </div>
        <div class="row  mt-md-5 mt-2 no-gutters">
          <div *ngFor="let store of this.stores" class="col-md-4">
            <div class="p-2 h-100">
              <div class=" p-3 card h-100">
                <div class="text-one font-lexend text-1D1919">
                  {{ store.name }}
                </div>
                <div class="address-text text-444445 fnt-size14px fnt_Medium mt-2 addressWhiteSpace" [innerHTML]="store.address">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-2 bg-white mt-auto">
        <div class="py-2 bg-black text-center rounded-16">
          <div class="p-4 mt-2 mb-2">
            <img src="assets/images/docquity-logo.png" alt="docquity-logo">
            <p class="text-white mt-3">{{"RIGHTS_RESERVED" | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
