/**
 * Angular imports.
 */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TH_SERVIER_PROCESS } from 'projects/nga-PAP/src/app/constant/app.constant';
import { IdNamePair } from 'projects/nga-PAP/src/app/models/sign-up-chatbot.model';

/**
 * Used to show the form for pediatric report.
 */
@Component({
  selector: 'pap-adverse-event-info-form',
  templateUrl: './adverse-event-info-form.component.html',
  styleUrls: ['./adverse-event-info-form.component.scss']
})
export class AdverseEventInfoFormComponent implements OnInit, OnChanges {
  @Input() functionality = '';
  @Input() formData: any;
  @Input() process: string;

  /**
   * Form pediatric report.
   */
  public adverseEventInfoForm: FormGroup;
  private disabled: boolean;

  /**
   * setter for the form controls.
   */
  get form(): { [key: string]: AbstractControl; } { return this.adverseEventInfoForm.controls; }
  get formValidity(): boolean { return this.adverseEventInfoForm.valid; }
  get formValues(): { adverseEventInfoForm: any; } { return { adverseEventInfoForm: this.adverseEventInfoForm.value }; }

  /**
   * Outcome list.
   */
  outcomeList = [
    { id: 1, name: 'REC: Completely recovered' },
    { id: 2, name: 'SEQ: Recovered with sequelae' },
    { id: 3, name: 'IMP: Condition improving' },
    { id: 4, name: 'UNC: Condition unchanged' },
    { id: 5, name: 'DET: Condition deteriorating' },
    { id: 6, name: 'FAT: Fatal /Death' },
    { id: 7, name: 'UNK: Outcome unknown' }
  ];
  servierOutcomeList = [
    { id: 1, name: 'Recovered' },
    { id: 2, name: 'Recovered with sequelae' },
    { id: 3, name: 'Recovering' },
    { id: 4, name: 'Not recovered' },
    { id: 5, name: 'Fatal' },
    { id: 6, name: 'Unknown' },
  ];
  selectedOutcome = '';
  /**
   * event casuality list
   */
  eventCausalityList = [
    { id: 1, name: 'S:Suspected/ Related' },
    { id: 2, name: 'NS: Not Suspected/ Not Related' },
    { id: 3, name: 'NA: Not Assessable' }
  ];
  /**
   * seriousness list
   */
  seriousnessCriteriaListNonServier = [
    { id: 1, name: 'D: Patient died' },
    { id: 2, name: 'LT: Life threatening' },
    { id: 3, name: 'HOSP: Required hospitalization or prolonged hospitalization' },
    { id: 4, name: 'DIS: Persistent or significant disability or incapacity' },
    { id: 5, name: 'MS: Medically significant' },
    { id: 6, name: 'CA: Congenital anomaly or birth defect' },
    { id: 7, name: 'NS: Non-Serious' }
  ];
  seriousnessCriteriaListServier = [
    { id: 1, name: 'Non-Serious' },
    { id: 2, name: 'Fatal' },
    { id: 3, name: 'Life-threatening' },
    { id: 4, name: 'Hospitalisation or prolongation of hospitalisation' },
    { id: 5, name: 'Persistent or significant disability or incapacity' },
    { id: 6, name: 'Congenital anomaly or birth defect' },
    { id: 7, name: 'Medically significant' }
  ];
  selectedSeriousCriteria = '';
  /**
   * casual relationship list
   */
  casualRelationShipList = [
    { id: 1, name: 'No' },
    { id: 2, name: 'Yes' },
    { id: 3, name: 'Not Applicable' }
  ];

  seriousnessCriteriaList: IdNamePair[] = [];

  /**
   * Necessary instances.
   */
  constructor(private fb: FormBuilder) { }

  /**
   * Calling initialize form.
   */
  ngOnInit(): void {
    this.disabled = this.functionality === 'view';
    this.initializeForm();
  }

  /**
   * to detect changes on incoming form values
   * @param changes changes on formData
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formData) {
      this.initializeForm();
    }
    if (changes?.process) {
      this.updateFormWrtCondition();
    }
  }
  /**
   * function to restrict only blankspaces entry.
   */
    userInput(fieldName: string, event: any): void {
      if (event.target.value) {
        const value = event.target.value;
        if (value[0] === ' ') {
          this.adverseEventInfoForm.get(fieldName).setValue(value.trimStart());
        }
      }
    }

  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.adverseEventInfoForm = this.fb.group({
      aeOverview: new FormControl(
        {value : this.formData?.aeOverview ? this.formData?.aeOverview : '', disabled: this.disabled},
        [Validators.required, Validators.maxLength(500)]),
      descriptionAdverseEvent: new FormControl(
        {value : this.formData?.descriptionAdverseEvent ? this.formData?.descriptionAdverseEvent : '',
         disabled: this.disabled},
        [Validators.required, Validators.maxLength(10000)]),
      outcome: new FormControl(
        {value : this.formData?.outcome ? this.formData?.outcome : '', disabled: this.disabled}),
      eventCausality: new FormControl(
        {value : this.formData?.eventCausality ? this.formData?.eventCausality : '', disabled: this.disabled}),
      seriousnessCriteria: new FormControl(
        {value : this.formData?.seriousnessCriteria ? this.formData?.seriousnessCriteria : '',
         disabled: this.disabled}),
      symptomOnsetDate: new FormControl(
        {value : this.formData?.symptomOnsetDate ? this.formData?.symptomOnsetDate : '', disabled: this.disabled}),
      symptomEndDate: new FormControl(
        {value : this.formData?.symptomEndDate ? this.formData?.symptomEndDate : '', disabled: this.disabled}),
      genDisease: new FormControl(
        {value : this.formData?.genDisease ? this.formData?.genDisease : '', disabled: this.disabled}),
      drgRelation: new FormControl(
        {value : this.formData?.drgRelation ? this.formData?.drgRelation : '', disabled: this.disabled}),
    });
    this.updateFormWrtCondition();
  }

  /**
   * to update the form wrt condition
   */
  private updateFormWrtCondition(): void {
    if (this.checkForServierProcess()) {
      this.adverseEventInfoForm.get('aeOverview').clearValidators();
      this.seriousnessCriteriaList = this.seriousnessCriteriaListServier;
      this.outcomeList = this.servierOutcomeList;
    } else {
      this.adverseEventInfoForm.get('aeOverview').setValidators([Validators.required, Validators.maxLength(500)]);
      this.seriousnessCriteriaList = this.seriousnessCriteriaListNonServier;
    }
    this.adverseEventInfoForm.get('aeOverview').updateValueAndValidity();
    /**
     * set selected values
     */
    if (this.formData?.outcome) {
      this.selectedOutcome = this.outcomeList.find(outcome => outcome.name === this.formData?.outcome)?.name;
    }
    if (this.formData?.seriousnessCriteria) {
      this.selectedSeriousCriteria = this.seriousnessCriteriaList.find(serious =>
        serious.name === this.formData?.seriousnessCriteria)?.name;
    }
  }

  /**
   * Check if process is for servier chatbot flow
   */
  checkForServierProcess(): boolean {
    return this.process === TH_SERVIER_PROCESS;
  }
}
