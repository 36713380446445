import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';

/**
 * Component used for modal.
 */
@Component({
  selector: 'dngx-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.css']
})
export class ModelComponent {

  /**
   * Contain modal title which is coming from parent component.
   */
  @Input() modalTitle: string;

  /**
   * Flag for hide/show modal.
   */
  @Input() displayModal = true;

  /**
   * Flag for hide/show modal on outside click.
   */
  @Input() dismissableMask = true;

  /**
   * Flag for maximize the modal.
   */
  @Input() maximizable = false;

  /**
   * Flag for allow the model draggable.
   */
  @Input() draggable = false;

  /**
   * Flag for allow the modal resizable.
   */
  @Input() resizable = false;

  /**
   * Flag for set the base z index of model.
   */
  @Input() baseZIndex = 10000;

  /**
   * Flag for header Visibility.
   */
  @Input() headerVisible = true;

  /**
   * Flag for header Visibility.
   */
  @Input() closable = true;

  /**
   * Used to show Modal Content.
   */
  @Input() modalContent: TemplateRef<any>;

  /**
   * Send hideModal event outside.
   */
  @Output() hideModal = new EventEmitter<boolean>();

  @Input() contentStyleClass = 'rounded-bottom px-0 pb-0 pt-3';

  @Input() styleClass = 'p-0 rounded custom-p-dialog';

  @Input() blockScroll = false;

  /**
   * Emit hideModal event outside.
   */
  onHide(event): void {
    this.hideModal.emit(false);
  }

}
