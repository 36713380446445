import { LOCAL_STORAGE_COUNTRY, COUNTRIES_LIST } from './../../../../constant/app.constant';
/**
 * model imports
 */
import { InputLog } from 'projects/nga-PAP/src/app/models/doctor.model';
/**
 * angular imports
 */
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
/**
 * constant imports
 */
import { LANGUAGES, PATTERN } from 'projects/nga-PAP/src/app/constant/app.constant';
/**
 * ngrx imports
 */
import { Store } from '@ngrx/store';
import * as DoctorAction from '../../../../store/actions/doctor/doctor.actions';
import * as DoctorSelector from '../../../../store/selectors/doctor/doctor.selectors';
/**
 * rxjs imports
 */
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';


/**
 * component for verify otp
 */
@Component({
  selector: 'pap-otp-verify',
  templateUrl: './otp-verify.component.html',
  styleUrls: ['./otp-verify.component.scss']
})
export class OtpVerifyComponent implements OnInit, OnDestroy {
  /**
   * view otp1 child.
   */
  @ViewChild('otp1')
  otp1: ElementRef;
  /**
   * view otp2 child.
   */
  @ViewChild('otp2')
  otp2: ElementRef;
  /**
   * view otp3 child.
   */
  @ViewChild('otp3')
  otp3: ElementRef;
  /**
   * view otp4 child.
   */
  @ViewChild('otp4')
  otp4: ElementRef;
  /**
   * get pharma code.
   */
  public pharmaCode: string;
  /**
   * Get support language.
   */
  public languages = LANGUAGES;
  /**
   * Contain login formGroup.
   */
  public otpForm: FormGroup;
  /**
   * authorization token.
   */
  public authToken: string;
  /**
   * hcaptcha Token.
   */
  public hcaptchaToken: string;
  /**
   * var for masking.
   */
  public maskedLoginInput: string;
  /**
   * Used for resend otp view counter value.
   */
  public counterValue = 0;
  /**
   * Used for resend otp counter.
   */
  public counter = 0;
  /**
   * Used for resend otp counter interval.
   */
  public interval = 0;
  /**
   * login data subscription data.
   */
  public apiErrorMsgSub: Subscription;
  /**
   * public query params sub
   */
  public pharmaQueryParam: Subscription;
  /**
   * This is used to store the api error message.
   */
  public apiErrorMsg: string;
  /**
   * var for storing login input.
   */
  public loginInput: string;
  /**
   * verify otp count.
   */
  public verifyOtpCnt = 0;

  private routeParamsSub: Subscription;
  /**
   * get country from localstorage
   */
  private country: string;
  /**
   * Submit confirm otp api.
   */
  enterConfirmOtp(): void {
    this.verifyOtpCnt++;
    const otp = this.otpForm.get('otp1').value + this.otpForm.get('otp2').value
      + this.otpForm.get('otp3').value + this.otpForm.get('otp4').value;
    this.store.dispatch(DoctorAction.verifyOTP({ otp, otpRequestToken: this.authToken, pharmaCode: this.pharmaCode }));
    this.store.dispatch(DoctorAction.resetErrors());
    this.apiErrorMsg = '';
  }
  /**
   *  Used to auto focus.
   */
  keytab(event, index: number): void {
    const numbers = PATTERN.WHOLE_NUMBER_REGEX;
    if (event.code === 'Backspace' || event.key.match(numbers)) {
      if (index === 1) {
        event.code === 'Backspace' ? this.otp1.nativeElement.focus() : this.otp2.nativeElement.focus();
      } else if (index === 2) {
        event.code === 'Backspace' ? this.otp1.nativeElement.focus() : this.otp3.nativeElement.focus();
      } else if (index === 3) {
        event.code === 'Backspace' ? this.otp2.nativeElement.focus() : this.otp4.nativeElement.focus();
      } else {
        event.code === 'Backspace' ? this.otp3.nativeElement.focus() : this.otp4.nativeElement.focus();
      }
    }
  }
  /**
   * Used to redirect enter back to doctor login page.
   */
  backToDoctorLoginPage(): void {
    this.router.navigate(['/doctor/login/' + this.country], {
      queryParams: {
        pharmaCode: this.pharmaCode
      }
    });
  }
  /**
   *  Used to redirect enter back to doctor login page
   */
  backEditDoctorLoginPage(): void {
    let routeLog: InputLog;
    if (this.loginInput.match('@')) {
      routeLog = {
        isRouted: true,
        inputType: 'email',
        inputValue: this.loginInput,
      };
    } else {
      routeLog = {
        isRouted: true,
        inputType: 'phone',
        inputValue: this.loginInput,
      };
    }
    this.router.navigate(['/doctor/login/' + this.country, {
      queryParams: { pharmaCode: this.pharmaCode },
      inputLog: JSON.stringify(routeLog)
    }
    ]);
  }
  /**
   * Navigate to resend otp.
   */
  resendOtp(): void {
    this.store.dispatch(DoctorAction.resendOTP({ otpRequestToken: this.authToken, hCaptchaToken: this.hcaptchaToken }));
    this.store.dispatch(DoctorAction.resetErrors());
    this.apiErrorMsgSub = null;
    this.counterValue = 20;
    this.counter = 20;
    this.interval = 1000;
  }
  /**
   * Receive counter value.
   */
  receiveCounterValue(event): void {
    this.counterValue = event;
    if (this.counterValue === 0) {
      this.counter = 0;
    }
  }
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private store: Store,
    private route: ActivatedRoute
  ) {
    /**
     * block browser back button
     */
    history.pushState(null, '', location.href);
    window.onpopstate = (): void => {
      history.go(1);
    };
  }

  ngOnInit(): void {
    this.routeParamsSub  = this.route.queryParams.subscribe(params => {
        if (params.pharmaCode) {
          this.pharmaCode = params.pharmaCode;
        }
      });
    this.country = localStorage.getItem(LOCAL_STORAGE_COUNTRY.COUNTRY);
    if (!this.country) {
      this.country = COUNTRIES_LIST.PHILLIPPINS.value;
      localStorage.setItem(LOCAL_STORAGE_COUNTRY.COUNTRY, COUNTRIES_LIST.PHILLIPPINS.value);
    }
    this.apiErrorMsgSub = this.store.select(DoctorSelector.getError).subscribe(data => {
      if (data != null) {
        this.apiErrorMsg = data.messages[0];
      }
    });
    this.store.select(DoctorSelector.getRequestOTPToken).subscribe(authToken => {
      if (authToken != null) {
        this.store.select(DoctorSelector.getHcaptchaToken).subscribe(hcaptchaToken => {
          if (hcaptchaToken != null) {
            this.hcaptchaToken = hcaptchaToken;
          }
        });
        this.store.select(DoctorSelector.getLoginInput).subscribe(loginInput => {
          if (loginInput != null) {
            this.loginInput = loginInput;
            this.authToken = authToken;
            if (loginInput.includes('@')) {
              this.maskedLoginInput = loginInput.substring(0, loginInput.indexOf('@') - 4).replace(/\w/g, 'X') +
                loginInput.substring(loginInput.indexOf('@') - 4, loginInput.length);
            } else {
              this.maskedLoginInput = loginInput.substring(0, loginInput.length - 4).replace(/\w/g, 'X') +
                loginInput.substring(loginInput.length - 4, loginInput.length);
            }
          }
        });
      }
      else {
        this.router.navigate(['/doctor/login/' + this.country], {
          queryParams: {
            pharmaCode: this.pharmaCode
          }
        });
      }
    });
    this.otpForm = this.formBuilder.group({
      otp1: ['', [Validators.required]],
      otp2: ['', [Validators.required]],
      otp3: ['', [Validators.required]],
      otp4: ['', [Validators.required]]
    });
    /**
     * Resend otp timer.
     */
    this.counterValue = 20;
    this.counter = 20;
    this.interval = 1000;
  }

  ngOnDestroy(): void {
    this.store.dispatch(DoctorAction.resetHcaptchaToken({}));
    this.store.dispatch(DoctorAction.resetHcaptchaToken({}));
    if (this.pharmaQueryParam) {
      this.pharmaQueryParam.unsubscribe();
    }
  }
}
