import { ChatbotInfo } from './../../../models/sign-up-chatbot.model';
/**
 * Model import.
 */
import { Signin } from 'projects/nga-PAP/src/app/models/signin.model';
import { Message, HybridData, ProgramData } from '../../../models/sign-up-chatbot.model';
import { CareGiverData, Error, PharmaDetail } from '../../../models/responses.model';

/**
 * Store import.
 */
import { createReducer, on } from '@ngrx/store';
import * as HybridAction from '../../actions/hybrid/hybrid.actions';

export const hybridFeatureKey = 'hybrid';

export interface State {
  hybridData: HybridData;
  formSubmittedId: number;
  programList: ProgramData[];
  patientAuthResult: Signin;
  consentToken: ChatbotInfo;
  careGiverData: CareGiverData;
  isCaregiverDeleted: boolean;
  error: Error;
  pharmaData: PharmaDetail;
}

export const initialState: State = {
  hybridData: null,
  formSubmittedId: null,
  programList: null,
  patientAuthResult: null,
  consentToken: null,
  careGiverData: null,
  isCaregiverDeleted: null,
  error: null,
  pharmaData: null
};


export const reducer = createReducer(
  initialState,
  on(HybridAction.setHybridForms, (state, payload) => ({
    ...state,
    hybridData: payload.hybridData,
  })),
  on(HybridAction.setCreds, (state, payload) => ({
    ...state,
    patientAuthResult: payload.patientAuthResult,
  })),
  on(HybridAction.resetProgramList, (state, payload) => ({
    ...state,
    programList: initialState.programList,
  })),
  on(HybridAction.setProgramList, (state, payload) => ({
    ...state,
    programList: payload.programList,
  })),
  on(HybridAction.setFormSubmittedId, (state, payload) => ({
    ...state,
    formSubmittedId: payload.formSubmittedId
  })),
  on(HybridAction.setConsentToken, (state, payload) => ({
    ...state,
    consentToken: payload
  })),
  on(HybridAction.setCaregiver, (state, payload) => ({
    ...state,
    careGiverData: payload.caregiverData
  })),
  on(HybridAction.setDeletedCaregiver, (state, payload) => ({
    ...state,
    isCaregiverDeleted: payload.isCaregiverDeleted
  })),
  on(HybridAction.setErrors, (state, payload) => ({
    ...state,
    error: payload.error
  })),
  on(HybridAction.resetErrors, (state, payload) => ({
    ...state,
    error: initialState.error
  })),
  on(HybridAction.resetCreds, (state, payload) => ({
    ...state,
    patientAuthResult: initialState.patientAuthResult
  })),
  on(HybridAction.resetHybridForms, (state, payloaf) => ({
    ...state,
    hybridData: initialState.hybridData
  })),
  on(HybridAction.resetPatientConsent, (state, payload) => ({
    ...state,
    consentToken: initialState.consentToken
  })),
  on(HybridAction.resetCaregiver, (state, payload) => ({
    ...state,
    careGiverData: initialState.careGiverData
  })),
  on(HybridAction.resetDeletedCaregiver, (state, payload) => ({
    ...state,
    isCaregiverDeleted: initialState.isCaregiverDeleted
  })),
  on(HybridAction.setHybridPharmas , (state, payload) => ({
    ...state,
    pharmaData: payload.data
  })),
  on(HybridAction.reSetHybridPharmas , (state, payload) => ({
    ...state,
    pharmaData: initialState.pharmaData
  })),
);

