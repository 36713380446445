/**
 * Angular imports.
 */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

/**
 * This component is used to display the page not found message.
 */
@Component({
  selector: 'pap-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {

  /**
   * Required instances.
   */
  constructor(private router: Router) { }

  /**
   * Navigate user to home.
   */
  navigateToHome(): void {
    this.router.navigateByUrl('/');
  }

}
