/**
 * Ngrx store.
 */
import { createSelector } from '@ngrx/store';
import * as fromRoot from '../../reducers/index';
import * as fromSignup from '../../reducers/signup/signup.reducer';

export const signup = (state: fromRoot.State) => state.signup;
/**
 * Selector for signup chatbot api data.
 */
export const getSignupConsent = createSelector(
  signup,
  (state: fromSignup.State) => state.signupConsent
);

/**
 * Selector for signup chatbot consent updated form.
 */
export const updatedSignupConsent = createSelector(
  signup,
  (state: fromSignup.State) => state.updateConsent
);

/**
 * Selector for country list.
 */
export const getCountryList = createSelector(
  signup,
  (state: fromSignup.State) => state.countryList
);

/**
 * Selector for hospital list.
 */
export const getHospitalList = createSelector(
  signup,
  (state: fromSignup.State) => state.hospitalList
);

/**
 * Selector for hospital users list.
 */
export const getUsersList = createSelector(
  signup,
  (state: fromSignup.State) => state.usersList
);

/**
 * Selector for regions list.
 */
export const getRegionsList = createSelector(
  signup,
  (state: fromSignup.State) => state.regionsList
);

/**
 * Selector for cities list.
 */
export const getCitiesList = createSelector(
  signup,
  (state: fromSignup.State) => state.citiesList
);

/**
 * Selector for submitted form id.
 */
export const getSubmittedFormID = createSelector(
  signup,
  (state: fromSignup.State) => state.formSubmittedId
);

/**
 * Get file upload url.
 */
export const getFileUploadUrl = createSelector(
  signup,
  (state: fromSignup.State) => state.fileUploadUrl
);

/**
 * Get file url.
 */
export const getFileUploadStatus = createSelector(
  signup,
  (state: fromSignup.State) => state.fileUploadStatus
);

/**
 * Get file presigned url.
 */
export const getFilePresignedUrl = createSelector(
  signup,
  (state: fromSignup.State) => state.presignedUrl
);

/**
 * Selector for chatbot errors.
 */
export const getChatbotError = createSelector(
  signup,
  (state: fromSignup.State) => state.error
);


/**
 * Selector for fetch resend Otp or not.
 */
export const getResendOtp = createSelector(
  signup,
  (state: fromSignup.State) => state.resendOtp
);
