import { Component, OnInit } from '@angular/core';
import { LOCAL_STORE } from '../../../constant/app.constant';
import { PharmaUserData } from '../../../models/responses.model';
import { PharmaNameService } from '../../../services/pharma/pharma-name.service';
import { Store } from '@ngrx/store';
import { HelperService } from '../../../helper.service';
import { Router } from '@angular/router';


@Component({
  selector: 'pap-attendant-dashboard',
  templateUrl: './attendant-dashboard.component.html',
  styleUrls: ['./attendant-dashboard.component.scss']
})
export class AttendantDashboardComponent implements OnInit {
  public programPageFlag: boolean;
  public selectedPharma: PharmaUserData;
  public isUrlActive = false;
  public userEmail: string;
  public userDetail: any;

  constructor(private pharmaNameChange: PharmaNameService, private store: Store ,public helperService: HelperService,private router: Router,
  ) { 
  // if(event.url == '/dashboard'){
  //   this.isUrlActive = true;
  // }else{
  //   this.isUrlActive=false;
  // }

  }
   /**
   * for pharma name change
   */
   onPharmaNameChange(event: any): void {
    localStorage.setItem(LOCAL_STORE.PHARMA_CODE, this.selectedPharma.code);
    localStorage.setItem(LOCAL_STORE.PHARMA_ID, this.selectedPharma.id.toString());
    localStorage.setItem('pharma', JSON.stringify(this.selectedPharma));
    this.pharmaNameChange.setData(localStorage.getItem(LOCAL_STORE.PHARMA_CODE));
    if (this.pharmaNameChange.changePharma.value === true) {
      window.location.reload();
    }
    this.pharmaNameChange.setchangePharmaForListing(true);

  }
  logout(): void {
    // this.store.dispatch(LoginAction.resetLoginStore());
    // localStorage.removeItem(LOCAL_STORE.PHARMA_CODE);
    // localStorage.removeItem(LOCAL_STORE.PHARMA_ID);
    this.router.navigate(['/attendant']);
  }
  changeRoute(){
    this.router.navigate(['/attendant/addPatient']);
  }

  ngOnInit(): void {
    this.userDetail = this.helperService.userDetail;
    console.log("deta",this.userDetail)
  }

}
