<div class="row no-gutters h-100 doctorRegistration" *ngIf="this.userId && this.doctorProfile">
  <div class="col-md-2 d-none d-md-block">
    <div class=" bg-white h-100 border-right">
      <div class="text-center p-3">
        <img src="assets/images/logo.svg" alt="Logo">
      </div>
      <nav class="vertical-nav bg-white pl-3 mt-3 pr-1" id="sidebar">
        <ul class="nav flex-column bg-white mb-0 leftNav h-100">
          <li class="nav-item mb-1">
            <a class="cursor-pointer nav-link text-dark bg-light fnt-size15px d-flex align-items-center w-100 rounded"
              (click)="navigateToDoctorHome()">
              <img src="assets/images/profile-logo.svg" alt="profile">
              <span>{{ "PROFILE" | translate }}</span>
            </a>
          </li>
          <li class="nav-item mb-1">
            <a class="nav-link text-dark bg-light fnt-size15px d-flex align-items-center w-100 rounded"
              (click)="navigateToAEReporting()">
              <img src="assets/images/profile-logo.svg" alt="profile">
              <span>{{ "REPORT_ADVERSE_EVENT" | translate }}</span>
            </a>
          </li>
          <li class="nav-item active">
            <a class="nav-link text-dark bg-light fnt-size15px d-flex align-items-center w-100 rounded">
              <img src="assets/images/resource-active.svg" alt="profile">
              <span>{{ "RESOURCES" | translate }}</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div class="col-md-10">
    <div class="bg-white p-3 border-bottom  text-right sticky-top header-view">
      <pap-doctor-header [doctorDetails]="element" [userProfile]="doctorProfile"></pap-doctor-header>
    </div>

    <div class="container" *ngIf="resourcesData?.length">
      <h4 class="fnt-size22px font-weight-bold mt-2">
        {{'RESOURCES' | translate}}
      </h4>
      <div class="resources-container">
        <div class="hcp-resources-card" *ngFor="let resource of resourcesData"
          (click)="onResourceSelect(resource, resources[resource?.thumbnailName]?.type)">
          <img class="card-banner" onerror="this.src = 'assets/images/default-banner.svg'"
            [src]="resources[resource?.thumbnailName] ? resources[resource?.thumbnailName]?.source : 'assets/images/default-banner.svg'"
            alt="thumbnailName">
          <img *ngIf="resources[resource?.thumbnailName]?.type === 'video'" class="video-play"
            src="assets/images/video-play.svg" alt="video">
          <p class="color-grey-650 fnt-size18px card-heading">
            {{resource.title}}
          </p>
        </div>
      </div>
    </div>
  </div>

  <p-dialog *ngIf="selectedResource?.url" [header]="'PREVIEW' | translate" [(visible)]="selectedResource"
    [showHeader]="true" [modal]="true" [baseZIndex]="'1021'" [appendTo]="'body'" [blockScroll]="true"
    [closeOnEscape]="false" styleClass="successful-edit mx-3" contentStyleClass="rounded">
    <div>
      <div *ngIf="selectedResource.type === 'image'">
        <img class="w-100 h-100" style="object-fit: contain;height: 75vh;" [src]="selectedResource.url" alt="image"
          onerror="this.src = 'assets/images/default-banner.svg'">
      </div>
      <div *ngIf="selectedResource.type === 'pdf'">
        <div id="outerContainer" *ngIf="!isPdfError">
          <div class="pdf-container">
            <pdf-viewer [src]="selectedResource.url" [rotation]="0" [original-size]="false" [show-all]="true"
              [fit-to-page]="false" [zoom]="1" [zoom-scale]="'page-width'" [stick-to-page]="false" [render-text]="true"
              [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false" (error)="onPdfError($event)"
              style="display: block;width: 100%; height: 75vh;"></pdf-viewer>
          </div>
        </div>
        <p class="text-danger" *ngIf="isPdfError">
          {{'Resources is crupted'}}
        </p>
      </div>
      <div *ngIf="selectedResource.type === 'video'">
        <vg-player>
          <video [vgMedia]="media" #media id="singleVideo" preload="auto" controls
            style="display: block;width: 100%; height: 75vh;">
            <source [src]="selectedResource.url" type="video/mp4">
          </video>
        </vg-player>
      </div>
    </div>
  </p-dialog>

  <div class="modalDisplay">
    <p-dialog [(visible)]="isVerified" [modal]="true" [baseZIndex]="10000"
      [draggable]="false" [resizable]="false" [closeOnEscape]="false" [closable]="false">
      <ng-template pTemplate="header">
        <img src="assets/images/success.svg" class="mx-auto" alt="success">
      </ng-template>
      <div class="px-5">
        <p class="my-2 text-center font-weight-bold">{{"PROFILE_VERIFICATION_IN_PROGRESS" | translate}}</p>
        <p class="text-center p-2 text-abbey-grey">{{"PROFILE_CREAT_MSG" | translate}}</p>
        <div class="text-center mb-3 mt-3">
          <button class="btn btn-outline-success fnt-size14px px-5 py-2" (click)="navigateToDoctorHome()">
            Okay
          </button>
        </div>
      </div>
    </p-dialog>
  </div>
