/**
 * Angular imports.
 */
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

/**
 * Constant and modals.
 */
import { PARAMS_KEY, PATTERN } from 'projects/nga-PAP/src/app/constant/app.constant';
import { Country } from 'projects/nga-PAP/src/app/models/sign-up-chatbot.model';

/**
 * Ngrx store imports.
 */
import { Store } from '@ngrx/store';
import * as DoctorAction from '../../../../store/actions/doctor/doctor.actions';
import * as DoctorSelector from '../../../../store/selectors/doctor/doctor.selectors';
import * as LoginSelector from '../../../../store/selectors/login/login.selectors';

import { Subscription } from 'rxjs';

/**
 * Jwt import.
 */
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'pap-search-doctor',
  templateUrl: './search-doctor.component.html',
  styleUrls: ['./search-doctor.component.scss']
})
export class SearchDoctorComponent implements OnInit {


  public findDoctorForm: FormGroup;
  error: string;
  notFound: boolean;
  userRole: string;
  get form(): { [key: string]: AbstractControl; } { return this.findDoctorForm.controls; }

  /**
   * var for default country.
   */
  public defaultCountry: Country;

  private subscriptions: Subscription = new Subscription();

  /**
   * Necessary instances.
   */
  constructor(private fb: FormBuilder, private store: Store, private route: ActivatedRoute, private router: Router) { }

  /**
   *
   */
  ngOnInit(): void {
    this.getUserRole();
    this.getError();
    this.getCountryInfo();
    this.initializeForm();
  }

  getUserRole(): void {
    this.subscriptions.add(this.store.select(LoginSelector.getUserCredential).subscribe(data => {
      if (data) {
        const idToken = data.accessToken;
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(idToken);
        this.userRole = decodedToken.role;
      }
    }));
  }

  /**
   *
   */
  getError(): void {
    this.subscriptions.add(this.store.select(DoctorSelector.getDoctorFindError)
      .subscribe(data => {
        if (data) {
          console.log('error::::::', data);
          this.notFound = true;
        } else {
          console.log('Verify-otp 6');
          this.resetError();
        }
      }));
  }

  /**
   *
   */
  getCountryInfo(): void {
    switch (localStorage.getItem('country')) {
      case 'TH':
        this.defaultCountry = {
          code: localStorage.getItem('country'),
          countryCode: 66,
          id: 4,
          logo: 'assets/images/thailand-signup-icon.png\n',
          name: 'Thailand'
        };
        break;
      case 'PH':
        this.defaultCountry = {
          code: localStorage.getItem('country'),
          countryCode: 63,
          id: 3,
          logo: 'assets/images/philippines-signup-icon.png\n',
          name: 'philippines'
        };
        break;
      case 'SG':
        this.defaultCountry = {
          code: localStorage.getItem('country'),
          countryCode: 65,
          id: 5,
          logo: 'assets/images/singapore-signup-icon.png\n',
          name: 'singapore'
        };
        break;
      default:
        break;
    }
  }

  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.findDoctorForm = this.fb.group({
      countryCode: [this.defaultCountry.countryCode],
      phoneNumber: ['', [this.minLengthValidator(7), this.maxLengthValidator(14)]],
      email: ['', [Validators.email, Validators.pattern(PATTERN.EMAIL)]]
    });
  }

  /**
   *
   */
  minLengthValidator(minLength: number): any {
    return (control) => {
      if (control.value) {
        const stringValue = control.value.toString();
        if (stringValue.length < minLength) {
          return { minLength: { requiredLength: minLength } };
        }
      }
      return null;
    };
  }

  /**
   *
   */
  maxLengthValidator(maxLength: number): any {
    return (control) => {
      if (control.value !== null) {
        const stringValue = control.value.toString();
        if (stringValue.length > maxLength) {
          return { maxLength: { requiredLength: maxLength } };
        }
      }
      return null;
    };
  }

  /**
   * function to restrict paste input in number
   */
  public onPaste(event: any, fieldName: string): void {
    if (this.findDoctorForm.get(fieldName).value?.length > 0) {
      let value = this.findDoctorForm.get(fieldName).value;
      if (value[0] === '0') {
        value = value.substring(1, value.length);
        this.findDoctorForm.get(fieldName).setValue(value);
      }
    }
  }
  /**
   * function to restrict zero at first index
   */
  public phoneValidator(event: any, fieldName: string): void {
    if (this.findDoctorForm.get(fieldName).value?.toString()?.length > 0) {
      const pattern = PATTERN.NO_ZERO;
      if (!pattern.test(event.target.value)) {
        this.findDoctorForm.get(fieldName).setValue(event.target.value.substring(1, event.target.value.length));
      }
    }
  }

  /**
   * used to reset the error.
   */
  public resetError(): void {
    this.store.dispatch(DoctorAction.resetErrors());
    this.error = '';
  }

  /**
   *
   */
  onFindDoctor(): void {
    console.log('this.findDoctorForm', this.findDoctorForm);
    if (this.findDoctorForm.invalid) {
      return;
    }
    this.notFound = false;
    if (this.form?.email?.value) {
      console.log('this.findDoctorForm value', this.findDoctorForm.value);
      this.findRequest(this.findDoctorForm.value, 'email');
    } else {
      this.findRequest(this.findDoctorForm.value, 'phoneNumber');
    }
  }

  /**
   *
   */
  findRequest(value: { countryCode: number; phoneNumber: number, email: string }, requestType): void {
    this.store.dispatch(DoctorAction.findDoctor({
      countryCode: value.countryCode,
      phoneNumber: value.phoneNumber,
      email: value.email,
      requestType,
      pharmaCode: localStorage.getItem('pharmaCode'),
      role: this.userRole,
    }));
  }
}

