<div class="row align-items-center">
  <div class="col-12">
    <div class="col-md-12 text-center pt-5 w-100">
      <img src="assets/images/404.svg" alt="error icon">
      <div>
        <p class="fnt-size26px fnt_Bold p-3">opps! sorry page not found</p>
        <button (click)="navigateToHome()" type="button" class="btn btn-primary btn-success text-white  fnt-size16px pl-4 pr-4" tabindex="0">
          Go To Home </button>
      </div>
    </div>
  </div>
</div>
