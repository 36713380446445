/**
 * Angular imports.
 */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TH_SERVIER_PROCESS } from 'projects/nga-PAP/src/app/constant/app.constant';

/**
 * Used to show the form for pediatric report.
 */
@Component({
  selector: 'pap-suspect-med-info-form',
  templateUrl: './suspect-med-info-form.component.html',
  styleUrls: ['./suspect-med-info-form.component.scss']
})
export class SuspectMedInfoFormComponent implements OnInit, OnChanges {
  @Input() functionality = '';
  @Input() formData = null;
  @Input() process: string;

  /**
   * Form pediatric report.
   */
  public suspectMedInfoForm: FormGroup;
  private disabled: boolean;
  /**
   * setter for the form controls.
   */
  get form(): { [key: string]: AbstractControl } { return this.suspectMedInfoForm.controls; }
  get formValidity(): boolean { return this.suspectMedInfoForm.valid; }
  get formValues(): { suspectMedInfoForm: any; } { return { suspectMedInfoForm: this.suspectMedInfoForm.value }; }

  /**
   * Drug strength list.
   */
  drugStrengthUnitList = [
    { id: 1, name: 'mg' },
    { id: 2, name: 'ug' },
    { id: 3, name: 'g' },
    { id: 4, name: 'mg/mL' },
    { id: 5, name: 'IU/mL' },
    { id: 6, name: '%' }
  ];
  administrationRouteList = [
    { id: 1, name: 'Oral' },
    { id: 2, name: 'Intravenous, IV' },
    { id: 3, name: 'Intramuscular, IM' },
    { id: 4, name: 'Subcutaneous, SC' },
    { id: 5, name: 'Intraocular' },
    { id: 6, name: 'NA' }
  ];

  /**
   * Necessary instances.
   */
  constructor(private fb: FormBuilder) { }

  /**
   * Calling initialize form.
   */
  ngOnInit(): void {
    this.disabled = this.functionality === 'view';
    this.initializeForm();
  }

  /**
   * to detect changes on incoming form values
   * @param changes changes on formData
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formData) {
      this.initializeForm();
    }
  }
  /**
   * function to restrict only blankspaces entry.
   */
  userInput(fieldName: string, event: any): void {
    if (event.target.value) {
      const value = event.target.value;
      if (value[0] === ' ') {
        this.suspectMedInfoForm.get(fieldName).setValue(value.trimStart());
      }
    }
  }
  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.suspectMedInfoForm = this.fb.group({
      drugName: new FormControl(
        {value : this.formData?.drugName ? this.formData?.drugName : '', disabled: this.disabled},
       [Validators.required, Validators.maxLength(100)]),
      drugStrength: new FormControl(
        {value : this.formData?.drugStrength ? this.formData?.drugStrength : '', disabled: this.disabled}),
      drugStrengthUnit: new FormControl(
        {value : this.formData?.drugStrengthUnit ? this.formData?.drugStrengthUnit : 'mg', disabled: this.disabled}),
      administrationRoute: new FormControl(
        {value : this.formData?.administrationRoute ? this.formData?.administrationRoute : '', disabled: this.disabled},
       Validators.maxLength(100)),
      dailyDosing: new FormControl(
        {value : this.formData?.dailyDosing ? this.formData?.dailyDosing : '', disabled: this.disabled},
       Validators.maxLength(50)),
      treatmentStartDate: new FormControl(
        {value : this.formData?.treatmentStartDate ? this.formData?.treatmentStartDate : '', disabled: this.disabled}),
      treatmentEndDate: new FormControl(
        {value : this.formData?.treatmentEndDate ? this.formData?.treatmentEndDate : '', disabled: this.disabled}),
      indicationForUse: new FormControl(
        {value : this.formData?.indicationForUse ? this.formData?.indicationForUse : '', disabled: this.disabled},
       Validators.maxLength(500)),
      batchNoAndExpiryDate: new FormControl(
        {value : this.formData?.batchNoAndExpiryDate ? this.formData?.batchNoAndExpiryDate : '',
         disabled: this.disabled},
       Validators.maxLength(500)),
      abateAfterStopping: new FormControl(
        {value : this.formData?.abateAfterStopping ? this.formData?.abateAfterStopping : '', disabled: this.disabled}),
      recurOnReadMinistration: new FormControl(
        {value : this.formData?.recurOnReadMinistration ? this.formData?.recurOnReadMinistration : '',
         disabled: this.disabled}),
      formulation: new FormControl(
        {value : this.formData?.formulation ? this.formData?.formulation : '', disabled: this.disabled},
       Validators.maxLength(50))
    });
    if (this.checkForServierProcess())
      {
        this.suspectMedInfoForm.removeControl('formulation');
      }
  }
  /**
   * Check if process is for servier chatbot flow
   */
  checkForServierProcess(): boolean {
    return this.process === TH_SERVIER_PROCESS;
  }

  /**
   * clear the selected value for given form field if
   * already selected
   * @param formField input form field
   * @param value input value of the option
   */
  clearSelection(formField: string, value: string): void {
    const currentValue = this.suspectMedInfoForm.get(formField).value;
    if (currentValue === value) {
      this.suspectMedInfoForm.get(formField).setValue('');
    }
  }
}
