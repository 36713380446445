/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

/**
 * Rxjs import.
 */
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

/**
 * Ngrx store imports.
 */
import { Store } from '@ngrx/store';
import * as LoginSelector from '../store/selectors/login/login.selectors';
import * as LoginAction from '../store/actions/login/login.actions';

/**
 * This guard is used to make the url accessable without login.
 */
@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(LoginSelector.getUserCredential)
      .pipe(
        take(1),
        map(data => {
          if (data || localStorage.getItem('login')) {
            this.router.navigate(['/approval']);
            return false;
          }
          return true;
        })
      );
  }

  /**
   * Created all required instances.
   */
  constructor(private router: Router, private store: Store) { }

}
