import { createSelector } from '@ngrx/store';
import * as fromRoot from '../../reducers/index';
import * as fromLogin from '../../reducers/login/login.reducer';

export const login = (state: fromRoot.State) => state.login;


/**
 * Selector for user credential.
 */
export const getUserCredential = createSelector(
  login,
  (state: fromLogin.State) => state.authenticationResult
);

/**
 * Selector for login error data.
 */
export const getLoginError = createSelector(
  login,
  (state: fromLogin.State) => state.error
);
