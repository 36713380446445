import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PharmaNameService {
  // behaviour for pharma data
  private data: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // observable for data change
  data$: Observable<any> = this.data.asObservable();

  // behaviour for pharma data
  public changePharma: BehaviorSubject<any> = new BehaviorSubject<boolean>(null);
  public changePharmaForPatientListing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public changePharmaForPatientListing$ = this.changePharmaForPatientListing.asObservable();
  // observable for data change
  changePharma$: Observable<boolean> = this.changePharma.asObservable();

  private program: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // observable for data change
  program$: Observable<any> = this.program.asObservable();

  // user id
  private userId;

  private programPageFlag;

  setData(newData: string): void {
    this.data.next(newData);
  }
  set userToEdit(userId) {
    this.userId = userId;
  }

  get userToEdit(): string {
    return this.userId;
  }
  /**
   * used to check pharma changed or not.
   */
  setChangePharma(newData: boolean): void {
    this.changePharma.next(newData);
  }
  setchangePharmaForListing(data: boolean): void {
    this.changePharmaForPatientListing.next(data);
  }

  // helper functions to raise flag whether its a program page or not

  setProgramPage(newData: boolean): void {
    this.program.next(newData);
  }
  set activeProgramPage(programPageFlag) {
    this.programPageFlag = programPageFlag;
  }
  get activeProgramPage(): string {
    return this.programPageFlag;
  }

}
