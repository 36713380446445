/**
 * Angular imports.
 */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

/**
 * Used to show the form for pediatric report.
 */
@Component({
  selector: 'pap-suspect-med-device-info-form',
  templateUrl: './suspect-med-device-info-form.component.html',
  styleUrls: ['./suspect-med-device-info-form.component.scss']
})
export class SuspectMedDeviceInfoFormComponent implements OnInit, OnChanges {
  @Input() functionality = '';
  @Input() formData = null;
  /**
   * Form pediatric report.
   */
  public suspectMedDeviceInfoForm: FormGroup;
  private disabled: boolean;

  /**
   * setter for the form controls.
   */
  get form(): { [key: string]: AbstractControl } { return this.suspectMedDeviceInfoForm.controls; }
  get formValidity(): boolean { return this.suspectMedDeviceInfoForm.valid; }
  get formValues(): { suspectMedDeviceInfoForm: any; } {
    return {
      suspectMedDeviceInfoForm: this.suspectMedDeviceInfoForm.value
    };
  }

  /**
   * User device list.
   */
  userOfDeviceList = [
    { id: 1, name: 'HCP' },
    { id: 2, name: 'Patient' },
    { id: 3, name: 'Lay User' }
  ];

  /**
   * Necessary instances.
   */
  constructor(private fb: FormBuilder) { }

  /**
   * Calling initialize form.
   */
  ngOnInit(): void {
    this.disabled = this.functionality === 'view';
    this.initializeForm();
  }

  /**
   * to detect changes on incoming form values
   * @param changes changes on formData
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formData) {
      this.initializeForm();
    }
  }

  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.suspectMedDeviceInfoForm = this.fb.group({
      deviceName: new FormControl(
        {value : this.formData?.deviceName ? this.formData?.deviceName : '', disabled: this.disabled},
        [Validators.maxLength(20)]),
      associatedMedicinalProduct: new FormControl(
        {value : this.formData?.associatedMedicinalProduct ? this.formData?.associatedMedicinalProduct : '',
          disabled: this.disabled},
        Validators.maxLength(20)),
      userOfDevice: new FormControl(
        {value : this.formData?.userOfDevice ? this.formData?.userOfDevice : '', disabled: this.disabled}),
      wasDeviceReused: new FormControl(
        {value : this.formData?.wasDeviceReused ? this.formData?.wasDeviceReused : '', disabled: this.disabled}),
      deviceUseStartDate: new FormControl(
        {value : this.formData?.deviceUseStartDate ? this.formData?.deviceUseStartDate : '', disabled: this.disabled}),
      deviceUseStopDate: new FormControl(
        {value : this.formData?.deviceUseStopDate ? this.formData?.deviceUseStopDate : '', disabled: this.disabled}),
      udiAndSoftwareVersion: new FormControl(
        {value : this.formData?.udiAndSoftwareVersion ? this.formData?.udiAndSoftwareVersion : '',
          disabled: this.disabled},
        Validators.maxLength(100)),
      actionAndLocation: new FormControl(
        {value : this.formData?.actionAndLocation ? this.formData?.actionAndLocation : '', disabled: this.disabled},
        Validators.maxLength(100)),
      softwareVersion: new FormControl(
        {value : this.formData?.softwareVersion ? this.formData?.softwareVersion : '', disabled: this.disabled},
        Validators.maxLength(100))
    });
  }
}
