<div class="welcome p-3">
    <h3>Welcome </h3>
    <p>What would you like to do?</p>
  </div>

  <section class="actions p-3">
    
    <div class="col-3 bg-white section-div text-center p-3 cursor-pointer rounded">
       <img src="assets/images/add-patient.svg" alt="">
       <p>Add New Patient</p>
    </div>
    <div class="col-3 bg-white section-div text-center p-3 cursor-pointer rounded">
        <img src="assets/images/clipboard.svg" alt="">
        <p>View Existing Patients</p>
     </div>
  </section>