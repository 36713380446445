<div class="row no-gutters align-items-center signin-box">
  <div class="col-12 col-md-6 col-lg-4 mx-auto">
    <div class="header  pt-4 bg-white mb-2 signin-box__content-box rounded">
      <div class="text-center pb-4">
        <img src="assets/images/logo.svg" alt="Logo">
        <span class="position-absolute langSelectorRightSpace">
          <dngx-language-selector [languages]="languages"></dngx-language-selector>
        </span>
      </div>
    </div>
    <div class=" d-flex justify-content-center text-danger" *ngIf="this.apiErrorMsg">
      {{this.apiErrorMsg}}
    </div>
    <div *ngIf="this.defaultCountry" class="body p-4 bg-white signin-box__content-box rounded">
      <p class="text-secondary-grey fnt-size20px mb-2 fnt_Medium">
        {{ "WELCOME_PROGRAM" | translate }}
      </p>
      <p class="text-secondary-grey fnt-size15px fnt_Medium mb-3">{{ "ENTER_MOBILE_EMAIL" | translate }}</p>

      <div class="form-group mb-0">
        <div class="d-flex">
          <div class="pr-5 custom-checkbox">
            <input [attr.checked]="this.radioSelect === 'phone' ? 'checked' : null"
              class="radio-green p-2 mr-2 custom-control-input" (change)="onRadioChange($event)" value="phone"
              type="radio" name="loginCred" id="phoneRadio">
            <label
              class="radio-button-text custom-control-label cursor-pointer ml-4 fnt-size15px fnt_Medium text-rolling-stone-grey"
              for="phoneRadio">
              {{ "MOBILE_NUMBER" | translate }}
            </label>
          </div>
          <div class="pr-5 custom-checkbox">
            <input [attr.checked]="this.radioSelect === 'email' ? 'checked' : null"
              class="radio-green p-2 mr-2 custom-control-input" (change)="onRadioChange($event)" value="email"
              type="radio" name="loginCred" id="emailRadio">
            <label for="emailRadio"
              class="custom-control-label ml-4 cursor-pointer radio-button-text fnt-size15px fnt_Medium text-rolling-stone-grey">
              {{ "EMAIL_ID" | translate }}
            </label>
          </div>
        </div>
      </div>

      <form [formGroup]="loginForm">
        <div *ngIf="this.radioSelect === 'email'" class="form-group">
          <label class="fnt-size12px fnt_Regular text-rolling-stone-grey mb-0">{{ "ENTER_YOUR_EMAIL_ID" | translate
            }}</label>
          <input formControlName="email" type="email"
            class="form-control fnt-size12px fnt_Medium bg-porcelain-white border-0" placeholder="Enter Email ID"
            [ngClass]="{
                'border border-danger': this.loginForm.get('email').touched && this.loginForm.get('email').invalid,
                'border-0': (this.loginForm.get('email').untouched || this.loginForm.get('email').valid)
                            && (!this.loginForm.submitted)
              }">
          <div *ngIf="(this.loginForm.get('email').touched || this.loginForm.submitted)
            &&
            this.loginForm.get('email').invalid" class="text-danger fnt-size10px">
            <div *ngIf="this.loginForm.get('email').invalid">{{ "DOCTOR_REG_EMAIL_ERR" | translate }}</div>
          </div>
        </div>
        <div *ngIf="this.radioSelect === 'phone'" class="form-group">
          <label class="fnt-size12px fnt_Regular text-rolling-stone-grey mb-0">{{ "ENTER_YOUR_MOBILE_NUMBER" | translate
            }}</label>
          <div class="d-flex position-relative">
            <p-dropdown class="d-flex align-items-center position-absolute h-100 signup-phone-code pr-2"
              [options]="[this.defaultCountry]" formControlName="countryCode" [optionValue]="'countryCode'">
              <ng-template pTemplate="selectedItem">
                <div class="country-item-value" *ngIf="this.loginForm.get('countryCode')">
                  <div class="fnt-size12px fnt_Medium text-rolling-stone-grey mr-1">
                    <img [src]="this.defaultCountry.logo" class="flag mr-1" onerror="this.src='assets/images/logo.svg'"
                      alt="country flag" />
                    +{{this.loginForm.get('countryCode').value}}
                  </div>
                </div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="d-flex justify-content-between align-items-center p-2">
                  <div class="country-item-value d-flex">
                    <img [src]="item.logo" class="flag mr-2" onerror="this.src='assets/images/logo.svg'"
                      alt="country flag" />
                    <div class="fnt-size12px text-abbey-grey mr-2"
                      [ngClass]="{'font-weight-bold': this.loginForm.get('countryCode').value === item.countryCode}">
                      {{item.name}}
                    </div>
                    <div class="fnt-size12px text-abbey-grey"
                      [ngClass]="{'font-weight-bold': this.loginForm.get('countryCode').value === item.countryCode}">
                      +{{item.countryCode}}
                    </div>
                  </div>
                  <img *ngIf="this.loginForm.get('countryCode').value === item.countryCode"
                    src="assets/images/checkbox.svg" alt="green checked checkbox">
                </div>
              </ng-template>
            </p-dropdown>
            <input formControlName="phone" type="text" inputmode="numeric"
              class="form-control fnt-size12px fnt_Medium bg-porcelain-white border-0 signup-phone-country-input"
              placeholder="Enter mobile number" [ngClass]="{
                'border border-danger': this.loginForm.get('phone').touched && this.loginForm.get('phone').invalid,
                'border-0': (this.loginForm.get('phone').untouched || this.loginForm.get('phone').valid)
                            && (!this.loginForm.submitted)
              }" dngxDigitOnly (paste)="onPaste($event,'phone')" (input)="phoneValidator($event,'phone')">
          </div>
          <div *ngIf="(this.loginForm.get('phone').touched || this.loginForm.submitted)
            &&
            this.loginForm.get('phone').invalid" class="text-danger fnt-size10px">
            <div *ngIf="this.loginForm.get('phone').invalid">
              <div
                *ngIf="this.loginForm.get('phone').errors.maxlength || this.loginForm.get('phone').errors.minlength; else other_content">
                {{ "MOBILE_NUMBER_MUST_BE_BETWEEN" | translate }}
              </div>
              <ng-template #other_content>{{ "PLEASE_ENTER_VALID_MOBILE_NUMBER" | translate }}</ng-template>
            </div>
          </div>
        </div>

        <button [disabled]="checkInvalid() || this.loginForm.invalid || disabledButton" type="button"
          class="btn btn-success btn-block mt-3 p-0">
          <span ngHcaptchaInvisibleButton (click)="disableOnclick()" (verify)="showResponse($event)" (expired)="onExpired($event)"
            [disabled]="checkInvalid() || this.loginForm.invalid" class="d-block p-2">
            {{ "VERIFY" | translate }}
          </span>
        </button>
        <!-- <button [disabled]="checkInvalid() || this.loginForm.invalid" type="button"
          class="btn btn-success btn-block mt-3 p-0">
          <span ngHcaptchaInvisibleButton (verify)="showResponse($event)" (expired)="onExpired($event)"
            [disabled]="checkInvalid() || this.loginForm.invalid" class="d-block p-2">
            {{ "GET_OTP" | translate }}
          </span>
        </button> -->
      </form>
    </div>
  </div>
</div>
