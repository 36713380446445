/**
 * angular imports.
 */
import { Component, OnInit } from '@angular/core';
/**
 * constant import.
 */
import { CONTACT_US } from '../../../constant/app.constant';
/**
 * component used to show the contact us page.
 */
@Component({
  selector: 'pap-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  /**
   * var used to hold the contact us constants.
   */
  public contactConstant = CONTACT_US;
  /**
   * hide logout dropdown on page load.
   */
  ngOnInit(): void {
    const element: HTMLElement = document.getElementById('contactUs') as HTMLElement;
    element.click();
  }
}
