/**
 * Angular imports.
 */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'pap-further-info-form',
  templateUrl: './further-info-form.component.html',
  styleUrls: ['./further-info-form.component.scss']
})
export class FurtherInfoFormComponent implements OnInit, OnChanges {
  @Input() functionality = '';
  @Input() formData = null;
  /**
   * Form pediatric report.
   */
  public furtherInfoForm: FormGroup;
  private possibleRiskFactorList = [
    { id: 1, name: 'Alcohol' },
    { id: 2, name: 'Allergies' },
    { id: 3, name: 'Drug Abuse' },
    { id: 4, name: 'Nicotine' }
  ];
  private disabled: boolean;
  public filterPossibleRiskFactorList = [];

  /**
   * setter for the form controls.
   */
  get form(): { [key: string]: AbstractControl } { return this.furtherInfoForm.controls; }
  get formValidity(): boolean { return this.furtherInfoForm.valid; }
  get formValues(): { furtherInfoForm: any; } { return { furtherInfoForm: this.furtherInfoForm.value }; }

  /**
   * Necessary instances.
   */
  constructor(private fb: FormBuilder) { }

  /**
   * Calling initialize form.
   */
  ngOnInit(): void {
    this.disabled = this.functionality === 'view';
    this.initializeForm();
  }

  /**
   * to detect changes on incoming form values
   * @param changes changes on formData
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formData) {
      this.initializeForm();
    }
  }

  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.furtherInfoForm = this.fb.group({

      medicalHistory: new FormControl(
        {value : this.formData?.medicalHistory ? this.formData?.medicalHistory : '', disabled: this.disabled},
       [Validators.maxLength(500)]),
      possibleRiskFactor: new FormControl(
        {value : this.formData?.possibleRiskFactor ? this.formData?.possibleRiskFactor : '', disabled: this.disabled}),
      isPatientPregnant: new FormControl(
        {value : this.formData?.isPatientPregnant ? this.formData?.isPatientPregnant : '', disabled: this.disabled}),
      lastMenstrualPeriod: new FormControl(
        {value : this.formData?.lastMenstrualPeriod ? this.formData?.lastMenstrualPeriod : '',
         disabled: this.disabled}),
      expectedDeliveryDate: new FormControl(
        {value : this.formData?.expectedDeliveryDate ? this.formData?.expectedDeliveryDate : '',
         disabled: this.disabled}),
    });
  }

  /**
   * funntion to restrict only blankspaces entry.
   */
  userInput(fieldName: string, event: any): void {
    if (event.target.value) {
      const value = event.target.value;
      if (value[0] === ' ') {
        this.furtherInfoForm.get(fieldName).setValue(value.trimStart());
      }
    }
  }

  filterPossibleRiskFactor(event): void {
    this.filterPossibleRiskFactorList = this.possibleRiskFactorList;
    const filtered: any[] = [];
    const query = event.query;

    for (const factor of this.possibleRiskFactorList) {
      const hospital = factor;
      if (hospital.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(hospital);
      }
    }

    this.filterPossibleRiskFactorList = filtered;
  }
}
