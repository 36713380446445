/**
 * angular imports.
 */
import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
/**
 * model imports.
 */
import { ViewChild } from '@angular/core';

import { Country, Hospital, ProgramData } from '../../../../models/sign-up-chatbot.model';
import { SignInCountry } from '../../../../models/signin.model';
import { PharmaDetail } from '../../../../models/responses.model';
/**
 * Environment import.
 */
import { environment } from '../../../../../environments/environment';
/**
 * Ngrx store imports.
 */
import { Store } from '@ngrx/store';
import * as DoctorAction from '../../../../store/actions/doctor/doctor.actions';
import * as DoctorSelector from '../../../../store/selectors/doctor/doctor.selectors';
import * as SignupAction from '../../../../store/actions/signup/signup.actions';
import * as SignupSelector from '../../../../store/selectors/signup/signup.selectors';
import * as PharmaSelector from '../../../../store/selectors/pharma/pharma.selectors';
import * as PharmaAction from '../../../../store/actions/pharma/pharma.actions';
/**
 * constant imports.
 */
import {
  COUNTRIES_LIST, COUNTRIES_NAME, HREF_LINKS, LOCAL_STORAGE_COUNTRY,
  PATTERN, IMAGE_TYPES, MIME_TYPES, FILE_MODULE, ALL_PAP_FETCH_LIMIT,
  FIELD_TYPE, REQUEST_TYPE, USER_TYPE, UN_EMBEDDED_PHARMA
} from '../../../../constant/app.constant';
/**
 * Angular imports.
 */
import { FormGroup, FormControl, FormBuilder, Validators, FormArray } from '@angular/forms';
/**
 * Rxjs import.
 */
import { timer } from 'rxjs';
import { Subscription } from 'rxjs';
import { SpecialtyListData, EducationListResponse, DoctorData, Error } from '../../../../models/responses.model';
/**
 * service imports.
 */
import { HelperService } from 'projects/nga-PAP/src/app/helper.service';
import { FileService } from 'projects/nga-PAP/src/app/file.service';
import { PharmaNameService } from 'projects/nga-PAP/src/app/services/pharma/pharma-name.service';
import { ToastrService } from 'projects/ngp-docquity-ds/src/lib/toastr/services/toastr.service';

/**
 * component used for add doctor.
 */
@Component({
  selector: 'pap-add-doctor',
  templateUrl: './add-doctor.component.html',
  styleUrls: ['./add-doctor.component.scss']
})
export class AddDoctorComponent implements OnInit, OnDestroy {
  /**
   * to store pharma detail
   */
  public pharmaDetail: PharmaDetail;
  /**
   * to store pharma specific doctor consent
   */
  public doctorConsent: string;
  /**
   * pharma code
   */
  public pharmaCode: string;
  /**
   * user id
   */
  public userId: number;
  /**
   * education name
   */
  public eduacationName: string;
  /**
   * to store doctor detail
   */
  public existingData: any;
  /**
   * flag for pap view
   */
  public papView = false;
  /**
   * render form flag
   */
  public renderForm = false;
  /**
   * flag variable for is doctor detail fetched
   */
  public dataFetched = false;
  /**
   * flag varible wether docotor is verifed or not
   */
  public isVerified: boolean;
  /**
   * to store prc input field.
   */
  @ViewChild('prcInput')
  prcInput: ElementRef;
  /**
   *  to store signature field.
   */
  @ViewChild('signatureInput')
  signatureInput: ElementRef;
  /**
   * Country
   */
  public country: string;
  /**
   * Countries subscription.
   */
  public countriesSub: Subscription;
  /**
   * var for default country.
   */
  public defaultCountry: Country;
  /**
   * to show doctor create success dialog
   */
  public displayCreate: boolean;
  /**
   * to show doctor edit success dialog
   */
  public displayEdit: boolean;
  /**
   * Variable for storing accepted file types for prc.
   */
  public fileTypesDocPrc: string[];
  /**
   * Variable for storing accepted file types for prc.
   */
  public fileTypesDocSign: string[];
  /**
   * This is used to hold countries data.
   */
  public countries: SignInCountry[];
  /**
   * Default country code.
   */
  public defaultCountryCode: number;
  /**
   * to store pharma id
   */
  public pharmaId: string;
  /**
   * Get logo src.
   */
  public logoSrc = 'assets/images/logo.svg';
  /**
   * Var used show for selected country value.
   */
  public selectedCountry = '';
  /**
   * Var used to get hostname.
   */
  public domainName = location.origin;
  /**
   * var used to create form group.
   */
  public doctorForm: FormGroup;
  /**
   * File Upload Status Sub.
   */
  public fileUploadStatusSub: Subscription;
  /**
   * File upload url subscription.
   */
  public fileUploadUrlSub: Subscription;
  /**
   * pharma in query params subscription
   */
  public pharmaQueryParam: Subscription;
  /**
   * doctor data subscription
   */
  public doctorEditSubs: Subscription;
  /**
   * doctor edit data success subscription
   */
  public doctorDataSubs: Subscription;
  /**
   * pharma detail subscription
   */
  public pharmaDetailSubs: Subscription;
  /**
   * pharma doctor consent subscription
   */
  public pharmaDoctorConsentSubs: Subscription;
  /**
   *  to store patch data
   */
  public inital: any;
  /**
   * flag variable to store whether the user is existing or not
   */
  public isExistingUser = false;
  /**
   * used to subscribe doctor specialty subscribe.
   */
  public addDoctorSpecialtySub: Subscription;
  /**
   * used to subscribe pap program.
   */
  public addDoctorProgramSub: Subscription;
  /**
   * subscription for success add doctor
   */
  public doctorSuccessSub: Subscription;
  /**
   * error subscription
   */
  public errorSub: Subscription;
  /**
   * used to store doctor speciality
   */
  public doctorSpecialtyData: SpecialtyListData[];
  /**
   * used to subscribe education detail.
   */
  public educationSubs: Subscription;
  /**
   * used to education list.
   */
  public doctorEducation: EducationListResponse[];
  /**
   * to store user name
   */
  public UserProfile: string;
  /**
   * used to program data.
   */
  public doctorProgramData: ProgramData[];
  /**
   * used to store speciality program
   */
  public doctorSpecialityPrograms: ProgramData[];
  /**
   * flag for successfull doctor creation
   */
  public isDoctorCreated: boolean;
  /**
   * used to hold the verify request success or not.
   */
  public isDoctorCredUpdated: boolean;
  /**
   * to store error during doctor creation
   */
  public error: any;
  /**
   * used to show the selected document/signature.
   */
  public imageDocSrc: string | ArrayBuffer;
  public imageSignatureSrc: string | ArrayBuffer;
  /**
   * timeout subscription
   */
  public timeOut: Subscription;
  /**
   * used to store the max size of document.
   */
  public fileMaxSize: number;
  /**
   * flag for max size of PRC document
   */
  public docSizeInvalid = false;
  /**
   * flag for max size of PRC document
   */
  public signSizeInvalid = false;
  /**
   * flag for invalid format of signature
   */
  public signFormatInvalid = false;
  /**
   * flag for invalid format of signature
   */
  public docFormatInvalid = false;
  /**
   * varibale to store novartis link
   */
  public novartisPrivacy: string;
  /**
   * to store doctor prc url
   */
  public DoctorPrcUrl: string;
  /**
   * system time.
   */
  public systemTime = 0;
  /**
   * subscription for session timeout.
   */
  public sessionTimeOutSub: Subscription;

  public DCPMSupervisor = false;
  /**
   * used to display edit credential popup.
   */
  public displayEditCred = false;
  /**
   * used to show the verify otp screen of edit cred.
   */
  public displayEditCredOtp = false;
  /**
   * used to show the success popup after cred update.
   */
  public displayEditCredSuccess = false;
  /**
   * used to disable the verify otp button.
   */
  public disabledVerifyOtp = true;
  /**
   * used to hold the otp value.
   */
  public otpEnteredValue: string;

  /**
   * Contain login formGroup.
   */
  public loginForm: FormGroup;
  /**
   * used to assign the form type from constant.
   */
  public fieldType = FIELD_TYPE;
  /**
   * used to hold the field Type from change form.
   */
  public fieldTypeValue: string;
  /**
   * used to hold the masked value entered in update form.
   */
  public editCredValue: string;
  /**
   * used to subscribe the token data from api response.
   */
  private editCredSuccessTokenSub: Subscription;
  /**
   * used to hold request otp token.
   */
  private editCredSuccessToken: string;
  /**
   * used to hold send otp button disable.
   */
  public disableSendOtp = false;
  public toHighlight: any;
  public hospitalAffiliationList: Hospital[];
  public filterHospitalAffiliationList: Hospital[];
  public userRole: string;
  public userLoginRole = USER_TYPE;
  findDoctor: boolean;
  public toggleViewMode = false;
  /**
   * Get selected country id.
   */
  setSelectedCountry(event): void {
    this.selectedCountry = event;
    const errorElement = document.getElementById('countryError');
    errorElement.classList.remove('d-none');
    if (this.selectedCountry === COUNTRIES_LIST.INDONESIA.value) {
      location.href = environment.indonesiaDomain;
    } else {
      if (this.domainName === environment.otherDomain) {
        localStorage.setItem('country', this.selectedCountry);
        return;
      } else {
        location.href = environment.otherDomain + '?country=' + this.selectedCountry;
      }
    }
  }

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private decodeToken: HelperService,
    private fileService: FileService,
    private pharmaNameChange: PharmaNameService,
    private toastrService: ToastrService
  ) {
    this.countries = [
      COUNTRIES_LIST.INDONESIA,
      COUNTRIES_LIST.PHILLIPPINS,
      COUNTRIES_LIST.THAILAND
    ];
    /**
     * block browser back button
     */
    history.pushState(null, '', location.href);
    window.onpopstate = (): void => {
      history.go(1);
    };

    if (this.router.url === '/approval/addDoctor' || this.router.url === '/supervisor/addDoctor') {
      this.DCPMSupervisor = true;
      this.pharmaNameChange.setChangePharma(true);
    }

    this.getCountryInfo();
  }
  /**
   * function to get content type of file
   */
  getContentType(extension: string): string {
    let contentType = '';
    switch (extension.toLowerCase()) {
      case IMAGE_TYPES.jpeg:
        contentType = MIME_TYPES.JPEG;
        break;
      case IMAGE_TYPES.jpg:
        contentType = MIME_TYPES.JPG;
        break;
      case IMAGE_TYPES.png:
        contentType = MIME_TYPES.PNG;
        break;
    }
    return contentType;
  }

  /**
   * function to get doctor prc url
   *
   */
  async getDoctorPRC(element: any): Promise<void> {
    if (element === '') {
      return;
    }
    const countryCode = localStorage.getItem(LOCAL_STORAGE_COUNTRY.COUNTRY);
    let countryName = COUNTRIES_NAME[countryCode];
    if (!countryName) {
      countryName = COUNTRIES_LIST.PHILLIPPINS.name;
    }
    const fileExtension = element.split('.').pop().split(PATTERN.URL_SUFFIX)[0];
    const contentType = this.getContentType(fileExtension);
    const path = 'doctor/document' + '/' + element.split('.')[0];
    this.imageDocSrc = await this.fileService.fetchPresignedUrl('null', path, FILE_MODULE.NA,
      countryName, false, contentType);
  }
  /**
   * function to get doctor signature
   */
  async getDoctorSignature(element: any): Promise<void> {
    if (element === '') {
      return;
    }
    const countryCode = localStorage.getItem(LOCAL_STORAGE_COUNTRY.COUNTRY);
    let countryName = COUNTRIES_NAME[countryCode];
    if (!countryName) {
      countryName = COUNTRIES_LIST.PHILLIPPINS.name;
    }
    const fileExtension = element.split('.').pop().split(PATTERN.URL_SUFFIX)[0];
    const contentType = this.getContentType(fileExtension);
    const path = 'signature_image' + '/' + element.split('.')[0];
    this.imageSignatureSrc = await this.fileService.fetchPresignedUrl('null', path, FILE_MODULE.NA,
      countryName, false, contentType);
  }
  /**
   * used check file type is allowed or not;
   */
  fileCheckValidation(allowedDoc: string[], typeofImage: string): boolean {
    if (allowedDoc.includes(typeofImage)) {
      return true;
    }
    else {
      return false;
    }
  }
  /**
   * used check file size is not more than 20 mb;
   */
  fileSizeCheck(size: number): boolean {
    if (size > this.fileMaxSize) {
      return false;
    }
    else {
      return true;
    }
  }
  /**
   * logout functionality
   */
  logout(): void {
    localStorage.removeItem('doctorAuth');
    window.location.reload();
  }

  /**
   * used to get the preSign url of document;
   */
  async onSelectDoc(event): Promise<void> {
    const countryCode = localStorage.getItem(LOCAL_STORAGE_COUNTRY.COUNTRY);
    let countryName = COUNTRIES_NAME[countryCode];
    if (!countryName) {
      countryName = COUNTRIES_LIST.PHILLIPPINS.name;
    }
    const typeofImage = event.target.files[0].type;
    const ifAllowed = this.fileCheckValidation(this.fileTypesDocPrc, typeofImage);
    if (!ifAllowed) {
      this.docSizeInvalid = false;
      this.docFormatInvalid = true;
      return;
    }
    if (!this.fileSizeCheck(event.target.files[0].size)) {
      this.docSizeInvalid = true;
      this.docFormatInvalid = false;
      return;
    }
    else {
      this.docSizeInvalid = false;
      this.docFormatInvalid = false;
    }
    if (event.target.files && event.target.files.length) {
      this.doctorForm.get('registrationDetails.document').setValue('');
      const file: File = event.target.files[0];
      let fileName = file.name.split('.')[0];
      let extension = file.name.split('.').pop().split(PATTERN.URL_SUFFIX)[0];
      extension = extension.toLowerCase();
      fileName = fileName.replace('/[^a-zA-Z0-9_]/', '-') + '_' + Date.now();
      const pathName = 'doctor/document' + '/' + fileName;
      const getPresignedUrl = await this.fileService.uploadFile(file, 'null',
        pathName, FILE_MODULE.NA, countryName);
      if (file.type === 'image/jpeg') {
        extension = 'jpeg';
      }
      this.doctorForm.get('registrationDetails.document').setValue(fileName + '.' + extension);
      this.doctorForm.get('registrationDetails.mediaType').setValue(file.type);

      const reader = new FileReader();
      reader.onload = e => this.imageDocSrc = reader.result;

      reader.readAsDataURL(file);
    }
  }
  /**
   * used to get the preSign url of signature;
   */
  async onSelectSignature(event): Promise<void> {
    const countryCode = localStorage.getItem(LOCAL_STORAGE_COUNTRY.COUNTRY);
    let countryName = COUNTRIES_NAME[countryCode];
    if (!countryName) {
      countryName = COUNTRIES_LIST.PHILLIPPINS.name;
    }
    const typeofImage = event.target.files[0].type;
    const ifAllowed = this.fileCheckValidation(this.fileTypesDocPrc, typeofImage);
    if (!ifAllowed) {
      this.signFormatInvalid = true;
      this.signSizeInvalid = false;
      return;
    }
    if (!this.fileSizeCheck(event.target.files[0].size)) {
      this.signSizeInvalid = true;
      this.signFormatInvalid = false;
      return;
    }
    else {
      this.signSizeInvalid = false;
      this.signFormatInvalid = false;
    }
    if (event.target.files && event.target.files.length) {
      this.doctorForm.get('clinicDetails.digitalSignature').setValue('');
      const file: File = event.target.files[0];
      let fileName = file.name.split('.')[0];
      let extension = file.name.split('.').pop().split(PATTERN.URL_SUFFIX)[0];
      extension = extension.toLowerCase();
      fileName = fileName.replace('/[^a-zA-Z0-9_]/', '-') + '_' + Date.now();
      const pathName = 'signature_image' + '/' + fileName;
      const getPresignedUrl = await this.fileService.uploadFile(file, 'null',
        pathName, FILE_MODULE.NA, countryName);
      if (file.type === 'image/jpeg') {
        extension = 'jpeg';
      }
      this.doctorForm.get('clinicDetails.digitalSignature').setValue(fileName + '.' + extension);
      const reader = new FileReader();
      reader.onload = e => this.imageSignatureSrc = reader.result;

      reader.readAsDataURL(file);
    }
  }

  /**
   * used to remove selected doc/signature
   */
  closeDocImage(): void {
    // if (this.isVerified) {
    //   return;
    // }
    this.prcInput.nativeElement.value = '';
    this.imageDocSrc = '';
    this.doctorForm.get('registrationDetails.mediaType').setValue('');
    this.doctorForm.get('registrationDetails.document').setValue('');
  }
  closeSignatureImage(): void {
    this.toggleViewMode = false;
    this.signatureInput.nativeElement.value = '';
    this.imageSignatureSrc = '';
    this.doctorForm.get('clinicDetails.digitalSignature').setValue('');
  }

  /**
   * Remove the empty keys.
   */
  removeEmptyKeys(obj): any {
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      if (
        value === null ||
        value === undefined ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'object' && Object.keys(value).length === 0)
      ) {
        delete obj[key];
      }
    });

    return obj;
  }

  /**
   * used to submit the doctor registration form
   */
  submitForm(): void {
    const formValue = this.doctorForm.value;
    if ((this.checkForThailandCountry()
      || this.checkForSingaporeCountry()) && this.doctorForm.get('personalAssistant')) {
      const nonEmptyObject = this.removeEmptyKeys(this.doctorForm.get('personalAssistant').value);
      if (nonEmptyObject?.phoneNumber === undefined) {
        delete nonEmptyObject.countryCode;
      }
      if (!Object.keys(nonEmptyObject)?.length) {
        delete formValue.personalAssistant;
      } else {
        this.doctorForm.get('personalAssistant').setValue({
          firstName: nonEmptyObject?.firstName !== null ? nonEmptyObject?.firstName : null,
          lastName: nonEmptyObject?.lastName !== null ? nonEmptyObject?.lastName : null,
          countryCode: nonEmptyObject?.countryCode !== undefined ? nonEmptyObject?.countryCode : null,
          phoneNumber: nonEmptyObject?.phoneNumber !== undefined ? nonEmptyObject?.phoneNumber : null,
          email: nonEmptyObject?.email !== null ? nonEmptyObject?.email : null
        });
      }
    }
    if (this.DCPMSupervisor) {
      this.store.dispatch(DoctorAction.registerDoctorWithDCPM({
        formData: formValue
      }));
    } else {
      if (this.isExistingUser) {
        const patchData = this.diffData(formValue, this.inital);
        if (sessionStorage.getItem('process') && this.checkForIsPhilippineCountry()
        && this.checkForIsRegistrationInPh()) {
          patchData.process = sessionStorage.getItem('process');
        }
        this.store.dispatch(DoctorAction.editDoctorFormData({
          id: this.userId, token: this.findDoctor ? localStorage.getItem('findDoctorAuth') : localStorage.getItem('doctorAuth'),
          formData: patchData
        }));
        this.doctorEditSubs = this.store.select(DoctorSelector.doctorEditSuccess).subscribe(data => {
          if (data != null) {
            if (data === true) {
              this.dataFetched = true;
              this.store.dispatch(DoctorAction.resetDoctorData({}));
              this.showDialogEdit();
              if (!this.findDoctor) {
                this.timeOut = timer(4000).subscribe(res => {
                  if (this.pharmaCode) {
                    this.router.navigate(['/viewDoctor'], {
                      queryParams: {
                        pharmaCode: this.pharmaCode
                      },
                    });
                  }
                  else {
                    this.router.navigate(['/viewDoctor']);
                  }
                });
              }
            }
          }

        });
      }
      else {
        this.store.dispatch(DoctorAction.submitDoctorFormData({
          formData: this.doctorForm.getRawValue(),
          token: this.findDoctor ? localStorage.getItem('findDoctorAuth') : localStorage.getItem('doctorAuth')
        }));

      }
    }
    this.doctorSuccessSub = this.store.select(DoctorSelector.getDoctorSubmitSuccess).subscribe(data => {
      if (data != null) {
        this.isDoctorCreated = data;
        this.isDoctorCredUpdated = data;
        this.dataFetched = true;
        this.showDialogCreate();
        this.store.dispatch(DoctorAction.resetDoctorSubmitSuccess());
      }
    });
  }
  private subscribeDataFromSelector(): void {
    this.addDoctorProgramSub =
      this.store.select(DoctorSelector.getPapProgram).subscribe(data => {
        if (data != null) {
          this.doctorProgramData = data;
          this.doctorSpecialityPrograms = data;
          this.addDoctorSpecialtySub =
            this.store.select(DoctorSelector.getSpecialty).subscribe(specialityData => {
              if (specialityData != null) {
                this.doctorSpecialtyData = JSON.parse(JSON.stringify(specialityData));
                this.doctorSpecialtyData = this.doctorSpecialtyData.filter((ele1) => {
                  for (const val of this.doctorProgramData) {
                    const papMappings = JSON.parse(val.Program.Mappings);
                    for (const ele2 of papMappings) {
                      if (ele2?.Specaility === ele1.speciality_unique_id) {
                        return true;
                      }
                    }
                  }
                  return false;
                });
              }
            });
        }
      }
      );

    this.editCredSuccessTokenSub = this.store.select(DoctorSelector.getRequestOTPToken).subscribe(
      data => {
        if (data) {
          this.editCredSuccessToken = data;
          this.displayEditCredOtp = true;
          this.displayEditCred = false;
          this.disableSendOtp = false;
        }
      }
    );
  }
  /**
   * function to store difference between inital and final patch data for edit;
   */
  public diffData(tgt, src): any {

    if (Array.isArray(tgt)) { // if you got array
      return tgt; // just copy it
    }
    // if you got object
    const rst = {};
    for (const k in tgt) { // visit all fields
      if (typeof tgt[k] === 'object') { // if field contains object (or array because arrays are objects too)
        rst[k] = this.diffData(tgt[k], src[k]); // diff the contents
      } else if (src[k] !== tgt[k]) { // if field is not an object and has changed
        if (tgt[k] !== '') {
          rst[k] = tgt[k]; // use new value
          if (k === 'phoneNumber') {
            const countryCode = 'countryCode';
            rst[countryCode] = tgt[countryCode];
          } else if (k === 'officePhoneNumber') {
            const officeCountryCode = 'officeCountryCode';
            rst[officeCountryCode] = tgt[officeCountryCode];
          }
        } else {
          rst[k] = '{empty}';
        }
      }
      // otherwise just skip it
    }
    return rst;
  }
  /**
   * function to restrict paste input in number
   */
  public onPaste(event: any, fieldName: string): void {
    if (this.doctorForm.get('personalDetails').get(fieldName).value?.length > 0) {
      let value = this.doctorForm.get('personalDetails').get(fieldName).value;
      if (value[0] === '0') {
        value = value.substring(1, value.length);
        this.doctorForm.get('personalDetails').get(fieldName).setValue(value);
      }
    }
  }
  /**
   * function to restrict zero at first index
   */
  public phoneValidator(event: any, fieldName: string): void {
    if (this.doctorForm.get(fieldName).value?.length > 0) {
      const pattern = PATTERN.NO_ZERO;
      if (!pattern.test(event.target.value)) {
        this.doctorForm.get(fieldName).setValue(event.target.value.substring(1, event.target.value.length));
      }
    }
  }
  /**
   * function to initialize form both at the beginning and at the success or failure condition
   */
  formIitialization(element: DoctorData): void {
    if (this.checkForThailandCountry() || this.checkForSingaporeCountry()) {
      this.doctorForm = this.formBuilder.group({
        personalDetails: new FormGroup({
          firstName: new FormControl(
            element?.personalDetails?.firstName ? element.personalDetails.firstName : '',
            [Validators.required, Validators.maxLength(50)]),
          lastName: new FormControl(
            element?.personalDetails?.lastName ? element.personalDetails.lastName : '',
            [Validators.required, Validators.maxLength(50)]),
          countryCode: new FormControl(this.defaultCountry.countryCode, [Validators.required]),
          phoneNumber: new FormControl(
            element?.personalDetails?.phoneNumber ? element.personalDetails.phoneNumber : null,
            [Validators.required, Validators.minLength(7), Validators.maxLength(14)]),
          email: new FormControl(
            element?.personalDetails?.email ? element.personalDetails.email : '',
            [Validators.required, Validators.email, Validators.pattern(PATTERN.EMAIL)]),
        }),
        referenceId: new FormControl(
          element?.referenceId ? element?.referenceId : '',
          [Validators.maxLength(20)]),
        officeCountryCode: new FormControl(this.defaultCountry.countryCode),
        officePhoneNumber: new FormControl(
          element?.officePhoneNumber ? element?.officePhoneNumber : '',
          [Validators.minLength(7), Validators.maxLength(14)]),
        speciality: new FormControl(
          element?.specialities?.[0]?.id ? element.specialities[0].id : '',
          [Validators.required]),
        hospitalIds: new FormControl(
          element?.hospitalIds && element?.hospitalIds ? element?.hospitalIds : '',
          [Validators.required]),
        papIds: new FormArray([]),
        personalAssistant: new FormGroup({
          firstName: new FormControl(
            element?.personalAssistant?.firstName ? element.personalAssistant.firstName : '',
            [Validators.maxLength(50)]),
          lastName: new FormControl(
            element?.personalAssistant?.lastName ? element.personalAssistant.lastName : '',
            [Validators.maxLength(50)]),
          countryCode: new FormControl(this.defaultCountry.countryCode),
          phoneNumber: new FormControl(
            element?.personalAssistant?.phoneNumber ? element.personalAssistant.phoneNumber : null,
            [Validators.minLength(7), Validators.maxLength(14)]),
          email: new FormControl(
            element?.personalAssistant?.email ? element.personalAssistant.email : '',
            [Validators.email, Validators.pattern(PATTERN.EMAIL)]),
        }),
        clinicDetails: new FormGroup({
          name: new FormControl(
            element?.clinicDetails?.name ? element.clinicDetails.name : '',
            [Validators.pattern(PATTERN.NO_SPACE_BEGIN)]),
          address: new FormControl
            (element?.clinicDetails?.address ? element?.clinicDetails?.address : '',
              [Validators.pattern(PATTERN.NO_SPACE_BEGIN)]),
          digitalSignature: new FormControl(
            element?.clinicDetails?.digitalSignature ? element.clinicDetails.digitalSignature : ''),
        }),
      });
    } else if (this.checkForIsPhilippineCountry() && !this.checkForIsRegistrationInPh()) {
      this.doctorForm = this.formBuilder.group({
        personalDetails: new FormGroup({
          firstName: new FormControl(
            element?.personalDetails?.firstName ? element.personalDetails.firstName : '',
            [Validators.required, Validators.pattern(PATTERN.DOCTOR_NAME_REG), Validators.maxLength(50)]),
          middleName: new FormControl(
            element?.personalDetails?.middleName ? element.personalDetails.middleName : '',
            [Validators.maxLength(50)]),
          lastName: new FormControl(
            element?.personalDetails?.lastName ? element.personalDetails.lastName : '',
            [Validators.required, Validators.pattern(PATTERN.DOCTOR_NAME_REG), Validators.maxLength(50)]),
          countryCode: new FormControl(this.defaultCountry.countryCode, [Validators.required]),
          gender: new FormControl(
            element?.personalDetails?.gender ? element.personalDetails.gender : ''),
          phoneNumber: new FormControl(
            element?.personalDetails?.phoneNumber ? element.personalDetails.phoneNumber : null,
            [Validators.required, Validators.minLength(7), Validators.maxLength(14)]),
          email: new FormControl(
            element?.personalDetails?.email ? element.personalDetails.email : '',
            [Validators.required, Validators.email, Validators.pattern(PATTERN.EMAIL)]),
        }),
        speciality: new FormControl(
          element?.specialities?.[0]?.id ? element.specialities[0].id : '',
          [Validators.required]),
        papIds: new FormArray([]),
        registrationDetails: new FormGroup({
          document: new FormControl(
            element?.registrationDetails?.document ? element.registrationDetails.document : ''),
          number: new FormControl(
            element?.registrationDetails?.number ? element.registrationDetails.number : null,
            [Validators.maxLength(256), Validators.required, Validators.pattern(PATTERN.DOCTOR_REG_PRC)]),
          mediaType: new FormControl(
            element?.registrationDetails?.mediaType ? element.registrationDetails.mediaType : ''),
        }),
        clinicDetails: new FormGroup({
          name: new FormControl(
            element?.clinicDetails?.name ? element.clinicDetails.name : '',
            [Validators.pattern(PATTERN.NO_SPACE_BEGIN)]),
          address: new FormControl
            (element?.clinicDetails?.address ? element?.clinicDetails?.address : '',
              [Validators.pattern(PATTERN.NO_SPACE_BEGIN)]),
          digitalSignature: new FormControl(
            element?.clinicDetails?.digitalSignature ? element.clinicDetails.digitalSignature : ''),
        }),
        educationDetails: new FormGroup({
          id: new FormControl(element?.educationDetails?.id ? element.educationDetails.id : ''),
          name: new FormControl(
            element?.educationDetails?.name ? element.educationDetails.name : '',
            [Validators.pattern(PATTERN.NO_SPACE_BEGIN)])
        })
      });
    } else if (this.checkForIsPhilippineCountry() && this.checkForIsRegistrationInPh()) {
      this.doctorForm = this.formBuilder.group({
        personalDetails: new FormGroup({
          firstName: new FormControl(
            element?.personalDetails?.firstName ? element.personalDetails.firstName : '',
            [Validators.required, Validators.pattern(PATTERN.DOCTOR_NAME_REG), Validators.maxLength(50)]),
          middleName: new FormControl(
            element?.personalDetails?.middleName ? element.personalDetails.middleName : '',
            [Validators.maxLength(50)]),
          lastName: new FormControl(
            element?.personalDetails?.lastName ? element.personalDetails.lastName : '',
            [Validators.required, Validators.pattern(PATTERN.DOCTOR_NAME_REG), Validators.maxLength(50)]),
          countryCode: new FormControl(this.defaultCountry.countryCode, [Validators.required]),
          phoneNumber: new FormControl(
            element?.personalDetails?.phoneNumber ? element.personalDetails.phoneNumber : null,
            [Validators.required, Validators.minLength(7), Validators.maxLength(14)]),
          email: new FormControl(
            element?.personalDetails?.email ? element.personalDetails.email : '',
            [Validators.required, Validators.email, Validators.pattern(PATTERN.EMAIL)]),
        }),
        speciality: new FormControl(
          element?.specialities?.[0]?.id ? element.specialities[0].id : '',
          [Validators.required]),
        papIds: new FormArray([]),
        process: sessionStorage.getItem('process'),
        registrationDetails: new FormGroup({
          number: new FormControl(
            element?.registrationDetails?.number ? element.registrationDetails.number : null,
            [Validators.maxLength(256), Validators.pattern(PATTERN.DOCTOR_REG_PRC)]),
        })
      });
    } else {
      this.doctorForm = this.formBuilder.group({
        personalDetails: new FormGroup({
          firstName: new FormControl(
            element?.personalDetails?.firstName ? element.personalDetails.firstName : '',
            [Validators.required, Validators.pattern(PATTERN.DOCTOR_NAME_REG), Validators.maxLength(50)]),
          lastName: new FormControl(
            element?.personalDetails?.lastName ? element.personalDetails.lastName : '',
            [Validators.required, Validators.pattern(PATTERN.DOCTOR_NAME_REG), Validators.maxLength(50)]),
          countryCode: new FormControl(this.defaultCountry.countryCode, [Validators.required]),
          gender: new FormControl(
            element?.personalDetails?.gender ? element.personalDetails.gender : '', [Validators.required]),
          phoneNumber: new FormControl(
            element?.personalDetails?.phoneNumber ? element.personalDetails.phoneNumber : null,
            [Validators.required, Validators.minLength(7), Validators.maxLength(14)]),
          email: new FormControl(
            element?.personalDetails?.email ? element.personalDetails.email : '',
            [Validators.required, Validators.email, Validators.pattern(PATTERN.EMAIL)]),
        }),
        speciality: new FormControl(
          element?.specialities?.[0]?.id ? element.specialities[0].id : '',
          [Validators.required]),
        papIds: new FormArray([]),
        registrationDetails: new FormGroup({
          document: new FormControl(
            element?.registrationDetails?.document ? element.registrationDetails.document : '',
            [Validators.required]),
          number: new FormControl(
            element?.registrationDetails?.number ? element.registrationDetails.number : null,
            [Validators.maxLength(256), Validators.required, Validators.pattern(PATTERN.DOCTOR_REG_PRC)]),
          mediaType: new FormControl(
            element?.registrationDetails?.mediaType ? element.registrationDetails.mediaType : '',
            Validators.required),
        }),
        clinicDetails: new FormGroup({
          name: new FormControl(
            element?.clinicDetails?.name ? element.clinicDetails.name : '',
            [Validators.pattern(PATTERN.NO_SPACE_BEGIN)]),
          address: new FormControl
            (element?.clinicDetails?.address ? element?.clinicDetails?.address : '',
              [Validators.pattern(PATTERN.NO_SPACE_BEGIN)]),
          digitalSignature: new FormControl(
            element?.clinicDetails?.digitalSignature ? element.clinicDetails.digitalSignature : '',
            Validators.required),
        }),
        educationDetails: new FormGroup({
          id: new FormControl(element?.educationDetails?.id ? element.educationDetails.id : ''),
          name: new FormControl(
            element?.educationDetails?.name ? element.educationDetails.name : '',
            [Validators.pattern(PATTERN.NO_SPACE_BEGIN), Validators.required])
        })
      });
    }

    this.renderForm = true;
    if (!this.isExistingUser) {
      this.doctorForm.controls.papIds.setValidators([Validators.required]);
    }
    if (this.isExistingUser) {
      this.doctorForm.get('personalDetails.phoneNumber').disable();
      this.doctorForm.get('personalDetails.email').disable();
      this.doctorForm.get('speciality').disable();
      this.UserProfile = element?.personalDetails?.firstName + ' ' + element?.personalDetails?.lastName;
    }
    if (!(this.checkForThailandCountry() || this.checkForSingaporeCountry())) {
      if (element?.isVerified) {
        this.doctorForm.get('registrationDetails.number').disable();
      }
    }
    this.inital = this.doctorForm.value;
  }
  /**
   * show doctor create success
   */
  public showDialogCreate(): void {
    this.displayCreate = true;
  }
  /**
   * show doctor create success
   */
  public showDialogEdit(): void {
    this.displayEdit = true;
  }

  /**
   * function to map pap with speciality
   */
  public onSpecialityChange(event): void {
    this.papView = true;
    const specialityCode = event.value;
    const programData = this.doctorProgramData.filter((element) => {
      const papMappings = JSON.parse(element.Program.Mappings);
      for (const ele1 of papMappings) {
        if (ele1?.Specaility === specialityCode) {
          return true;
        }
      }
      return false;
    });
    this.doctorSpecialityPrograms = programData;
  }
  /**
   * function to set id of education
   */
  public onEducationChange(event): void {
    const data = this.doctorEducation.find(x => x.name === event.value);
    if (data) {
      this.doctorForm.get('educationDetails.id').patchValue(data.id);
    }
  }

  /**
   * to redirect to view Doctor
   */
  public toView(): void {
    if (this.DCPMSupervisor) {
      window.location.reload();
    } else {
      if (this.pharmaCode) {
        this.router.navigate(['/viewDoctor'], {
          queryParams: {
            pharmaCode: this.pharmaCode
          },
        });
      }
      else {
        this.router.navigate(['/viewDoctor']);
      }
    }
  }
  /**
   * disbale and checked pap
   */
  disbalePap(id: number): boolean {
    if (this.userId) {
      if (this.existingData) {
        if (this.existingData.enrolledPapDetails) {
          return this.existingData.enrolledPapDetails.some(item => item.id === id);
        }
        return false;
      }
    }
    return false;
  }
  /**
   * used to store the country.
   */
  ngOnInit(): void {
    this.findDoctor = ((this.router.url.split('?') && ((this.router.url.split('?')[0]) === '/approval/findDoctor/editDoctor') ? true : false || (this.router.url.split('?') && this.router.url.split('?')[0]) === '/supervisor/findDoctor/editDoctor') ? true : false);
    this.country = localStorage.getItem(LOCAL_STORAGE_COUNTRY.COUNTRY);
    this.getCountryInfo();
    this.store.dispatch(SignupAction.resetCountryList());
    this.country = localStorage.getItem(LOCAL_STORAGE_COUNTRY.COUNTRY);
    if (!this.DCPMSupervisor) {
      this.systemTime = Math.floor(Date.now() / 1000);
      this.pharmaQueryParam = this.route.queryParams
        .subscribe(params => {
          this.pharmaCode = params.pharmaCode;
          this.userRole = params?.role;
        });
    } else {
      this.pharmaCode = localStorage.getItem('pharmaCode');
    }
    if (this.pharmaCode) {
      this.pharmaId = localStorage.getItem('pharmaId');
      if (localStorage.getItem('doctorAuth')) {
        this.store.dispatch(PharmaAction.loadPharmas({ pharmaCode: this.pharmaCode }));
        this.pharmaDetailSubs = this.store.select(PharmaSelector.getPharmaDetail).subscribe(data => {
          if (data != null) {
            this.pharmaDetail = data;
          }
        });
        this.store.dispatch(PharmaAction.getDoctorPharmaConsent({
          pharmaCode: this.pharmaCode,
          token: localStorage.getItem('doctorAuth')
        }));
        this.pharmaDoctorConsentSubs = this.store.select(PharmaSelector.pharmaDoctorConsent).subscribe(res => {
          if (res !== null) {
            this.doctorConsent = res.data.consent;
          }
        });
      }
      else {
        if (!this.findDoctor) {
          if (!this.DCPMSupervisor) {
            this.router.navigate(['/doctor/login/' + this.country], {
              queryParams: {
                pharmaCode: this.pharmaCode
              },
            });
          }
        } else {
          if (localStorage.getItem('findDoctorAuth')) {
            this.store.dispatch(PharmaAction.loadPharmas({ pharmaCode: this.pharmaCode }));
            this.pharmaDetailSubs = this.store.select(PharmaSelector.getPharmaDetail).subscribe(data => {
              if (data != null) {
                this.pharmaDetail = data;
              }
            });
            this.store.dispatch(PharmaAction.getDoctorPharmaConsent({
              pharmaCode: this.pharmaCode,
              token: localStorage.getItem('findDoctorAuth')
            }));
            this.pharmaDoctorConsentSubs = this.store.select(PharmaSelector.pharmaDoctorConsent).subscribe(res => {
              if (res !== null) {
                this.doctorConsent = res.data.consent;
              }
            });
          } else {
            this.router.navigate(['approval/findDoctor']);
          }
        }
      }
    }
    if (localStorage.getItem('doctorAuth')) {
      const idToken = localStorage.getItem('doctorAuth');
      const decodeToken = this.decodeToken.decodeTokenFunc(idToken);
      this.sessionTimeOut(this.systemTime, decodeToken.exp);
      if (decodeToken.data?.userId) {
        this.userId = decodeToken.data.userId;
        this.isExistingUser = true;
      }
    }
    else {
      if (!this.findDoctor) {
        if (!this.DCPMSupervisor) {
          const redirectionUrl = localStorage.getItem('pharmaUrl');
          const redirectUrlCode = localStorage.getItem('pharmaCode');
          if ((redirectionUrl !== undefined && redirectionUrl !== null && redirectionUrl)
            &&
            (redirectUrlCode !== undefined && redirectUrlCode !== null && redirectUrlCode)) {
            this.router.navigate([redirectionUrl], {
              queryParams: {
                code: redirectUrlCode
              }
            });
          } else {
            this.router.navigate(['/doctor/login/' + this.country]);
          }
        }
      } else {
        if (localStorage.getItem('findDoctorAuth')) {
          const idToken = localStorage.getItem('findDoctorAuth');
          const decodeToken = this.decodeToken.decodeFindDoctorTokenFunc(idToken);
          this.sessionTimeOut(this.systemTime, decodeToken.exp);
          if (decodeToken.data?.userId) {
            this.userId = decodeToken.data.userId;
            this.isExistingUser = true;
          }
        } else {
          this.router.navigate(['approval/findDoctor']);
        }
      }
    }

    this.store.dispatch(SignupAction.loadCountries({}));
    this.countriesSub = this.store.select(SignupSelector.getCountryList).subscribe(data => {
      if (data !== null) {
        // localStorage.setItem('country', COUNTRIES_LIST.PHILLIPPINS.value);
        for (const country of data.list) {
          if (country.code === this.country) {
            this.defaultCountry = country;
            break;
          }
          this.defaultCountry = country;
        }
      }
    });
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.pattern(PATTERN.EMAIL)]],
      phone: ['', [Validators.minLength(7), Validators.maxLength(14), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      countryCode: [this?.defaultCountry?.countryCode],
      hCaptchaToken: ['']
    });
    if (this.userId) {
      if (this.pharmaCode) {
        this.store.dispatch(DoctorAction.getDoctorDetail({
          id: this.userId, token: this.findDoctor ? localStorage.getItem('findDoctorAuth') : localStorage.getItem('doctorAuth'),
          pharmaCode: this.pharmaCode
        }));
      }
      else {
        this.store.dispatch(DoctorAction.getDoctorDetail({
          id: this.userId,
          token: this.findDoctor ? localStorage.getItem('findDoctorAuth') : localStorage.getItem('doctorAuth')
        }));
      }
      this.doctorDataSubs = this.store.select(DoctorSelector.getDoctorData).subscribe(async data => {
        if (data !== null && this.dataFetched === false) {
          this.dataFetched = true;
          this.isExistingUser = true;
          this.existingData = data;
          this.papView = true;
          this.isVerified = this.existingData?.isVerified;
          this.formIitialization(data);
          await this.getDoctorPRC(data.registrationDetails.document);
          await this.getDoctorSignature(data.clinicDetails.digitalSignature);
          const programData = this.doctorProgramData.filter((element) => {
            const papMappings = JSON.parse(element.Program.Mappings);
            for (const ele1 of papMappings) {
              if (ele1?.Specaility === data.specialities[0]?.id) {
                return true;
              }
            }
            return false;
          });
          this.doctorSpecialityPrograms = programData;
        }
      });
    }
    else if (!this.userId) {
      this.formIitialization(null);
      let decoded;
      if (!this.findDoctor) {
        decoded = this.decodeToken.decodeTokenFunc(localStorage.getItem('doctorAuth'));
      } else {
        decoded = this.decodeToken.decodeFindDoctorTokenFunc(localStorage.getItem('findDoctorAuth'));
      }
      if (decoded?.data) {
        if (decoded.data?.requestType === 'email') {
          this.doctorForm.get('personalDetails.email').setValue(decoded.data?.email);
          this.doctorForm.get('personalDetails.email').disable();
        }
        else if (decoded.data?.requestType === 'mobile') {
          this.doctorForm.get('personalDetails.phoneNumber').setValue(decoded.data?.phoneNumber);
          this.doctorForm.get('personalDetails.phoneNumber').disable();
        }
      }
    }
    this.fileTypesDocPrc = ['image/jpg', 'image/jpeg', 'image/png'];
    this.fileTypesDocSign = ['image/jpg', 'image/jpeg', 'image/png'];
    this.fileMaxSize = 20971520;
    this.novartisPrivacy = HREF_LINKS.NOVARTIS_PRIVACY;
    this.errorSub = this.store.select(DoctorSelector.getError).subscribe(data => {
      if (data != null) {
        this.error = data;
        if (this.error.error && this.error.error.messages) {
          this.toastrService.showError('Error', this.error.error.messages[0]);
        }
        if (!(this.displayEditCred || this.displayEditCredOtp || this.displayEditCredSuccess) && this.DCPMSupervisor) {
          this.doctorForm.reset();
          /* tslint:disable:no-string-literal */
          (this.doctorForm.controls['papIds'] as FormArray).clear();
          this.imageSignatureSrc = '';
          this.imageDocSrc = '';
          this.doctorForm.get('personalDetails').get('countryCode').setValue(this.defaultCountry.countryCode);
          if (this.checkForThailandCountry() || this.checkForSingaporeCountry()) {
            this.doctorForm.get('personalAssistant').get('countryCode').setValue(this.defaultCountry.countryCode);
            this.doctorForm.get('officeCountryCode').setValue(this.defaultCountry.countryCode);
          }
          this.doctorSpecialityPrograms = [];
        }
        this.disableSendOtp = false;
        this.disabledVerifyOtp = false;
      } else {
        this.resetError();
      }
    });
    if (this.domainName === environment.indonesiaDomain) {
      this.selectedCountry = COUNTRIES_LIST.INDONESIA.value;
    } else {
      this.selectedCountry = this.country;
    }
    localStorage.setItem(LOCAL_STORAGE_COUNTRY.COUNTRY, this.selectedCountry);
    this.store.dispatch((DoctorAction.loadPapPrograms({ pharma: this.pharmaId, limit: ALL_PAP_FETCH_LIMIT })));
    this.store.dispatch(DoctorAction.loadSpeciality({}));
    this.store.dispatch(DoctorAction.loadEducation({}));
    this.addDoctorSpecialtySub =
      this.store.select(DoctorSelector.getSpecialty).subscribe(data => {
        if (data != null) {
          this.doctorSpecialtyData = data;
        }
      }
      );

    this.educationSubs = this.store.select(DoctorSelector.getEducation).subscribe(data => {
      if (data != null) {
        this.doctorEducation = data;
      }
    });

    this.subscribeDataFromSelector();
    this.resetEditCredData();
    if (this.checkForThailandCountry() || this.checkForSingaporeCountry()) {
      this.searchHospital();
      this.store.select(DoctorSelector.getHospitalAffiliation)
        .subscribe(data => {
          if (data) {
            this.hospitalAffiliationList = data?.list ? data?.list.map(obj => ({ id: obj.id, name: obj.name })) : [];
          }
        });
    }
  }

  /**
   * Get the country info
   */
  getCountryInfo(): void {
    switch (localStorage.getItem('country')) {
      case 'TH':
        this.defaultCountry = {
          code: localStorage.getItem('country'),
          countryCode: 66,
          id: 4,
          logo: 'assets/images/thailand-signup-icon.png\n',
          name: 'Thailand'
        };
        break;
      case 'PH':
        this.defaultCountry = {
          code: localStorage.getItem('country'),
          countryCode: 63,
          id: 3,
          logo: 'assets/images/philippines-signup-icon.png\n',
          name: 'philippines'
        };
        break;
        case 'SG':
        this.defaultCountry = {
          code: localStorage.getItem('country'),
          countryCode: 65,
          id: 5,
          logo: 'assets/images/singapore-signup-icon.png\n',
          name: 'singapore'
        };
        break;
      default:
        break;
    }
  }

  /**
   * used to reset all the dat of edit cred popup
   */
  private resetEditCredData(): void {
    this.displayEditCredOtp = false;
    this.displayEditCred = false;
    this.displayEditCredSuccess = false;
    this.loginForm.reset();
    this.otpEnteredValue = '';
    this.editCredSuccessToken = '';
    this.fieldTypeValue = '';
    this.resetError();
  }
  /**
   * used to navigate ae reporting page.
   */
  public navigateToAEReporting(): void {
    const redirectUrlCode = localStorage.getItem('pharmaCode');
    this.router.navigate(['doctor/ae-reporting'], {
      queryParams: {
        pharmaCode: redirectUrlCode
      }
    });
  }

  /**
   * used to navigate ae reporting page.
   */
  public navigateToResources(): void {
    const redirectUrlCode = localStorage.getItem('pharmaCode');
    this.router.navigate(['doctor/resources'], {
      queryParams: {
        pharmaCode: redirectUrlCode
      }
    });
  }
  /**
   * used to show the edit popup.
   */
  public showEditFields(e: string): void {
    this.fieldTypeValue = e;
    this.disabledVerifyOtp = true;
  }
  /**
   * used to request edit cred cred otp.
   */
  public requestEditCredPopup(): void {
    this.disableSendOtp = true;
    let requestType = '';
    if (this.fieldTypeValue === this.fieldType.PHONE) {
      this.editCredValue = this.decodeToken.hideCharacters(this.loginForm.value.phone, this.fieldType.PHONE);
      requestType = REQUEST_TYPE.PHONE_NUMBER;
    } else {
      this.editCredValue = this.decodeToken.hideCharacters(this.loginForm.value.email, this.fieldType.EMAIL);
      requestType = REQUEST_TYPE.EMAIL;
    }
    this.store.dispatch(DoctorAction.updateDoctorCredRequest(
      {
        requestType,
        email: this.loginForm.value.email,
        phoneNumber: this.loginForm.value.phone,
        countryCode: this.defaultCountry.countryCode,
        loginAuth: this.findDoctor ? localStorage.getItem('findDoctorAuth') : localStorage.getItem('doctorAuth'),
        id: this.userId,
        requesterUserRole: this.userRole ?? USER_TYPE.DOCTOR
      }
    )
    );
  }
  /**
   * used to reset the error.
   */
  public resetError(): void {
    this.store.dispatch(DoctorAction.resetErrors());
    this.error = '';
  }

  /**
   * used to navigate to doctor details page.
   */
  public navigateToDoctorHome(): void {
    const redirectUrlCode = localStorage.getItem('pharmaCode');
    this.router.navigate(['viewDoctor'], {
      queryParams: {
        pharmaCode: redirectUrlCode
      }
    });
  }
  /**
   * function to change selection of pap array
   */
  onCheckChange(event): void {
    const formArray: FormArray = this.doctorForm.get('papIds') as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value === event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
  }

  /**
   * function for validating invalidity of email and phone.
   */
  checkInvalid(): boolean {
    if ((this.loginForm.get('phone').value || this.loginForm.get('email').value)) {
      return false;
    }
    return true;
  }
  /**
   * used to hold the otp value.
   * @param event receive otp data from otp component.
   */
  public receiveOtpData(event): void {
    this.resetError();
    this.otpEnteredValue = event.otp1 + event.otp2 + event.otp3 + event.otp4;
    if (!this.otpEnteredValue) {
      this.disabledVerifyOtp = true;
    } else {
      this.disabledVerifyOtp = false;
    }
  }
  /**
   * used to resend the update cred otp.
   */
  public otpResendData(e: boolean): void {
    this.resetError();
    this.requestEditCredPopup();
  }
  /**
   * used to verify the entered otp.
   */
  public verifyEnteredOtp(): void {
    this.disabledVerifyOtp = true;
    this.store.dispatch(DoctorAction.verifyDoctorCredRequest(
      {
        loginAuth: this.findDoctor ? localStorage.getItem('findDoctorAuth') : localStorage.getItem('doctorAuth'),
        otp: this.otpEnteredValue.toString(),
        verificationSignature: this.editCredSuccessToken,
        id: this.userId
      }));

    this.doctorSuccessSub = this.store.select(DoctorSelector.getDoctorSubmitSuccess).subscribe(data => {
      if (data != null) {
        this.isDoctorCredUpdated = data;
        if (this.isDoctorCredUpdated) {
          this.displayEditCredSuccess = true;
          this.displayEditCredOtp = false;
        }
      }
    });
  }
  /**
   * used to reload pae
   */
  public reloadPage(): void {
    window.location.reload();
  }
  redirectionToPharmaPage(): void {
    const redirectionUrl = localStorage.getItem('pharmaUrl');
    const redirectUrlCode = localStorage.getItem('pharmaCode');
    this.router.navigate([redirectionUrl], {
      queryParams: {
        code: redirectUrlCode
      }
    });
  }

  /**
   * funntion to restrict only blankspaces entry.
   */
  userInput(fieldName: string, event: any): void {
    if (event.target.value) {
      const value = event.target.value;
      if (value[0] === ' ') {
        this.doctorForm.get(fieldName).setValue(value.trimStart());
      }
    }
  }

  /**
   * Used to search the hospital affiliation.
   */
  searchHospital(): void {
    this.toHighlight = '';
    this.store.dispatch(DoctorAction.loadHospitalAffiliation({
      keyword: '',
      limit: 200
    }));
  }

  /**
   * Filter the country.
   */
  filterCountry(event): void {
    this.filterHospitalAffiliationList = this.hospitalAffiliationList;
    const filtered: any[] = [];
    const query = event.query;

    for (const hospitalList of this.hospitalAffiliationList) {
      const hospital = hospitalList;
      if (hospital.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(hospital);
      }
    }

    this.filterHospitalAffiliationList = filtered;
  }

  /**
   * reload component when session expired.
   */
  sessionTimeOut(sysTime: number, expTime: number): void {
    const time = expTime - sysTime > 0 ? expTime - sysTime : 0;
    this.sessionTimeOutSub = timer(time * 1000).subscribe(res => {
      this.findDoctor ? localStorage.getItem('findDoctorAuth') : localStorage.getItem('doctorAuth');
      this.redirectionToPharmaPage();
    });
  }

  redirectToFindDoctor(): void {
    if (this.userRole === USER_TYPE.APPROVER) {
      this.router.navigate(['/approval/findDoctor']);
    } else {
      this.router.navigate(['/supervisor/findDoctor']);
    }
  }

  /**
   * Check if country is thailand.
   */
  checkForThailandCountry(): boolean {
    return localStorage.getItem('country') === COUNTRIES_LIST.THAILAND.value;
  }

  /**
   * Check if country is singapore.
   */
  checkForSingaporeCountry(): boolean {
    return localStorage.getItem('country') === COUNTRIES_LIST.SINGAPORE.value;
  }

  /**
   * Check if country is thailand.
   */
  checkForIsPhilippineCountry(): boolean {
    return localStorage.getItem('country') === COUNTRIES_LIST.PHILLIPPINS.value;
  }

  /**
   * Check if country is thailand.
   */
  checkForIsRegistrationInPh(): boolean {
    return sessionStorage.getItem('process') === UN_EMBEDDED_PHARMA;
  }

  /**
   * Clear the find auth storage.
   */
  clearFindDoctorAuth(): void {
    if (localStorage.getItem('findDoctorAuth')) {
      localStorage.removeItem('findDoctorAuth');
    }
  }

  /**
   * unsubscribe the variables.
   */
  ngOnDestroy(): void {
    this.clearFindDoctorAuth();
    this.pharmaNameChange.setChangePharma(false);
    if (this.timeOut) {
      this.timeOut.unsubscribe();
    }
    if (this.doctorSuccessSub) {
      this.doctorSuccessSub.unsubscribe();
    }
    if (this.errorSub) {
      this.errorSub.unsubscribe();
    }
    if (this.addDoctorSpecialtySub) {
      this.addDoctorSpecialtySub.unsubscribe();
    }
    if (this.addDoctorProgramSub) {
      this.addDoctorProgramSub.unsubscribe();
    }
    if (this.fileUploadUrlSub) {
      this.fileUploadUrlSub.unsubscribe();
    }
    if (this.fileUploadStatusSub) {
      this.fileUploadStatusSub.unsubscribe();
    }
    if (this.doctorEditSubs) {
      this.doctorEditSubs.unsubscribe();
    }
    if (this.countriesSub) {
      this.countriesSub.unsubscribe();
    }
    if (this.pharmaQueryParam) {
      this.pharmaQueryParam.unsubscribe();
    }
    if (this.pharmaDetailSubs) {
      this.pharmaDetailSubs.unsubscribe();
    }
    if (this.pharmaDoctorConsentSubs) {
      this.pharmaDoctorConsentSubs.unsubscribe();
    }
    if (this.sessionTimeOutSub) {
      this.sessionTimeOutSub.unsubscribe();
    }
    if (this.editCredSuccessTokenSub) {
      this.editCredSuccessTokenSub.unsubscribe();
    }
    this.store.dispatch(DoctorAction.resetPapPrograms());
  }
}
