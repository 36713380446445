import { Component, OnInit, AfterViewChecked, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as moment from 'moment';
import 'moment-timezone';

/**
 * Rxjs import.
 */
import { Subscription } from 'rxjs';
/**
 * Ngrx store imports.
 */
import { Store } from '@ngrx/store';
import * as LoginSelector from '../../../../store/selectors/login/login.selectors';
import * as DoctorAction from '../../../../store/actions/doctor/doctor.actions';
import * as HomeAction from '../../../../featureModules/dashboard/featureModules/home/store/actions/home.actions';
import * as HomeSelector from '../../../../featureModules/dashboard/featureModules/home/store/selectors/home.selectors';
import * as DoctorSelector from '../../../../store/selectors/doctor/doctor.selectors';
/**
 * Service Imports.
 */
import { TranslateService } from '@ngx-translate/core';
import { PharmaNameService } from 'projects/nga-PAP/src/app/services/pharma/pharma-name.service';
/**
 * Constant imports.
 */
import { USER_TYPE, ERROR_CODE, ALL_PAP_FETCH_LIMIT, AE_REPORT_STATUS } from '../../../../constant/app.constant';
/**
 * jwt import.
 */
import { JwtHelperService } from '@auth0/angular-jwt';
/**
 * model import.
 */
import { Signin } from 'projects/nga-PAP/src/app/models/signin.model';
import { AeReportListResponse } from 'projects/nga-PAP/src/app/models/responses.model';
import { ProgramData } from 'projects/nga-PAP/src/app/models/sign-up-chatbot.model';
import { FormArray, FormBuilder } from '@angular/forms';
/**
 * prime ng imports.
 */
import { Paginator } from 'primeng/paginator';


@Component({
  selector: 'pap-ae-report-list',
  templateUrl: './ae-report-list.component.html',
  styleUrls: ['./ae-report-list.component.scss']
})
export class AeReportListComponent implements OnInit, AfterViewChecked, OnDestroy {

  /**
   * Home detail data subscription.
   */
  public aeReportListSub: Subscription;
  // List from api
  public aeReportList: AeReportListResponse;
  // selected tab for list
  public activeTab = 'PENDING';
  // user Role of Approver supervisor
  public userLoginRole = USER_TYPE;
  /**
   * Used to hold the home api error.
   */
  public getHomeError: any;
  /**
   * Used for home api error subscription.
   */
  public homeErrorSub: Subscription;
  /**
   * Flag for api response.
   */
  public hasListData = false;
  /**
   * to store current pharma id
   */
  public pharma: string;
  /**
   * subscription for pharma id
   */
  public pharmaIdSubs: Subscription;

  private pharmaNameChangeSub: Subscription;
  /**
   * used to subscribe the pap programs.
   */
  private fetchProgramSub: Subscription;
  /**
   * used to store the program data.
   */
  public papProgramData: ProgramData[];
  /**
   * User to hold the search Keyword by user.
   */
  public searchKeyword = '';

  /**
   * Offset for patient approval list data.
   */
  public offset = 1;

  private queryParamPage: 1;
  /**
   * Record limit per page.
   */
  public limit = 10;
  /**
   * Column for scrolable.
   */
  public scrollableCols: any[];

  /**
   * used to show the filter option.
   */
  public visibleFilter = false;

  /**
   * used to limit the max date.
   */
  public maxDate = new Date();

  /**
   * var used to set the min selection date of prescription.
   */
  public minDate = new Date();

  /**
   * used to store the change year dropdown.
   */
  public yearDropDown: string;

  public userRole!: string;

  /**
   * Used to store login api response.
   */
  public loginDetail: Signin;

  /**
   * Loigin detail subscription.
   */
  public loginDetailSub: Subscription;

  /**
   * home error messages
   */
  public homeErrorMessages: string;
  /**
   * used to get the all patient status from constant.
   */
  public aeReportStatus = AE_REPORT_STATUS;

  /**
   * used to do the lazy load on table
   */
  public tableDataLoading: boolean;
  /**
   * used to show to table pagination.
   */
  public showPagination = true;
  /**
   * used to hold the pharma id.
   */
  public pharmaId: string;

  public programArray: FormArray;

  public filterAdded: boolean;

  /**
   * used to create calender date
   */
  public defaultDateContactOn: any;

  @ViewChild('paginator', { static: false }) paginator?: Paginator;

  private loadOffset = false;



  /**
   * used to subscribe the activated route.
   */
  private activatedRouteSub: Subscription;
  /**
   *
   * @param store used to get ngrx store.
   * @param router used to navigate.
   * @param translate used to translate the language.
   * @param formBuilder used to create form
   * @param pharmaNameChange used to detect which pharma selected.
   * @param activatedRoute used to get activate route.
   */
  constructor(
    private store: Store,
    private router: Router,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    public pharmaNameChange: PharmaNameService,
    private activatedRoute: ActivatedRoute,
  ) { }
  /**
   * used to initiate the data after page load.
   */
  ngOnInit(): void {
    this.loadOffset = true;

    this.tableDataLoading = true;
    this.pharmaIdSubs = this.pharmaNameChange.data$.subscribe(res => {
      if (res !== null) {
        this.pharma = res.toString();
        this.pharmaId = localStorage.getItem('pharmaId');
        this.store.dispatch((DoctorAction.loadPapPrograms({ pharma: this.pharmaId, limit: ALL_PAP_FETCH_LIMIT })));
        this.subscribeRouteData();
        this.getAeReportList(this.offset.toString(), this.limit.toString());
      }
      this.searchKeyword = '';
    });

    this.aeReportListSub = this.store.select(HomeSelector.getAeReportList).subscribe(data => {
      if (data) {
        this.getHomeError = null;
        this.aeReportList = data;
        this.hasListData = true;
        this.showPagination = true;

        const timerLoadingData = setInterval(() => {
          clearInterval(timerLoadingData);
          if (this.loadOffset) {
            this.paginator?.changePage(this.offset - 1);
            this.loadOffset = false;
          }
          this.tableDataLoading = false;
        }, 1000);
      }
    });

    this.loginDetailSub = this.store.select(LoginSelector.getUserCredential).subscribe(data => {
      this.loginDetail = data;
      const helper = new JwtHelperService();
      const decodedToken = helper.decodeToken(this.loginDetail.accessToken);
      this.userRole = decodedToken.role;
    });

    this.homeErrorSub = this.store.select(HomeSelector.getHommeError).subscribe(data => {
      if (data) {
        this.hasListData = false;
        if (data?.error?.code === ERROR_CODE.E4040) {
          this.homeErrorMessages = this.translate.instant('NO_DATA_AVAILABLE');
          const timerLoadingData = setInterval(() => {
            clearInterval(timerLoadingData);
            this.tableDataLoading = false;
            this.router.navigate([]);
          }, 1000);
        }
      }
    });
    this.getTheProgramData();
  }

  /**
   * used to subscribe route data.
   */
  private subscribeRouteData(): void {
    this.activatedRouteSub = this.activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.page) {
        this.offset = queryParams.page;
        this.router.navigate([], {
          queryParams: {
            page: this.offset
          }
        });
      }
    });
  }
  /**
   * Change listing tab.
   */
    changeTab(tab: string): void {
      this.activeTab = tab;
      this.offset = 1;
      this.aeReportList = null;
      this.getHomeError = '';
      this.getAeReportList(this.offset.toString(), this.limit.toString());
    }

  /**
   * get the program data.
   */
  getTheProgramData(): void {
    this.fetchProgramSub =
      this.store.select(DoctorSelector.getPapProgram).subscribe(data => {
        if (data != null) {
          this.papProgramData = data;
        }
      });
  }

  /**
   * Search data by user.
   */
  public searchData(data): void {
    this.store.dispatch(HomeAction.reSetHomeError());
    this.searchKeyword = data.target.value;
    if (this.searchKeyword !== '' && this.searchKeyword.length < 1) {
      return;
    }
    this.tableDataLoading = true;
    this.offset = 1;
    this.router.navigate([]);
    this.getAeReportList(this.offset.toString(), this.limit.toString());
  }

  public navigateToAeReportDetail(id, status: string): void {
    console.log(id);
    if (this.userRole === USER_TYPE.PAP_SUPERVISOR) {
      this.router.navigate(['supervisor/aeReport/details/' + id + '/view']);
    } else {
      if (status === AE_REPORT_STATUS.RE_REVIEW) {
        this.router.navigate(['approval/aeReport/details/' + id + '/edit']);
      } else {
        this.router.navigate(['approval/aeReport/details/' + id + '/view']);
      }
    }
  }

  /**
   * Get home list data.
   */
  getAeReportList(page: string, limit: string): void {
    this.store.dispatch(HomeAction.resetAeReportListData());

    this.store.dispatch(HomeAction.loadAeReports({
      page,
      limit,
      pharmaCode: this.pharma,
      keyword: this?.searchKeyword,
      activeTab: this.activeTab,
    }));
  }

  /**
   * Calling pagination.
   */
  setMyPagination(data): void {
    this.tableDataLoading = true;
    this.offset = data.page + 1;
    this.getAeReportList(this.offset.toString(), this.limit.toString());
    const timerLoadingData = setInterval(() => {
      clearInterval(timerLoadingData);
      this.pharmaNameChange.setChangePharma(false);
    }, 300);
  }

  /**
   * Initialize the list header.
   */
  ngAfterViewChecked(): void {
    this.scrollableCols = [
      { field: 'id', header: this.translate.instant('AE_ID') },
      { field: 'doc', header: this.translate.instant('DATE_OF_CREATION') },
      { field: 'patient', header: this.translate.instant('PATIENT_ID_CAP') },
      { field: 'program', header: this.translate.instant('PROGRAM_NAME') },
      { field: 'status', header: this.translate.instant('STATUS') },
      { field: 'action', header: this.translate.instant('Action') }
    ];
  }

  /**
   * used to unsubscribe the variables.
   */
  private unSubscribeDate(): void {
    if (this.aeReportListSub) {
      this.aeReportListSub.unsubscribe();
    }

    if (this.homeErrorSub) {
      this.homeErrorSub.unsubscribe();
    }

    if (this.pharmaIdSubs) {
      this.pharmaIdSubs.unsubscribe();
    }

    if (this.pharmaNameChangeSub) {
      this.pharmaNameChangeSub.unsubscribe();
    }

    if (this.fetchProgramSub) {
      this.fetchProgramSub.unsubscribe();
    }

    if (this.activatedRouteSub) {
      this.activatedRouteSub.unsubscribe();
    }
  }
  /**
   * call function on destroy when page is destroying.
   */
  ngOnDestroy(): void {
    this.homeErrorMessages = '';
    this.store.dispatch(HomeAction.resetAeReportListData());
    this.store.dispatch(HomeAction.reSetHomeError());
    this.unSubscribeDate();
  }

}

