<div>
  <h5 class="fnt-size18px text-heading-grey mb-3">{{'MEASURE_TAKEN' | translate}}</h5>
  <form [formGroup]="measureTokenForm">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div class="form-group hospital-search">
            <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
              {{'ADDITIONAL_DRUG_INFO_MEASURES' | translate}}
              <span class="text-danger d-none">*</span>
            </label>
            <p-autoComplete class="w-100" (keyup)="userInput('measuresToken', $event)" [dropdown]="true"
              [inputStyleClass]="'form-control fnt-size14px w-100'" [suggestions]="filterMeasuresTokenList" [multiple]="!checkForServierProcess()"
              [styleClass]="form.measuresToken.touched && form.measuresToken.invalid ? 'pcomponent-invalid w-100 bg-e9ecef' : 'w-100 bg-e9ecef'"
              placeholder="{{'SELECT_OPTION' | translate}}" [minLength]="1" (completeMethod)="filterCountry($event)" [autoHighlight]="true"
              formControlName="measuresToken" field="name" [completeOnFocus]="true" [forceSelection]="true">
              <ng-template let-token pTemplate="item">
                <div [innerHTML]="token.name | highlight: toHighlight"></div>
              </ng-template>
            </p-autoComplete>

          <div *ngIf="(form.measuresToken.touched) && form.measuresToken.invalid"
            class="text-danger fnt-size10px d-none">
            {{'ADDITIONAL_DRUG_INFO_MEASURES' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"TREATMENT_REQUIRED_DESC" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <textarea formControlName="treatment" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_DESC' | translate}}" [maxlength]="500">
            </textarea>
          </div>
          <div *ngIf="form.treatment.touched && form.treatment.invalid" class="d-none text-danger fnt-size12px">
            {{"TREATMENT_REQUIRED_DESC" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-9" *ngIf="!checkForServierProcess()">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"MORE_DETAILS" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="moreDetails" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_HERE' | translate}}" [maxlength]="100"/>
          </div>
          <div *ngIf="form.moreDetails.touched && form.moreDetails.invalid" class="d-none text-danger fnt-size12px">
            {{"MORE_DETAILS" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="!checkForServierProcess()">
        <div class="form-group">
          <label class="fnt-size12px">
            {{ 'QUALITY_COMPLAINT' | translate }}
          </label>
          <div class="custom-checkbox">
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="aeQualityYes" value="Yes"
                formControlName="aeQuality">
              <label class="radio-button-text cursor-pointer" for="aeQualityYes">{{ 'YES' | translate }}</label>
            </span>
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="aeQualityNo" value="No" formControlName="aeQuality">
              <label class="radio-button-text cursor-pointer" for="aeQualityNo">{{ 'NO' | translate }}</label>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="!checkForServierProcess()">
        <div class="form-group">
          <label class="fnt-size12px">
            {{'AE_STOPPED_AFTER_STOPPING_DRUGS' | translate}}
          </label>
          <div class="custom-checkbox">
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="aeStopDrugUsageYes" value="Yes"
                formControlName="aeStopDrugUsage">
              <label class="radio-button-text cursor-pointer" for="aeStopDrugUsageYes">{{ 'YES' | translate }}</label>
            </span>
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="aeStopDrugUsageNo" value="No"
                formControlName="aeStopDrugUsage">
              <label class="radio-button-text cursor-pointer" for="aeStopDrugUsageNo">{{ 'NO' | translate }}</label>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-4" *ngIf="!checkForServierProcess()">
        <div class="form-group">
          <label class="fnt-size12px">
            {{ 'LACK_OF_EFFICACY' | translate }}
          </label>
          <div class="custom-checkbox">
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="aeLackEfficiencyYes" value="Yes"
                formControlName="aeLackEfficiency">
              <label class="radio-button-text cursor-pointer" for="aeLackEfficiencyYes">{{ 'YES' | translate }}</label>
            </span>
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="aeLackEfficiencyNo" value="No"
                formControlName="aeLackEfficiency">
              <label class="radio-button-text cursor-pointer" for="aeLackEfficiencyNo">{{ 'NO' | translate }}</label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
