/**
 * Angular imports.
 */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TH_SERVIER_PROCESS } from 'projects/nga-PAP/src/app/constant/app.constant';

@Component({
  selector: 'pap-measures-token-form',
  templateUrl: './measures-token-form.component.html',
  styleUrls: ['./measures-token-form.component.scss']
})
export class MeasuresTokenFormComponent implements OnInit, OnChanges {
  @Input() functionality = '';
  @Input() formData = null;
  @Input() process: string;
  /**
   * Form pediatric report.
   */
  public measureTokenForm: FormGroup;
  public filterMeasuresTokenList: { id: number; name: string; }[];
  private disabled: boolean;
  /**
   * setter for the form controls.
   */
  get form(): { [key: string]: AbstractControl } { return this.measureTokenForm.controls; }
  get formValidity(): boolean { return this.measureTokenForm.valid; }
  get formValues(): { measureTokenForm: any; } {
    return {
      measureTokenForm: this.measureTokenForm.value
    };
  }

  /**
   *
   */
  measuresTokenList = [
    { id: 1, name: 'Novartis drug continued' },
    { id: 2, name: 'Novartis drug temporarily discontinued' },
    { id: 3, name: 'Novartis drug permanently discontinued' },
    { id: 4, name: 'Dose reduction Novartis drug' },
    { id: 5, name: 'Non-drug treatment of adverse event' },
    { id: 6, name: 'Drug treatment of adverse event' },
    { id: 7, name: 'Other' },
    { id: 8, name: 'No treatment' },
  ];

  alternateMeasuresTokenList = [
    { id: 1, name: 'Continued' },
    { id: 2, name: 'Temporarily discontinued' },
    { id: 3, name: 'Permanently discontinued' },
    { id: 4, name: 'Dose reduction' },
    { id: 5, name: 'Non-drug treatment of adverse event' },
    { id: 6, name: 'Drug treatment of adverse event' },
    { id: 7, name: 'Other' },
    { id: 8, name: 'No treatment' },
  ];

  /**
   * Necessary instances.
   */
  constructor(private fb: FormBuilder) { }

  /**
   * Calling initialize form.
   */
  ngOnInit(): void {
    this.disabled = this.functionality === 'view';
    this.initializeForm();
  }

  /**
   * to detect changes on incoming form values
   * @param changes changes on formData
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formData) {
      this.initializeForm();
    }
  }

  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.measureTokenForm = this.fb.group({
      measuresToken: new FormControl(
        {value : this.formData?.measuresToken ? this.formData?.measuresToken : '', disabled: this.disabled}),
      treatment: new FormControl(
        {value : this.formData?.treatment ? this.formData?.treatment : '', disabled: this.disabled},
       Validators.maxLength(500)),
      moreDetails: new FormControl(
        {value : this.formData?.moreDetails ? this.formData?.moreDetails : '', disabled: this.disabled},
       Validators.maxLength(100)),
      aeQuality: new FormControl(
        {value : this.formData?.aeQuality ? this.formData?.aeQuality : '', disabled: this.disabled}),
      aeStopDrugUsage: new FormControl(
        {value : this.formData?.aeStopDrugUsage ? this.formData?.aeStopDrugUsage : '', disabled: this.disabled}),
      aeLackEfficiency: new FormControl(
        {value : this.formData?.aeLackEfficiency ? this.formData?.aeLackEfficiency : '', disabled: this.disabled})
    });
    if (this.checkForServierProcess()) {
      this.measuresTokenList = this.alternateMeasuresTokenList;
      this.measureTokenForm.removeControl('aeStopDrugUsage');
      this.measureTokenForm.removeControl('moreDetails');
      this.measureTokenForm.removeControl('aeQuality');
      this.measureTokenForm.removeControl('aeLackEfficiency');
    }
  }

  /**
   * funntion to restrict only blankspaces entry.
   */
  userInput(fieldName: string, event: any): void {
    if (event.target.value) {
      const value = event.target.value;
      if (value[0] === ' ') {
        this.measureTokenForm.get(fieldName).setValue(value.trimStart());
      }
    }
  }

  filterCountry(event): void {
    this.filterMeasuresTokenList = this.measuresTokenList;
    const filtered: any[] = [];
    const query = event.query;

    for (const token of this.measuresTokenList) {
      const hospital = token;
      if (hospital.name.toLowerCase().indexOf(query.toLowerCase()) === 0) {
        filtered.push(hospital);
      }
    }

    this.filterMeasuresTokenList = filtered;
  }

  onSubmit(): void {
    console.log('this.form', this.measureTokenForm.value);
  }

  /**
   * Check if process is for servier chatbot flow
   */
  checkForServierProcess(): boolean {
    return this.process === TH_SERVIER_PROCESS;
  }
}

