/**
 * Ngrx store.
 */
 import { createReducer, on } from '@ngrx/store';
 import * as FileAction from '../../actions/file/file.action';

 export interface State {
   presignedUrl: string;
   uploadReqTime: number;
   presignedReqTime: number;
   error: Error;
   presignedUrlWithIndex: { url: string; index: number, presignedReqTime: number };
 }

 export const initialState: State = {
   presignedUrl: null,
   uploadReqTime: null,
   presignedReqTime: null,
   error: null,
   presignedUrlWithIndex: null
 };

 export const reducer = createReducer(
   initialState,
   on(FileAction.setUploadingTime, (state, payload) => ({
    ...state,
    uploadReqTime: payload.uploadReqTime
  })),
   on(FileAction.setFilePresignedUrl, (state, payload) => ({
     ...state,
     presignedUrl: payload.presignedUrl,
     presignedReqTime: payload.presignedReqTime
   })),
   on(FileAction.resetFilePresignedUrl, (state, payload) => ({
     ...state,
     presignedUrl: initialState.presignedUrl,
   })),
   on(FileAction.resetFilePresignedUrlWithIndex, (state, payload) => ({
    ...state,
    presignedUrlWithIndex: initialState.presignedUrlWithIndex,
  })),
   on(FileAction.setFilePresignedUrlWithIndex, (state, payload) => ({
    ...state,
    presignedUrlWithIndex: {
      index: payload.index,
      url: payload.presignedUrl,
      presignedReqTime: payload.presignedReqTime
    },
  })),
   on(FileAction.setErrors, (state, payload) => ({
     ...state,
     error: payload.error
   }))
 );
