/**
 * store imports
 */
import { createSelector } from '@ngrx/store';
import * as fromRoot from '../../reducers/index';
import * as fromPharma from '../../reducers/pharma/pharma.reducer';
export const pharma = (state: fromRoot.State) => state.pharma;

/**
 * selector for pharma detail
 */
export const getPharmaDetail =  createSelector(
    pharma,
    (state: fromPharma.State) => state.pharmaData
);
/**
 * selector for pharma error
 */
export const pharmaError = createSelector(
    pharma,
    (state: fromPharma.State) => state.error
);
/**
 * selector for pharma detail
 */
export const getPharmaInfo =  createSelector(
    pharma,
    (state: fromPharma.State) => state.pharmaInfo);

//  selector for pharma user mapped detail
export const UserMappedPharmaList = createSelector(
    pharma,
    (state: fromPharma.State) => state.pharmaList
);
/**
 * selector for pharma doctor consent
 */
export const pharmaDoctorConsent = createSelector(
    pharma,
    (state: fromPharma.State) => state.doctorPharmaConsent
);
