/**
 * angular imports.
 */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
/**
 * model imports.
 */
import { DoctorData, DoctorToken } from 'projects/nga-PAP/src/app/models/responses.model';
/**
 * rxjs imports.
 */
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
/**
 * store imports.
 */
import { Store } from '@ngrx/store';
import * as DoctorAction from '../../../../store/actions/doctor/doctor.actions';
import * as DoctorSelector from '../../../../store/selectors/doctor/doctor.selectors';
/**
 * service imports.
 */
import { HelperService } from 'projects/nga-PAP/src/app/helper.service';
/**
 * Environment import.
 */
import { environment } from '../../../../../environments/environment';
/**
 * constant import.
 */
import { AEREPORTING_URL } from 'projects/nga-PAP/src/app/constant/app.constant';
/**
 * component used to show to the ae reporting page.
 */
@Component({
  selector: 'pap-doctor-ae-reporting',
  templateUrl: './doctor-ae-reporting.component.html',
  styleUrls: ['./doctor-ae-reporting.component.scss']
})
export class DoctorAeReportingComponent implements OnInit, OnDestroy {
  /**
   * to store pharma detail
   */
  public decodedToken: DoctorToken;
  /**
   * userId
   */
  public userId: number;
  /**
   * query param sub
   */
  public paramSub: Subscription;
  /**
   * to store pharma code
   */
  public pharmaCode: string = null;

  /**
   * to store doctor detail
   */
  public element: DoctorData = null;
  public doctorProfile: string;
  /**
   * doctor data subscription
   */
  public doctorDataSubs: Subscription;
  /**
   * used to subscribe the decoded token.
   */
  private decodeTokenSub: Subscription;
  /**
   * used to hold the ae reporting iframe url.
   */
  public iframeUrl: string;
  /**
   * create all necessary instances.
   * @param store used for to use ngrx store.
   * @param router used to create route.
   * @param route used to get activate route.
   */
  constructor(
    public store: Store,
    public router: Router,
    public route: ActivatedRoute,
    private decodeToken: HelperService
  ) { }

  /**
   * used to create route and decode doctor authToken.
   */
  ngOnInit(): void {
    this.paramSub = this.route.queryParams
      .pipe(take(1)).subscribe(params => {
        if (params.pharmaCode) {
          this.pharmaCode = params.pharmaCode;
        }
      });
    if (localStorage.getItem('doctorAuth')) {
      const idToken = localStorage.getItem('doctorAuth');
      const decodeToken = this.decodeToken.decodeTokenFunc(idToken);
      if (decodeToken) {
        this.decodedToken = decodeToken;
        this.userId = this.decodedToken.data.userId;
      }
    }
    else {
      const redirectionUrl = localStorage.getItem('pharmaUrl');
      const redirectUrlCode = localStorage.getItem('pharmaCode');
      this.router.navigate([redirectionUrl], {
        queryParams: {
          code: redirectUrlCode
        }
      });
    }

    this.store.dispatch(DoctorAction.getDoctorDetail(
      { id: this.userId, token: localStorage.getItem('doctorAuth'), pharmaCode: this.pharmaCode }));
    this.doctorDataSubs = this.store.select(DoctorSelector.getDoctorData).subscribe(data => {
      if (data !== null && this.element === null) {
        this.element = data;
        this.doctorProfile = data?.personalDetails?.firstName + ' ' + data?.personalDetails?.lastName;
      }
    });

    if (environment.production === true) {
      this.iframeUrl = AEREPORTING_URL.doctor_prod;
    } else {
      this.iframeUrl = AEREPORTING_URL.doctor;
    }
  }

  /**
   * used to navigate to doctor details page.
   */
  navigateToDoctorHome(): void {
    const redirectUrlCode = localStorage.getItem('pharmaCode');
    this.router.navigate(['viewDoctor'], {
      queryParams: {
        pharmaCode: redirectUrlCode
      }
    });
  }

  /**
   * used to navigate to doctor details page.
   */
  navigateToResources(): void {
    const redirectUrlCode = localStorage.getItem('pharmaCode');
    this.router.navigate(['doctor/resources'], {
      queryParams: {
        pharmaCode: redirectUrlCode
      }
    });
  }
  /**
   * used to unsubscribe the variables.
   */
  ngOnDestroy(): void {
    if (this.doctorDataSubs) {
      this.doctorDataSubs.unsubscribe();
    }
    if (this.decodeTokenSub) {
      this.decodeTokenSub.unsubscribe();
    }
    if (this.paramSub) {
      this.paramSub.unsubscribe();
    }
  }

}
