<div>
  <h5 class="fnt-size18px text-heading-grey mb-3">{{checkForServierProcess() ? ('COURSE' | translate) :
    ('RELEVANT_LAB_TEST_DATA' | translate)}}</h5>
  <form [formGroup]="relevantLabTestForm">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{checkForServierProcess() ? ("ENCLOSE_RELEVANT_FINDINGS" | translate) :
              ("MENTIONS_LAB_TEST_DATA_RESULTS_NORMAL_RANGE"
              | translate)}}
              <span class="d-none text-danger">*</span>
            </span>
            <span class="fnt-size12px">
              {{!form.possibleRisk.value.length ? 0 :
              form.possibleRisk.value.length}}/500
            </span>
          </label>
          <div class="position-relative">
            <textarea formControlName="possibleRisk" type="text" class="form-control fnt-size14px"
              placeholder="{{checkForServierProcess() 
                ? ('ENCLOSE_RELEVANT_FINDINGS' | translate) 
                : ('MENTIONS_LAB_TEST_DATA_RESULTS_NORMAL_RANGE_PLACEHOLDER' | translate)}}"
              [maxlength]="500">
            </textarea>
          </div>
          <div *ngIf="form.possibleRisk.touched && form.possibleRisk.invalid"
            class="d-none text-danger fnt-size12px">
            {{checkForServierProcess() 
                ? ('ENCLOSE_RELEVANT_FINDINGS' | translate) 
                : ('MENTIONS_LAB_TEST_DATA_RESULTS_NORMAL_RANGE_PLACEHOLDER' | translate)}}
                 {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
