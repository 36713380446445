<div class="p-4 prescriptionFreezeDiv">
  <div class="row">
    <h5 class="ml-3">{{'FIND_DOCTOR' | translate}}</h5>
    <!-- details -->
    <div class="col-md-12 prescriptionFreezeDiv__details">
      <div class="bg-white border rounded box-shadow p-3 prescriptionFreezeDiv__details-no-data-heading">
        <form [formGroup]="findDoctorForm" (ngSubmit)="onFindDoctor()">
          <div class="row justify-content-center">
            <div class="col-md-6 form-group">
              <p class="text-black font-weight-bold mb-1">{{"MOBILE_NUMBER" | translate}}</p>
              <div class="d-flex">
                <div class="w-100 position-relative coutryCodeDesign countryDiv__findDoctor"
                  *ngIf="this.defaultCountry">
                  <p-dropdown
                    class="d-flex align-items-center position-absolute h-100  pr-2 form-control disable-country"
                    [options]="[this.defaultCountry]" [optionValue]="'countryCode'">
                    <ng-template pTemplate="selectedItem">
                      <div class="country-item-value">
                        <div class="fnt-size12px fnt_Medium text-rolling-stone-grey mr-1">
                          <img [src]="this.defaultCountry.logo" class="flag mr-1"
                            onerror="this.src='assets/images/logo.svg'" alt="country flag" />
                          +{{this.defaultCountry.countryCode}}
                        </div>
                      </div>
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                      <div class="d-flex justify-content-between align-items-center p-2">
                        <div class="country-item-value d-flex">
                          <img [src]="item.logo" class="flag mr-2" onerror="this.src='assets/images/logo.svg'"
                            alt="country flag" />
                          <div class="fnt-size12px text-abbey-grey mr-2">
                            {{item.name}}
                          </div>
                          <div class="fnt-size12px text-abbey-grey">
                            +{{item.countryCode}}
                          </div>
                        </div>
                        <img src="assets/images/checkbox.svg" alt="green checked checkbox">
                      </div>
                    </ng-template>
                  </p-dropdown>
                </div>
                <div class="w-100">
                  <input type="number" formControlName="phoneNumber" (input)="phoneValidator($event,'phoneNumber')"
                    (focus)="resetError()" (paste)="onPaste($event,'phoneNumber')" maxlength="14"
                    class="form-control fnt-size14px" placeholder="{{'ENTER_MOBILE_NUMBER' | translate }} "
                    dngxDigitOnly />
                </div>
              </div>
              <div *ngIf="form.phoneNumber.touched && form.phoneNumber.invalid" class="text-danger fnt-size12px"> {{
                "DOCTOR_REG_PHONE_NUMBER_LIMIT" | translate }}</div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <p class="text-black font-weight-bold">{{"EMAIL_ID" | translate}}</p>
                <div class="position-relative">
                  <input formControlName="email" type="email" class="form-control fnt-size14px"
                    placeholder="{{'ENTER_EMAIL_ID' | translate}}" />
                </div>
                <div *ngIf="form?.email?.touched && form?.email?.invalid" class="text-danger fnt-size12px">
                  {{ "DOCTOR_REG_EMAIL_ERR" | translate }}
                </div>
              </div>
            </div>
            <div class="col-12 text-center">
              <button class="btn btn-success" type="submit"
                [disabled]="this.findDoctorForm.invalid || !(form?.phoneNumber?.value || form?.email?.value)">
                {{'SEARCH_DOCTOR' | translate}}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="notFound"
        class="bg-white border rounded box-shadow p-3 prescriptionFreezeDiv__details-no-data-heading mt-3">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="text-center">
              <img class="mb-3" src="assets/images/search.svg" alt="blank document" />
              <h4>{{'NO_RESULT' | translate}}</h4>
              <p>{{'ENTER_AGAIN' | translate}}.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
