import { createReducer, on } from '@ngrx/store';
import * as AttendantAction from '../../actions/attendent/attendent.action';


export interface State {
  attendant: any;
  otpRequestToken:any;
  otpverify:any;
  otpResend:any;
  attendantForm: any;
  error: any;
  attendantdcpm:any;
  attendantlist:any;
}
export const initialState: State = {
  attendant: null,
  otpRequestToken:null,
  otpverify:null,
  otpResend:null,
  attendantForm: null,
  error: null,
  attendantdcpm:null,
  attendantlist:null,
};
export const reducer = createReducer(
  initialState,
  on(AttendantAction.resetErrors, (state, payload) => ({
    ...state,
    error: initialState.error
  })),
  on(AttendantAction.setOtp, (state, payload) => ({
    ...state,
    otpRequestToken: payload.otpRequestToken
  })),
  on(AttendantAction.destroyToken, (state, payload) => ({
    ...state,
    otpRequestToken: initialState.otpRequestToken
  })),

  on(AttendantAction.setVerifyOTPData, (state, payload) => ({
    ...state,
    otpverify: payload.user_detail
  })),
  on(AttendantAction.resendOTP, (state, payload) => ({
    ...state,
    otpResend: payload.otpRequestToken
  })),

  on(AttendantAction.setErrors, (state, payload) => ({
    ...state,
    error: payload.error
  })),
  on(AttendantAction.submitFormSuccess, (state, payload) => ({
    ...state,
    attendantForm: payload.response
  })),
  on(AttendantAction.addAttendtSuccess, (state, payload) => ({
    ...state,
    attendantdcpm: payload.response
  })),
  on(AttendantAction.addAttendReset, (state, payload) => ({
    ...state,
    attendantdcpm: initialState.attendantdcpm
  })),
  on(AttendantAction.resetVerifyOtp, (state, payload) => ({
    ...state,
    otpverify: initialState.otpverify
  })),
  on(AttendantAction.searchSuccess, (state, payload) => ({
    ...state,
    attendantlist: payload.attendantList
  })),
  on(AttendantAction.attendantListReset, (state, payload) => ({
    ...state,
    attendantlist: initialState.attendantlist
  })),
 
);



