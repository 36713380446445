<div class="row no-gutters h-100 doctorRegistration" *ngIf="this.userId && this.doctorProfile">
  <div class="col-md-2 d-none d-md-block">
    <div class=" bg-white h-100 border-right">
      <div class="text-center p-3">
        <img src="assets/images/logo.svg" alt="Logo">
      </div>
      <nav class="vertical-nav bg-white pl-3 mt-3 pr-1" id="sidebar">
        <ul class="nav flex-column bg-white mb-0 leftNav h-100">
          <li class="nav-item mb-1">
            <a class="cursor-pointer nav-link text-dark bg-light fnt-size15px d-flex align-items-center w-100 rounded"
              (click)="navigateToDoctorHome()">
              <img src="assets/images/profile-logo.svg" alt="profile">
              <span>{{ "PROFILE" | translate }}</span>
            </a>
          </li>
          <li class="nav-item active mb-1">
            <a class="nav-link text-dark bg-light fnt-size15px d-flex align-items-center w-100 rounded">
              <img src="assets/images/profile-logo.svg" alt="profile">
              <span>{{ "REPORT_ADVERSE_EVENT" | translate }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-dark bg-light fnt-size15px d-flex align-items-center w-100 rounded"
              (click)="navigateToResources()">
              <img src="assets/images/resource-active.svg" alt="profile">
              <span>{{ "RESOURCES" | translate }}</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div class="col-md-10">
    <div class="bg-white p-3 border-bottom  text-right sticky-top header-view">
      <pap-doctor-header [doctorDetails]="element" [userProfile]="doctorProfile"></pap-doctor-header>
    </div>

    <div class="">

      <script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script>

      <iframe class="airtable-embed airtable-dynamic-height" [src]="iframeUrl | safe" frameborder="0" onmousewheel=""
        width="100%" height="2713"></iframe>

    </div>
  </div>
  <p-dialog [(visible)]="displayModal" [closeOnEscape]="false" styleClass="successful-edit" [closable]="false">
    <ng-template pTemplate="header">
      <img src="assets/images/success.svg" class="mx-auto mt-5" alt="success">
    </ng-template>
    <p class="mb-5 px-5  font-weight-bold text-center">{{"PROFILE_UPDATE" | translate}}</p>
  </p-dialog>
