/**
 * Ngrx store.
 */
import { createReducer, on } from '@ngrx/store';
import * as SignupAction from '../../actions/signup/signup.actions';

/**
 * Models imports.
 */
import { SignupChatbot, UpdateConsent, CountryList, RegionList, CityList, HospitalList } from './../../../models/sign-up-chatbot.model';

/**
 * This is used to store the signup chatbot detail.
 */
export const signupFeatureKey = 'signup';

export interface State {
  signupConsent: SignupChatbot;
  updateConsent: UpdateConsent;
  countryList: CountryList;
  hospitalList: any;
  usersList: any;
  regionsList: RegionList;
  citiesList: CityList;
  formSubmittedId: number;
  fileUploadUrl: string;
  fileUploadStatus: string;
  presignedUrl: string;
  error: Error;
  resendOtp: boolean;
}

export const initialState: State = {
  signupConsent: null,
  updateConsent: null,
  countryList: null,
  hospitalList: null,
  usersList: null,
  regionsList: null,
  citiesList: null,
  formSubmittedId: null,
  fileUploadUrl: null,
  fileUploadStatus: null,
  presignedUrl: null,
  error: null,
  resendOtp: false
};

export const reducer = createReducer(
  initialState,
  on(SignupAction.setSignupConsentData, (state, payload) => ({
    ...state,
    signupConsent: payload.signupConsent
  })),
  on(SignupAction.setUpdateConsentData, (state, payload) => ({
    ...state,
    updateConsent: payload.updateData
  })),
  on(SignupAction.setCountriesList, (state, payload) => ({
    ...state,
    countryList: payload.countryList
  })),
  on(SignupAction.setHospitalList, (state, payload) => ({
    ...state,
    hospitalList: payload.hospitalList
  })),
  on(SignupAction.setUsersList, (state, payload) => ({
    ...state,
    usersList: payload.usersList
  })),
  on(SignupAction.setRegionsList, (state, payload) => ({
    ...state,
    regionsList: payload.regionsList
  })),
  on(SignupAction.setCitiesList, (state, payload) => ({
    ...state,
    citiesList: payload.citiesList
  })),
  on(SignupAction.setFormSubmittedId, (state, payload) => ({
    ...state,
    formSubmittedId: payload.formSubmittedId
  })),
  on(SignupAction.setFileUploadUrl, (state, payload) => ({
    ...state,
    fileUploadUrl: payload.fileUploadUrl
  })),
  on(SignupAction.setFileUploadStatus, (state, payload) => ({
    ...state,
    fileUploadStatus: payload.fileUploadStatus
  })),
  on(SignupAction.resetFileUploadUrl, (state, payload) => ({
    ...state,
    fileUploadUrl: payload.fileUploadUrl
  })),
  on(SignupAction.resetFileUploadStatus, (state, payload) => ({
    ...state,
    fileUploadStatus: payload.fileUploadStatus
  })),
  on(SignupAction.resetformSubmittedId, (state, payload) => ({
    ...state,
    formSubmittedId: payload.formSubmittedId
  })),
  on(SignupAction.setFilePresignedUrl, (state, payload) => ({
    ...state,
    presignedUrl: payload.presignedUrl
  })),
  on(SignupAction.resetFilePresignedUrl, (state, payload) => ({
    ...state,
    presignedUrl: payload.presignedUrl
  })),
  on(SignupAction.setErrors, (state, payload) => ({
    ...state,
    error: payload.error
  })),
  on(SignupAction.resetCountryList, (state, payload) => ({
    ...state,
    countryList: initialState.countryList
  })),
  on(SignupAction.setRequestResendOtp, (state, payload) => ({
    ...state,
    resendOtp : payload.resendOtp
  }))
);
