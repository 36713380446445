/**
 * Angular Imports
 */
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';

/**
 * Rxjs import.
 */
import { Subscription } from 'rxjs';

/**
 * Ngrx store imports.
 */
import { Store } from '@ngrx/store';
import * as SignupSelector from '../../../store/selectors/signup/signup.selectors';
import * as SignupAction from '../../../store/actions/signup/signup.actions';
import * as HybridAction from '../../../store/actions/hybrid/hybrid.actions';
import * as HybridSelector from '../../../store/selectors/hybrid/hybrid.selectors';

/**
 * Router imports.
 */
import { ActivatedRoute } from '@angular/router';

/**
 * Models imports.
 */
import { SignupChatbot, Message, Country, CareGiverDeleteRequest } from '../../../models/sign-up-chatbot.model';

/**
 * Constants import.
 */
import { CHATBOT_CONSTANTS, BLOCK_TYPE, FIELD_TYPE, LOCAL_STORAGE_COUNTRY, HYBRID_STATES } from '../../../constant/app.constant';

/**
 * This component is used for user signup in chatbot.
 */
@Component({
  selector: 'pap-sign-up-chatbot',
  templateUrl: './sign-up-chatbot.component.html',
  styleUrls: ['./sign-up-chatbot.component.scss']
})
export class SignUpChatbotComponent implements OnInit, OnDestroy, AfterViewChecked {
  /**
   * Auto scroll.
   */
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  /**
   * Chatbot chat blocks.
   */
  public chatBlocks: SignupChatbot;

  /**
   * Variable for storing response
   */
  public responseData: SignupChatbot;

  /**
   * Read more flag.
   */
  public readMore = false;

  /**
   * Time Delay
   */
  public timerSub: any;

  /**
   * Patient token.
   */
  public token: string;

  /**
   * Reapplication status.
   */
  public reapplication: boolean;

  /**
   * Dr Shift status.
   */
  public drshift: boolean;

  /**
   * Reapplication count.
   */
  public reapplicationCount: string;

  /**
   * Api response data subscription.
   */
  public responseSub: Subscription;

  /**
   * Update consent response subscription.
   */
  public updateConsentSub: Subscription;

  /**
   * query parameters subscription.
   */
  public queryParametersSub: Subscription;

  /**
   * Countries subscription.
   */
  public countriesSub: Subscription;

  /**
   * Time for docquity messages.
   */
  public docquityTime: Date[] = [];

  /**
   * Time for user messages.
   */
  public userTime: Date[] = [];

  /**
   * Docquity message type.
   */
  public docquityMessage = CHATBOT_CONSTANTS.DOCQUITY_MESSAGE;

  /**
   * User message type.
   */
  public userMessage = CHATBOT_CONSTANTS.USER_MESSAGE;

  /**
   * This is used to hold countries data.
   */
  public countries: Country[];

  /**
   * This is used to show message for invalid token.
   */
  public invalidTokenMessage: string;

  /**
   * This is used to show message for something went wrong.
   */
  public somethingWentWrongMessage: string;

  /**
   * This is used to show which option is selected Yes or No.
   */
  public selectedOption: string;

  /**
   * This is used to stop looping of messages.
   */
  public actionMode = false;

  /**
   * This is used to stop duplicate edit messages.
   */
  public editMode = false;

  /**
   * Storing the block types and get it from constants file.
   */
  public blockType: object;

  /**
   * Error Subscription.
   */
  public chatbotErrorSub: Subscription;

  /**
   * Chatbot API error
   */
  public apiError: any;

  /**
   * Flag for new chatbot messages.
   */
  public newMessages = false;

  /**
   * This is used to show which doctor is selected.
   */
  public selectedDoctor: string;

  /**
   * This is used to show confirmation on doctor.
   */
  public selectedDoctorConfirmationOption: string;

  /**
   * Subscription for doctor update.
   */
  public formSubmittedIdSub: Subscription;

  /**
   * This is used to store id of selected doctor.s
   */
  public selectedDoctorId: number;
  /**
   * Deleted Caregiver Subscription.
   */
  public isDeletedCaregiverSub: Subscription;

  /**
   * Hold the doctor switch Message.
   */
  public doctorSwitchMessage: Message;
  public caregiverDeleteRequest: CareGiverDeleteRequest;
  public careGiverName: string;
  public isSubmissionOngoing = false;

  /**
   * Dispatch action for consent.
   */
  givePatientConsent(currentBlock: Message, index: number): void {
    if (!this.actionMode) {
      this.actionMode = !this.actionMode;
      this.store.dispatch(SignupAction.updateConsent({
        endPoint: currentBlock.apiEndPoints.submit,
        method: currentBlock.apiEndPoints.submitMethod,
        consent: true,
        formId: currentBlock.id
      }));
      /**
       * Update signup consent.
       */
      this.updateConsentSub = this.store.select(SignupSelector.updatedSignupConsent).subscribe(data => {
        if (data !== null) {
          this.chatBlocks.block[currentBlock.index - 1].submitText = data.submitText;
          this.chatBlocks.block[currentBlock.index - 1].submitted = true;
          const nextBlockIndex = currentBlock.index;
          this.insertNextBlocks(nextBlockIndex);
          this.actionMode = !this.actionMode;
        }
      });
    }
  }

  /**
   * Time update for particular message.
   */
  currentTime(index: number, type: string): boolean {
    if (type === this.docquityMessage && this.docquityTime[index] === undefined) {
      this.docquityTime[index] = new Date();
    } else if (type === this.userMessage && this.userTime[index] === undefined) {
      this.userTime[index] = new Date();
    }
    return true;
  }

  /**
   *  Add data to chatBlocks on submit event.
   */
  // TODO: sumit add data typing.
  submitPatientData(data): void {
    if (!this.actionMode) {
      this.actionMode = true;
      this.responseData.block[data.nextIndex - 1].attributes.forEach((ele) => {
        /**
         * Comparison of 0 is for country code.
         */
        if (data.formData[ele.fieldName] || data.formData[ele.fieldName] === 0 ) {
          if (ele.type === FIELD_TYPE.SEARCH) {
            /**
             * converting city and region object into string.
             */
            ele.value = JSON.stringify(data.formData[ele.fieldName]);
          } else {
            ele.value = data.formData[ele.fieldName];
          }
        }
      });
      this.chatBlocks.block[data.chatBlockIndex].submitText = CHATBOT_CONSTANTS.EDIT;
      this.chatBlocks.block[data.chatBlockIndex].submitted = true;
      this.insertNextBlocks(data.nextIndex);
    }
  }

  /**
   *  Add data to chatblocks on Edit event.
   */
  editPatientData(data): void {
    if (!this.actionMode && !this.editMode) {
      this.actionMode = true;
      this.insertEditBlock(data.nextIndex - 1);
    }
  }

  /**
   * Chatblocks initialization.
   */
  initialization(): void {
    this.chatBlocks = {
      block: [],
      chatBotHeading: '',
      images: {
        chatLogo: '',
        checkBox: ''
      },
      productLogo: ''
    };
    this.chatBlocks.chatBotHeading = this.responseData.chatBotHeading;
    this.chatBlocks.images = this.responseData.images;
    this.chatBlocks.productLogo = this.responseData.productLogo;
    this.insertNextBlocks(0);
  }

  /**
   * Insert next chatblocks using API response.
   */
  insertNextBlocks(index: number): void {
    while ( index < this.responseData.block.length
          &&
          (
            ( ( this.responseData.block[index].type === BLOCK_TYPE.FORM ||
                this.responseData.block[index].type === BLOCK_TYPE.CONSENT ||
                this.responseData.block[index].type === BLOCK_TYPE.OPTION ||
                this.responseData.block[index].type === BLOCK_TYPE.DR_SHIFT_OPTION ||
                this.responseData.block[index].type === BLOCK_TYPE.DR_SHIFT_CONFIRMATION
              )
              &&
              this.responseData.block[index].submitted
            )
            ||
             ( this.responseData.block[index].type === BLOCK_TYPE.INFO ||
               this.responseData.block[index].type === BLOCK_TYPE.REJECT_REASONS_MESSAGE ||
               this.responseData.block[index].type === BLOCK_TYPE.REJECT_APP_THANKYOU_MESSAGE
             )
          )
        )
    {
      const block: Message = JSON.parse(JSON.stringify(this.responseData.block[index]));
      if (this.responseData.block[index].formType === HYBRID_STATES.CARETAKER_INFO_FORM) {
        if (this.responseData.block[index].attributes.find(ab => ab.fieldName === 'email').value) {
          block.isCareGiverExist = true;
        }
      }
      this.chatBlocks.block.push(block);
      index++;
      this.newMessages = true;
    }
    if ( index < this.responseData.block.length
         &&
         ( this.responseData.block[index].type === BLOCK_TYPE.FORM ||
           this.responseData.block[index].type === BLOCK_TYPE.CONSENT ||
           this.responseData.block[index].type === BLOCK_TYPE.OPTION ||
           this.responseData.block[index].type === BLOCK_TYPE.DR_SHIFT_OPTION ||
           this.responseData.block[index].type === BLOCK_TYPE.DR_SHIFT_CONFIRMATION
         )
        ) {
          this.editMode = true;
          this.timerSub = setInterval(() => {
            clearInterval(this.timerSub);
            const block: Message = JSON.parse(JSON.stringify(this.responseData.block[index]));
            if (this.responseData.block[index].formType === HYBRID_STATES.CARETAKER_INFO_FORM) {
              if (this.responseData.block[index].attributes.find(ab => ab.fieldName === 'email').value) {
                block.isCareGiverExist = true;
              }
            }
            this.chatBlocks.block.push(block);
            this.actionMode = false;
            this.editMode = false;
            this.newMessages = true;
          }, 2000);
    } else {
      this.editMode = false;
      this.actionMode = false;
    }
  }

  /**
   * Insert form block to chatblocks.
   */
  insertEditBlock(index: number): void {
    if (index < this.responseData.block.length && !this.editMode) {
      this.store.dispatch(HybridAction.resetCaregiver({}));
      this.editMode = true;
      this.timerSub = setInterval(() => {
        clearInterval(this.timerSub);
        const chatBlockIndex = this.chatBlocks.block.length;
        this.chatBlocks.block.push(JSON.parse(JSON.stringify(this.responseData.block[index])));
        this.chatBlocks.block[chatBlockIndex].submitted = false;
        this.chatBlocks.block[chatBlockIndex].submitText = CHATBOT_CONSTANTS.SUBMIT;
        if (this.chatBlocks.block[chatBlockIndex].formType === HYBRID_STATES.CARETAKER_INFO_FORM) {
          this.chatBlocks.block[chatBlockIndex].isCareGiverExist = true;
        }
        this.actionMode = false;
        this.newMessages = true;
      }, 2000);
    }
  }

  /**
   * Function to handle whether to add caregiver details or not.
   */
  careGiverOption(responseDataindex: number, chatBlockindex: number, option: boolean): void {
    if (option) {
      this.selectedOption = CHATBOT_CONSTANTS.YES;
      this.chatBlocks.block[chatBlockindex].submitted = true;
      this.insertNextBlocks(responseDataindex + 1);
    } else {
      this.selectedOption = CHATBOT_CONSTANTS.NO;
      this.chatBlocks.block[chatBlockindex].submitted = true;
      this.chatBlocks.block.push(this.responseData.block[responseDataindex - 1]);
      this.newMessages = true;
    }
  }

  /**
   * Function to handle selected doctor.
   */
  drShiftOption(
    doctorSwitchMessage: Message, selectedDocId: number,
    responseDataindex: number, chatBlockindex: number, drName: string
    ): void {
    this.selectedDoctor = drName;
    this.selectedDoctorId = selectedDocId;
    this.chatBlocks.block[chatBlockindex].submitted = true;
    this.doctorSwitchMessage = doctorSwitchMessage;
    this.insertNextBlocks(responseDataindex + 1);
  }

  /**
   * Function to handle whether to confirm selected doctor or not.
   */
  doctorConfirmationOption(responseDataindex: number, chatBlockindex: number, option: boolean): void {
    if (option && this.selectedDoctorId) {
      let isDoctorShifted = false;
      this.selectedDoctorConfirmationOption = CHATBOT_CONSTANTS.YES;
      if (this.doctorSwitchMessage.currDoc.id === this.selectedDoctorId) {
        isDoctorShifted = true;
      } else {
        isDoctorShifted = false;
      }
      this.store.dispatch(SignupAction.updateDoctor({
        endPoint: this.chatBlocks.block[chatBlockindex].apiEndPoints.submit,
        method: this.chatBlocks.block[chatBlockindex].apiEndPoints.submitMethod,
        isShifted: isDoctorShifted,
        formId: this.chatBlocks.block[chatBlockindex].id,
        currDoc: this.doctorSwitchMessage.currDoc,
        oldDoc: this.doctorSwitchMessage.oldDoc,
        patient: this.doctorSwitchMessage.patient
      }));
      this.formSubmittedIdSub = this.store.select(SignupSelector.getSubmittedFormID).subscribe(data => {
        if (data !== null && data === this.chatBlocks.block[chatBlockindex].id) {
          this.chatBlocks.block[chatBlockindex].submitted = true;
          this.insertNextBlocks(responseDataindex + 1);
        }
      });
    } else {
      this.selectedDoctorConfirmationOption = CHATBOT_CONSTANTS.NO;
      this.chatBlocks.block[chatBlockindex].submitted = true;
      const docquitycareMessage = this.responseData.block.find(ele => ele.type === BLOCK_TYPE.DR_SHIFT_FAILURE);
      this.chatBlocks.block.push(docquitycareMessage);
    }
  }

  /**
   * Function for scrolling.
   */
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  /**
   * Required instances
   */
  constructor(private store: Store, private route: ActivatedRoute) { }

  /**
   * Initialise the data.
   */
  ngOnInit(): void {

     this.blockType = BLOCK_TYPE;
     /**
      * Get the token, country reapplication status from url.
      */
     this.queryParametersSub = this.route.queryParams.subscribe(params => {
      if (params.token) {
        this.token = params.token.replace(new RegExp('\\s', 'g'), '');
      }
      if (params.reapply) {
        const parameter = params.reapply.replace(new RegExp('\\s', 'g'), '');
        this.reapplication = (parameter === CHATBOT_CONSTANTS.TRUE) ? true : false;
      }
      if (params.count) {
        this.reapplicationCount = params.count.replace(new RegExp('\\s', 'g'), '');
      }
      if (params.country) {
        localStorage.setItem(LOCAL_STORAGE_COUNTRY.COUNTRY, params.country);
      }
      if (params.drshift) {
        const parameter = params.drshift.replace(new RegExp('\\s', 'g'), '');
        this.drshift = (parameter === CHATBOT_CONSTANTS.TRUE) ? true : false;
      }
    });
    /**
     * Scrolling to bottom of the screen.
     */
     this.scrollToBottom();
    /**
     * Action for loading countries.
     */
     this.store.dispatch(SignupAction.loadCountries({}));
    /**
     * Selector for countries.
     */
     this.countriesSub = this.store.select(SignupSelector.getCountryList).subscribe(data => {
      if (data !== null) {
        this.countries = data.list;
      }
    });
    /**
     * Get the data from api.
     */
     this.responseSub = this.store.select(SignupSelector.getSignupConsent).subscribe(data => {
      if (data !== null && this.countries) {
          this.responseData = JSON.parse(JSON.stringify(data));
          this.responseData.block.sort((a, b) => {
            return a.id > b.id ? 1 : -1;
          });
          let index = 1;
          this.responseData.block.forEach((block) => {
            if (this.drshift && block.type === BLOCK_TYPE.INFO) {
              block.type = BLOCK_TYPE.DR_SHIFT_FAILURE;
            }
            block.index = index;
            index++;
          });
          this.initialization();
      }
    });
    /**
     * Action for loading chatbot data.
     */
     this.store.dispatch(SignupAction.loadSignups({
      token: this.token, reapplication: this.reapplication, drshift: this.drshift
     }));
    /**
     * Selector for errors.
     */
     this.chatbotErrorSub = this.store.select(SignupSelector.getChatbotError).subscribe(data => {
      if (data !== null && data !== undefined) {
        if (data.name === CHATBOT_CONSTANTS.INVALID_TOKEN) {
          this.invalidTokenMessage = data.message;
        } else if (data.name === CHATBOT_CONSTANTS.SOMETHING_WENT_WRONG) {
          this.somethingWentWrongMessage = data.message;
        } else if (data.name === CHATBOT_CONSTANTS.DEFAULT_ERROR) {
          this.somethingWentWrongMessage = data.message;
        } else {
          this.somethingWentWrongMessage = data.message;
          this.apiError = data;
        }
      }
    });
  }

  /**
   * Scrolling to bottom when a new message is pushed in chatblocks.
   */
  ngAfterViewChecked(): void {
    if (this.newMessages) {
      this.scrollToBottom();
      this.newMessages = false;
    }
  }

  /**
   * Unsubscribe the subscription.
   */
  ngOnDestroy(): void {
    if (this.responseSub) {
      this.responseSub.unsubscribe();
    }
    if (this.updateConsentSub) {
      this.updateConsentSub.unsubscribe();
    }
    if (this.queryParametersSub) {
      this.queryParametersSub.unsubscribe();
    }
    if (this.countriesSub) {
      this.countriesSub.unsubscribe();
    }
    if (this.chatbotErrorSub) {
      this.chatbotErrorSub.unsubscribe();
    }
    if (this.formSubmittedIdSub) {
      this.formSubmittedIdSub.unsubscribe();
    }
    if (this.isDeletedCaregiverSub) {
      this.isDeletedCaregiverSub.unsubscribe();
    }
    this.store.dispatch(HybridAction.resetDeletedCaregiver({}));
  }
  deleteEpapCaregiverData(data): void {
    this.caregiverDeleteRequest = data.formData;
    this.careGiverName = data.careGiverName;
    const block: Message = {
      type: BLOCK_TYPE.DELETE_CAREGIVER_CONFIRMATION,
      formType: BLOCK_TYPE.DELETE_CAREGIVER_CONFIRMATION
    };
    block.submitted = false;
    this.chatBlocks.block.push(block);
    this.newMessages = true;
  }
  deleteEpapCareGiverConfirm(i: number, responeText: string, isYes: boolean): void {
    if (!this.isSubmissionOngoing) {
      this.isSubmissionOngoing = true;
      this.chatBlocks.block[i].submitted = true;
      this.chatBlocks.block[i].response = responeText;
      if (isYes) {
        this.store.dispatch(HybridAction.deleteCareGiver({token: this.token,
          careGiverRequest: this.caregiverDeleteRequest}));
        this.isDeletedCaregiverSub = this.store.select(HybridSelector.getIsDeletedCaregiver).subscribe(data => {
          if (data !== null && data === true) {
            window.location.reload();
          }
        });
      } else {
        window.location.reload();
      }
    }
  }
}
