<div id="contactUs" class="p-3">
  <div class="p-3 bg-white border rounded">
    <div class="mb-4 text-center">

      <img src="assets/images/contact-us-icon.svg" alt="contact us" />

      <p class="fnt-size16px medium-font-weight mb-1 text-333333">{{"CONTACT_US_HEADING" | translate}}</p>
      <p class="text-secondary fnt-size12px">({{"CONTACT_US_SUBHEADING" | translate}})</p>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="d-flex">
          <div class="mr-2">
            <img src="assets/images/phone-circle-icon.svg" alt="contact us" />
          </div>
          <div>
            <p class="medium-font-weight text-secondary fnt-size16px">{{contactConstant.PHONE_NO}}</p>
            <p class="medium-font-weight text-secondary fnt-size16px">{{contactConstant.PHONE_NO_1}}</p>
            <p class="medium-font-weight text-secondary fnt-size16px">{{contactConstant.PHONE_NO_2}}</p>
          </div>
        </div>
        <hr />
        <div class="d-flex align-items-center">
          <div class="mr-2">
            <img src="assets/images/email-circle-icon.svg" alt="contact us" />
          </div>
          <div>
            <p class="medium-font-weight text-secondary fnt-size16px">{{contactConstant.EMAIL_ID}}</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
