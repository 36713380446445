/**
 * angular imports
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
/**
 * ngrx store imports
 */
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as PharmaAction from '../../actions/pharma/pharma.actions';
import * as ErrorAction from '../../actions/error/error.actions';
/**
 * constant imports
 */
import { API } from '../../../constant/api.constant';
/**
 * model imports
 *
 */
import { PharmaResponse, UserMappedPharma, DoctorPharmaConsent, PharmaInfoResponse } from '../../../models/responses.model';
/**
 * rxjs imports
 */
import { mergeMap, map, catchError } from 'rxjs/operators';

@Injectable()
export class PharmaEffects {
  /**
   * Effect for loading programs.
   */
  public loadPharmas$ = createEffect(
    () => this.actions$
    .pipe(
      ofType(PharmaAction.loadPharmas),
      mergeMap((action) =>
        this.http.get<PharmaResponse>(
          API.PHARMA_DETAIL + action.pharmaCode + '/pap'
        ).pipe(
          map(res => {
            return PharmaAction.setPharmas({data: res.data});
          }),
          catchError(err => {
            return [PharmaAction.loadPharmasFailure({error: err.error.error})
          ];
          })
        )
      )
    )
);

  /**
   * Effect for loading pharma info.
   */
  public loadPharmaInfo$ = createEffect(
    () => this.actions$
    .pipe(
      ofType(PharmaAction.loadPharmaInfo),
      mergeMap((action) =>
        this.http.get<PharmaInfoResponse>(
          API.PHARMA_DETAIL + action.pharmaCode + '/info'
        ).pipe(
          map(res => {
            return PharmaAction.setPharmaInfo({data: res.data});
          }),
          catchError(err => {
            return [ErrorAction.catchError(err.error.error)];
          }))
        )
        ));
  /**
   * Effect for loading programs.
   */
  public getUserMappedPharmaList$ = createEffect(
    () => this.actions$
    .pipe(
      ofType(PharmaAction.getUserMappedPharmaList),
      mergeMap((action) =>
        this.http.get<UserMappedPharma>(
          API.USER_MAPED_PHARMA + action.userId
        ).pipe(
          map(res => {
            return PharmaAction.setUserMappedPharmaList({pharmaList: res.data.mappedTo});
          }),
          catchError(err => {
            return [PharmaAction.loadPharmasFailure({error: err.error.error})
          ];
          })
        )
      )
    )
  );
  /**
   * Effect for loading programs.
   */
  public getDoctorPharmaConsent$ = createEffect(
    () => this.actions$
    .pipe(
      ofType(PharmaAction.getDoctorPharmaConsent),
      mergeMap((action) =>
        this.http.get<DoctorPharmaConsent>(
          API.DOCTOR_CONSENT_PHARMA + action.pharmaCode + '/consent',
          {
            headers : {
              'login-auth': action.token
            },
          }
        ).pipe(
          map(res => {
            return PharmaAction.setDoctorPharmaConsent({consent: res});
          }),
          catchError(err => {
            return [PharmaAction.loadPharmasFailure({error: err.error})
          ];
          })
        )
      )
    )
);
  constructor(
    private actions$: Actions,
    private http: HttpClient,
  ) {}
}
