<div class="pharma pharmaView">
  <div class="bg-white sticky-top header">
    <div class="container h-100">
      <nav aria-labelledby="nav" class="navbar navbar-expand-md navbar-light position-sticky navbar-head h-100 px-0">
        <a class="navbar-brand">
          <img src="assets/images/docquity-only.svg" class="mt-1  px-1 d-none d-md-block" alt="Logo">
          <img src="assets/images/docquity-image.png" class="mt-1 px-1 d-md-none d-block" alt="Logo">
        </a>
        <a class="navbar-brand">
          <img src="assets/images/line-image.png" class="px-1" alt="Logo">
        </a>
        <a *ngIf="pharma" class="navbar-brand">
          <img src={{pharma?.logo}} class="mt-1 pharma-logo px-1" alt="Logo">
        </a>
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li class="nav-item">
            </li>
          </ul>
          <div class="form-inline" [hidden]="papProgram?.length === 0">
            <span class="cursor-pointer fnt-size16px mr-lg-4" (click)="scroll(program, false)">{{"PROGRAMS" |
              translate}}</span>
          </div>
          <div class="form-inline" [hidden]="pharma?.termsAndConditions?.length === 0">
            <span (click)="redirectToPharmaPrivacy()" class="ml-2 cursor-pointer fnt-size16px mr-lg-4">{{"TNC" |
              translate}}</span>
          </div>
          <div class="form-inline" [hidden]="!pharma?.resources?.length">
            <span (click)="scroll(resourcesDiv, false)" class="ml-2 cursor-pointer fnt-size16px mr-lg-4">{{"RESOURCES" | translate}}</span>
          </div>
        </div>
        <div class="d-flex ml-auto">
          <div class="form-inline my-1">
            <button label="Primary" class="signup-doctor custom-btn btn btn-dark ml-0 ml-md-2"
              (click)="redirectDoctor('signIn')">{{"SIGN_DOCTOR" | translate}}</button>
          </div>
          <div class="form-inline my-2 ml-2">
            <img src="assets/images/hamburger.png" class="mt-1 d-block d-md-none" (click)="displaynav()" alt="hamburger"/>
          </div>
        </div>
        <div class="ml-auto d-md-flex align-items-center d-none">
          <img src="assets/images/global.png" class="global mr-1" alt="Logo">
          <dngx-language-selector [languages]="languages"></dngx-language-selector>
        </div>
      </nav>
    </div>
  </div>
  <p-sidebar [(visible)]="display" [styleClass]="'navbar-brand bg-white'" [baseZIndex]="10000">
    <nav aria-labelledby="nav" class="navbar-dark  bg-white navbar-expand-md p-md-2">
      <ul class="list-group">
        <li class="list-group-item pl-2 d-flex">
          <img src="assets/images/docquity-only.svg" class="docquity-logo mt-2 px-1 ml-0 hide-image" alt="Logo">
          <img src="assets/images/line-image.png" class="mt-2 " alt="Logo">
          <img src={{pharma?.logo}} class="mt-2  pharma-logo" alt="Logo">
        </li>
        <li class="list-group-item pl-2" [hidden]="papProgram?.length === 0">
          <span class="cursor-pointer fnt-size15px" (click)="scroll(program, true)">{{"PROGRAMS" |
            translate}}</span>
        </li>
        <li class="list-group-item pl-2" [hidden]="pharma?.termsAndConditions?.length === 0">
          <span class="cursor-pointer fnt-size15px" (click)="redirectToPharmaPrivacy()">{{"TNC" | translate}}</span>
        </li>
        <li class="list-group-item pl-2 pt-2">
          <div>
            <button label="Primary" class="w-100 btn custom-btn  btn-dark ml-0 ml-md-2 mt-2 pl-2"
              (click)="redirectDoctor('signIn')">{{"SIGN_DOCTOR" |
              translate}}</button>
          </div>
          <div>
            <button label="Primary" (click)="signUpPatient()"
              class="w-100 mt-3 btn patient-button btn-white ml-0 ml-md-2 border-dark pl-2">{{"SIGN_PAT" |
              translate}}</button>
          </div>
        </li>

      </ul>
    </nav>
    <div class="mt-5 pr-3 cursor-pointer d-flex justify-content-end align-items-center position-relative">
      <img src="assets/images/global.png" class="global mr-1" alt="Logo">
      <dngx-language-selector class="mt-auto" [languages]="languages"></dngx-language-selector>
    </div>
  </p-sidebar>
  <div class="background-pharma w-100 mx-0 p-3 text-center text-md-right">
    <div class="container">
      <div class="row">
        <div class="col-md-8 order-md-0 order-1 m-md-auto mt-4 program-font text-md-left text-center fnt_medium">
          <div *ngIf="pharma?.name">
            <p class="fnt-size32px font-lexend text-1B4A52">{{"WEL_TO" | translate}}</p>
            <div class="fnt-size40px font-lexend">
              <span class="text-danger medium-font-weight">{{pharma?.name}} </span>
              <span class="text-danger medium-font-weight">{{"PAP_FULL" | translate}}</span>
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-3 mt-md-5 p-md-4">
          <img src="assets/images/pharma-pap-view.png">
        </div>
      </div>
    </div>
  </div>
  <div class="chatbot newSlideAnimation">

    <div class="contents cursor-pointer" (click)="startSession()">
      <span class="newImage">
        <img src="assets/images/docquity-icon-white.svg" height="20px" width="20px" alt="Docquity" />
      </span>
      <div class="newClass">
        <div class="inner fnt-size12px">
          {{"PATIENT_REGISTRATION" | translate}}
        </div>
      </div>
    </div>

    <div class="py-3 pl-3 p-2 custom-btn position-fixed tooltiptext">
      <span>{{"HEY_REGISTER_PROGRAM" | translate}}</span>
    </div>
  </div>
  <div class="w-100 mx-0 p-3 hcp-bg" [hidden]="!pharma?.resources?.length" #resourcesDiv>
    <div class="container hcp-resources">
      <p class="fnt-size24px fnt_Medium color-grey-650">
        {{'HCP_RESOURCES' | translate}}
      </p>
      <div *ngIf="pharma?.resources?.length">
        <p-carousel [value]="pharma?.resources" [numVisible]="3" [verticalViewPortHeight]="200" [numScroll]="3" var="index" [circular]="false" [responsiveOptions]="responsiveOptions">
          <ng-template let-item let-i="index" pTemplate="item">
            <div class="hcp-resources-card cursor-pointer" (click)="redirectDoctor('resource')">
              <img class="card-banner" [src]="resources[item?.thumbnailName] ? resources[item?.thumbnailName]?.source : 'assets/images/default-banner.svg'" alt="thumbnailName"
                onerror="this.src = 'assets/images/default-banner.svg'">
              <img *ngIf="resources[item?.thumbnailName]?.type === 'video'" class="video-play" src="assets/images/video-play.svg" alt="video">
              <p class="color-grey-650 fnt-size18px card-heading">
                {{item.title}}
              </p>
            </div>
          </ng-template>
        </p-carousel>
      </div>
    </div>
  </div>
  <div [ngClass]="{'w-100 mx-0 p-3 privacyBg': papProgram?.length  > 0,
        'd-none': papProgram?.length === 0 }">
    <div class="mx-md-auto mb-5 fnt-size20px">
      <div class="mb-2 d-flex pl-md-5" #program>
        <div class="font-weight-bold fnt-size20px m-auto program-font pt-3 pt-md-5 font-lexend"
          *ngIf="papProgram?.length >0">
          <span class="text-danger fnt_Medium">{{pharma?.name}} </span>
          <span class="text-dark fnt_Medium">{{"PROGRAMS" | translate}}</span>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="papProgram?.length >0">
      <div *ngFor="let program of papProgram; let index = index"
        class="bg-white d-flex flex-md-row flex-column pap-data text-md-left text-center w-100 mb-5 p-3">
        <div class="program-logo mx-auto mx-md-0 w-100">
          <img src={{program.logo}} alt="program-logo" class="w-100 h-100 rounded-12" alt="program logo" />
        </div>
        <div class="pt-3 pl-3 d-flex flex-column">
          <p class="text-dark font-weight-bold program-font text-444242 font-lexend line-height-35">{{program?.name}}
          </p>
          <p class="mt-3 text-444242 address-text programInfo" *ngIf="!program.isReadMore">{{program?.info |
            slice:0:this.readMoreLimit}}
            <span (click)="readMore(index, true)" class="cursor-pointer"
              *ngIf="program.info.length > this.readMoreLimit - 1">...{{ "READ_MORE"
              | translate }}</span>
          </p>
          <p class="mt-3 address-text programInfo" *ngIf="program.isReadMore">{{program?.info}}
            <span class="cursor-pointer" (click)="readMore(index, false)">...{{ "READ_LESS" | translate }}</span>
          </p>
          <p class="mt-2">
            <a (click)="navigateToPharmaStore(program.code)" [routerLinkActive]="'active'"
              class="cursor-pointer fnt_Medium fnt-size16px align-items-center mt-auto">
              <u class="text-primary">{{ "VIEW_PHARMACY_STORE" | translate }}</u>
            </a>
          </p>

        </div>
      </div>
    </div>
  </div>
  <div class="bg-white">
    <div class="container">
      <div class="d-md-flex background-pharma px-3 pt-3 bg-white">
        <div class="col-md-6 m-auto text-md-left text-center px-md-0 pt-2">
          <p class="fnt-size20px text-danger fnt_Bold pt-md-0 pt-3 mb-3 fnt-size56 font-lexend">{{"DOC_CARE" |
            translate}}
          </p>
          <span class="pharma-width fnt-size18px medium-font-weight">{{"DOC_PAP_SERVICE" | translate}}</span>
        </div>
        <div class="col-md-6 mt-md-5 p-auto pl-md-5 text-md-left text-center pr-md-0">
          <img src="assets/images/docquity-pap-access.png" class="float-md-right">
        </div>
      </div>
    </div>
  </div>
  <div class="docquity-contact d-md-flex px-md-5">
    <div class="py-5 col-md-12 pl-md-0 text-center">
      <p class="text-444242 font-weight-regular">{{"DOC_QUERIES" | translate}}</p>
      <p class="text-444242 font-weight-bold fnt-size16px">{{contactUs.PHONE_NO}}, {{contactUs.PHONE_NO_1}}, {{contactUs.PHONE_NO_2}} {{ 'OR' | translate }}</p>
      <span class="font-weight-bold fnt-size16px"><a class="text-primary"
          href="mailto: {{contactUs.EMAIL_ID}}">{{contactUs.EMAIL_ID}}</a></span>
      <p class="text-737171 fnt-size14px">{{"8_AM_TO_5_PM" | translate}}</p>
    </div>
    <div class="col-md-6 text-md-right px-2 py-4 w-md-50 pr-md-0 d-none">
      <button label="Primary" class="custom-btn btn-dark  px-md-4 ml-auto
            py-2 w-100 send-query">{{"SEND_QUERY" | translate}}</button>
    </div>
  </div>
  <div class="p-2 bg-white">
    <div class="py-2 bg-black text-center rounded-16">
      <div class="p-4 mt-2 mb-2">
        <img src="assets/images/docquity-logo.png" alt="docquity-logo">
        <p class="text-white mt-3 font-weight-normal">{{"RIGHTS_RESERVED" | translate}}</p>
      </div>
    </div>
  </div>

  <p-dialog [(visible)]="showPopUp" [style]="'p-2'" [modal]="true" [closeOnEscape]="true" [closable]="true"
    [baseZIndex]="1005">
    <ng-template pTemplate="header">
      <img src="assets/images/leave-chatbot.png" class="mx-auto" alt="leave-chatbot">
    </ng-template>
    <p class="my-2 text-center font-weight-bold fnt-size20px">{{"CONFIRMATION_SURE" | translate}}?</p>
    <p class="mb-2 text-center  font-weight-bold fnt-size20px">{{"MISS_BENIFITS" | translate}}</p>
    <p class="mb-2 text-center  text-abbey-grey">{{"CLOSE_SESSION" | translate}}</p>
    <div class="text-center mb-3 d-flex p-3 justify-content-center">
      <button class="btn btn-border  dialog-button  py-2 mr-2" (click)="resetChatbotSession()">
        {{"YES_LEAVE" | translate}}
      </button>
      <button class="btn dialog-button  py-2 btn-success" (click)="showPopUp = false;">
        {{"NO_COMPLETE" | translate}}
      </button>
    </div>
  </p-dialog>
</div>
<div [ngClass]="{'chatbot__mWeb': isMobile, 'chatbot': !isMobile}">
  <p-sidebar [(visible)]="displayChatbot" [dismissible]="false" [styleClass]="'border-2'" position="right"
    [baseZIndex]="1000" [blockScroll]="true" [showCloseIcon]="false" [closeOnEscape]="false">
    <nav aria-labelledby="chatBot" class="navbar navbar-light bg-danger">
      <div>
        <img src="assets/images/docquity-icon.svg">
        <span class="ml-2 text-white">{{"DOC_CARE" | translate}}</span>
      </div>
      <div>
        <img src="assets/images/cross-white-icon.svg" class="cursor-pointer" (click)="showpopmessage()">
      </div>
    </nav>
    <ng-template pTemplate>
      <div *ngIf="!showChatBot" class="bg-white px-4 pt-4 fnt-size16px h-100 registerPage">
        <div>
          <span class="fnt_Bold">{{"WEL_TO" | translate}} </span>
          <span class="text-danger fnt_Bold">{{"DOC_CARE" | translate}}</span>
          <p class="mt-2 text-rolling-stone-grey">{{"PAP_BENIFITS" | translate}}</p>
        </div>
        <div class="text-center">
          <img src="assets/images/chatbot-switch.png">
        </div>
        <p class="mb-2">{{"ENROLL_CONFIRM" | translate}}</p>
        <span class="text-rolling-stone-grey">{{"CLICK_REGISTER" | translate}}</span>
        <button label="Primary" class="custom-btn btn btn-dark register  w-100 mt-4"
          (click)="showChatBotPanel()">{{"REG_PROG" | translate}}</button>
      </div>
      <div *ngIf="showChatBot" class="h-100 hyridComponent">
        <pap-hybrid [chatView]="true" [pharmaCode]="pharmaCode" [process]="pharma?.process"></pap-hybrid>
      </div>
    </ng-template>
  </p-sidebar>
</div>
