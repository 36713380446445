import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {CalendarModule} from 'primeng/calendar';

import { DateFormComponent } from './components/date-form/date-form.component';


@NgModule({
  declarations: [DateFormComponent],
  imports: [
    CommonModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [DateFormComponent]
})
export class CalendarFormModule { }
