import { createAction, props } from '@ngrx/store';
/**
 * Model import.
 */
import { CaregiverList, PostPatientMigrationStoreOperator } from '../../models/store-operator/store-operator.model';
import { ErrorHandler, PatientMigrationStoreOperatorResponse, RequestOtpResponse } from 'projects/nga-PAP/src/app/models/responses.model';
import { DrugDisburseReq, DrugDataList, OrderDetailData } from './../../models/store-operator/orderDetail.model';
import { InvoiceList } from './../../../../../../models/responses.model';

/**
 * Load Care Giver data.
 */
export const loadCareGiverData = createAction(
  'Home | API [Store Home] loading Care Giver Data List',
  props<{ storeNo: string, offset: string, limit: string, prescriptionId: string }>()
);

/**
 * Set Care Giver data.
 */
export const setCareGiverData = createAction(
  'Home|API [ Store Home] set Care Giver Data List',
  props<{ CareGiverList: CaregiverList }>()
);
/**
 * Set care giver error data in store.
 */
export const setCareGiverDataError = createAction(
  'ERROR|CATCH [Store Home] Care Giver error handler for effects',
  props<{ error: ErrorHandler }>()
);
/**
 * Reset care giver error data in store.
 */
export const resetCareGiverDataError = createAction(
  'ERROR|CATCH [Store Home] Reset Care Giver error handler'
);
/**
 * Reset care giver list store.
 */
export const resetCareGiverList = createAction(
  'RESET|GET [Store Home] Reset Care Giver List'
);
/**
 * Set care giver id in store.
 */
export const careGiverRequestOtp = createAction(
  'CAREGIVER | API [Store Home] Care Giver Id',
  props<{ careGiverId: number, id: string, userType: string, patientId: number }>()
);

export const setCareGiverRequestOtp = createAction(
  'CAREGIVER | SET [Store Home] Care Giver Id',
  props<{ careGiverData: RequestOtpResponse }>()
);
/**
 * Action used to confirm otp.
 */
export const postConfirmOtp = createAction(
  'CAREGIVER|API [Store Home] Care Giver Confirm Otp',
  props<{ otp: number, careGiverId: number, prescriptionId: string, patientId: number }>()
);

/**
 * Action for get prescription detail of given prescription id.
 */
export const getPrescriptionDetail = createAction(
  'CAREGIVER|API [Store Prescription] Prescription detail',
  props<{ prescriptionId: string, verifyOtpToken: string }>()
);

/**
 * Action for set prescription detail in store.
 */
export const setPrescriptionDetail = createAction(
  'CAREGIVER|SET [Store Prescription] set Prescription detail',
  props<{ prescriptionDetail: OrderDetailData }>()
);

/**
 * Action for calculate drug dispence.
 */

export const calculateDrugDisburse = createAction(
  'ORDER DETAIL|GET [Store Drug] get Drug disburse list',
  props<{ prescriptionId: string, reqData: DrugDisburseReq, verifyOtpToken: string, id: number, userType: string }>()
);

/**
 * Action for set disburse drug list data in store.
 */
export const setDisburseDrugList = createAction(
  'ORDER DETAIL|SET [Store disburse drug list] set disburse list data',
  props<{ disburseDrugList: DrugDataList }>()
);
/**
 * Action for cancel the order.
 */
export const cancelOrder = createAction(
  'CAREGIVER [Store Prescription] cancel order',
  props<{
    cartId: string,
    prescriptionId: string,
    id: number,
    userType: string,
    verifyOtpToken: string,
    purchaserId: number}>()
);
/**
 * Set dispense order cancel status in store.
 */
export const setDispenseOrderCancelStatus = createAction(
  'CAREGIVER [Store Cancel order Status] cancel order',
  props<{ DispenseOrderCancelStatus: boolean }>()
);

/**
 * Action for API request on drug despense.
 */
export const despenseDrug = createAction(
  'ORDER DESPENSE|API [Drug despense] despense drug',
  props<{
    country?: string;
    prescriptionId: string,
    cartId: string,
    verifyOtpToken: string,
    id: string,
    userType: string,
    purchaserId: number,
    paymentMethod: string,
    invoiceAmount: string,
    invoiceNumber: string,
    psId: number
    hospitalId?: number;
    orderProof?: string;
    actualPurchaseDate?: string;
  }>()
);

/**
 * Action to Set drug order disburse status.
 */
export const setDrugDisburseStatus = createAction(
  'ORDER DISBURSE|SET [Drug disburse status] set drug disburse status',
  props<{ drugOrderDisbursedStatus: boolean }>()
);

/**
 * Reset dispense order cancel status in store.
 */
export const resetDispenseOrderCancelStatus = createAction(
  'CAREGIVER [Store Cancel order Status] cancel order',
  props<{ DispenseOrderCancelStatus: false }>()
);

export const setVerifyOtpToken = createAction(
  'CAREGIVER SET| verify otp token',
  props<{ verifyOtpToken: string }>()
);

/**
 * Set care giver id in store.
 */
export const patientMigrationDetail = createAction(
  'STORE OPERATOR | API [Patient Migration] Patient Migration Detail',
  props<{ prescriptionId: string, patientId: string }>()
);
/**
 * Reset care giver list store.
 */
export const resetPatientMigrationDetail = createAction(
  'RESET STORE OPERATOR | RESET [Patient Migration] Patient Migration Detail'
);
/**
 * Action for set patient migration detail in store.
 */
export const setPatientMigrationDetail = createAction(
  'STORE OPERATOR | SET [Patient Migration] Patient Migration Detail',
  props<{ patientMigrationDetail: PatientMigrationStoreOperatorResponse }>()
);

/**
 * Action for post patient migration.
 */
export const postPatientMigrationStoreOperator = createAction(
  'STORE OPERATOR | Post [Patient Migration] Patient Migration Details',
  props<{ data: PostPatientMigrationStoreOperator }>()
);

/**
 * Action for saving migration success.
 */
export const setMigrationSuccess = createAction(
  'STORE OPERATOR | SET [Patient Migration] Set migration success',
  props<{}>()
);

/**
 * Action for reset migration success.
 */
export const resetMigrationSuccess = createAction(
  'STORE OPERATOR | Reset [Patient Migration] Reset migration success',
  props<{}>()
);

/**
 * Action for fetching invoice list.
 */
export const getInvoiceList = createAction(
  'STORE OPERATOR | API Get invoice list',
  props<{prescriptionId: string, verifyOtpToken: string}>()
);

/**
 * Action for set invoice list.
 */
export const setInvoiceList = createAction(
  'STORE OPERATOR | SET invoice list',
  props<{invoiceList: InvoiceList}>()
);
/**
 * Action for set invoice list.
 */
export const resetInvoiceList = createAction(
  'STORE OPERATOR | RESET invoice list'
);
