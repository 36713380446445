/**
 * Angular imports.
 */
import { Component, OnDestroy} from '@angular/core';

@Component({
  selector: 'pap-find-doctor',
  templateUrl: './find-doctor.component.html',
  styleUrls: ['./find-doctor.component.scss']
})
export class FindDoctorComponent implements OnDestroy {

  constructor() {
    this.clearFindDoctorAuth();
  }

  clearFindDoctorAuth(): void {
    if (localStorage.getItem('findDoctorAuth')) {
      localStorage.removeItem('findDoctorAuth');
    }
  }

  ngOnDestroy(): void {
    this.clearFindDoctorAuth();
  }
}
