/**
 * Angular imports.
 */
import { Injectable, OnDestroy } from '@angular/core';
/**
 * Ngrx store.
 */
import { Store } from '@ngrx/store';
import * as FileAction from '../../src/app/store/actions/file/file.action';
import * as FileSelector from '../../src/app/store/selectors/file/file.selectors';
/**
 * Constant imports.
 */
import { API } from 'projects/nga-PAP/src/app/constant/api.constant';
/**
 * Rxjs imports.
 */
import { Subscription } from 'rxjs';
import { IMAGE_TYPES, MIME_TYPES } from './constant/app.constant';

@Injectable({
  providedIn: 'root'
})
export class FileService implements OnDestroy {
  /**
   * Subscription for file upload time.
   */
  public fileUploadTimeSub: Subscription;
  /**
   * Subscription for presigned url.
   */
  public presignedUrlSub: Subscription;
  /**
   * Subscription for internal presigned url.
   */
  public presignedUrlInSub: Subscription;

  /**
   * Helper function for uploading file.
   * @params file - File for uploading.
   * @params cloudFolderPath - path of folder i.e logo, prescription, idcard.
   * @params fileName - name of the file without extension.
   */
  async uploadFile(file: File, cloudFolderPath: string, fileName: string,
                   module: string, country: string): Promise<string> {
    const putPresignedUrl = await this.fetchPresignedUrl(cloudFolderPath, fileName, module, country, true, file.type);
    await this.uploadFileToCloud(file, putPresignedUrl);
    const getPresignedUrl = await this.fetchPresignedUrl(cloudFolderPath, fileName, module, country, false, file.type);
    return getPresignedUrl;
  }

  /**
   * @param cloudFolderPath path of the you want to upload. eg prescription, id-card.
   * we don't have doctor cloudFolderPath for doctor document and doctor signature. So you need to
   * send 'null' in that case.
   * @param fileName name of the file.
   * @param module module of file. eg. pap, na
   * @param country country for upload. eg. philippines, india
   * @param isPut method for presigned url. for file view isPut=false, for uploading file isPut=true
   * @param contentType content type of file. eg. image/png
   * @param time upload file or view file time in milliseconds
   * @returns Promise with presigned url.
   */
  fetchPresignedUrl(cloudFolderPath: string, fileName: string, module: string,
                    country: string, isPut: boolean, contentType: string): Promise<string> {
    const time = new Date().getMilliseconds();
    return new Promise((resolve, reject) => {
      this.store.dispatch(FileAction.loadFilePresignedUrl({
        endPoint: API.FILE,
        fileType: cloudFolderPath,
        fileName,
        contentType,
        fileMethod: isPut ? 'true' : 'false',
        module,
        country,
        time
      }));
      this.presignedUrlSub = this.store.select(FileSelector.getFilePresignedUrl).subscribe(url => {
        if (url !== null) {
          this.store.dispatch(FileAction.resetFilePresignedUrl({
            presignedUrl: null
          }));
          this.presignedUrlInSub = this.store.select(FileSelector.getFilePresignedReqTime).subscribe(data => {
            if (data !== null && data === time) {
              resolve(url);
            }
          });
        }
      });
    });
  }

  /**
   * Uploading file to cloud from presigned url.
   */
  uploadFileToCloud(file: File, presignedUrl: string): Promise<number> {
    const uploadReqTime = new Date().getMilliseconds();
    return new Promise((resolve, reject) => {
      this.store.dispatch(FileAction.fileUpload({
        endPoint: presignedUrl,
        file,
        uploadReqTime
      }));
      this.fileUploadTimeSub = this.store.select(FileSelector.getFileUploadingTime).subscribe(data => {
        if (data !== null && data === uploadReqTime) {
          resolve(data);
        }
      });
    });
  }
  getContentType(extension: string): string {
    let contentType = '';
    switch (extension.toLowerCase()) {
      case IMAGE_TYPES.jpeg:
        contentType = MIME_TYPES.JPEG;
        break;
      case IMAGE_TYPES.jpg:
        contentType = MIME_TYPES.JPG;
        break;
      case IMAGE_TYPES.png:
        contentType = MIME_TYPES.PNG;
        break;
    }
    return contentType;
  }

  ngOnDestroy(): void {
    if (this.fileUploadTimeSub) {
      this.fileUploadTimeSub.unsubscribe();
    }
    if (this.presignedUrlSub) {
      this.presignedUrlSub.unsubscribe();
    }
    if (this.presignedUrlInSub) {
      this.presignedUrlInSub.unsubscribe();
    }
  }

  constructor(
    private store: Store
  ) { }
}
