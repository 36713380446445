import { Component, OnInit } from '@angular/core';
import { SignInCountry } from '../../../models/signin.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Country } from '../../../models/sign-up-chatbot.model';
import * as SignupAction from '../../../store/actions/signup/signup.actions';
import * as SignupSelector from '../../../store/selectors/signup/signup.selectors';
import { Store } from '@ngrx/store';
import * as attendantAction from '../../../store/actions/attendent/attendent.action';
import { HelperService } from '../../../helper.service';
import * as attendant from '../../../store/selectors/attendent/attendent.selector'
@Component({
  selector: 'pap-attendent-register',
  templateUrl: './attendent-register.component.html',
  styleUrls: ['./attendent-register.component.scss']
})
export class AttendentRegisterComponent implements OnInit {
  countriesSub: Subscription;
  public countries: Country[];
  public defaultCountry: Country;
  userRole: string;
  phoneNumber:string;
  storedPhoneNumber: string;
  pharma_id : number = 0;
  public authToken:string;
  userDetail: any;
  userRegister: any;


  registrationForm: FormGroup;
  constructor(private fb: FormBuilder, private store: Store ,public helperService: HelperService

  ) {
    const storedPhoneNumber = localStorage.getItem('phoneNumber');
    this.phoneNumber = storedPhoneNumber 
    this.userDetail = this.helperService.userDetail;
    this.userRegister = this.helperService.userRegister;
    console.log("userdetail",this.userDetail)
    console.log("userReg",this.userRegister)
    const pharmaDetails = JSON.parse(localStorage.getItem('pharma'));
    
    this.registrationForm = this.fb.group({
      countryCode: ['65',this.defaultCountry],
      phoneNumber: [this.phoneNumber, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(8)]],
      email: [this.userDetail.email, [Validators.required, Validators.email]],
      first_name: [this.userDetail.first_name, [Validators.required, Validators.minLength(2)]],
      last_name: [this.userDetail.last_name, [Validators.required, Validators.minLength(2)]],
      // pharma_id:[pharmaDetails?.id]
    });
    console.log("rog",this.registrationForm)
  }

  getCountryInfo(): void {
    switch (localStorage.getItem('country')) {
      case 'SG':
        this.defaultCountry = {
          code: localStorage.getItem('country'),
          countryCode: 65,
          id: 5,
          logo: 'assets/images/singapore-signup-icon.png\n',
          name: 'singapore'
        };
        break;
      default:
        break;
    }
  }


  ngOnInit(): void {
    this.store.dispatch(SignupAction.loadCountries({}));
    this.countriesSub = this.store.select(SignupSelector.getCountryList).subscribe(data => {
      if (data !== null) {
        this.countries = data.list;
      }
    });
    this.getCountryInfo();
    this.pharma_id = Number.parseInt((JSON.parse(localStorage.getItem('pharma')) as any).id);
    this.authToken = localStorage.getItem('attendantAuthToken');

    this.store.select(attendant.addAttendantDCPM).subscribe(data => {
      if (data) {
        console.log("hello")
      }
    })
  }  

  onSubmit(): void {
    console.log(this.registrationForm);
    if (this.registrationForm.valid) {
      this.store.dispatch(attendantAction.submitForm({
        first_name: this.registrationForm.get('first_name')?.value as string,
        last_name:  this.registrationForm.get('last_name')?.value as string, 
        email: this.registrationForm.get('email')?.value as string,
        pharma_id:this.pharma_id as number,
        otpRequestToken:this.authToken as string,
      }));
      console.log(this.registrationForm);
    }
    const pharmaDetails = JSON.parse(localStorage.getItem('pharma'));
  }

}


