/**
 * Store imports.
 */
import { createAction, props } from '@ngrx/store';

/**
 * Models imports.
 */
import { SignupChatbot, UpdateConsent, CountryList, RegionList, CityList, IdName, HospitalList } from '../../../models/sign-up-chatbot.model';

/**
 * This is used to get signupChatbot API data using token.
 */
export const loadSignups = createAction(
  '[Signup] Load Signups consent',
  props<{ token: string, reapplication: boolean, drshift: boolean}>()
);

/**
 * This is used to get countries.
 */
export const loadCountries = createAction(
  '[Signup] Load Countries',
  props<{}>()
);

/**
 * This is used to get hospital.
 */
export const loadHospitals = createAction(
  '[Signup] Load Hospitals',
  props<{token: string}>()
);

/**
 * This is used to set the countries data.
 */
export const setCountriesList = createAction(
  'Countries|SET [Signup] Set countries list',
  props<{ countryList: CountryList }>()
);

/**
 * This is used to set the hospital data.
 */
export const setHospitalList = createAction(
  'Hospitals|SET hospital list',
  props<{ hospitalList: any[] }>()
);

/**
 * This is used to set the user data based on hospital.
 */
export const setUsersList = createAction(
  'USERS|SET hospital users list',
  props<{ usersList: HospitalList }>()
);

/**
 * This is used to get the user based on hospital.
 */
export const getUserForHospital = createAction(
  'USERS|SET [HOSPITAL] user list from hospital',
  props<{hospitalId: string}>()
);

/**
 * This is used to load regions.
 */
export const loadRegions = createAction(
  '[Signup] Load Regions',
  props<{ keywords: string, endPoint: string, method: string }>()
);

/**
 * This is used to set the regions data.
 */
export const setRegionsList = createAction(
  'Regions|SET [Signup] Set regions list',
  props<{ regionsList: RegionList }>()
);

/**
 * This is used to load regions.
 */
export const loadCities = createAction(
  '[Signup] Load Cities',
  props<{ keywords: string, endPoint: string, method: string }>()
);

/**
 * This is used to set the regions data.
 */
export const setCitiesList = createAction(
  'Regions|SET [Signup] Set cities list',
  props<{ citiesList: CityList }>()
);

/**
 * This is used to set the signUp consent data.
 */
export const setSignupConsentData = createAction(
  'SignupConsent|SET [SignUp consent] Set signup consent data',
  props<{ signupConsent: SignupChatbot }>()
);


/**
 * This is used to set the signUp error messages.
 */
export const setErrors = createAction(
  'ErrorSignUp|SET [SignUp] Catch error of sign api',
  props<{ error: Error }>()
);

/**
 * This is used to update consent for signUp chatbot.
 */
export const updateConsent = createAction(
  'Consent|API [SignUp] Update the existing consent form',
  props< {endPoint: string; method: string; consent: boolean; formId: number } >()
);

/**
 * This is used to set the updated consent data
 */
export const setUpdateConsentData = createAction(
  'Consent|SET [SignUp] Set updated consent data',
  props<{updateData: UpdateConsent}>()
);

/**
 * This is used to submit form data.
 */
// TODO: sumit add data typing.
export const submitFormData = createAction(
  'Form|API [SignUp] Submit form data',
  props<{endPoint: string, method: string, formData: any, applicationType: string, idToken: string}>()
);

/**
 * This is used to set submitted form id.
 */
export const setFormSubmittedId = createAction(
  'Form|SET [SignUp] Set form submitted id',
  props<{ formSubmittedId: number}>()
);

/**
 * This is used to get presigned file upload url.
 */
export const loadFileUploadUrl = createAction(
  'FILE|API [SignUp] Load url',
  props<{ endPoint: string,
     method: string, fileType: string, fileName: string, contentType: string, module?: string, country?: string}>()
);

/**
 * Set file upload url.
 */
export const setFileUploadUrl = createAction(
  'FILE|SET [SignUp] Set fileUploadUrl',
  props<{ fileUploadUrl: any}>()
);

/**
 * This is used to reset file upload url.
 */
export const resetFileUploadUrl = createAction(
  'FILE|SET [SignUp] reset url',
  props<{ fileUploadUrl: any }>()
);

/**
 * Upload File.
 */
export const fileUpload = createAction(
  'FILE|API [SignUp] Upload file',
  props<{ endPoint: string, file: File }>()
);

/**
 * Set stored file url.
 */
export const setFileUploadStatus = createAction(
  'FILE|SET [SignUp] Set file upload status',
  props<{ fileUploadStatus: string }>()
);

/**
 * Reset stored file url.
 */
export const resetFileUploadStatus = createAction(
  'FILE|SET [SignUp] reset file upload status',
  props<{ fileUploadStatus: string }>()
);

/**
 * Reset Form Submitted Id.
 */
export const resetformSubmittedId = createAction(
  'FORM|SET [SignUp] reset form submitted id',
  props<{ formSubmittedId: number }>()
);


/**
 * Load presigned URL.
 */
export const loadFilePresignedUrl = createAction(
  'FILE|API [SignUp] Load presigned url',
  props<{ endPoint: string }>()
);

/**
 * Set presigned URL.
 */

export const setFilePresignedUrl = createAction(
  'FILE|SET [SignUp] set file presigned url',
  props<{ presignedUrl: string }>()
);

/**
 * This is used to reset file presigned url.
 */
export const resetFilePresignedUrl = createAction(
  'FILE|SET [SignUp] reset presigned url',
  props<{ presignedUrl: string }>()
);

/**
 * This is used to update doctor for patient.
 */
export const updateDoctor = createAction(
  'FORM|API [SignUp] Update the doctor for patient',
  props< {
    endPoint: string; method: string; isShifted: boolean;
    formId: number, currDoc: IdName, oldDoc: IdName, patient: IdName;
  } >()
);
/**
 * This is used to reset country list.
 */
export const resetCountryList = createAction(
  'Countries|RESET [Signup] Set countries list'
);
/**
 * This is used to resend the Otp for patient verification.
 */
export const setRequestResendOtp = createAction(
  'FORM|RESEND [Signup] Resend Otp Request',
  props<{ resendOtp: boolean }>()
);
