import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {AutoCompleteModule} from 'primeng/autocomplete';

import { AutoCompleteFormComponent } from './components/auto-complete-form/auto-complete-form.component';

@NgModule({
  declarations: [AutoCompleteFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    AutoCompleteModule,
    ReactiveFormsModule
  ],
  exports: [AutoCompleteFormComponent]
})
export class AutoCompleteFormModule { }
