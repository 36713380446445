import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../reducers/index';
import * as fromDoctor from '../../reducers/doctor/doctor.reducer';

export const doctor = (state: fromRoot.State) => state.doctor;
/**
 * Selector for get program data.
 */
export const getPapProgram = createSelector(
  doctor,
  (state: fromDoctor.State) => state.papProgramList
);
/**
 * Selector for get program data.
 */
export const getSpecialty = createSelector(
  doctor,
  (state: fromDoctor.State) => state.specialityList
);
/**
 * Selector for get doctor submit response.
 */
export const getDoctorSubmitSuccess = createSelector(
  doctor,
  (state: fromDoctor.State) => state.isDoctorCreated
);
/**
 * Selector for get doctor submit response.
 */
export const getRequestOTPToken = createSelector(
  doctor,
  (state: fromDoctor.State) => state.otpRequestToken
);
/**
 * Selector for get doctor captcha token.
 */
export const getHcaptchaToken = createSelector(
  doctor,
  (state: fromDoctor.State) => state.hcaptchaToken
);
/**
 * Selector for get doctor login input.
 */
export const getLoginInput = createSelector(
  doctor,
  (state: fromDoctor.State) => state.loginInput
);
/**
 * Selector for api error.
 */
export const getError = createSelector(
  doctor,
  (state: fromDoctor.State) => state.error
);
/**
 * Selector for fetching doctor detail from store.
 */
export const getDoctorData = createSelector(
  doctor,
  (state: fromDoctor.State) => state.doctorData
);
/**
 * Selector for edit success response.
 */
export const doctorEditSuccess = createSelector(
  doctor,
  (state: fromDoctor.State) => state.DoctorEdit
);
/**
 * Selector for fetching education list.
 */
export const getEducation = createSelector(
  doctor,
  (state: fromDoctor.State) => state.educationList
);
/**
 * Selector for fetching hospital affiliation list.
 */
export const getHospitalAffiliation = createSelector(
  doctor,
  (state: fromDoctor.State) => state.hospitalAffiliationList
);
/**
 * Selector for api error.
 */
export const getDoctorFindError = createSelector(
  doctor,
  (state: fromDoctor.State) => state.doctorFindError
);
