import { createSelector } from '@ngrx/store';
import * as fromRoot from '../../reducers/index';
import * as fromError from '../../reducers/error/error.reducer';

export const error = (state: fromRoot.State) => state.error;


/**
 * Selector for user credential.
 */
export const getOnlineStatus = createSelector(
  error,
  (state: fromError.State) => state.internetConnectionStatus
);
