import { createReducer, on } from '@ngrx/store';
import * as HomeAction from '../actions/home.actions';

/**
 * Model imports.
 */
import { AddDrug, DrugSelectedData, PatientDetailData, PrescriptionClass, PrescriptionData, RemarksDataList } from '../../models/prescription-detail';
import {
  AeReportListResponse,
  ConsultedDoctor,
  DrugSearchList,
  ErrorHandler,
  MrnSearchListData,
  OtpData,
  PatientListResponse,
  PatientPrescriptionResponse,
  UserDetailListResponse
} from '../../../../../../models/responses.model';


export const homeFeatureKey = 'home';

export interface State {
  HomeList: [];
  priscriptionDetail: PrescriptionData;
  otpData: OtpData;
  drugSearchList: DrugSearchList;
  error: ErrorHandler;
  addedDrugData: DrugSelectedData[];
  mrnSearchData: MrnSearchListData[];
  // TODO: rahul add data typing.
  PatientMigrationList: any;
  PatientEnabled: boolean;
  MrnError: ErrorHandler;
  RemarksData: RemarksDataList;
  consultedDoctors: ConsultedDoctor[];
  PatientList: PatientListResponse;
  AeReportList: AeReportListResponse;
  patientDetails: PatientDetailData;
  patientStatus: string | null;
  patientPrescription: PatientPrescriptionResponse;
  programId: string;
  documentId: string;
  notifyStatus: boolean;
  notifyStatusCta: boolean;
  deliveryDate: string;
  orderNumber: string;
  aeFormList: any;
  aeSubmitRes: any;
  ackSubmitRes: any;
  patientDetail: any;
  process: string;
  aeReport: any;
  usersList: any;
  pharmaProgramList: any;
  pharmaDetailList: any;
}

export const initialState: State = {
  HomeList: null,
  priscriptionDetail: null,
  otpData: null,
  drugSearchList: null,
  error: null,
  addedDrugData: null,
  mrnSearchData: null,
  PatientMigrationList: null,
  PatientEnabled: false,
  MrnError: null,
  RemarksData: null,
  consultedDoctors: null,
  PatientList: null,
  patientDetails: null,
  patientStatus: null,
  patientPrescription: null,
  programId: null,
  documentId: null,
  notifyStatus: null,
  notifyStatusCta: null,
  deliveryDate: null,
  orderNumber: null,
  aeFormList: null,
  aeSubmitRes: null,
  ackSubmitRes: null,
  patientDetail: null,
  process: null,
  AeReportList: null,
  aeReport: null,
  usersList: null,
  pharmaProgramList: null,
  pharmaDetailList: null
};


export const reducer = createReducer(
  initialState,
  on(HomeAction.setHomeListData, (state, payload) => ({
    ...state,
    HomeList: payload.HomeList
  })),
  on(HomeAction.setHomeError, (state, payload) => ({
    ...state,
    error: payload.error
  })),
  on(HomeAction.reSetHomeError, (state, payload) => ({
    ...state,
    error: initialState.error
  })),
  on(HomeAction.setPriscriptionDetail, (state, payload) => ({
    ...state,
    priscriptionDetail: payload.detail
  })),

  on(HomeAction.reSetPriscriptionDetail, (state, payload) => ({
    ...state,
    priscriptionDetail: initialState.priscriptionDetail
  })),

  on(HomeAction.setSmsDashboard, (state, payload) => ({
    ...state,
    otpData: payload.data
  })),

  on(HomeAction.setDrugList, (state, payload) => ({
    ...state,
    drugSearchList: payload.data
  })),

  on(HomeAction.resetDrugList, (state, payload) => ({
    ...state,
    drugSearchList: initialState.drugSearchList
  })),

  on(HomeAction.resetGetSmsDashboard, (state, payload) => ({
    ...state,
    otpData: initialState.otpData
  })),

  on(HomeAction.setSelectedDrug, (state, payload) => ({
    ...state,
    selectedDrug: payload.data
  })),

  on(HomeAction.addPrescriptionDrug, (state, payload) => ({
    ...state,
    addedDrugData: payload.data
  })),
  on(HomeAction.resetAddPrescriptionDrug, (state, payload) => ({
    ...state,
    addedDrugData: initialState.addedDrugData
  })),
  on(HomeAction.setMrnSearchData, (state, payload) => ({
    ...state,
    mrnSearchData: payload.data
  })),
  on(HomeAction.resetMrnSearchData, (state, payload) => ({
    ...state,
    mrnSearchData: initialState.mrnSearchData
  })),
  on(HomeAction.setMrnError, (state, payload) => ({
    ...state,
    MrnError: payload.error
  })),
  on(HomeAction.reSetHomeError, (state, payload) => ({
    ...state,
    MrnError: initialState.MrnError
  })),
  on(HomeAction.setPatientMigrationList, (state, payload) => ({
    ...state,
   PatientMigrationList: payload.MigrationList
  })),
  on(HomeAction.setEnableSuccess, (state, payload) => ({
    ...state,
    PatientEnabled: payload.EnableSucess
  })),
  on(HomeAction.setRemarksData, (state, payload) => ({
    ...state,
    RemarksData: payload.data
  })),
  on(HomeAction.resetRemarksData, (state, payload) => ({
    ...state,
    RemarksData: initialState.RemarksData
  })),
  on(HomeAction.setConsultedDoctors, (state, payload) => ({
    ...state,
    consultedDoctors: payload.data
  })),
  on(HomeAction.resetConsultedDoctors, (state, payload) => ({
    ...state,
    consultedDoctors: initialState.consultedDoctors
  })),
  on(HomeAction.setPatientListData, (state, payload) => ({
    ...state,
    PatientList: payload.PatientList
  })),
  on(HomeAction.setPatientDetails, (state, payload) => ({
    ...state,
    patientDetails: payload.patientDetails
  })),
  on(HomeAction.setPatientStatus, (state, payload) => ({
    ...state,
    patientStatus: payload.patientStatus
  })),
  on(HomeAction.setPatientPrescription, (state, payload) => ({
    ...state,
    patientPrescription: payload.list
  })),
  on(HomeAction.resetPatientPrescription, (state) => ({
    ...state,
    patientPrescription: initialState.patientPrescription
  })),
  on(HomeAction.setCreateOrderId, (state, payload) => ({
    ...state,
    programId: payload.programId
  })),
  on(HomeAction.setMedicalDocumentId, (state, payload) => ({
    ...state,
    documentId: payload.documentId
  })),
  on(HomeAction.resetProgramId, (state) => ({
    ...state,
    programId: initialState.programId
  })),
  on(HomeAction.resetDocumentId, (state) => ({
    ...state,
    documentId: initialState.documentId
  })),
  on(HomeAction.resetPatientDetailData, (state, payload) => ({
    ...state,
    patientDetails: initialState.patientDetails
  })),
  on(HomeAction.resetPatientListData, (state, payload) => ({
    ...state,
    PatientList: initialState.PatientList
  })),
  on(HomeAction.resetNotifyStatus, (state) => ({
    ...state,
    notifyStatus: initialState.notifyStatus
  })),
  on(HomeAction.setNotifyStatus, (state, payload) => ({
    ...state,
    notifyStatus: payload.notifyStatus
  })),
  on(HomeAction.resetNotifyStatusCta, (state) => ({
    ...state,
    notifyStatusCta: initialState.notifyStatusCta
  })),
  on(HomeAction.setNotifyStatusCta, (state, payload) => ({
    ...state,
    notifyStatusCta: payload.notifyStatusCta
  })),
  on(HomeAction.resetDeliveryDate, (state) => ({
    ...state,
    deliveryDate: initialState.deliveryDate
  })),
  on(HomeAction.resetOrderNumber, (state) => ({
    ...state,
    orderNumber: initialState.orderNumber
  })),
  on(HomeAction.setOrderNo, (state, payload) => ({
    ...state,
    orderNumber: payload.orderNumber
  })),
  on(HomeAction.setDeliveryDate, (state, payload) => ({
    ...state,
    deliveryDate: payload.deliveryDate
  })),
  on(HomeAction.setAeFormList, (state, payload) => ({
    ...state,
    aeFormList: payload.aeFormList
  })),
  on(HomeAction.setAeSubmitRes, (state, payload) => ({
    ...state,
    aeSubmitRes: payload.res
  })),
  on(HomeAction.resetAeSubmitRes, (state, payload) => ({
    ...state,
    aeSubmitRes: initialState.aeSubmitRes
  })),
  on(HomeAction.setAckSubmitRes, (state, payload) => ({
    ...state,
    ackSubmitRes: payload.res
  })),
  on(HomeAction.resetAckSubmitRes, (state, payload) => ({
    ...state,
    ackSubmitRes: initialState.aeSubmitRes
  })),

  on(HomeAction.resetAeFormList, (state, payload) => ({
    ...state,
    aeFormList: initialState.aeFormList
  })),
  on(HomeAction.setSearchedPatientDetail, (state, payload) => ({
    ...state,
    patientDetail: payload.patientDetail
  })),
  on(HomeAction.resetSearchedPatientDetail, (state, payload) => ({
    ...state,
    patientDetail: initialState.patientDetail
  })),
  on(HomeAction.setPharmaPap, (state, payload) => ({
    ...state,
    process: payload.process
  })),
  on(HomeAction.resetPharmaPap, (state, payload) => ({
    ...state,
    process: initialState.process
  })),
  on(HomeAction.setAeReportListData, (state, payload) => ({
    ...state,
    AeReportList: payload.AeReportList
  })),
  on(HomeAction.resetAeReportListData, (state, payload) => ({
    ...state,
    AeReportList: initialState.AeReportList
  })),
  on(HomeAction.setAeReport, (state, payload) => ({
    ...state,
    aeReport: payload.aeReport
  })),
  on(HomeAction.resetAeReport, (state, payload) => ({
    ...state,
    aeReport: initialState.aeReport
  })),
  on(HomeAction.setUsersList, (state, payload) => ({
    ...state,
    usersList: payload.usersList
  })),
  on(HomeAction.resetUsersList, (state, payload) => ({
    ...state,
    usersList: initialState.usersList
  })),
  on(HomeAction.setPharmaProgramList, (state, payload) => ({
    ...state,
    pharmaProgramList: payload.pharmaProgramList
  })),
  on(HomeAction.resetPharmaProgramList, (state, payload) => ({
    ...state,
    pharmaProgramList: initialState.pharmaProgramList
  })),
  on(HomeAction.setPharmaDetails, (state, payload) => ({
    ...state,
    pharmaDetailList: payload.pharmaDetailList
  })),
  on(HomeAction.resetPharmaDetailsList, (state, payload) => ({
    ...state,
    pharmaDetailList: initialState.pharmaDetailList
  })),
);

