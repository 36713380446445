/**
 * Angular imports.
 */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TH_SERVIER_PROCESS, TH_SPR_PROCESS } from 'projects/nga-PAP/src/app/constant/app.constant';

/**
 * Used to show the form for pediatric report.
 */
@Component({
  selector: 'pap-concomitant-medication-info-form',
  templateUrl: './concomitant-medication-info-form.component.html',
  styleUrls: ['./concomitant-medication-info-form.component.scss']
})
export class ConcomitantMedicationInfoFormComponent implements OnInit, OnChanges {
  @Input() functionality = '';
  @Input() formData = null;
  @Input() process: string;
  /**
   * Form pediatric report.
   */
  public concomitantMedInfoForm: FormGroup;
  private disabled: boolean;
  /**
   * setter for the form controls.
   */
  get form(): { [key: string]: AbstractControl } { return this.concomitantMedInfoForm.controls; }
  get formValidity(): boolean { return this.concomitantMedInfoForm.valid; }
  get formValues(): { concomitantMedInfoForm: any; } {
    return {
      concomitantMedInfoForm: this.concomitantMedInfoForm.value
    };
  }

  /**
   *
   */
  drugStrengthUnitList = [
    { id: 1, name: 'mg' },
    { id: 2, name: 'ug' },
    { id: 3, name: 'g' },
    { id: 4, name: 'mg/mL' },
    { id: 5, name: 'IU/mL' },
    { id: 6, name: '%' }
  ];
  administrationRouteList = [
    { id: 1, name: 'Oral' },
    { id: 2, name: 'Intravenous, IV' },
    { id: 3, name: 'Intramuscular, IM' },
    { id: 4, name: 'Subcutaneous, SC' },
    { id: 5, name: 'Intraocular' },
    { id: 6, name: 'NA' }
  ];

  /**
   * Necessary instances.
   */
  constructor(private fb: FormBuilder) { }

  /**
   * Calling initialize form.
   */
  ngOnInit(): void {
    this.disabled = this.functionality === 'view';
    this.initializeForm();
  }

  /**
   * to detect changes on incoming form values
   * @param changes changes on formData
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formData) {
      this.initializeForm();
    }
  }

  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.concomitantMedInfoForm = this.fb.group({

      drugName: new FormControl(
        {value : this.formData?.drugName ? this.formData?.drugName : '', disabled: this.disabled},
        [Validators.maxLength(100)]),
      drugStrength: new FormControl(
        {value : this.formData?.drugStrength ? this.formData?.drugStrength : '', disabled: this.disabled}),
      drugStrengthUnit: new FormControl(
        {value : this.formData?.drugStrengthUnit ? this.formData?.drugStrengthUnit : 'mg',
       disabled: this.disabled}),
      administrationRoute: new FormControl(
        {value : this.formData?.administrationRoute ? this.formData?.administrationRoute : '', disabled: this.disabled},
        Validators.maxLength(100)),
      dosage: new FormControl(
        {value : this.formData?.dosage ? this.formData?.dosage : '', disabled: this.disabled},
        [Validators.maxLength(100)]),
      frequency: new FormControl(
        {value : this.formData?.frequency ? this.formData?.frequency : '', disabled: this.disabled},
        Validators.maxLength(20)),
      treatmentStartDate: new FormControl(
        {value : this.formData?.treatmentStartDate ? this.formData?.treatmentStartDate : '', disabled: this.disabled},
        Validators.maxLength(50)),
      treatmentEndDate: new FormControl(
        {value : this.formData?.treatmentEndDate ? this.formData?.treatmentEndDate : '', disabled: this.disabled}),
      indicationForUse: new FormControl(
        {value : this.formData?.indicationForUse ? this.formData?.indicationForUse : '', disabled: this.disabled},
        Validators.maxLength(50)),
      batchNoAndExpiryDate: new FormControl(
        {value : this.formData?.batchNoAndExpiryDate ? this.formData?.batchNoAndExpiryDate : '',
         disabled: this.disabled},
        Validators.maxLength(50)),
      formulation: new FormControl(
        {value : this.formData?.formulation ? this.formData?.formulation : '', disabled: this.disabled},
        Validators.maxLength(50))
    });
    if (this.checkForServierProcess())
      {
        this.concomitantMedInfoForm.removeControl('formulation');
        this.concomitantMedInfoForm.removeControl('frequency');
      }
  }

  /**
   * Check for a particular process
   */
  checkForProcess(): boolean {
    return this.process === TH_SPR_PROCESS;
  }
  /**
   * Check if process is for servier chatbot flow
   */
  checkForServierProcess(): boolean {
    return this.process === TH_SERVIER_PROCESS;
  }
}
