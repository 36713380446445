/**
 * Angular imports.
 */
import { Component, Input } from '@angular/core';

/**
 * This component is used to show the image galary in full view.
 */
@Component({
  selector: 'dngx-full-view-galaria',
  templateUrl: './full-view-galaria.component.html',
  styleUrls: ['./full-view-galaria.component.css']
})
export class FullViewGalariaComponent {
// TODO:Pavan: data typing for image.
  /**
   * Receive the image array.
   */
  @Input() images: any;

  /**
   * Receive flag for circular rotation of image gallary.
   */
  @Input() circular: boolean;

  /**
   * Receive flag for fullscreen image.
   */
  @Input() fullScreen: boolean;

  /**
   * Receive flag for left and right navigator of image gallary.
   */
  @Input() showItemNavigators: boolean;

  /**
   * Flag for show image thumbnail.
   */
  @Input() showThumbnails = false;

  /**
   * Receive custome class.
   */
  @Input() maskClass: string;

  /**
   * Receive flag for show zoom icon.
   */
  @Input() showZoomIcon: boolean;

  /**
   * Receive zoom icon image path.
   */
  @Input() zoomIcon: string;

  /**
   * Flag for set zoom as false.
   */
  public zoomIdcard: boolean;

  /**
   * This method is used to zoom the image.
   */
  imageClick(index: number): void {
    this.zoomIdcard = !this.zoomIdcard;
  }
}
