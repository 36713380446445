import { COUNTRIES_LIST } from './../../../constant/app.constant';

/**
 * Angular imports.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
/**
 * Model imports.
 */
import { SigninResponse } from '../../../models/responses.model';
import { SignInCountry } from '../../../models/signin.model';
/**
 * Environment import.
 */
import { environment } from '../../../../environments/environment';
/**
 * Rxjs import.
 */
import { window } from 'rxjs/operators';
import { Subscription } from 'rxjs';

/**
 * Constant import.
 */
import { LANGUAGES } from '../../../constant/app.constant';

/*
 * Service Imports.
 */
import { TranslateService } from '@ngx-translate/core';

/**
 * Ngrx store
 */
import { Store } from '@ngrx/store';
import * as LoginAction from '../../../store/actions/login/login.actions';

/**
 * This component is used for user signin.
 */
export interface LoginData {
  email: string;
  password: string;
  keepSignedIn: boolean;
}
@Component({
  selector: 'pap-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  /**
   * Var used for country data.
   */
  public countries: SignInCountry[];
  /**
   * Var used show for selected country value.
   */
  public selectedCountry = '';
  /**
   * Var used to get hostname.
   */
  public domainName = location.origin;
  /**
   * Login Data emit.
   */
  @Output() loginData = new EventEmitter();

  /**
   * Get logo src.
   */
  @Input() logoSrc: string;

  /**
   * Get login Api error.
   */
  @Input() apiError: SigninResponse;

  /**
   * Contain login formGroup.
   */
  public loginForm: FormGroup;

  /**
   * Hold flag for submit form
   */
  public submitted = false;

  /**
   * Get support language.
   */
  public languages = LANGUAGES;

  /**
   * Email formControl for input validation.
   */
  get email(): AbstractControl { return this.loginForm.get('email'); }

  /**
   * Password formControl for input validation.
   */
  get password(): AbstractControl { return this.loginForm.get('password'); }

  /**
   * Used to validate the reactive form.
   */
  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      keepSignedIn: [false]
    });

    const params = new URLSearchParams(location.search);
    if (
      params.get('country') === COUNTRIES_LIST.PHILLIPPINS.value ||
      params.get('country') === COUNTRIES_LIST.THAILAND.value ||
      params.get('country') === COUNTRIES_LIST.SINGAPORE.value) {
      this.selectedCountry = params.get('country');
    }

    if (this.selectedCountry !== null && this.selectedCountry) {
      localStorage.setItem('country', this.selectedCountry);
    } else {
      this.selectedCountry = localStorage.getItem('country');
    }
  }

  /**
   * Check enter values are correct and send the values outside.
   */
  onSubmit(): void {
    this.submitted = true;

    /**
     * stop here if form is invalid.
     */
    if (this.loginForm.invalid) {
      return;
    }

    this.loginData.emit(this.loginForm.value);
  }
  /**
   * Get selected country id.
   */
  setSelectedCountry(event): void {
    this.selectedCountry = event;
    const errorElement = document.getElementById('countryError');
    errorElement.classList.remove('d-none');
    if (event === COUNTRIES_LIST.PHILLIPPINS.value
      || event === COUNTRIES_LIST.THAILAND.value
      || event === COUNTRIES_LIST.SINGAPORE.value) {
      localStorage.setItem('country', this.selectedCountry);
    } else {
      localStorage.setItem('country', COUNTRIES_LIST.PHILLIPPINS.value);
    }
  }
  /**
   * Redirect to reset Page
   */
  resetPassword(): void {
    const errorElement = document.getElementById('countryError');
    if (this.selectedCountry !== null && this.selectedCountry !== '' && this.selectedCountry !== 'undefined') {
      this.store.dispatch(LoginAction.resetLoginStore());
      this.router.navigate(['/resetPassword']);
    }
    else {
      errorElement.innerHTML = '';
      errorElement.classList.remove('d-none');
      errorElement.innerHTML += this.translate.instant('PLEASE_SELECT_COUNTRY');
    }
  }
  /**
   * Required instances.
   */
  constructor(
    private formBuilder: FormBuilder, private router: Router,
    public translate: TranslateService, private store: Store) {
    this.countries = [
      COUNTRIES_LIST.PHILLIPPINS,
      COUNTRIES_LIST.THAILAND,
      COUNTRIES_LIST.SINGAPORE,
    ];
  }


}
