<div>
  <h5 class="fnt-size18px text-heading-grey mb-3">{{'FURTHER_INFORMATION' | translate}}</h5>
  <form [formGroup]="furtherInfoForm">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            <span>
              {{"MEDICAL_HISTORY_CONCURRENT_PRE_EXISTING_CONDITION" | translate}}
              <span class="d-none text-danger">*</span>
            </span>
            <span class="fnt-size12px">
              {{!form.medicalHistory.value.length ? 0 :
              form.medicalHistory.value.length}}/500
            </span>
          </label>
          <div class="position-relative">
            <textarea formControlName="medicalHistory" type="text" class="form-control fnt-size14px"
              placeholder="{{'MEDICAL_HISTORY_CONCURRENT_PRE_EXISTING_CONDITION_PLACEHOLDER' | translate}}"
              [maxlength]="500">
            </textarea>
          </div>
          <div *ngIf="form.medicalHistory.touched && form.medicalHistory.invalid"
            class="d-none text-danger fnt-size12px">
            {{"MEDICAL_HISTORY_CONCURRENT_PRE_EXISTING_CONDITION" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <div class="form-group hospital-search">
            <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
              {{'POSSIBLE_RISK_FACTORS' | translate}}
              <span class="text-danger d-none">*</span>
            </label>
            <p-autoComplete class="w-100" (keyup)="userInput('possibleRiskFactor', $event)" [dropdown]="true"
              [inputStyleClass]="'form-control fnt-size14px w-100'" [suggestions]="filterPossibleRiskFactorList" [multiple]="true"
              [styleClass]="form.possibleRiskFactor.touched && form.possibleRiskFactor.invalid ? 'pcomponent-invalid w-100 bg-e9ecef' : 'w-100 bg-e9ecef'"
              placeholder="{{'SELECT_RISK_FACTORS' | translate}}" [minLength]="1" (completeMethod)="filterPossibleRiskFactor($event)" [autoHighlight]="true"
              formControlName="possibleRiskFactor" field="name" [completeOnFocus]="true">
              <ng-template let-possibleRiskFactor pTemplate="item">
                <div [innerHTML]="possibleRiskFactor.name | highlight: toHighlight"></div>
              </ng-template>
            </p-autoComplete>

          <div *ngIf="(form.possibleRiskFactor.touched) && form.possibleRiskFactor.invalid"
            class="text-danger fnt-size10px d-none">
            {{'POSSIBLE_RISK_FACTORS' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="fnt-size12px">
            {{ 'IS_FEMALE_PATIENT_PREGNANT' | translate }}
          </label>
          <div class="custom-checkbox">
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="pregYes" value="Yes"
                formControlName="isPatientPregnant">
              <label class="radio-button-text cursor-pointer" for="pregYes">{{ 'YES' | translate }}</label>
            </span>
            <span class="mr-3">
              <input type="radio" class="radio-green p-2 mr-2" id="pregNo" value="No"
                formControlName="isPatientPregnant">
              <label class="radio-button-text cursor-pointer" for="pregNo">{{ 'NO' | translate }}</label>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group mb-2 position-relative">
          <label class="fnt-size12px">
            {{'LAST_MENSTRUAL_PERIOD' | translate}}
            <span class="text-danger d-none">*</span>
          </label>
          <div class="calendar-field w-100">
            <p-calendar [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [touchUI]="false"
              yearRange="1922:2030" [inline]="false" panelStyleClass="bg-white border"
              [styleClass]="form?.lastMenstrualPeriod?.touched && form?.lastMenstrualPeriod?.invalid ? 'pcomponent-invalid w-100' : 'w-100'"
              [inputStyleClass]="'form-control fnt-size12px fnt_Medium w-100 rounded'"
              formControlName="lastMenstrualPeriod" placeholder="{{'SELECT_DATE' | translate}}" dateFormat="dd M yy">
            </p-calendar>
          </div>
          <div *ngIf="(form.lastMenstrualPeriod.touched ) && form.lastMenstrualPeriod.invalid"
            class="d-none text-danger fnt-size10px">
            {{'LAST_MENSTRUAL_PERIOD' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group mb-2 position-relative">
          <label class="fnt-size12px">
            {{'EXPECTED_DELIVERY_DATE' | translate}}
            <span class="text-danger d-none">*</span>
          </label>
          <div class="calendar-field w-100">
            <p-calendar [showIcon]="true" [monthNavigator]="true" [yearNavigator]="true" [touchUI]="false"
              yearRange="1922:2030" [inline]="false" panelStyleClass="bg-white border"
              [styleClass]="form.expectedDeliveryDate.touched && form.expectedDeliveryDate.invalid ? 'pcomponent-invalid w-100' : 'w-100'"
              [inputStyleClass]="'form-control fnt-size12px fnt_Medium w-100 rounded'"
              formControlName="expectedDeliveryDate" placeholder="{{'SELECT_DATE' | translate}}" dateFormat="dd M yy">
            </p-calendar>
          </div>
          <div *ngIf="(form.expectedDeliveryDate.touched ) && form.expectedDeliveryDate.invalid"
            class="d-none text-danger fnt-size10px">
            {{'EXPECTED_DELIVERY_DATE' | translate}} {{'IS_REQUIRED' | translate}}
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
