/**
 * Angular imports.
 */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TH_SERVIER_PROCESS } from 'projects/nga-PAP/src/app/constant/app.constant';

@Component({
  selector: 'pap-relevant-laboratory-test-data-form',
  templateUrl: './relevant-laboratory-test-data-form.component.html',
  styleUrls: ['./relevant-laboratory-test-data-form.component.scss']
})
export class RelevantLaboratoryTestDataFormComponent implements OnInit, OnChanges {
  @Input() functionality = '';
  @Input() formData = null;
  @Input() process: string;
  /**
   * Form pediatric report.
   */
  public relevantLabTestForm: FormGroup;
  private disabled: boolean;

  /**
   * setter for the form controls.
   */
  get form(): { [key: string]: AbstractControl } { return this.relevantLabTestForm.controls; }
  get formValidity(): boolean { return this.relevantLabTestForm.valid; }
  get formValues(): { relevantLabTestForm: any; } {
    return {
      relevantLabTestForm: this.relevantLabTestForm.value
    };
  }

  /**
   * Necessary instances.
   */
  constructor(private fb: FormBuilder) { }

  /**
   * Calling initialize form.
   */
  ngOnInit(): void {
    this.disabled = this.functionality === 'view';
    this.initializeForm();
  }

  /**
   * to detect changes on incoming form values
   * @param changes changes on formData
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.formData) {
      this.initializeForm();
    }
  }

  /**
   * Initialize the form.
   */
  initializeForm(): void {
    this.relevantLabTestForm = this.fb.group({
      possibleRisk: new FormControl(
        {value : this.formData?.possibleRisk ? this.formData?.possibleRisk : '',
          disabled: this.disabled},
        [Validators.maxLength(500)])
    });
  }

  /**
   * Check if process is for servier flow
   */
  checkForServierProcess(): boolean {
    return this.process === TH_SERVIER_PROCESS;
  }
}
