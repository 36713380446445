/**
 * Angular Imports.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

/**
 * Component Imports.
 */
import { SearchDropDownComponent } from './components/drop-down/drop-down.component';

/**
 * Module Imports.
 */
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [SearchDropDownComponent],
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule
  ],
  exports: [SearchDropDownComponent]
})
export class DropDownModule { }
