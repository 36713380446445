import { createAction, props } from '@ngrx/store';

/**
 * Model imports.
 */
import { Signin } from '../../../models/signin.model';
import { ErrorHandler } from '../../../models/responses.model';

/**
 * Load login page.
 */
export const loadLogins = createAction(
  '[Login] Load Logins'
);

/**
 * Verify user login Action.
 */
export const verifyEmailPassword = createAction(
  'Login|[Login Page] login Verify',
  props<{ email: string; password: string }>()
);

/**
 * Set the user credential in store.
 */
export const setUserCredentials = createAction(
  'Login|SET [Login Page] login Verify',
  props<{ authenticationResult: Signin }>()
);

/**
 * Set login error data in store.
 */
export const setLoginError = createAction(
  'ERROR|CATCH [Login page] Login error handler for effects',
  props<{ error: ErrorHandler }>()
);

/**
 * Reset login data in store.
 */
export const resetLoginStore = createAction(
  'Login|SET [Login Page] reset login'
);

/**
 * Load refresh token page.
 */
export const loadRefreshToken = createAction(
  '[Login] Load refreshToken'
);

/**
 * Submit Create Password page.
 */
export const createLoginPassword = createAction(
  '[Create Password Page] Create Login Password',
  props<{ password: string; confirm_password: string; verify_token: string }>()
);

/**
 * Reset create login password
 */
export const resetCreateLoginPassword = createAction(
  'Login|RESET [Create Password Page] reset create password'
);


/**
 * Request Change Password page.
 */
export const requestChangeLoginPassword = createAction(
  '[Request Change Password Page] Change Login Password',
  props<{ email: string; }>()
);
