<div>
  <h5 class="fnt-size18px text-heading-grey mb-3">{{'PEDIATRIC_REPORT_ONLY_BETWEEN_BIRTH_DATA_PRIVACY_LAWS' | translate}}</h5>
  <form [formGroup]="pediatricReportForm">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">{{"AGE_DATE_BIRTH_GESTATIONAL_AGE_CASE_NEONATES" | translate}}<span
              class="text-danger d-none">*</span>
            <span class="fnt-size12px align-self-end">
              {{!form.age.value.length ? 0 :
              form.age.value.length}}/20
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="age" type="text" class="form-control fnt-size14px"
              placeholder="{{'DATE_BIRTH_GESTATIONAL_AGE' | translate}}" [maxlength]="20" />
          </div>
          <div *ngIf="form.age.touched && form.age.invalid" class="d-none text-danger fnt-size12px">
            {{"AGE" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            {{"MAJOR_DEVELOPMENT_PARAMETERS_INFO"| translate}}
            <span class="fnt-size12px align-self-end">
              {{!form.majorDevelopmentalParameters.value.length ? 0 :
              form.majorDevelopmentalParameters.value.length}}/100
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="majorDevelopmentalParameters" type="text" class="form-control fnt-size14px"
              placeholder="{{'MAJOR_DEVELOPMENT_PARAMETERS_PLACEHOLDER' | translate}}" [maxlength]="100" />
          </div>
          <div *ngIf="form.majorDevelopmentalParameters.touched && form.majorDevelopmentalParameters.invalid"
            class="d-none text-danger fnt-size12px">
            {{"MAJOR_DEVELOPMENT_PARAMETERS" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            {{"BIRTH_HISTORY_PREMATURITY_TYPE_DELIVERY_INFO" | translate}}
            <span class="fnt-size12px align-self-end">
              {{!form.birthHistory.value.length ? 0 :
              form.birthHistory.value.length}}/100
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="birthHistory" type="text" class="form-control fnt-size14px"
              placeholder="{{'ENTER_BIRTH_HISTORY' | translate}}" [maxlength]="100" />
          </div>
          <div *ngIf="form.birthHistory.touched && form.birthHistory.invalid" class="d-none text-danger fnt-size12px">
            {{"BIRTH_HISTORY" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label class="mb-1 text-secondary-grey fnt-size14px d-flex justify-content-between">
            {{"METHOD_USED_DETERMINE_DOSAGE_TREATMENT_COMPLIANCE" | translate}}
            <span class="fnt-size12px align-self-end">
              {{!form.dosageCalculationMethod.value.length ? 0 :
              form.dosageCalculationMethod.value.length}}/100
            </span>
          </label>
          <div class="position-relative">
            <input formControlName="dosageCalculationMethod" type="text" class="form-control fnt-size14px"
              placeholder="{{'DOSAGE_CALCULATION_METHOD_PLACEHOLDER' | translate}}" [maxlength]="100" />
          </div>
          <div *ngIf="form.dosageCalculationMethod.touched && form.dosageCalculationMethod.invalid"
            class="d-none text-danger fnt-size12px">
            {{"DOSAGE_CALCULATION_METHOD" | translate}} {{ "IS_REQUIRED" | translate }}
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
