/**
 * Angular imports.
 */
import { Component, Input } from '@angular/core';

/**
 * Service imports.
 */
import { TranslateService } from '@ngx-translate/core';

/**
 * This is the common language selector component for multiple projects.
 */
@Component({
  selector: 'dngx-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css']
})
export class LanguageSelectorComponent {
  /**
   * Get language list.
   */
  @Input() languages;

  /**
   * Used to store the already selected language by value.
   */
  public selectedLanguage = localStorage.getItem('lang') ?? 'en';

  /**
   * Create necessary instances.
   */
  constructor(public translate: TranslateService) { }

  /**
   * Used to select and store the language in localstorage.
   */
  onLangChange(event): void {
    this.translate.use(event);
    localStorage.setItem('lang', event);
    location.reload();
  }
}
