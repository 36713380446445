/**
 * Angular imports.
 */
import { Component } from '@angular/core';

/**
 * USed to show the chat bot.
 */
@Component({
  selector: 'pap-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.scss']
})
export class AddPatientComponent {

  /**
   * Show the chat bot.
   * Store the pharma code.
   * Show/hide the session modal.
   */
  public showChatBot = true;
  public pharmaCode = localStorage.getItem('pharmaCode');
  public isLeaveSessionModal = false;

  /**
   * Open the session leave modal.
   */
  closeChatBot(): void {
    this.isLeaveSessionModal = true;
  }

  /**
   * Get patient auth.
   */
  getPatientAuth(): boolean {
    return localStorage.getItem('patientAuth') ? true : false;
  }

  /**
   * Reset the chat bot session.
   */
  resetChatbotSession(): void {
    this.showChatBot = false;
    if (this.getPatientAuth) {
      localStorage.removeItem('patientAuth');
    }
    this.isLeaveSessionModal = false;
    setTimeout(() => {
      this.showChatBot = true;
    }, 1000);
  }
}
