import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Table,TableModule } from 'primeng/table';
import * as attendantAction from '../../../store/actions/attendent/attendent.action'
import * as attendant from '../../../store/selectors/attendent/attendent.selector'
import { Subscription } from 'rxjs';
import { Paginator } from 'primeng/paginator';
import { PharmaNameService } from '../../../services/pharma/pharma-name.service';
import * as LoginSelector from '../../../store/selectors/login/login.selectors';
import { PANEL_TITLE, USER_TYPE } from '../../../constant/app.constant';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'pap-attendant-list',
  templateUrl: './attendant-list.component.html',
  styleUrls: ['./attendant-list.component.scss']
})
export class AttendantListComponent implements OnInit {
  @ViewChild('paginator', { static: false }) paginator?: Paginator;
  public pharma: string;
  public attendantListSub: Subscription;
  public attendantList:any;
  public hasListData = false;
  public showdropdownflag = false;
  public searchKeyword = '';
  private pharmaNameChangeSub: Subscription;
  public offset = 1;
  isLoading: boolean = true; 
  public showEmptyCard:boolean = false;
  public loginDetailSub: Subscription;
  public idToken: string;
  public userRole: string;
  public userEmail: string;
  public userID: number;
  public title = PANEL_TITLE.DASHBOARD;
  public userLoginRole = USER_TYPE;



  @ViewChild('dt') dataTable !:Table;

   /**
   * Column for scrolable.
   */
    public scrollableCols: any[];
    /**
   * used to do the lazy load on table
   */
  public tableDataLoading: boolean;

  constructor(public translate: TranslateService, private store: Store,public pharmaNameChange: PharmaNameService,) { }

  ngOnInit(): void {
    this.ngAfterViewChecked();
    this.pharma =(localStorage.getItem('pharmaCode')) as string;
    
    this.attendantListSub = this.store.select(attendant.loadAttendantList).subscribe(data => {
      if (data?.length > 0) {
        console.log("data",data)
        this.attendantList = data;
        this.hasListData = true;
        this.isLoading = false; 
        this.showEmptyCard = false;
      }else this.showEmptyCard = true
      this.isLoading = false;

    })
    
    /**
     * for user role
     */
    this.loginDetailSub = this.store.select(LoginSelector.getUserCredential).subscribe(data => {
      if (data) {
        this.idToken = data.accessToken;
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(this.idToken);
        this.userEmail = decodedToken.email;
        this.userRole = decodedToken.role;
        this.userID = decodedToken.sub;
        if (this.userRole === USER_TYPE.APPROVER) {
          this.title = PANEL_TITLE.PAP_APPROVER;
        } else if (this.userRole === USER_TYPE.PAP_SUPERVISOR) {
          this.title = PANEL_TITLE.PAP_SUPERVISOR;
        }
      }
    });

    this.pharmaNameChangeSub = this.pharmaNameChange.changePharmaForPatientListing$.subscribe(data => {
      if(data){
        this.attendantList = null;
        this.pharma =(localStorage.getItem('pharmaCode')) as string;
        this.store.dispatch(attendantAction.attendantListReset());
        this.isLoading = true;
        this.getAttendantList()
        this.showEmptyCard = false;
      }
    });
    this.searchKeyword = '';
  }
    showdropdown():void{
      this.showdropdownflag = true
    }
  
    selectDropdownOption(option: string): void {
      this.showdropdownflag = false;
      if (option === 'edit') {
        this.showdropdownflag = false;
      } else if (option === 'preview') {
        this.showdropdownflag = false;
      }
    }

  public searchData(data): void {
    this.searchKeyword = data.target.value.trim();
    if (this.searchKeyword === '') {
        this.getAttendantList([]);
        this.isLoading = true;
        return;
    }

    if (this.searchKeyword.length < 3) {
        return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    let searchData = [];

    if (!isNaN(Number(this.searchKeyword)) && this.searchKeyword !== "") {
        if (this.searchKeyword.length > 6) {
            searchData = [
                {
                    key: "phone_number",
                    value: this.searchKeyword,
                },
            ];
        } else {
            searchData = [
                {
                    key: "id",
                    value: this.searchKeyword,
                },
            ];
        }
    } else if (emailRegex.test(this.searchKeyword)) {
        searchData = [
            {
                key: "email",
                value: this.searchKeyword,
            },
        ];
    } else if (/[a-zA-Z ]/.test(this.searchKeyword)) {
        const [firstName, ...lastNameParts] = this.searchKeyword.split(" ");
        const lastName = lastNameParts.join(" ").trim();
        searchData = [];
        if (firstName) {
            searchData.push({
                key: "first_name",
                value: firstName,
            });
        }
        if (lastName) {
            searchData.push({
                key: "last_name",
                value: lastName,
            });
        }
    }

    this.tableDataLoading = true;
    this.getAttendantList(searchData);
    this.isLoading = true;
}

  
redirectRegisterPage(){
  // [routerLink]="userRole === userLoginRole.APPROVER ? '/approval/addAttendant' : '/supervisor/addAttendant'"
}


  getAttendantList(searchData?: any): void {
    this.store.dispatch(attendantAction.loadAttendantList({
      pharma_code: this.pharma,
      search: searchData
      
    }));
  }

    /**
   * Initialize the list header.
   */
    ngAfterViewChecked(): void {
      this.scrollableCols = [
        { field: 'doc', header: this.translate.instant('Date of Creation') },
        { field: 'attendant', header: this.translate.instant('Attendant ID') },
        { field: 'attendantName', header: this.translate.instant('Attendant Name') },
        { field: 'attendantNumber', header: this.translate.instant('Mobile Number') },
        { field: 'email', header: 'Email Address' },
        { field: 'action', header: 'Actions'},
      ];
    }

    ngOnDestroy(): void {
    this.attendantListSub.unsubscribe()
    this.store.dispatch(attendantAction.attendantListReset());
    this.showEmptyCard = false;
    }

}
