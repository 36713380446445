import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  /**
   * This pipe is used for highlighting the matched words.
   */
  transform(value: string, search: string): string {
    const regex = new RegExp(search, 'gi');
    /**
     * Inserting bold tag into matching words.
     */
    return search ? value.replace(regex, match => `<b>${match}</b>`) : value;
  }

}
