<div class="aeReportList">
  <!-- Page Content -->
  <div class="text-center text-red mt-4" *ngIf="this.homeErrorMessages && !aeReportList">{{this.homeErrorMessages}}
  </div>
  <div class="row" *ngIf="userRole !== userLoginRole.PAP_SUPERVISOR">
    <div class="col-10"></div>
    <div class="col-2 mt-4"> 
      <a [routerLink]="userRole === userLoginRole.APPROVER ? '/approval/aeReport/create' : '/supervisor/aeReport/create'"
                class=" btn btn-success cursor-pointer nav-link  fnt-size15px  align-items-center w-75 rounded">
            + {{'CREATE_AE_REPORT' | translate}}
      </a>
    </div>
  </div>
  <div class="page-content pt-4 pl-5 pr-5 pb-1 h-100 " id="content" *ngIf="aeReportList">
    <div class="h-100">
      <div class="row">
        <div class="col-6">
          <p class="pt-2 pb-2 fnt-size14px d-none d-lg-block">
            <span [ngClass]='{"active text-success border-bottom border-success font-weight-bold":activeTab === "PENDING"}' class="pt-2 pb-2 mr-4 text-secondary border-3 cursor-pointer" (click)="changeTab('PENDING')">{{'PENDING' | translate}}
              {{(activeTab === 'PENDING' && aeReportList.data)? '('+aeReportList.data.page.totalCount+')':''}}
            </span>
            <span [ngClass]='{"active text-success border-bottom border-success font-weight-bold":activeTab === "APPROVED"}' class="pt-2 pb-2  mr-4 text-secondary  border-3 cursor-pointer" (click)="changeTab('APPROVED')">{{'APPROVED' | translate}}
              {{(activeTab === 'APPROVED' && aeReportList.data)? '('+aeReportList.data.page.totalCount+')':''}}
            </span>
            <span [ngClass]='{"active text-success border-bottom border-success font-weight-bold":activeTab === "REJECTED"}' class="pt-2 pb-2  mr-4 text-secondary  border-3 cursor-pointer" (click)="changeTab('REJECTED')">{{'REJECTED' | translate}}
              {{(activeTab === 'REJECTED' && aeReportList.data)? '('+aeReportList.data.page.totalCount+')':''}}
            </span>
          </p>
        </div>
        <div class="col-6 userSearch mr-0">
          <div class="row">
            <div class="col-12">
              <span class="p-input-icon-left p-ml-auto position-relative w-100 userSearchInput">
                <i class="pi pi-search userSearchIcon"></i>
                <input class="w-100" pInputText type="text" [(ngModel)]="searchKeyword" (keyup)="searchData($event)" placeholder="{{'SEARCH' | translate}}" />
              </span>
            </div>
            <div class="col-2 pl-0">
              <img [src]="filterAdded ? 'assets/images/search-filter-icon.svg' : 'assets/images/filter-white.svg'" alt="search filter" class="cursor-pointer" (click)="visibleFilter = true" />
            </div>
          </div>
        </div>
      </div>
      <div class="card recordList h-100 mt-3" style="max-height: calc( 100% - 56px );">
        <p-table [columns]="scrollableCols" [value]="hasListData ? aeReportList.data?.list : []" [scrollable]="true" frozenWidth="0px" scrollHeight="calc(100vh - 255px)" [rows]="10" [paginator]="false" [loading]="tableDataLoading">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th id="" *ngFor="let col of columns">
                {{col.header}}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr >
              <td colspan="7" class="text-left">{{'NO_RECORD_FOUND' | translate}}</td>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-customer let-columns="columns">
            <tr *ngIf="!hasListData">
              <td colspan="7" class="text-left">{{'NO_RECORD_FOUND' | translate}}</td>
            </tr>
            <tr *ngIf="hasListData" class="trBorder">
              <td class="font-weight-normal" *ngFor="let col of columns">
                <span *ngIf="col.field === 'id'">
                  {{customer?.id}}
                </span>
                <span *ngIf="col.field === 'doc'">
                  {{customer?.dateOfCreation * 1000 | date:'dd MMM yyyy, hh:mm a'}}
                </span>
                <span class="medium-font-weight" *ngIf="col.field === 'patient'">
                  {{customer?.patientId}}
                </span>
                <span *ngIf="col.field === 'program'">
                  {{customer?.programName}}
                </span>
                <p *ngIf="col.field === 'status'">
                  <span class="text-00B5D5 fnt_Medium bg-00B5D5-30 radius-20 px-2 py-1" *ngIf="customer?.status === aeReportStatus.REVIEWED"> New </span>
                  <span class="text-E2B93B fnt_Medium" *ngIf="customer?.status === aeReportStatus.RE_REVIEW"> To be reviewed by DCPM </span>
                  <span class="text-27AE60 fnt_Medium" *ngIf="customer?.status === aeReportStatus.APPROVED"> Approved </span>
                  <span class="text-DC1313 fnt_Medium" *ngIf="customer?.status === aeReportStatus.REJECTED"> Rejected </span>
                </p>
                <span *ngIf="col.field === 'action'">
                    <button (click)="navigateToAeReportDetail(customer?.id, customer?.status)"
                      class="btn btn-success fnt-size10px">{{'VIEW_DETAIL' | translate}}
                    </button>
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="position-relative">
          <p-paginator [hidden]="tableDataLoading" #paginator *ngIf="aeReportList && aeReportList.data && hasListData && showPagination" [rows]="limit" [totalRecords]="aeReportList.data.page.totalCount" [alwaysShow]="false" (onPageChange)="setMyPagination($event)" [styleClass]="tableDataLoading ? 'pointer-events-none' : ''"></p-paginator>
        </div>
      </div>
    </div>
  </div>
</div>