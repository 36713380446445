/**
 * Angular imports.
 */
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

/**
 * Rxjs import.
 */
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

/**
 * Ngrx store imports.
 */
import { Store } from '@ngrx/store';
import * as LoginSelector from '../store/selectors/login/login.selectors';
import * as LoginAction from '../store/actions/login/login.actions';

/**
 * Jwt import.
 */
import { JwtHelperService } from '@auth0/angular-jwt';

/**
 * Constant import.
 */
import { USER_TYPE } from '../constant/app.constant';


/**
 * This guard is used to make the url accessable if user is logged-in state.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(LoginSelector.getUserCredential)
      .pipe(
        take(1),
        map(data => {
          if (!data && !localStorage.getItem('login')) {
            this.router.navigate(['/']);
            return false;
          } else {
            if (localStorage.getItem('login') && !data) {
              const idToken = JSON.parse(localStorage.getItem('login'));
              const helper = new JwtHelperService();
              const decodedToken = helper.decodeToken(idToken.accessToken);
              if (decodedToken.role === USER_TYPE.APPROVER) {
                if (location.pathname.indexOf('/approval') === -1) {
                  this.router.navigate(['/approval']);
                }
              } else if (decodedToken.role === USER_TYPE.STORE_OPERATOR) {
                if (location.pathname.indexOf('/store') === -1) {
                  this.router.navigate(['/store']);
                }
              }
              else if (decodedToken.role === USER_TYPE.PAP_SUPERVISOR) {
                if (location.pathname.indexOf('/supervisor') === -1) {
                  this.router.navigate(['/supervisor']);
                }
              }
              this.store.dispatch(LoginAction
                .setUserCredentials({ authenticationResult: JSON.parse(localStorage.getItem('login')) }));
            }
          }
          return true;
        })
      );
  }

  /**
   * Created all required instances.
   */
  constructor(private router: Router, private store: Store) { }

}
