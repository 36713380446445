
/**
 * Angular imports.
 */
import { Component, OnInit, AfterContentChecked, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { NgForm} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

// Ngrx Imports.
import { Store } from '@ngrx/store';
import * as LoginAction from '../../../store/actions/login/login.actions';
import * as LoginSelector from '../../../store/selectors/login/login.selectors';
/**
 * Constant import.
 */
import { LANGUAGES } from '../../../constant/app.constant';

// Model import.
import { ErrorHandler } from '../../../models/responses.model';

// Rxjs Import.
import { Subscription } from 'rxjs';

// Interface defined for form data.
export interface RequestData {
  password: string;
  confirm_password: string;
  verify_token: string;
}

@Component({
  selector: 'pap-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit, AfterContentChecked, OnDestroy {

  /**
   * Var used show for selected country value.
   */
  public country = '';

  /**
   * Get logo src.
   */
  public logoSrc = 'assets/images/logo.svg';

  /**
   * Get password eye closed.
   */
  public passwordClosedSrc = 'assets/images/password-eye-closed.svg';

  /**
   * Get password eye closed.
   */
   public tickIconSrc = 'assets/images/password-match-tick.svg';

  /**
   * Password view status.
   */
  public passwordViewStatus = 'password';

  /**
   * Confirm password view status.
   */
    public confirmPasswordViewStatus = 'password';

  /**
   * Confirm password view status.
   */
    public isMatch = false;
  /**
   * User verification token
   */
   public token = '';


  /**
   * Get support language.
   */
  public languages = LANGUAGES;

  /**
   * Error Object
   */
   public errorObj: ErrorHandler;

  /**
   * Error Message
   */
   public errorMessage: string;

  /**
   * Subscription
   */
    public createPasswordErrorSub: Subscription;

  /**
   * Form button status
   */
   public disable = true;


  // This is used to detect error changes from api.
  ngOnInit(): void {
    // Retrieving user verification token from route parameter
    this.token = this.route.snapshot.paramMap.get('token');

    // Retrieving country from query parameter
    this.route.queryParams
      .subscribe(params => {
        this.country = params.ecountryId;
      }
    );
    // Setting country in local storage
    localStorage.setItem('country', this.country);

    this.createPasswordErrorSub = this.store.select(LoginSelector.getLoginError).subscribe(error => {
      this.errorObj = null;
      if (error != null) {
        this.errorObj = error;
        if (this.errorObj.message !== ''){
          this.errorMessage = this.errorObj.message;
        }
      }
    });
  }

  // Functio to display typed password
  togglePasswordView(element: HTMLInputElement): void{
   if (element.id === 'password'){
    if (element.type === 'password'){
      this.passwordViewStatus = 'text';
    }
    else{
      this.passwordViewStatus = 'password';
    }
   }
   else{
    if (element.type === 'password'){
      this.confirmPasswordViewStatus = 'text';
    }
    else{
      this.confirmPasswordViewStatus = 'password';
    }
   }
  }

  // Function to check if password fields are same.
  isEqual(password: string, confirmPassword: string): void{
    if (password !== '' && password === confirmPassword){
      this.isMatch = true;
      this.disable = false;
    }
    else{
      this.isMatch = false;
      this.disable = true;
    }
  }

  // Function to clear form error.
  onFocus(): void{
    this.errorObj = null;
  }

  // Form submit function.
  createLoginPassword(data: RequestData): void{
    data.verify_token = this.token;
    this.store.dispatch(LoginAction.createLoginPassword(data));
  }

  // This is used to detect changes after page load.
  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  // This is used to unsubscribe from error.
  ngOnDestroy(): void {
    if (this.createPasswordErrorSub) {
      this.createPasswordErrorSub.unsubscribe();
    }
  }

  // This is used to initialize instances.
  constructor(private route: ActivatedRoute, private store: Store, private cdRef: ChangeDetectorRef) { }

}
