import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
/**
 * constant imports.
 */
import { PATTERN } from '../../constant/app.constant';

@Component({
  selector: 'pap-otp-submit',
  templateUrl: './otp-submit.component.html',
  styleUrls: ['./otp-submit.component.scss']
})
export class OtpSubmitComponent implements OnInit {
  /**
   * view otp1 child.
   */
  @ViewChild('otp1')
  otp1: ElementRef;
  /**
   * view otp2 child.
   */
  @ViewChild('otp2')
  otp2: ElementRef;
  /**
   * view otp3 child.
   */
  @ViewChild('otp3')
  otp3: ElementRef;
  /**
   * view otp4 child.
   */
  @ViewChild('otp4')
  otp4: ElementRef;

  /**
   * Used for resend otp view counter value.
   */
  public counterValue = 0;
  /**
   * Used for resend otp counter.
   */
  public counter = 0;
  /**
   * Used for resend otp counter interval.
   */
  public interval = 0;
  /**
   * Contain login formGroup.
   */
  public otpForm: FormGroup;
  /**
   * verify otp count.
   */
  public verifyOtpCnt = 0;

  @Output() otpData = new EventEmitter();
  @Output() otpResendData = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    /**
     * Resend otp timer.
     */
    this.counterValue = 20;
    this.counter = 20;
    this.interval = 1000;

    this.otpForm = this.formBuilder.group({
      otp1: [null, [Validators.required]],
      otp2: [null, [Validators.required]],
      otp3: [null, [Validators.required]],
      otp4: [null, [Validators.required]]
    });
  }
  /**
   * Receive counter value.
   */
  receiveCounterValue(event): void {
    this.counterValue = event;
    if (this.counterValue === 0) {
      this.counter = 0;
    }
  }
  /**
   *  Used to auto focus.
   */
  keytab(event, index: number): void {
    const numbers = PATTERN.WHOLE_NUMBER_REGEX;
    if (event.code === 'Backspace' || event.key.match(numbers)) {
      if (index === 1) {
        event.code === 'Backspace' ? this.otp1.nativeElement.focus() : this.otp2.nativeElement.focus();
      } else if (index === 2) {
        event.code === 'Backspace' ? this.otp1.nativeElement.focus() : this.otp3.nativeElement.focus();
      } else if (index === 3) {
        event.code === 'Backspace' ? this.otp2.nativeElement.focus() : this.otp4.nativeElement.focus();
      } else {
        event.code === 'Backspace' ? this.otp3.nativeElement.focus() : this.otp4.nativeElement.focus();
      }
    }
  }
  /**
   * used emit the entered otp.
   */
  public checkValue(): void {
    if (this.otpForm.valid) {
      this.otpData.emit(this.otpForm.value);
    } else {
      this.otpData.emit('');
    }
  }

  /**
   * Navigate to resend otp.
   */
  resendOtp(): void {
    this.verifyOtpCnt++;
    this.counterValue = 20;
    this.counter = 20;
    this.interval = 1000;
    this.otpResendData.emit(true);
  }

}
