<!-- <span class="cursor-pointer pt-2 pb-2" (click)="op.toggle($event)">{{headerTitle}}  <img src={{headerIcon}}></span> -->

<!-- need help popover -->

<!-- <p-overlayPanel class="header__need-help-popup" #op [showCloseIcon]="false" [style]="{width: '330px'}">
  <ng-content></ng-content>
</p-overlayPanel> -->
<div class="position-relative">
  <span class="cursor-pointer" (click)="op.toggle($event)">
    <ng-content></ng-content>
  </span>

  <p-overlayPanel
    #op
    class="header__need-help-popup"
    [styleClass]="popOverCssClass"
    [showCloseIcon]="showCloseIcon"
  >
  <ng-container *ngTemplateOutlet="headerItems">
  </ng-container>
  </p-overlayPanel>
</div>
