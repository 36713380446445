<div [formGroup]="otpForm" class="form-group d-flex gap-1 w-100">
  <div class="d-flex w-75 otpForm">
    <input #otp1 type="text" inputmode="numeric" (keyup)="keytab($event, 1)" dngxDigitOnly formControlName="otp1" autocomplete="off"
      class="form-control otpInputBox text-center pr-2 bg-rolling-grey" required name="otp1" maxlength="1" (input)="checkValue()">

    <input #otp2 type="text" inputmode="numeric" (keyup)="keytab($event, 2)" dngxDigitOnly formControlName="otp2" autocomplete="off"
      class="form-control otpInputBox text-center px-2 bg-rolling-grey" required name="otp2" maxlength="1" (input)="checkValue()">

    <input #otp3 type="text" inputmode="numeric" (keyup)="keytab($event, 3)" dngxDigitOnly formControlName="otp3" autocomplete="off"
      class="form-control otpInputBox text-center px-2 bg-rolling-grey" required name="otp3" maxlength="1" (input)="checkValue()">

    <input #otp4 type="text" inputmode="numeric" (keyup)="keytab($event, 4)" dngxDigitOnly formControlName="otp4" autocomplete="off"
      class="form-control otpInputBox text-center pl-2 bg-rolling-grey" required name="otp4" maxlength="1" (input)="checkValue()">
  </div>
  <div class="w-25"></div>
</div>
<div class="d-flex justify-content-between">
  <div>
    <div class="text-rolling-stone-grey fnt-size14px fnt_Regular" *ngIf="counter > 0 || this.verifyOtpCnt > 3 ">
      {{ "PLEASE_WAIT_FOR_OTP_DELIVERED" | translate }}
    </div>
    <div *ngIf="counter === 0">
      <button type="button" *ngIf="this.verifyOtpCnt < 3" (click)="resendOtp()"
        class="btn text-success fnt-size12px p-0">{{'RESEND_OTP' | translate}}</button>
    </div>
  </div>
  <div>
    <ng-container [dngxTimer]="counter" [interval]="interval" (value)="receiveCounterValue($event)">
      <span *ngIf="counter > 0" class="float-left mr-2">
        <span id="countdown" class="d-flex align-items-center">
          <span class="w-100 fnt-size10px medium-font-weight">
            {{ counterValue }}</span>
          <svg>
            <circle class="greyCircle" r="10" cx="12" cy="12"></circle>
            <circle class="greenCircle" r="10" cx="12" cy="12"></circle>
          </svg>
        </span>
      </span>
    </ng-container>
  </div>
</div>
