<div class="gallary">
  <img *ngIf="showZoomIcon"
    class="zoomIcon"
    [src]="zoomIcon"
    alt="zoom-icon"
    (click)="zoomIdcard = true" />
  <p-galleria
    [(value)]="images"
    [(visible)]="zoomIdcard"
    [circular]="circular"
    [fullScreen]="fullScreen"
    [showItemNavigators]="showItemNavigators"
    [showThumbnails]="showThumbnails"
    [baseZIndex]="10000000"
    [maskClass]="maskClass" >

    <ng-template pTemplate="item" let-item>
      <img [src]="item.source" [alt]="item.alt" />
    </ng-template>

    <ng-template pTemplate="thumbnail" let-item>
      <div class="p-grid p-nogutter p-justify-center">
        <img [src]="item.source" [alt]="item.alt"/>
      </div>
    </ng-template>
  </p-galleria>

  <div *ngIf="images" class="p-grid" >
    <div *ngFor="let image of images; let index = index;"  key="index">
      <img class="cardImg" [src]="image.source" [alt]="image.alt" style="cursor: pointer" (click)="imageClick(index)" />
    </div>
  </div>
</div>
