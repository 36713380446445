/**
 * ngrx store imports
 */
import { createReducer, on } from '@ngrx/store';
import * as PharmaAction from '../../actions/pharma/pharma.actions';
/**
 * model imports
 */
import { PharmaInfoData } from '../../../models/pharma.model';
import { Error, PharmaDetail, DoctorPharmaConsent } from '../../../models/responses.model';

export const pharmaFeatureKey = 'pharma';

export interface State {
  pharmaData: PharmaDetail;
  pharmaInfo: PharmaInfoData;
  error: Error;
  pharmaList: any;
  doctorPharmaConsent: DoctorPharmaConsent;
}

export const initialState: State = {
  pharmaData: null,
  pharmaInfo: null,
  error: null,
  pharmaList: null,
  doctorPharmaConsent: null,
};


export const reducer = createReducer(
  initialState,
  on(PharmaAction.setPharmas , (state, payload) => ({
    ...state,
    pharmaData: payload.data
  })),
  on(PharmaAction.loadPharmasFailure, (state, payload) => ({
    ...state,
    error: payload.error
  })),
  on(PharmaAction.resetError, (state, payload) => ({
    ...state,
    error: initialState.error
  })),
  on(PharmaAction.setPharmaInfo, (state, payload) => ({
    ...state,
    pharmaInfo: payload.data})),
  on(PharmaAction.setUserMappedPharmaList, (state, payload) => ({
    ...state,
    pharmaList: payload.pharmaList
  })),
  on(PharmaAction.setDoctorPharmaConsent, (state, payload) => ({
    ...state,
    doctorPharmaConsent: payload.consent
  })),
  on(PharmaAction.resetUserMappedPharmaList, (state, payload) => ({
    ...state,
    pharmaList: initialState.pharmaList
  }))
);

